/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import { useHttp } from '../../../hooks/useHttp';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/authContext';
import { useHistory } from 'react-router-dom';
import { menuItems } from '../../../assets/mockData/mockData';
import Loader from '../../../common/loader';
import { useAppContext } from '../../../context/AppProvider';
import LiquidButton from '../../../common/liquidButton';
import Toaster from '../../../common/toaster';

const validRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
let timeDelay;

const SignUp = () => {
  const { store } = useAppContext();
  const { selectedLanguage, user } = store;
  const { request, loading, apiUrl } = useHttp();
  const [error, setError] = useState({ type: '', value: null });
  const [open, setOpen] = useState(false);
  const [nameError, setNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [secondPasswordError, setSecondPasswordError] = useState('');
  const [role, setRole] = useState('learner'); // Add role state
  const inputId = ['email-address', 'password', 'firstname', 'lastname'];

  const [form, setForm] = useState({
    email: '',
    password: '',
    firstname: '',
    lastname: '',
    role: 'learner' // Add role to form state
  });

  const [passForm, setPassForm] = useState({
    password: '',
    secondPass: ''
  });

  const navigate = useHistory();

  const changeHandler = (event) => {
    changePassHandler(event);
    setForm({ ...form, [event.target.name]: event.target.value });
    setOpen(true);
  };

  const changePassHandler = (event) => {
    setPassForm({ ...passForm, [event.target.name]: event.target.value });
  };

  const roleChangeHandler = (event) => {
    setRole(event.target.value);
    setForm({ ...form, role: event.target.value });
  };

  useEffect(() => {
    inputId.forEach((element) => {
      if (document.getElementById(`${element}`).value) {
        setForm({
          ...form,
          [document.getElementById(`${element}`).name]: document.getElementById(
            `${element}`
          ).value
        });
      }
    });

    if (error.type) {
      timeDelay = setTimeout(() => {
        setError({ type: '', value: null });
        clearTimeout(timeDelay);
      }, 4000);
    }
  }, [error]);

  const registerHandler = async () => {
    if (
      nameError ||
      lastNameError ||
      emailError ||
      passwordError ||
      secondPasswordError
    ) {
      return;
    }

    try {
      if (passForm.password === passForm.secondPass) {
        if (!checkEmptyObj(form)) {
          const data = await request(
            apiUrl + '/api/auth/register',
            'POST',
            { ...form },
            {},
            navigate
          );

          setError({
            type: 'Passed',
            value: 'Please check your email and confirm it.'
          });
          setOpen(true);
        } else {
          setError({ type: 'Error', value: 'Please enter data' });
        }
      } else {
        setError({ type: 'Error', value: 'Password mismatch' });
      }
    } catch (err) {
      console.log('Error occurred...........', err);
      setError({ type: 'Error', value: err.message });
    }

    setOpen(false);
  };

  const checkEmptyObj = () => {
    let isEmpty = true;
    for (let i in form) {
      if (form[i]) {
        return (isEmpty = false);
      }
    }
    return isEmpty;
  };

  const firstNameValidation = (value) => {
    if (!value) {
      setNameError('First name is required.');
      return;
    }
    setNameError('');
  };

  const lastNameValidation = (value) => {
    if (!value) {
      setLastNameError('Last name is required.');
      return;
    }
    setLastNameError('');
  };

  const emailValidation = (value) => {
    if (!value) {
      setEmailError('Email is required.');
      return;
    }
    if (!value.match(validRegex)) {
      setEmailError('Invalid email address.');
      return;
    }
    setEmailError('');
  };

  const passwordValidation = (value) => {
    if (!value) {
      setPasswordError('Password is required.');
      return;
    }
    setPasswordError('');
  };

  const repeatPasswordValidation = (value) => {
    if (!value) {
      setSecondPasswordError('Repeat password is required.');
      return;
    }
    if (value !== passForm.password) {
      setSecondPasswordError('Repeat password does not match password.');
      return;
    }
    setSecondPasswordError('');
  };

  return (
    <>
      <div className="relative grow min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          {error.type && (
            <Toaster error={error} />
            // <div
            //   className={`border transition ease-in-out smmax:w-3/4 xsmax:w-3/4 duration-100 ${
            //     error.type === 'Passed'
            //       ? 'border-green-400 text-green-700 bg-green-100'
            //       : 'border-red-400 text-red-700 bg-red-100'
            //   } translate-x-16 -mt-16 right-20 absolute flex justify-between px-6 py-3 rounded`}
            //   role="alert"
            // >
            //   <div className="flex flex-col smmin:pr-32">
            //     <strong className="font-bold">
            //       {error.type === 'error' ? error.type : error.type}
            //     </strong>
            //     <span className="block sm:inline">{error.value}</span>
            //   </div>
            //   <span className="justify-end flex pl-3 py-3">
            //     <svg
            //       onClick={() => setOpen(false)}
            //       className={`self-end fill-current h-6 w-6 ${
            //         error.type === 'Passed' ? 'text-green-500' : 'text-red-500'
            //       }`}
            //       role="button"
            //       xmlns="http://www.w3.org/2000/svg"
            //       viewBox="0 0 20 20"
            //     >
            //       <title>Close</title>
            //       <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            //     </svg>
            //   </span>
            // </div>
          )}
          <div>
            <h1 className="mx-auto h-12 font-extrabold text-gray-900 text-5xl text-center">
              Lectio
            </h1>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {menuItems.signUp.info[selectedLanguage]}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600"></p>
          </div>
          <form className="mt-8 space-y-6">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="space-y-3 rounded-md shadow-sm">
              <div>
                <label
                  htmlFor="firstname"
                  className="p-1 text-gray-700 font-bold text-sm"
                >
                  {menuItems.firstName[selectedLanguage]}
                </label>
                <input
                  disabled={loading}
                  id="firstname"
                  name="firstname"
                  type="firstname"
                  required
                  onBlur={(event) => firstNameValidation(event.target.value)}
                  onChange={changeHandler}
                  className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${
                    nameError ? 'border-red-600' : 'border-gray-300'
                  } placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                  placeholder={menuItems.firstName[selectedLanguage]}
                />
                {nameError && (
                  <span className={'text-red-600 text-sm'}>{nameError}</span>
                )}
              </div>
              <div>
                <label
                  htmlFor="lastname"
                  className="p-1 text-gray-700 font-bold text-sm"
                >
                  {menuItems.lastName[selectedLanguage]}
                </label>
                <input
                  disabled={loading}
                  id="lastname"
                  name="lastname"
                  type="lastname"
                  required
                  onBlur={(event) => lastNameValidation(event.target.value)}
                  onChange={changeHandler}
                  className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${
                    lastNameError ? 'border-red-600' : 'border-gray-300'
                  } placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                  placeholder={menuItems.lastName[selectedLanguage]}
                />
                {lastNameError && (
                  <span className={'text-red-600 text-sm'}>
                    {lastNameError}
                  </span>
                )}
              </div>
              <div>
                <label
                  htmlFor="email-address"
                  className="p-1 text-gray-700 font-bold text-sm"
                >
                  {menuItems.email[selectedLanguage]}
                </label>
                <input
                  disabled={loading}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={changeHandler}
                  className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${
                    emailError ? 'border-red-600' : 'border-gray-300'
                  } placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                  placeholder={menuItems.email[selectedLanguage]}
                  onBlur={(event) => emailValidation(event.target.value)}
                />
                {emailError && (
                  <span className={'text-red-600 text-sm'}>{emailError}</span>
                )}
              </div>
              <div className="flex space-x-3">
                <div className="w-1/2">
                  <label
                    htmlFor="password"
                    className="p-1 text-gray-700 font-bold text-sm"
                  >
                    {menuItems.password[selectedLanguage]}
                  </label>
                  <input
                    disabled={loading}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={changeHandler}
                    className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${
                      passwordError ? 'border-red-600' : 'border-gray-300'
                    } placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                    placeholder={menuItems.password[selectedLanguage]}
                    onBlur={(event) => passwordValidation(event.target.value)}
                  />
                  {passwordError && (
                    <span className={'text-red-600 text-sm'}>
                      {passwordError}
                    </span>
                  )}
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="secondPass"
                    className="p-1 text-gray-700 font-bold text-sm"
                  >
                    {menuItems.repeatPassword[selectedLanguage]}
                  </label>
                  <input
                    disabled={loading}
                    id="secondPass"
                    name="secondPass"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={changeHandler}
                    onBlur={(event) =>
                      repeatPasswordValidation(event.target.value)
                    }
                    className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${
                      secondPasswordError ? 'border-red-600' : 'border-gray-300'
                    } placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                    placeholder={menuItems.repeatPassword[selectedLanguage]}
                  />
                  {secondPasswordError && (
                    <span className={'text-red-600 text-sm'}>
                      {secondPasswordError}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="role"
                  className="p-1 text-gray-700 font-bold text-sm"
                >
                  {menuItems.role[selectedLanguage]}
                </label>
                <select
                  id="role"
                  name="role"
                  value={role}
                  onChange={roleChangeHandler}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                >
                  <option value="learner">
                    {menuItems.roleTypes.student[selectedLanguage]}
                  </option>
                  <option value="school">
                    {' '}
                    {menuItems.roleTypes.teacher[selectedLanguage]}
                  </option>
                </select>
              </div>
            </div>

            <div className="flex items-center w-full justify-between">
              <div className="text-sm"></div>
              <Link to={'/signin'} className="text-sm">
                <p className="font-medium self-end text-indigo-600 mr-5 hover:text-indigo-500">
                  {menuItems.signIn[selectedLanguage]}
                </p>
              </Link>
            </div>

            <div>
              {loading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <div className="flex items-center justify-center ">
                  <LiquidButton
                    label={menuItems.signUp[selectedLanguage]}
                    clickHandler={() => {
                      registerHandler();
                    }}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUp;
