import * as THREE from 'three';

export function transformKeys(event, control) {
  switch (event.key) {
    case 'g':
      control.setSpace(control.space === 'local' ? 'world' : 'local');
      break;

    case 'Shift':
      control.setTranslationSnap(1);
      control.setRotationSnap(THREE.MathUtils.degToRad(15));
      control.setScaleSnap(0.25);
      break;

    case 'w':
      control.setMode('translate');
      break;

    case 'r':
      control.setMode('rotate');
      break;

    case 's':
      control.setMode('scale');
      break;

    case '+':
    case '=':
      control.setSize(control.size + 0.1);
      break;

    case '-':
    case '_':
      control.setSize(Math.max(control.size - 0.1, 0.1));
      break;

    case 'x':
      control.showX = !control.showX;
      break;

    case 'y':
      control.showY = !control.showY;
      break;

    case 'z':
      control.showZ = !control.showZ;
      break;

    case ' ':
      control.enabled = !control.enabled;
      break;

    case 'Escape':
      control.reset();
      break;
  }
}
