import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';

const AuthManager = () => {
  let { path } = useRouteMatch();
  return (
    <div>
      <Link to={`${path}/login`}>LOG IN</Link>
      <br />
      <Link to={`${path}/signup`}>SIGN UP</Link>
    </div>
  );
};

export default AuthManager;
