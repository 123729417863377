/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { Link } from 'react-router-dom';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { withRouter } from 'react-router-dom';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

import styles from './styles.css';
import { VRButton } from '../../../common/VRButton';
import { roundedSquare } from '../../../common/SceneModelGeneral/roundedSquareBg';
import { text3H2 } from './textConstants';
import { text2H2O } from './textConstants';
import { textCH3OH } from './textConstants';
import { textCH3Cl } from './textConstants';
import createTopicName from '../../../common/SceneModelGeneral/createTopicName';
import ControllerInstruction from './../../../common/controllerInstruction/index';
import { DirectionalLightSet } from '../../../common/SceneModelGeneral/directionalLightset';
import { SpotLightSet } from '../../../common/SceneModelGeneral/spotLightSet';
import { loader } from '../../../common/loaderForEnvironment';
import { textToSpeech } from '../../../common/TabletFunctionality/textToSpeech';
import { Tablet } from '../../../common/TabletFunctionality/index';
import { Pagination } from '../../../common/TabletFunctionality/Pagination';
import { playInstruction } from '../../../common/TabletFunctionality/playInstruction';
import { pagintationLerping } from '../../../common/TabletFunctionality/paginationLerping';

// import { DracoDecoderModule } from 'three/examples/js/libs/draco/gltf/draco_decoder';
// import { DracoEncoderModule } from 'three/examples/js/libs/draco/gltf/draco_encoder';
// import { DracoDecoderModule as Decoder_wrapper } from 'three/examples/js/libs/draco/gltf/draco_wasm_wrapper';

class OrganycSubstances extends Component {
  constructor(props) {
    super(props);

    this.lang = props.lang;
    this.containerRef = React.createRef();
    this.materials = {};

    this.state = {
      progress: true,
      loadedFlag: false,
      percent: 0,
      stateFlag: false,
      modelPosition: [],
      modelName: '',
      language: 5,
      lineLengthFlag: false,
      flagFullscreen: false,
      loadingType: props.type
    };
    this.modelNames = [];
    this.modelForScene =
      // 'https://s3.eu-central-1.amazonaws.com/lectio.app/models/organycSubst.glb';
      require('../../../assets/organycSubst.glb');
    const imageBg = require('../../../assets/img/gradientScene.jpeg');
    this.clock = new THREE.Clock();
    this.scene = new THREE.Scene();
    this.scene.background = this.backgroundTexture;
    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    );

    this.camera.position.set(0, 0.8, 4.5);

    this.scene.add(this.camera);

    const texture = new THREE.TextureLoader().load(imageBg);

    this.scene.background = texture;
    this.modelPosZ = 0;
    this.modelPosY = 0;
    this.modelPosX = 0;
    this.lineLength = 0;
    this.positionForCamera = [];
    this.textInstruction = [];

    this.renderer = new THREE.WebGLRenderer({
      antialias: false,
      powerPreference: 'high-performance'
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.ambientLight = new THREE.HemisphereLight(0xffffff);
    DirectionalLightSet(this);
    this.start_x;
    this.start_y;

    roundedSquare(this.scene, props);

    this.raycaster = new THREE.Raycaster();
    this.raycasterMethan = new THREE.Raycaster();

    this.workingMatrix = new THREE.Matrix4();
    this.workingVector = new THREE.Vector3();
    this.origin = new THREE.Vector3();

    this.dolly = new THREE.Object3D();
    this.scene.add(this.dolly);
    this.dummyCam = new THREE.Object3D();
    this.camera.add(this.dummyCam);
    this.loadedMusicBar = true;

    const xInstructionCoord = this.props.name?.controllerCoords?.x
      ? this.props.name.controllerCoords.x
      : 2.1;
    const yInstructionCoord = this.props.name?.controllerCoords?.y
      ? this.props.name.controllerCoords.y
      : 0.7;
    const zInstructionCoord = this.props.name?.controllerCoords?.z
      ? this.props.name.controllerCoords.z
      : 1.5;

    this.ControllerInstruction = new ControllerInstruction({
      x: xInstructionCoord,
      y: yInstructionCoord,
      z: zInstructionCoord
    });
    this.ControllerInstruction.bindInstructionToScene(this.scene);

    this.model;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();
    // this.controls.maxPolarAngle = Math.PI * 0.5;
    // this.controls.minDistance = 1;
    // this.controls.maxDistance = 10;

    this.renderer.setAnimationLoop(this.render.bind(this));

    this.initScene();
    this.setupVr(this.scene);
  }

  initScene() {
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    } else {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    }
    this.loadModel(this.scene);
    this.loadEnvironment(this.room);

    // this.venusLabel = this.createLinkLabel(0, -1, -4, 0.5, 'Instructions');
    // this.scene.add(this.venusLabel);
  }

  loadModel(scene) {
    const draco = new DRACOLoader();
    const self = this;
    const loader = new GLTFLoader();
    draco.setDecoderConfig({ type: 'js' });
    draco.setDecoderPath(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/draco/'
    );
    loader.setDRACOLoader(draco);
    loader.load(
      this.modelForScene,
      function (gltf) {
        scene.add(gltf.scene);
        self.model = gltf.scene;
        self.model.children.map((element) => {
          if (element.name.indexOf('Result') != -1) {
            element.visible = false;
          }
          // if (element.name.indexOf('Resulttext') != -1) {
          //   element.quaternion.x += 0.7;
          //   // element.visible = false;
          // }
          self.modelNames?.push(element.name);
        });
        if (gltf?.animations.length) {
          self.mixer = new THREE.AnimationMixer(gltf.scene);
          const clips = gltf?.animations;
          clips.forEach(function (clip) {
            self?.mixer?.clipAction(clip)?.play();
          });
        }

        self.setState({ ...self.state, progress: false });

        const setTextInScene = () => {
          self.props.text.map((element) => {
            self.scene.remove(self.scene.getObjectByName('text'));
            self.scene.remove(self.scene.getObjectByName('parrent'));
            self.scene.remove(self.scene.getObjectByName('children'));
            self.scene.remove(self.scene.getObjectByName('1'));
            self.scene.remove(self.scene.getObjectByName('2'));
            self.scene.remove(self.scene.getObjectByName('3'));
            self.loadText(self.model, element);
          });
        };

        setTextInScene();
      },
      function (xhr) {
        self.setState({
          ...self.state,
          percent: (xhr.loaded / xhr.total) * 100
        });
      }
    );
  }

  async loadText(scene, text) {
    const loader = new FontLoader();
    const self = this;
    this.textArray = [];
    this.scene.remove(this.scene.getObjectByName('text'));
    this.scene.remove(this.scene.getObjectByName('parrent'));
    this.scene.remove(this.scene.getObjectByName('children'));

    await loader.load(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/helvetiker_regular.typeface.json',
      function (font) {
        var textGeometry = new TextGeometry(text[self.props.lang], {
          font: font,
          size: text.modelSize ? text.modelSize : 0.1,
          height: text.modelHeight ? text.modelHeight : 0.03
        });
        let textMaterial;

        var mesh = new THREE.Mesh(textGeometry, textMaterial);
        scene.add(mesh);
        mesh.textType = true;
        if (self.renderer.xr.isPresenting) {
          mesh.material.color.setHex(0x00d6d9);
        }

        if (self.props.textType != 'static') {
          if (scene.getObjectByName('parrent') && text.modelType == 'parrent') {
            scene.getObjectByName('parrent').name = 'text';
            scene.getObjectByName('children').name = 'text';
            mesh.name = 'parrent';
          } else if (
            text.modelType == 'parrent' &&
            !scene.getObjectByName('parrent')
          ) {
            mesh.name = 'parrent';
          } else if (text.modelType == 'children') {
            mesh.name = 'children';
          } else {
            if (text.modelType) {
              mesh.name = text.modelType;
              if (
                text.modelType === '1' ||
                text.modelType === '2' ||
                text.modelType === '3'
              )
                self.textInstruction.push(mesh);
              text.modelType !== '1'
                ? (mesh.visible = false)
                : (mesh.visible = false);
            } else {
              mesh.name = 'text';
            }
          }

          if (text.modelType === 'children') {
            scene.getObjectByName('parrent').add(mesh);
          }
          if (text.name !== 'loader') {
            self.textArray.push(mesh);
          } else {
            mesh.name = text.name;
            mesh.visible = false;
          }
        }
        if (text.modelCoordsX && text.modelCoordsY && text.modelCoordsZ) {
          mesh.position.set(
            text.modelCoordsX,
            text.modelCoordsY,
            text.modelCoordsZ
          );
        } else {
          mesh.position.set(0, 0, 0);
        }
        if (text.modelRotationX && text.modelRotationY && text.modelRotationZ) {
          mesh.rotation.set(
            text.modelRotationX,
            text.modelRotationY,
            text.modelRotationZ
          );
        } else {
          mesh.rotation.set(0, 0, 0);
        }
      }

      // onError callback
    );
  }

  setupVr(scene) {
    this.renderer.xr.enabled = true;
    const self = this;
    this.controllers = this.buildControllers(scene);
    function onConnected(e) {
      this.gamepad = e.data.gamepad;
      self.updateControllers({
        right: { trigger: true, squeeze: true, side: e.data.handedness },
        left: { trigger: true, squeeze: true, side: e.data.handedness }
      });
    }

    const controllers = [
      this.renderer.xr.getController(0),
      this.renderer.xr.getController(1)
    ];

    controllers.map((element) => {
      element.addEventListener('connected', onConnected);
    });
  }
  loadEnvironment(room) {
    const draco = new DRACOLoader();
    const self = this;
    const loader = new GLTFLoader();
    draco.setDecoderConfig({ type: 'js' });
    const environment = require('../../../assets/EnvironmentWithoutSliderDesk.glb');
    draco.setDecoderPath(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/draco/'
    );

    loader.setDRACOLoader(draco);
    loader.load(
      environment,
      function (gltf) {
        room.add(gltf.scene);
        self.environment = gltf.scene;
        gltf.scene.position.set(0, 1.3, 0);
        gltf.scene.name = 'environment';
        gltf.scene.visible = false;
        if (gltf?.animations.length) {
          self.mixer = new THREE.AnimationMixer(gltf.scene);
          const clips = gltf?.animations;
          clips.forEach(function (clip) {
            self?.mixer?.clipAction(clip)?.play();
          });
        }
      },
      function (xhr) {
        self.setState({
          ...self.state,
          percent: (xhr.loaded / xhr.total) * 100
        });
      },
      function (err) {
        console.log('error========>', err);
      }
    );
  }
  onEnterVr(self) {
    self.model.scale.x = 0.35;
    self.model.scale.y = 0.35;
    self.model.scale.z = 0.35;
    self.room.getObjectByName('environment').visible = true;
  }
  updateControllers(info) {
    const self = this;

    function onSelectStart() {
      if (this.userData.selected && !this.userData.attachedObject) {
        Pagination(this, self);
        if (self.textInstruction[0].visible) {
          playInstruction(this, self, self.props.text[0]);
        } else if (self.textInstruction[1].visible) {
          playInstruction(this, self, self.props.text[1]);
        } else if (self.textInstruction[2].visible) {
          playInstruction(this, self, self.props.text[2]);
        }

        if (
          this.userData.selected.name == 'tabletSoundOn' &&
          self.props.description[self.props.lang] &&
          self.loadedMusicBar
        ) {
          textToSpeech(self);
        } else if (this.userData.selected.name == 'tabletSoundOff') {
          if (self.sound?.isPlaying) {
            self.sound.pause();
            self.loadedMusicBar = true;
          }
        }
      }
      if (
        self.intersectsMolecule.length > 0 &&
        self.intersectsMolecule[0].object.name === 'instructionButton' &&
        !self.intersectsMolecule[0].object.text
      ) {
        self.scene.getObjectByName('1').visible = true;
      }
      this.userData.squeezePressed = true;
      this.selectedItem = this.userData.selected;
      if (
        this.userData.selected &&
        !this.userData.attachedObject &&
        !self.posModelX &&
        !self.posModelY &&
        !self.posModelZ &&
        !this.userData.selected.textType &&
        !self.tablet.getObjectByName(this.userData.selected.name)
      ) {
        self.posModelX = this.userData.selected.position.x;
        self.posModelY = this.userData.selected.position.y;
        self.posModelZ = this.userData.selected.position.z;
        self.rotationModelX = this.userData.selected.rotation.x;
        self.rotationModelY = this.userData.selected.rotation.y;
        self.rotationModelZ = this.userData.selected.rotation.z;
        self.scaleModelX = this.userData.selected.scale.x;
        self.scaleModelY = this.userData.selected.scale.y;
        self.scaleModelZ = this.userData.selected.scale.z;

        this.selectedName = this.userData.selected.name;
        if (
          this.selectedName !== 'tabletRefresh' &&
          this.selectedName !== 'ResultRefreshButton'
        ) {
          this.userData.attachedObject = this.userData.selected;
          this.attach(this.userData.selected);
          self.setState({
            modelName: this.userData.selected.name,
            executed: true
          });
        }

        if (
          this.selectedName === 'tabletRefresh' ||
          this.selectedName === 'ResultRefreshButton'
        ) {
          self.model.getObjectByName('1').visible = true;
          self.model.getObjectByName('2').visible = false;
          self.model.getObjectByName('3').visible = false;
          self.modelNames.map((element) => {
            if (
              self.scene.getObjectByName(element).name.indexOf('Result') != -1
            )
              self.scene.getObjectByName(element).visible = false;
            else {
              self.scene.getObjectByName(element).visible = true;
            }
          });

          self.props.text.map((element) => {
            if (
              element.modelType !== '1' &&
              element.modelType !== '2' &&
              element.modelType !== '3'
            ) {
              self.model.remove(self.model.getObjectByName('text'));
              self.model.remove(self.model.getObjectByName('parrent'));
              self.model.remove(self.model.getObjectByName('children'));

              self.loadText(self.model, element);
            }
          });
          this.children[1].visible = false;
          this.children[0].scale.z = 10;
          self.posModelX = undefined;
          self.posModelY = undefined;
          self.posModelZ = undefined;
          self.posModelX = undefined;
          self.posModelY = undefined;
          self.posModelZ = undefined;
          this.userData.attachedObject = undefined;
          this.userData.selected = undefined;
        }
      }
    }

    function onSelectEnd() {
      // this.children[0].scale.z = 0;
      // this.userData.selectPressed = false;
      // this.userData.selected = undefined;

      this.userData.squeezePressed = false;
      if (this.userData.attachedObject !== undefined) {
        this.remove(this.userData.attachedObject);
        self.model.add(this.userData.attachedObject);
        self.model
          .getObjectByName(self.state.modelName)
          .position.set(self.posModelX, self.posModelY, self.posModelZ);
        self.model
          .getObjectByName(self.state.modelName)
          .rotation.set(
            self.rotationModelX,
            self.rotationModelY,
            self.rotationModelZ
          );
        self.model.getObjectByName(self.state.modelName).scale.x =
          self.scaleModelX;
        self.model.getObjectByName(self.state.modelName).scale.y =
          self.scaleModelY;
        self.model.getObjectByName(self.state.modelName).scale.z =
          self.scaleModelZ;
        this.children[1].visible = false;
        this.children[0].scale.z = 10;
        self.posModelX = undefined;
        self.posModelY = undefined;
        self.posModelZ = undefined;
        self.posModelX = undefined;
        self.posModelY = undefined;
        self.posModelZ = undefined;
        if (
          self.intersectsMolecule.length > 0 &&
          self.intersectsMolecule[0].object !== 'Methan' &&
          self.intersectsMolecule[0].object.visible &&
          !self.intersectsMolecule[0].object.text &&
          this.userData.attachedObject.name === 'Methan'
        ) {
          const intersectedLength =
            self.intersectsMolecule[0].object.name.split('+');

          let textFormula = [];

          if (intersectedLength[intersectedLength.length - 1] === '2H2O') {
            textFormula = text2H2O;
          } else if (
            intersectedLength[intersectedLength.length - 1] === '3H2'
          ) {
            textFormula = text3H2;
          } else if (
            intersectedLength[intersectedLength.length - 1] === 'CH3OH'
          ) {
            textFormula = textCH3OH;
          } else {
            textFormula = textCH3Cl;
          }

          self.modelNames.map((element) => {
            if (self.model.getObjectByName(element))
              self.model.getObjectByName(element).visible = false;

            if (this.getObjectByName(element))
              this.getObjectByName(element).visible = false;
          });

          self.intersectsMolecule[0].object.name.split('+').map((element) => {
            if (self.scene.getObjectByName(`Result${element}`)) {
              self.model.getObjectByName('1').visible = false;
              self.model.getObjectByName('2').visible = false;
              self.model.getObjectByName('3').visible = true;
              self.scene.getObjectByName(`Result${element}`).visible = true;
              self.scene.getObjectByName('ResultRefreshButton').visible = true;
            }
          });

          self.props.text.map(() => {
            self.model.remove(self.model.getObjectByName('text'));
            self.model.remove(self.model.getObjectByName('parrent'));
            self.model.remove(self.model.getObjectByName('children'));
          });
          textFormula.map((element) => {
            self.loadText(self.model, element);
          });
        }
        this.userData.attachedObject = undefined;
        this.userData.selected = undefined;
      }
    }

    function onSqueezeStart() {}

    function onSqueezeEnd() {}

    function onDisconnected() {
      const index = this.userData.index;

      if (self.controllers) {
        const obj = index == 0 ? self.controllers.right : self.controllers.left;

        if (obj) {
          if (obj.controller) {
            const controller = obj.controller;
            while (controller.children.length > 0)
              controller.remove(controller.children[0]);
            self.scene.remove(controller);
          }
          if (obj.grip) self.scene.remove(obj.grip);
        }
      }
    }

    if (info.right !== undefined && info.right.side === 'right') {
      const right = this.renderer.xr.getController(0);

      let trigger = false,
        squeeze = false;

      Object.keys(info.right).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
        if (key.indexOf('squeeze') != -1) squeeze = true;
      });

      if (trigger) {
        right.addEventListener('selectstart', onSelectStart);
        right.addEventListener('selectend', onSelectEnd);
      }

      if (squeeze) {
        right.addEventListener('squeezestart', onSqueezeStart);
        right.addEventListener('squeezeend', onSqueezeEnd);
      }

      right.addEventListener('disconnected', onDisconnected);
    }

    if (info.left !== undefined && info.left.side === 'left') {
      const left = this.renderer.xr.getController(1);

      let trigger = false,
        squeeze = false;

      Object.keys(info.left).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
        if (key.indexOf('squeeze') != -1) squeeze = true;
      });

      if (trigger) {
        left.addEventListener('selectstart', onSelectStart);
        left.addEventListener('selectend', onSelectEnd);
      }

      if (squeeze) {
        left.addEventListener('squeezestart', onSqueezeStart);
        left.addEventListener('squeezeend', onSqueezeEnd);
      }

      left.addEventListener('disconnected', onDisconnected);
    }
  }

  buildControllers() {
    const controllerModelFactory = new XRControllerModelFactory();
    const geometrySphere = new THREE.IcosahedronBufferGeometry(this.radius, 2);

    const sphere = new THREE.Mesh(
      geometrySphere,
      new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.BackSide })
    );
    sphere.scale.set(0.01, 0.01, 0.01);

    const geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, -1)
    ]);

    const line = new THREE.Line(geometry);
    line.name = 'line';
    line.scale.z = 2;
    sphere.visible = false;
    const controllers = [];

    for (let i = 0; i <= 1; i++) {
      const controller = this.renderer.xr.getController(i);

      controller.add(line.clone());
      controller.add(sphere.clone());
      controller.userData.selectPressed = false;

      this.dolly.add(controller);

      controllers.push(controller);

      const grip = this.renderer.xr.getControllerGrip(i);
      grip.add(controllerModelFactory.createControllerModel(grip));

      this.workingMatrix.identity().extractRotation(controller.matrixWorld);

      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);

      controller.children[0].scale.z = 10;

      if (i == 1) {
        this.dolly.add(grip);
      }
    }
    Tablet(this, controllers);

    return controllers;
  }
  handleController(controller) {
    const axes = controller.gamepad?.axes;

    this.intersectsMolecule;

    const speed = 0.03;

    let a = 0;
    let b = 0;

    if (this.model) {
      this.workingMatrix.identity().extractRotation(controller.matrixWorld);
      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);

      if (this.tablet?.children.length > 0) {
        const tabletRefreshInstruction = this.tablet.getObjectByName(
          'tabletInstructionRefresh'
        ).children;
        const tabletDescription =
          this.tablet.getObjectByName('tabletDescription').children;

        this.intersectsMolecule = this.raycaster.intersectObjects(
          [
            ...this.model.children,
            ...this.tablet.children,
            ...tabletRefreshInstruction,
            ...tabletDescription
          ],
          false
        );
      }
      if (
        this.intersectsMolecule.length > 0 &&
        !this.intersectsMolecule[0].object.text &&
        !this.intersectsMolecule[0].object.textType &&
        this.intersectsMolecule[0].object.visible
      ) {
        if (!controller.userData.attachedObject) {
          controller.children[1].visible = true;
          controller.children[1].position.x = controller.children[0].position.x;
          controller.children[1].position.z =
            -this.intersectsMolecule[0].distance + 0.01;
          controller.children[0].scale.z = this.intersectsMolecule[0].distance;
          this.lineLength = this.intersectsMolecule[0].distance;
          controller.userData.selected = this.intersectsMolecule[0].object;
        }
      } else {
        controller.children[0].scale.z = 10;
        controller.children[1].visible = false;
        controller.userData.selected = undefined;
      }
      if (controller.userData.squeezePressed) {
        if (
          this.intersectsMolecule.length > 0 &&
          !controller.userData.attachedObject &&
          this.intersectsMolecule[0].object.visible &&
          !this.intersectsMolecule[0].object.text &&
          !this.intersectsMolecule[0].object.textType &&
          this.intersectsMolecule[0].object.name !== 'instructionButton' &&
          (this.intersectsMolecule[0].object.name.indexOf('Result') === -1 ||
            !this.model.getObjectByName('Methan').visible)
        ) {
          this.lineLength = this.intersectsMolecule[0].distance;
          controller.userData.selected = this.intersectsMolecule[0].object;
          controller.children[1].visible = true;
          if (!controller.userData.attachedObject) {
            controller.children[0].scale.z =
              this.intersectsMolecule[0].distance;
            controller.children[1].position.x =
              controller.children[0].position.x;
            controller.children[1].position.z =
              -this.intersectsMolecule[0].distance + 0.01;
          }
        } else {
          controller.children[0].scale.z = 10;
          controller.children[1].visible = false;
          controller.userData.selected = undefined;
          controller.userData.attachedObject - undefined;
        }
        if (controller.userData.attachedObject) {
          controller.children[1].visible = true;
          controller.children[0].scale.z = this.lineLength;
          // this.setState({ ...this.state, lineLengthFlag: false });
        }
        if (controller.userData.attachedObject) {
          this.methanAttached =
            controller.userData.attachedObject.getObjectByName('Methan');
          if (this.methanAttached) {
            this.model.getObjectByName('1').visible = false;
            this.model.getObjectByName('2').visible = true;

            this.raycasterMethan.ray.origin.setFromMatrixPosition(
              this.methanAttached?.matrixWorld
            );
          }
        }
      }
    }
    //=================> movement <=================

    if (axes?.length) {
      if (axes[2] != 0) {
        a += axes[2];
      }
      if (axes[3] != 0) {
        b += axes[3];
      }
    }

    const quaternion = this.dolly.quaternion.clone();

    if (a !== 0 || b !== 0) {
      // this.dolly.quaternion.copy(this.camera?.quaternion);
      this.dolly.translateZ(b * speed);
      this.dolly.translateX(a * speed);

      this.dolly.quaternion.copy(quaternion);
    }
  }

  componentDidUpdate(prevProps) {
    const self = this;

    if (this.props.lang != prevProps.lang || this.props.lang === 'RO') {
      createTopicName(this.props, this.scene);
    }
    if (this.vrButton?.vrButton && this.model) {
      this.vrButton.vrButton.disabled = false;
    }
    //Changing text mesh on change language
    if (this.props.lang != prevProps.lang) {
      this.props.text.map((element, index) => {
        this.model.remove(this.model.getObjectByName('text'));
        this.model.remove(this.model.getObjectByName('parrent'));
        this.model.remove(this.model.getObjectByName('children'));
        this.model.remove(this.model.getObjectByName('1'));
        this.model.remove(this.model.getObjectByName('2'));
        this.model.remove(this.model.getObjectByName('3'));

        this.loadText(this.model, element, index);
        self.setState({ ...self.state, loadedFlag: true });
      });
    }
  }

  componentDidMount() {
    // this.button = document.getElementById('vrButton');
    this.containerRef?.current?.appendChild(this.renderer.domElement);
    this.loadText(this.room, loader);

    if (!document.getElementById('vrButton')) {
      this.vrButton = new VRButton(this.renderer);
    }

    // if (document.getElementById('vrButton')) {
    //   this.vrButtonHtmlElement = document.getElementById('vrButton');
    //   this.vrButtonHtmlElement.style.marginLeft = '50px';
    // }
    const self = this;
    createTopicName(this.props, this.scene);

    // Adding text mesh on mount component

    //When user turn on the VR mode.
    self.renderer.xr.addEventListener('sessionstart', function () {
      SpotLightSet(self);
      self.onEnterVr(self);
      self.textArray.forEach((element) => {
        element.material.color.setHex(0x00d6d9);
      });
      self.vrButton.vrButton.disabled = false;
      self.model.getObjectByName('1').visible = true;

      self.model.position.set(0, 0.6, 1.4);
      self.scene.remove(self.directionalLight1);
      self.scene.remove(self.directionalLight2);
      self.scene.remove(self.directionalLight3);
      self.scene.remove(self.directionalLight4);
      self.scene.remove(self.directionalLight5);
      self.scene.remove(self.directionalLight6);

      self.scene.getObjectByName('instructionPanel').visible = true;
      if (self.props.url.split('/').indexOf('fullscreen') == -1) {
        self.props.history.push(self.link);
      }
      self.dolly.add(self.camera);
    });

    //When user turn off the VR mode.
    self.renderer.xr.addEventListener('sessionend', function () {
      self.scene.getObjectByName('instructionPanel').visible = false;
      if (self.sound?.isPlaying) self.sound.pause();
      self.vrButton.vrButton.disabled = false;
      self.room.getObjectByName('environment').visible = false;
      DirectionalLightSet(self);
      self.scene.remove(self.spotLight);
      self.scene.remove(self.spotLight2);
      self.scene.remove(self.centerPartSpotLightTarger);
      self.scene.remove(self.directionalLight);

      self.textArray.forEach((element) => {
        element.material.color.setHex(0xffffff);
      });
      self.camera.position.set(0, 1.6, 4);
      self.model.position.set(0, 0, 0);
      self.model.scale.x = 1;
      self.model.scale.y = 1;
      self.model.scale.z = 1;

      self.model.getObjectByName('1').visible = false;
      self.model.getObjectByName('2').visible = false;
      self.model.getObjectByName('3').visible = false;
      self.model.getObjectByName('Methan').visible = true;
      self.modelNames.map((element) => {
        if (self.model.getObjectByName(element).name.indexOf('Result') != -1)
          self.model.getObjectByName(element).visible = false;
        else {
          self.model.getObjectByName(element).visible = true;
        }
      });

      self.dolly.remove(self.camera);
      self.dolly.position.z = 1.5;
      self.dolly.position.y = 1.6;
      let adminPreviewContainer = document.getElementById('previewContainer');
      if (self.props.onsessionendAdmin && adminPreviewContainer) {
        adminPreviewContainer.appendChild(self.vrButton.vrButton);
      }
    });
    window.addEventListener('resize', this.resize());
  }

  componentWillUnmount() {
    this.containerRef?.current?.removeChild(this.renderer.domElement);
    window.removeEventListener('resize', this.resize);

    !this.props.isPreview && this?.vrButton?.hideEnterVR();
    Object.keys(this).forEach((key) => {
      // Recursively call dispose() if possible.
      if (
        typeof this[key]?.dispose === 'function' &&
        this[key].type != 'Scene'
      ) {
        this[key].dispose();
      }
      // Remove any reference.
      this[key] = null;
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.room.name = 'room';
    this.lang = nextProps.lang;

    //Replacing text from scene after translate
    if (nextProps.lang !== this.props.lang) {
      this.props.text.map(() => {
        this.model.remove(this.model.getObjectByName('text'));
        this.model.remove(this.model.getObjectByName('parrent'));
        this.model.remove(this.model.getObjectByName('children'));
      });

      return true;
    }

    //Setting coordinates for scene (model)
    if (
      this.props.roomCoordsZ &&
      this.props.roomCoordsX &&
      this.props.roomCoordsY
    ) {
      this.room.position.set(
        this.props.roomCoordsX,
        this.props.roomCoordsY,
        this.props.roomCoordsZ
      );
    } else {
      this.room.position.set(0, 0, 0);
    }
    //Setting rotation coords for rotation (model)
    if (
      this.props.roomRotationZ &&
      this.props.roomRotationX &&
      this.props.roomRotationY
    ) {
      this.room.rotation.set(
        this.props.roomRotationX,
        this.props.roomRotationY,
        this.props.roomRotationZ
      );
    }
    //Setting scaling for room (model)
    if (
      this.props.roomScaleZ &&
      this.props.roomScaleX &&
      this.props.roomScaleY
    ) {
      this.room.scale.set(
        this.props.roomScaleX,
        this.props.roomScaleY,
        this.props.roomScaleZ
      );
    }
    //Setting camera position in scene

    //Setting coord for text in scene

    this.scene.add(this.room);

    //Setting loading
    if (nextProps.type !== this.state.loadingType) {
      this.setState({
        ...this.state,
        loadingType: nextProps.type
      });
      return true;
    }

    if (this.state.progress !== nextState) {
      return true;
    }
    if (nextProps.z !== this.props.z) {
      return true;
    } else {
      return false;
    }
  }

  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  render() {
    this.controls.update();
    if (this.renderer.xr.isPresenting && this.positionForCamera.length < 4) {
      this.positionForCamera.push(this.camera.position);

      if (this.positionForCamera[2]?.y) {
        this.dolly.position.y = -this.positionForCamera[2].y + 0.8;
        this.dolly.position.z = 3.5;
      }
    }
    this.renderer.render(this.scene, this.camera);
    const dt = this.clock.getDelta();
    pagintationLerping(this);

    this.textArray?.forEach((element) => {
      if (element.name === 'text' || element.name == 'parrent')
        element.lookAt(this.camera.position);
    });
    if (this.controllers) {
      const self = this;
      this.controllers.forEach((controller, index) => {
        self.handleController(controller, dt, index);
      });
    }

    this.mixer?.update(dt);

    return (
      <div ref={this.containerRef} className={`${styles.sceneContainer}`}>
        {this.props.isPreviewContainer && this.state.flagFullscreen ? (
          <Link
            onClick={this.updateState}
            className={
              'px-2 absolute py-2 bg-gray-500 border-2 border-white rounded-lg text-center hover:bg-gray-600 hover hover:border-gray-100 top-5 right-5 text-white '
            }
          >
            {' '}
            <svg
              className={'self-end fill-current h-6 w-6 '}
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Exit Fullscreen</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </Link>
        ) : null}

        {this.state.progress && (
          <div
            className={
              'w-full h-full bg-black flex items-center flex-col justify-center absolute opacity-50'
            }
          >
            <p className="text-white opacity-100 font-gothic text-lg mb-2 text-center">
              Loading...
            </p>
            <div className="w-32 h-1 opacity-100 rounded-lg bg-slate-600 ">
              {this.state.percent != 0 ? (
                <div
                  style={{ width: Math.ceil(this.state.percent) + '%' }}
                  className={' rounded-full opacitiy-90 h-full bg-cyan-500'}
                ></div>
              ) : (
                <div className={'w-full h-full bg-gray-500'}></div>
              )}
            </div>
          </div>
        )}
      </div>
    );
    // }
  }
}

export default withRouter(OrganycSubstances);
