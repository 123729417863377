import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/AppProvider';
export default function helpSupportTopicCard(props) {
  const {
    helpSupportParagraph,
    helpSupportInstructions,
    helpSupportSteps,
    helpSupportName,
    helpSupportIcon,
    helpSupportId,
    childToParent
  } = props;
  const { store } = useAppContext();
  const { selectedLanguage } = store;
  return (
    <div>
      {helpSupportId !== 't1' ? (
        <a
          href="#detailed"
          onClick={() =>
            childToParent({
              helpSupportId,
              helpSupportName,
              helpSupportParagraph,
              helpSupportInstructions,
              helpSupportSteps
            })
          }
          className={
            'columns-12 m-2 h-72 w-72  justify-center rounded-3xl border-close-blue border-2  bg-top bg-no-repeat bg-cover flex flex-col overflow-hidden  '
          }
        >
          <a
            href="#detailed"
            className="flex flex-col justify-center mx-auto items-center"
          >
            <div className={'h-8 w-8 bg-white self-center m-5'}>
              <FontAwesomeIcon icon={helpSupportIcon} size="2x" />
            </div>
            <p className={'font-gothic'}>{helpSupportName[selectedLanguage]}</p>
          </a>
        </a>
      ) : (
        <Link
          to={'/contactUs'}
          onClick={() =>
            childToParent({
              helpSupportId,
              helpSupportName,
              helpSupportParagraph,
              helpSupportInstructions,
              helpSupportSteps
            })
          }
          className={
            'columns-12 m-2 h-72 w-72  justify-center rounded-3xl border-close-blue border-2  bg-top bg-no-repeat bg-cover flex flex-col overflow-hidden  '
          }
        >
          <div className="flex flex-col justify-center mx-auto items-center">
            <div className={'h-8 w-8 bg-white self-center m-5'}>
              <FontAwesomeIcon icon={helpSupportIcon} size="2x" />
            </div>
            <p className={'font-gothic'}>{helpSupportName[selectedLanguage]}</p>
          </div>
        </Link>
      )}
    </div>
  );
}
