export const loader = {
  EN: 'Loading...',
  RO: 'Se incarca...',
  modelSize: 0.08,
  modelCoordsX: -0.4,
  modelCoordsY: 0.7,
  modelCoordsZ: 1.8,
  textScaleInVrX: 1,
  textScaleInVrY: 1,
  textScaleInVrZ: 1,

  name: 'loader'
};
