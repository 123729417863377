import { socket } from '../../../common/socketConnection';

const NextModel = (self) => {
  if (self.blockerForLerp > 1) {
    if (!self.resetedlerpingUp) {
      self.curModel--;
    }
    socket.emit('lerpingUp', {
      resetedlerpingUp: true,
      roomId: socket.room,
      curModel: self.curModel
    });
    self.resetedlerpingUp = true;
  }
};
export default NextModel;
