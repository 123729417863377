/* eslint-disable no-inner-declarations */
/* eslint-disable no-loss-of-precision */
import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry';
import styles from './styles.css';
import { VRButton } from '../../../common/VRButton';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { Link } from 'react-router-dom';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { withRouter } from 'react-router-dom';
import { roundedSquare } from '../../../common/SceneModelGeneral/roundedSquareBg';
import createTopicName from '../../../common/SceneModelGeneral/createTopicName';
import ControllerInstruction from './../../../common/controllerInstruction/index';
import { calcFormula } from './formula';
import { textToSpeech } from '../../../common/TabletFunctionality/textToSpeech';
import { Tablet } from '../../../common/TabletFunctionality/index';
import { Pagination } from '../../../common/TabletFunctionality/Pagination';
import { pagintationLerping } from '../../../common/TabletFunctionality/paginationLerping';
import { playInstruction } from '../../../common/TabletFunctionality/playInstruction';

class GeoFigures extends Component {
  constructor(props) {
    super(props);
    this.font;
    this.lang = props.lang;
    this.containerRef = React.createRef();
    this.materials = {};

    this.state = {
      progress: false,
      loadedFlag: false,
      removed: false,
      percent: 0,
      stateFlag: false,
      modelPosition: [],
      modelName: '',
      language: 5,
      flagFullscreen: false,
      loadingType: props.type,
      executed: false
    };

    this.localIntersectPos = new THREE.Vector3();

    const imageBg = require('../../../assets/img/gradientScene.jpeg');
    this.clock = new THREE.Clock();
    this.scene = new THREE.Scene();
    this.scene.background = this.backgroundTexture;
    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    );
    this.onmousedownFlag = false;
    this.curve = new THREE.CatmullRomCurve3([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(5, 0, 0),
      new THREE.Vector3(1, 2.15, 0),
      new THREE.Vector3(0, 0, 0)
    ]);

    this.loadedMusicBar = true;

    const points = this.curve.getPoints(3);
    const geometryTriangle = new THREE.BufferGeometry().setFromPoints(points);
    const materialTrinagle = new THREE.LineBasicMaterial({ color: 0xffffff });

    const Triangle = new THREE.Line(geometryTriangle, materialTrinagle);
    Triangle.scale.x = 0.4;
    Triangle.scale.y = 0.38;
    Triangle.scale.z = 0.38;
    Triangle.position.set(-1, 0, 0);

    // 360 full circle will be drawn clockwise
    const geometrySphere = new THREE.IcosahedronBufferGeometry(this.radius, 2);

    this.planeForIntersect = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(3, 3),
      new THREE.MeshBasicMaterial({
        color: 0x000000,
        opacity: 0,
        transparent: true
      })
    );

    this.curve = new THREE.CatmullRomCurve3([
      new THREE.Vector3(-1.47, -1.5, 0),
      new THREE.Vector3(1.47, -1.5, 0),
      new THREE.Vector3(1.47, 1.5, 0),
      new THREE.Vector3(-1.47, 1.5, 0),
      new THREE.Vector3(-1.47, -1.5, 0)
    ]);

    const borderPoints = this.curve.getPoints(4);
    const geometryBorder = new THREE.BufferGeometry().setFromPoints(
      borderPoints
    );
    const materialBorder = new THREE.LineBasicMaterial({ color: 0xffffff });

    const border = new THREE.Line(geometryBorder, materialBorder);
    this.scene.add(border);
    this.scene.add(this.planeForIntersect);
    this.planeForIntersect.position.set(0, 0, -0.1);
    this.planeForIntersect.name = 'planeForIntersect';
    this.pointToKeepOnTriangle = new THREE.Mesh(
      geometrySphere,
      new THREE.MeshBasicMaterial({ color: 0x00f6af, side: THREE.DoubleSide })
    );
    this.pointToKeepOnTriangle.scale.set(0.04, 0.04, 0.04);
    this.pointToKeepOnTriangle.name = 'sphereToHold';
    const innerRadius = 1;
    const outerRadius = 1;
    const segments = 60;
    const geometryCircle = new THREE.RingBufferGeometry(
      innerRadius,
      outerRadius,
      segments
    );
    this.mouse = new THREE.Vector2();

    this.intersectsMouseModel;

    this.line = new THREE.Line(geometryCircle, materialTrinagle);
    Triangle.name = 'triangle';
    this.scene.add(this.pointToKeepOnTriangle);
    const geometry = new THREE.PlaneGeometry(0.2, 0.2);
    const material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide
    });
    const plane = new THREE.Mesh(geometry, material);
    plane.rotation.set(0, 0, 1.1);
    plane.position.set(1.045, 2.009, 0);
    plane.name = 'degreesSquare';
    Triangle.add(plane);
    this.line.add(Triangle);
    this.scene.add(this.line);
    this.WorldCoordOfTopVertice = new THREE.Vector3();
    this.objectForGetWorldCoordOfTopVertice = new THREE.Object3D();

    Triangle.add(this.objectForGetWorldCoordOfTopVertice);

    this.positionsCircle = this.line.geometry.attributes.position.array;
    this.positionsTriangle =
      this.line.children[0].geometry.attributes.position.array;

    this.pointToKeepOnTriangle.position.set(-0.6, 0.8, 0);

    this.camera.position.set(0, 0.8, 3);

    this.scene.add(this.camera);
    const texture = new THREE.TextureLoader().load(imageBg);

    this.scene.background = texture;
    this.modelPosZ = 0;
    this.modelPosY = 0;
    this.modelPosX = 0;
    this.lineLength = 0;
    this.maxHeight = 0;
    this.renderer = new THREE.WebGLRenderer({
      antialias: false,
      powerPreference: 'high-performance'
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.ambientLight = new THREE.HemisphereLight(0xffffff);
    this.scene.add(this.ambientLight);
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight.position.set(3, 3, 3).normalize();
    this.directionalLight2 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight2.position.set(5, 5, 5).normalize();
    this.directionalLight3 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight3.position.set(5, 5, -5).normalize();
    this.directionalLight4 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight4.position.set(-7, -5, -5).normalize();
    this.directionalLight5 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight5.position.set(-5, 0, 0).normalize();
    this.directionalLight6 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight6.position.set(0, -5, 0).normalize();

    this.scene.add(this.directionalLight);
    this.scene.add(this.directionalLight2);
    this.scene.add(this.directionalLight3);
    this.scene.add(this.directionalLight4);
    this.scene.add(this.directionalLight5);
    this.scene.add(this.directionalLight6);
    this.start_x;
    this.start_y;

    roundedSquare(this.scene, props);
    this.globalSelf = this;

    this.raycaster = new THREE.Raycaster();
    this.workingMatrix = new THREE.Matrix4();
    this.workingVector = new THREE.Vector3();
    this.origin = new THREE.Vector3();

    this.dolly = new THREE.Object3D();
    this.dolly.position.x = 0;
    this.dolly.position.z = 6;
    this.scene.add(this.dolly);
    this.dummyCam = new THREE.Object3D();
    this.camera.add(this.dummyCam);

    const xInstructionCoord = this.props.name?.controllerCoords?.x
      ? this.props.name.controllerCoords.x
      : 1.7;
    const yInstructionCoord = this.props.name?.controllerCoords?.y
      ? this.props.name.controllerCoords.y
      : 0.5;
    const zInstructionCoord = this.props.name?.controllerCoords?.z
      ? this.props.name.controllerCoords.z
      : 3.5;

    this.ControllerInstruction = new ControllerInstruction({
      x: xInstructionCoord,
      y: yInstructionCoord,
      z: zInstructionCoord
    });
    this.ControllerInstruction.bindInstructionToScene(this.scene);

    this.model;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();

    this.renderer.setAnimationLoop(this.render.bind(this));

    this.initScene();
    this.setupVr(this.scene);
  }

  initScene() {
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    } else {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    }
    // this.uploadFont();
  }
  mouseEventMouseUp() {
    if (this.intersectsMouseModel.length > 0) {
      this.controls.enabled = true;
      let controller = this.controllers.find(
        (element) => element.userData.selected
      );
      if (controller) controller.userData.squeezePressed = false;
      if (this.intersectsMouseModel[0].object.name === 'planeForIntersect') {
        this.scene.getObjectByName('sphereToHold').scale.set(0.04, 0.04, 0.04);

        controller.userData.attachedObject = undefined;
        controller.userData.selected = undefined;
        controller.getObjectByName('controllerSphere').visible = false;
        controller.children[0].scale.z = 10;
        this.posModelX = undefined;
        this.posModelY = undefined;
        this.posModelZ = undefined;

        this.objectForGetWorldCoordOfTopVertice.position.set(
          this.positionsTriangle[6],
          this.positionsTriangle[7],
          0
        );
        this.objectForGetWorldCoordOfTopVertice.getWorldPosition(
          this.WorldCoordOfTopVertice
        );
        const formulaArray = calcFormula(this);

        this.scene
          .getObjectByName('sphereToHold')
          .position.set(
            this.WorldCoordOfTopVertice.x,
            this.WorldCoordOfTopVertice.y,
            this.WorldCoordOfTopVertice.z
          );

        //  else {
        formulaArray.map((element) => {
          if (element.name === 'cloneA') {
            this.loadText(element, true, true);
          }
        });
        // }
        this.setState({
          executed: true
        });
      }
    } else {
      this.setState({
        executed: false
      });
    }
    this.onmousedownFlag = false;
    this.controls.enabled = true;
  }
  mouseEventMove(e) {
    if (this.onmousedownFlag) {
      const rect = e.target.getBoundingClientRect();
      const contentHeight = this.containerRef.current.offsetHeight;
      const contentWidth = this.containerRef.current.offsetWidth + 300;
      const val = this.scene.getObjectByName('sphereToHold');
      this.mouse.x = ((e.clientX - rect.left) / contentWidth) * 2 - 1; //x position within the element.
      this.mouse.y = -((e.clientY - rect.top) / contentHeight) * 2 + 1; //y position within the element.
      this.onmousedownFlag = true;
      this.raycaster.setFromCamera(this.mouse, this.camera);
      this.intersectsMouseModel = this.raycaster.intersectObject(
        this.scene.getObjectByName('planeForIntersect'),
        false
      );
      const formulaArray = calcFormula(this);

      if (this.intersectsMouseModel.length > 0) {
        val.position.set(
          this.intersectsMouseModel[0].point.x,
          this.intersectsMouseModel[0].point.y,
          0
        );
        //  else {

        this.setState({
          executed: true
        });
        function removeUnnecesarText(self) {
          if (self.scene.getObjectByName('remove')) {
            self.scene.remove(self.scene.getObjectByName('remove'));

            removeUnnecesarText(self);
          }
        }
        removeUnnecesarText(this);
        formulaArray.map((element) => {
          if (
            (element.name === 'remove' ||
              !this.scene.getObjectByName('onceUpload') ||
              !this.scene.getObjectByName('cloneA')) &&
            element.name !== 'cloneA'
          ) {
            this.loadText(element, true, true);
          }
          // while (this.scene.getObjectByName('remove'))
          //   this.scene.remove(this.scene.getObjectByName('remove'));
        });
      }
    }
  }
  mouseEventMouseDown(e) {
    const rect = e.target.getBoundingClientRect();

    const contentHeight = this.containerRef.current.offsetHeight;
    let contentWidth = this.containerRef.current.offsetWidth + 300;

    this.mouse.x = ((e.clientX - rect.left) / contentWidth) * 2 - 1; //x position within the element.
    this.mouse.y = -((e.clientY - rect.top) / contentHeight) * 2 + 1; //y position within the element.
    this.onmousedownFlag = true;
    this.raycaster.setFromCamera(this.mouse, this.camera);

    this.intersectsMouseModel = this.raycaster.intersectObject(
      this.scene.getObjectByName('planeForIntersect'),
      false
    );
    if (this.intersectsMouseModel.length > 0 && this.onmousedownFlag) {
      const triangle = this.scene.getObjectByName('triangle');
      if (triangle) {
        triangle.remove(triangle.getObjectByName('cloneA'));
      }
      this.containerRef.current.addEventListener(
        'mousemove',
        this.mouseEventMove.bind(this)
      );

      this.scene.getObjectByName('B').position.set(-1.1, 0.0001, 0);
      this.scene.getObjectByName('C').position.set(1.05, 0.0001, 0);
      this.controls.enabled = false;
    }
  }
  async uploadFont(element) {
    const loader = new FontLoader();
    const self = this;
    await loader.load(
      // 'https://s3.eu-central-1.amazonaws.com/lectio.app/helvetiker_regular.typeface.json',
      '/helvetiker_regular.typeface.json',
      function (font) {
        self.font = font;
        self.loadText(element);
      }
    );
  }
  loadText(text) {
    if (this.scene.getObjectByName('cloneA')) {
      this.scene.getObjectByName('cloneA').geometry.dispose();
      this.scene.getObjectByName('cloneA').material.dispose();
      this.scene.remove(this.scene.getObjectByName('cloneA'));
    }
    if (this.scene.getObjectByName('remove')) {
      this.scene.getObjectByName('remove').geometry.dispose();
      this.scene.getObjectByName('remove').material.dispose();
    }
    if (this.scene.getObjectByName('onceUpload')) {
      this.scene.getObjectByName('onceUpload').geometry.dispose();
      this.scene.getObjectByName('onceUpload').material.dispose();
      this.scene.remove(this.scene.getObjectByName('text'));
    }

    // const ttfLoader = new TTFLoader();
    // const self = this;

    // ttfLoader.load(
    //   '/didact-gothic.regular.ttf',
    //   (json) => {
    // loader.load(
    //   // 'https://s3.eu-central-1.amazonaws.com/lectio.app/helvetiker_regular.typeface.json',
    //   '/helvetiker_regular.typeface.json',
    //   function (json) {
    // this.font = font;

    let textGeometry = new TextGeometry(text[this.props.lang], {
      font: this.font,
      size: text.modelSize ? text.modelSize : 0.1,
      height: text.modelHeight ? text.modelHeight : 0.03
    });
    let textMaterial = new THREE.MeshBasicMaterial({
      color: text.color ? +text.color : 0xffffff
    });

    let mesh = new THREE.Mesh(textGeometry, textMaterial);
    if (text.name && text.name != 'topVertice' && text.name != 'cloneA') {
      this.scene.add(mesh);
    } else {
      this.scene.getObjectByName('triangle').add(mesh);
    }
    if (text.name) {
      mesh.name = text.name;
    } else {
      mesh.name = 'text';
    }
    if (text.modelCoordsX && text.modelCoordsY && text.modelCoordsZ) {
      mesh.position.set(
        text.modelCoordsX,
        text.modelCoordsY,
        text.modelCoordsZ
      );
    } else {
      mesh.position.set(
        !text.modelCoordsX
          ? this.positionsTriangle[6] - 0.15
          : this.positionsTriangle[6] - 0.15 + text.modelCoordsX,
        this.positionsTriangle[7] + 0.15,
        0
      );
    }
    if (text.modelRotationX && text.modelRotationY && text.modelRotationZ) {
      mesh.rotation.set(
        text.modelRotationX,
        text.modelRotationY,
        text.modelRotationZ
      );
    } else {
      mesh.rotation.set(0, 0, 0);
    }
    // }

    //   // onProgress callback

    //   // onError callback
    // );
  }

  setupVr(scene) {
    this.renderer.xr.enabled = true;
    const self = this;
    this.controllers = this.buildControllers(scene);
    function onConnected(e) {
      this.gamepad = e.data.gamepad;
      self.updateControllers(
        {
          right: { trigger: true, squeeze: true, side: e.data.handedness },
          left: { trigger: true, squeeze: true, side: e.data.handedness }
        },
        self
      );
    }

    const controllers = [
      this.renderer.xr.getController(0),
      this.renderer.xr.getController(1)
    ];

    controllers.map((element) => {
      element.addEventListener('connected', onConnected);
    });
  }

  updateControllers(info, globalSelf) {
    const self = this;
    function onSelectStart() {
      Pagination(this, self);

      if (!self.sound?.isPlaying && self.loadedMusicBar) {
        playInstruction(this, self, self.props.text[0]);
      }

      if (this.userData.selected && !this.userData.attachedObject) {
        if (
          this.userData.selected.name == 'tabletSoundOn' &&
          self.props.description[self.props.lang] &&
          self.loadedMusicBar
        ) {
          textToSpeech(self);
          self.stopAttaching = true;
        } else if (this.userData.selected.name == 'tabletSoundOff') {
          if (self.sound?.isPlaying) {
            self.sound.pause();
            self.loadedMusicBar = true;
          }
          self.stopAttaching = true;
        }
      }
      this.userData.squeezePressed = true;
      if (
        !!this.userData.selected &&
        !self.posModelX &&
        !self.posModelY &&
        !self.posModelZ &&
        this.isInterseceted &&
        !self.tablet.getObjectByName(this.userData.selected.name) &&
        !self.stopAttaching &&
        this.userData.selected.name !== 'tabletRefresh'
      ) {
        const triangle = self.scene.getObjectByName('triangle');
        if (triangle) {
          triangle.remove(triangle.getObjectByName('cloneA'));
        }
        self.posModelX = this.userData.selected.position.x;
        self.posModelY = this.userData.selected.position.y;
        self.posModelZ = this.userData.selected.position.z;
        self.scaleX = this.userData.selected.scale.x;
        self.scaleY = this.userData.selected.scale.y;
        self.scaleZ = this.userData.selected.scale.z;
        self.rotationModelX = this.userData.selected.rotation.x;
        self.rotationModelY = this.userData.selected.rotation.y;
        self.rotationModelZ = this.userData.selected.rotation.z;
        self.scene.getObjectByName('B').position.set(-1.1, 0.0001, 0);
        self.scene.getObjectByName('C').position.set(1.05, 0.0001, 0);
        this.userData.attachedObject = this.userData.selected;
        this.attach(this.userData.selected);
        self.setState({
          modelName: this.userData.selected.name,
          executed: false
        });
      }
    }

    function onSelectEnd() {
      this.userData.squeezePressed = false;
      const formulaArray = calcFormula(self);

      if (this.userData.attachedObject !== undefined) {
        self.setState({
          modelName: this.userData.selected.name,
          executed: true
        });

        self.scene
          .getObjectByName('triangle')
          .add(this.userData.attachedObject);
        self.scene
          .getObjectByName('topVertice')
          .position.set(
            self.positionsTriangle[6] - 0.1,
            self.positionsTriangle[7] + 0.15
          );
        self.scene
          .getObjectByName(self.state.modelName)
          .position.set(
            self.positionsTriangle[6],
            self.positionsTriangle[7] + 0.05,
            self.posModelZ
          );

        self.scene
          .getObjectByName(self.state.modelName)
          .rotation.set(
            self.rotationModelX,
            self.rotationModelY,
            self.rotationModelZ
          );

        this.userData.attachedObject = undefined;
        this.userData.selected = undefined;
        this.getObjectByName('controllerSphere').visible = false;
        this.children[0].scale.z = 10;
        self.posModelX = undefined;
        self.posModelY = undefined;
        self.posModelZ = undefined;

        self.scene
          .getObjectByName(self.state.modelName)
          .scale.set(0.1, 0.1, 0.1);

        //  else {
        formulaArray.map(() => {
          self.scene.remove(self.scene.getObjectByName('remove'));
          // self.scene.remove(self.scene.getObjectByName('clone'));
        });
        // }
        formulaArray.map((element) => {
          globalSelf.loadText(element, true);
        });
      }
    }

    function onSqueezeStart() {}

    function onSqueezeEnd() {}

    function onDisconnected() {
      const index = this.userData.index;

      if (self.controllers) {
        const obj = index == 0 ? self.controllers.right : self.controllers.left;

        if (obj) {
          if (obj.controller) {
            const controller = obj.controller;
            while (controller.children.length > 0)
              controller.remove(controller.children[0]);
            self.scene.remove(controller);
          }
          if (obj.grip) self.scene.remove(obj.grip);
        }
      }
    }

    if (info.right !== undefined && info.right.side === 'right') {
      const right = this.renderer.xr.getController(0);

      let trigger = false,
        squeeze = false;

      Object.keys(info.right).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
        if (key.indexOf('squeeze') != -1) squeeze = true;
      });

      if (trigger) {
        right.addEventListener('selectstart', onSelectStart);
        right.addEventListener('selectend', onSelectEnd);
      }

      if (squeeze) {
        right.addEventListener('squeezestart', onSqueezeStart);
        right.addEventListener('squeezeend', onSqueezeEnd);
      }

      right.addEventListener('disconnected', onDisconnected);
    }

    if (info.left !== undefined && info.left.side === 'left') {
      const left = this.renderer.xr.getController(1);

      let trigger = false,
        squeeze = false;

      Object.keys(info.left).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
        if (key.indexOf('squeeze') != -1) squeeze = true;
      });

      if (trigger) {
        left.addEventListener('selectstart', onSelectStart);
        left.addEventListener('selectend', onSelectEnd);
      }

      if (squeeze) {
        left.addEventListener('squeezestart', onSqueezeStart);
        left.addEventListener('squeezeend', onSqueezeEnd);
      }

      left.addEventListener('disconnected', onDisconnected);
    }
  }

  buildControllers() {
    const controllerModelFactory = new XRControllerModelFactory();
    const geometrySphere = new THREE.IcosahedronBufferGeometry(this.radius, 2);

    const sphere = new THREE.Mesh(
      geometrySphere,
      new THREE.MeshBasicMaterial({ color: 0xff0000, side: THREE.BackSide })
    );
    sphere.scale.set(0.01, 0.01, 0.01);

    const geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, -1)
    ]);

    const line = new THREE.Line(geometry);
    line.name = 'line';
    sphere.name = 'controllerSphere';
    line.scale.z = 2;
    sphere.visible = false;
    const controllers = [];

    for (let i = 0; i <= 1; i++) {
      const controller = this.renderer.xr.getController(i);

      controller.add(line.clone());
      controller.add(sphere.clone());
      controller.userData.selectPressed = false;

      this.dolly.add(controller);

      controllers.push(controller);

      const grip = this.renderer.xr.getControllerGrip(i);
      grip.add(controllerModelFactory.createControllerModel(grip));

      this.workingMatrix.identity().extractRotation(controller.matrixWorld);

      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);

      controller.children[0].scale.z = 10;

      if (i == 1) {
        this.dolly.add(grip);
      }
    }
    Tablet(this, controllers);

    return controllers;
  }

  handleController(controller, dt, index) {
    const axes = controller.gamepad?.axes;
    const radius = 6.34;
    const x = 2.5;
    const moveOnXAxis = 0.98;
    const moveOnYAxis = 0.12;
    const multiplierForXAxis = 2.57;
    controller.isInterseceted = false;
    const speed = 0.03;
    let a = 0;
    let b = 0;
    if (controller.userData.selectPressed) {
      const speed = index === 0 ? 5 : -5; //movement for select button
      const quaternion = this.dolly.quaternion.clone(); //movement for select button
      this.dolly.quaternion.copy(this.camera?.quaternion); //movement for select button
      this.dolly.translateZ(-dt * speed); //movement for select button

      this.dolly.quaternion.copy(quaternion); //movement for select button
    }
    if (this.renderer.xr.isPresenting) {
      this.workingMatrix.identity().extractRotation(controller.matrixWorld);
      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);
    }
    let interationArray = [];

    const val = this.scene.getObjectByName('sphereToHold');
    if (this.tablet?.children.length > 0) {
      const tabletRefreshInstruction = this.tablet.getObjectByName(
        'tabletInstructionRefresh'
      ).children;
      const tabletDescription =
        this.tablet.getObjectByName('tabletDescription').children;

      interationArray = [
        ...this.tablet.children,
        ...tabletRefreshInstruction,
        ...tabletDescription
      ];
    }
    interationArray = [...interationArray, val];
    this.intersectsModel = this.raycaster.intersectObjects(
      // [...this.model.children, ...this.dolly.children.find((child) => child.name === 'instructionPanel').children],
      interationArray,
      false
    );

    if (!controller.userData.attachedObject) {
      if (
        this.intersectsModel.length > 0 &&
        !this.intersectsModel[0].object.text &&
        this.intersectsModel[0].object.parent.visible
      ) {
        controller.children[1].visible = true;
        controller.children[1].position.x = controller.children[0].position.x;
        controller.children[1].position.z =
          -this.intersectsModel[0].distance + 0.02;
        controller.children[0].scale.z = this.intersectsModel[0].distance;
        this.lineLength = this.intersectsModel[0].distance;
        controller.userData.selected = this.intersectsModel[0].object;
      } else {
        controller.children[0].scale.z = 10;
        controller.children[1].visible = false;
        controller.userData.selected = undefined;
      }
    }

    if (
      this.intersectsModel.length > 0 &&
      !controller.userData.squeezePressed &&
      !this.intersectsModel[0].object.text
    ) {
      controller.isInterseceted = true;
    } else {
      controller.children[0].scale.z = 10; //setting length of the line from controller
      controller.getObjectByName('controllerSphere').visible = false; //if no intersection setting visbility for red point on the end of the line form controller to false
      controller.isInterseceted = false;
    }
    if (controller.userData.squeezePressed || this.onmousedownFlag) {
      //squeeze pressed true
      if (
        this.intersectsModel.length > 0 &&
        !this.intersectsModel[0].object.text &&
        this.intersectsModel[0].object.parent.visible
      ) {
        controller.getObjectByName('controllerSphere').visible = true; //setting visbility for red point on the end of the line form controller to false
        controller.children[1].position.x = controller.children[0].position.x; //setting position x coords of the line from controller for the red point
        controller.children[1].position.z =
          -this.intersectsModel[0].distance + 0.02; //setting position z of the line from intersected model for the red point
        controller.children[0].scale.z = this.intersectsModel[0].distance; //setting as length of the line from controller  ,distance between controller and green point on the vertice of the triangle
        this.lineLength = this.intersectsModel[0].distance; //adding distance between controller and green point on the vertice of the triangle in letiable
        controller.userData.selected = this.intersectsModel[0].object; //adding intersected model to letiable for using in next steps
        //Check object is added to the controller as a child and checking if position of intersection between line from controller and green point on the vertice of the triangle on the y coords is more then 0.05
        if (
          (this.intersectsModel[0] &&
            controller.userData.attachedObject &&
            this.intersectsModel[0].point.y > 0.05) ||
          (!this.renderer.xr.isPresenting &&
            this.intersectsMouseModel[0] &&
            this.intersectsMouseModel[0].point.y > 0.05 &&
            this.onmousedownFlag)
        ) {
          this.scene
            .getObjectByName('topVertice')
            .position.set(
              this.positionsTriangle[6] - 0.1,
              this.positionsTriangle[7] + 0.15,
              0
            );
          if (!this.state.removed) {
            this.scene
              .getObjectByName('instruction')
              .position.lerp(new THREE.Vector3(100, 0, 0), 0.005); //adding animation of translating text on pressing squeeze button to the  right
          }
          if (
            this.scene.getObjectByName('instruction')?.position.x > 15 &&
            !this.state.removed
          ) {
            this.scene.remove(this.scene.getObjectByName('instruction'));
            this.setState({ ...this.state, removed: true });
          }
          if (
            ((this.intersectsModel[0].point.x > -0.96 && //checking if position of intersection between line from controller and green point on the vertice of the triangle on the x coords is between  0.96 and -0.96 and V/ radius * radius + x*x is NaN
              this.intersectsModel[0].point.x < 0.96) ||
              (!this.renderer.xr.isPresenting &&
                this.intersectsMouseModel[0].point.x > -0.96 &&
                this.intersectsMouseModel[0].point.x < 0.96)) &&
            !isNaN(
              Math.sqrt(
                radius - 0.15 - Math.pow(this.positionsTriangle[6] - x, 2) //checking if position of intersection between line from controller and green point on the vertice of the triangle on the x coords is between  0.96 and -0.96 and V/ radius * radius + x*x is NaN
              )
            )
          ) {
            //Changing x coord position for top triangle vertice
            if (
              !this.renderer.xr.isPresenting &&
              this.intersectsMouseModel[0]
            ) {
              this.positionsTriangle[6] =
                (this.intersectsMouseModel[0].point.x + moveOnXAxis) *
                multiplierForXAxis;
            } else {
              this.positionsTriangle[6] =
                (this.intersectsModel[0].point.x + moveOnXAxis) *
                multiplierForXAxis; //_____________________________
              //Changing y coord position for top triangle vertice using formula V/ radius * radius + x*x is NaN
            }

            this.positionsTriangle[7] =
              Math.sqrt(
                radius - 0.17 - Math.pow(this.positionsTriangle[6] - x, 2)
              ) + moveOnYAxis;
          } else {
            //if position of intersection between line from controller and green point on the vertice of the triangle on the x coords isn't between  0.96 and -0.96 position for vertice of triangle is setting for constant coords
            if (this.intersectsModel[0].point.x < 0) {
              //if position of intersection between line from controller and green point on the vertice of the triangle on the x coords isn't between  0.96 and -0.96 position for vertice of triangle is setting for constant coords for the RIGHT side of triangle
              this.positionsTriangle[6] = 0.02;
              this.positionsTriangle[7] = 0.3;
            } else {
              //if position of intersection between line from controller and green point on the vertice of the triangle on the x coords isn't between  0.96 and -0.96 position for vertice of triangle is setting for constant coords for the RIGHT side of triangle
              this.positionsTriangle[6] = 4.98;
              this.positionsTriangle[7] = 0.3;
            }
          }
          //Changing coord position for degrees square
          if (
            this.intersectsModel[0].point.x > -0.96 &&
            this.intersectsModel[0].point.x < 0.96 &&
            !isNaN(
              Math.sqrt(
                radius - 0.15 - Math.pow(this.positionsTriangle[6] - x, 2)
              )
            )
          ) {
            //Setting position with same formula as for vertice of triangle
            if (this.renderer.xr.isPresenting) {
              this.scene
                .getObjectByName('degreesSquare')
                .position.set(
                  (this.intersectsModel[0].point.x + 1.02) *
                    (multiplierForXAxis - 0.09) -
                    0.01,
                  Math.sqrt(
                    radius - 0.15 - Math.pow(this.positionsTriangle[6] - x, 2)
                  ) +
                    moveOnYAxis -
                    0.11 -
                    this.positionsTriangle[7] / 100,
                  0
                );
            } else {
              this.scene
                .getObjectByName('degreesSquare')
                .position.set(
                  (this.intersectsMouseModel[0].point.x + 1.02) *
                    (multiplierForXAxis - 0.09) -
                    0.01,
                  Math.sqrt(
                    radius - 0.15 - Math.pow(this.positionsTriangle[6] - x, 2)
                  ) +
                    moveOnYAxis -
                    0.11 -
                    this.positionsTriangle[7] / 100,
                  0
                );
            }
          } else {
            if (this.intersectsModel[0].point.x < 0) {
              this.scene
                .getObjectByName('degreesSquare')
                .position.set(0.1, 0.2, 0);
            } else {
              this.scene
                .getObjectByName('degreesSquare')
                .position.set(4.9, 0.2, 0);
            }
          }

          //Changing rotation of degree square according to top verice of triangle

          this.scene.getObjectByName('degreesSquare').rotation.z =
            Math.PI *
            (this.positionsTriangle[6] > 2.5 //if x coord of the top Vertice of Triangle is more then 2.5 then
              ? (Math.sqrt(
                  //
                  radius - Math.pow(this.positionsTriangle[6] - x, 2) //
                ) + //
                  moveOnYAxis) / //we are using same formula as we used for vertice of triangle for
                (this.positionsTriangle[7] < 2.1652 // *if y coords of the top of Vertice of Triangle is more then 2.1652*
                  ? 15 //splitting it by 15
                  : 15 - (this.positionsTriangle[7] - 2.1651) * 10) //otherwhise we are splitting it by 15 - current position of y coords of triangle vertice - 2.1651 (y Point in circle for smooth animation ) and multyplyin it by 10   //right part of the circle && rotation to the left
              : (Math.sqrt(
                  //otherwise
                  radius - Math.pow(this.positionsTriangle[6] - x, 2) //we are using same formula as we used for vertice of triangle for
                ) +
                  moveOnYAxis) /
                (this.positionsTriangle[7] < 2.1651 // *if y coords of the top of Vertice of Triangle is more then 2.1652*
                  ? -15 //splitting it by -15
                  : -15 + (this.positionsTriangle[7] - 2.1651) * 10)); //otherwhise we are splitting it by -15 + current position of y coords of triangle vertice - 2.1651 (y Point in circle for smooth animation )      //left part  to the right rotation
        }
      } else {
        controller.children[0].scale.z = 10;
        controller.getObjectByName('controllerSphere').visible = false;
      }
      if (controller.getObjectByName('sphereToHold')) {
        controller.getObjectByName('controllerSphere').visible = false;
        controller.children[0].visible = false;
        controller.children[0].scale.z = this.lineLength;
      }
    } else {
      controller.children[0].visible = true;
      if (this.state.executed) {
        this.scene
          .getObjectByName('B')
          .position.lerp(new THREE.Vector3(-0.12, -0.7, 0), 0.02);
        this.scene
          .getObjectByName('C')
          .position.lerp(new THREE.Vector3(0.23, -0.7, 0), 0.02);
        this.scene
          .getObjectByName('topVertice')
          .position.lerp(new THREE.Vector3(1.32, -1.85, 0), 0.02);
      }
    }
    //=================> movement <=================

    if (axes?.length) {
      if (axes[2] != 0) {
        a += axes[2];
      }
      if (axes[3] != 0) {
        b += axes[3];
      }
    }
    const quaternion = this.dolly.quaternion.clone();

    if (a !== 0 || b !== 0) {
      this.dolly.quaternion.copy(this.camera?.quaternion);
      this.dolly.translateZ(b * speed);
      this.dolly.translateX(a * speed);

      this.dolly.quaternion.copy(quaternion);
    }
  }

  componentDidUpdate(prevProps) {
    const self = this;
    if (this.props.lang != prevProps.lang || this.props.lang === 'RO') {
      createTopicName(this.props, this.scene);
    }
    //Changing text mesh on change language
    if (this.props.lang != prevProps.lang) {
      this.props.text.map((element) => {
        this.loadText(element);
        self.setState({ ...self.state, loadedFlag: true });
      });
    }
  }
  mouseEventClick(event) {
    this.clickMouse.x = this.props?.previewWidth
      ? (event.clientX / this.props.previewWidth) * 2 - 1
      : (event.clientX / window.innerWidth) * 2 - 1;
    this.clickMouse.y = this.props?.previewHeight
      ? -(event.clientY / this.props.previewHeight) * 2 + 1
      : -(event.clientY / window.innerHeight) * 2 + 1;
  }
  componentDidMount() {
    // this.button = document.getElementById('vrButton');

    this.containerRef?.current?.appendChild(this.renderer.domElement);
    if (!document.getElementById('vrButton'))
      this.vrButton = new VRButton(this.renderer);
    const self = this;
    createTopicName(this.props, this.scene);
    this.containerRef.current.addEventListener(
      'mousedown',
      this.mouseEventMouseDown.bind(this)
    );
    this.containerRef.current.addEventListener(
      'mouseup',
      this.mouseEventMouseUp.bind(this)
    );

    // window.addEventListener('click', this.mouseEventClick.bind(this));
    // window.addEventListener('mousemove', this.mouseEventMove.bind(this));
    // Adding text mesh on mount component
    const setTextInScene = () => {
      this.props.text.map((element) => {
        this.uploadFont(element);
      });
    };

    setTextInScene();

    //When user turn on the VR mode.
    self.renderer.xr.addEventListener('sessionstart', function () {
      self.scene.getObjectByName('instructionPanel').visible = true;

      if (self.props.url.split('/').indexOf('fullscreen') == -1) {
        self.props.history.push(self.link);
      }
      self.dolly.add(self.camera);
      self.dolly.position.z = 5;
      self.dolly.position.y = -0.5;
      self.dolly.position.x = 0;
    });

    //When user turn off the VR mode.
    self.renderer.xr.addEventListener('sessionend', function () {
      if (self.sound?.isPlaying) self.sound.pause();

      self.dolly.remove(self.camera);
      self.dolly.position.z = 3;
      self.dolly.position.y = 1.6;
      self.scene.getObjectByName('instructionPanel').visible = false;
      let adminPreviewContainer = document.getElementById('previewContainer');
      if (self.props.onsessionendAdmin && adminPreviewContainer) {
        adminPreviewContainer.appendChild(self.vrButton.vrButton);
      }
    });
    window.addEventListener('resize', this.resize());
  }

  componentWillUnmount() {
    this.containerRef?.current?.removeChild(this.renderer.domElement);
    window.removeEventListener('resize', this.resize);

    !this.props.isPreview && this?.vrButton?.hideEnterVR();
    Object.keys(this).forEach((key) => {
      // Recursively call dispose() if possible.
      if (
        typeof this[key]?.dispose === 'function' &&
        this[key].type != 'Scene'
      ) {
        this[key].dispose();
      }
      // Remove any reference.
      this[key] = null;
    });
  }

  shouldComponentUpdate(nextProps) {
    this.room.name = 'room';
    this.lang = nextProps.lang;

    //Replacing text from scene after translate
    if (nextProps.lang !== this.props.lang) {
      this.props.text.map(() => {
        this.scene.remove(this.scene.getObjectByName('text'));
        this.scene
          .getObjectByName('triangle')
          .remove(this.scene.getObjectByName('parrent'));
        this.scene.remove(this.room.getObjectByName('children'));
      });

      return true;
    }

    //Setting coordinates for room (model)
    if (
      this.props.roomCoordsZ &&
      this.props.roomCoordsX &&
      this.props.roomCoordsY
    ) {
      this.room.position.set(
        this.props.roomCoordsX,
        this.props.roomCoordsY,
        this.props.roomCoordsZ
      );
    } else {
      this.room.position.set(0, 0, 0);
    }
    //Setting rotation coords for rotation (model)
    if (
      this.props.roomRotationZ &&
      this.props.roomRotationX &&
      this.props.roomRotationY
    ) {
      this.room.rotation.set(
        this.props.roomRotationX,
        this.props.roomRotationY,
        this.props.roomRotationZ
      );
    }
    //Setting scaling for room (model)
    if (
      this.props.roomScaleZ &&
      this.props.roomScaleX &&
      this.props.roomScaleY
    ) {
      this.room.scale.set(
        this.props.roomScaleX,
        this.props.roomScaleY,
        this.props.roomScaleZ
      );
    }
    //Setting camera position in scene
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.camera.position.set(
        this.props.cameraCoordsX,
        this.props.cameraCoordsY,
        this.props.cameraCoordsZ
      );
    }

    //Setting coord for text in scene

    this.scene.add(this.room);

    //Setting loading
    if (nextProps.type !== this.state.loadingType) {
      this.setState({
        ...this.state,
        loadingType: nextProps.type
      });
      return true;
    }
    return false;
  }

  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  render() {
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    if (this.vrButton) this.vrButton.vrButton.disabled = false;

    const dt = this.clock.getDelta();
    this.line.children[0].geometry.attributes.position.needsUpdate = true;
    pagintationLerping(this);

    this.scene.children.forEach((element) => {
      if (element.name === 'text')
        element.quaternion?.copy(this.camera.quaternion);
    });
    if (this.controllers) {
      const self = this;
      this.controllers.forEach((controller, index) => {
        self.handleController(controller, dt, index);
        // if (
        //   !this.renderer.xr.isPresenting &&
        //   controller.getObjectByName('line') &&
        //   controller.getObjectByName('line').visible
        // ) {
        //   controller.getObjectByName('line').visible = false;
        // }
      });
    }

    this.mixer?.update(dt);

    return (
      <div ref={this.containerRef} className={`${styles.sceneContainer}`}>
        {this.props.isPreviewContainer && this.state.flagFullscreen ? (
          <Link
            onClick={this.updateState}
            className={
              'px-2 absolute py-2 bg-gray-500 border-2 border-white rounded-lg text-center hover:bg-gray-600 hover hover:border-gray-100 top-5 right-5 text-white '
            }
          >
            {' '}
            <svg
              className={'self-end fill-current h-6 w-6 '}
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Exit Fullscreen</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </Link>
        ) : null}

        {this.state.progress && (
          <div
            className={
              'w-full h-full bg-black flex items-center flex-col justify-center absolute opacity-50'
            }
          >
            <p className="text-white opacity-100 font-gothic text-lg mb-2 text-center">
              Loading...
            </p>
            <div className="w-32 h-1 opacity-100 rounded-lg bg-slate-600 ">
              {this.state.percent != 0 ? (
                <div
                  style={{ width: Math.ceil(this.state.percent) + '%' }}
                  className={' rounded-full opacitiy-90 h-full bg-cyan-500'}
                ></div>
              ) : (
                <div className={'w-full h-full bg-gray-500'}></div>
              )}
            </div>
          </div>
        )}
      </div>
    );
    // }
  }
}

export default withRouter(GeoFigures);
