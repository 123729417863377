import React, { useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sidebar from './../../../app/adminSidebar/Sidebar';
import { Redirect, Switch, Route } from 'react-router-dom';
import UserManagement from './userManagement';
import ContentPanel from './contentManager';
import TopicsPage from './contentManager/topicsPage';
import EditTopicPage from './contentManager/editTopicPage';
import { useAppContext } from '../../../context/AppProvider';
import { useHistory } from 'react-router-dom';
import ModelViewerPage from './MoleculeConstructor/ModelViewerPage';
import MoleculeConstructorContainer from './MoleculeConstructor/MoleculeConstructorContainer';

const AdminPanel = () => {
  const history = useHistory();
  const { store } = useAppContext();
  const { user } = store;
  const userData = JSON.parse(window.localStorage.getItem('userData'));

  useEffect(() => {
    if (user.userId) {
      if (user.role !== 'superAdmin' && user.role !== 'school') {
        history.push('/');
      }
    }
  }, [user]);

  return (
    <>
      <div className="w-full min-h-screen flex bg-blueGray-100">
        <Sidebar />
        <div className="w-full overflow-hidden  h-full">
          <Switch>
            <Route path="/admin/users" exact component={UserManagement} />
            <Route path="/admin/content" exact component={ContentPanel} />

            <Route
              path="/admin/moleculeConstructor"
              exact
              component={MoleculeConstructorContainer}
            />

            <Route exact path={'/admin/moleculeConstructor/ModelViewerPage'}>
              <ModelViewerPage />
            </Route>
            <Route
              path="/admin/content/:categoryId"
              exact
              component={TopicsPage}
            />
            <Route
              path="/admin/content/:categoryId/:topicId"
              exact
              component={EditTopicPage}
            />
            {userData.role === 'superAdmin' && (
              <Redirect from="/admin" to="/admin/users" />
            )}
            {userData.role === 'school' && (
              <Redirect from="/admin" to="/admin/content" />
            )}
          </Switch>
        </div>
      </div>
    </>
  );
};

export default AdminPanel;
