/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Link } from 'react-router-dom';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { VRButton } from '../../../common/VRButton';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import styles from './../../../common/styles.module.css';
import ControllerInstruction from '../../../common/controllerInstruction/index';
import { textToSpeech } from '../../../common/TabletFunctionality/textToSpeech';
import { Tablet } from '../../../common/TabletFunctionality/index';
class SunCharacteristics extends Component {
  constructor(props) {
    super(props);
    this.lang = props.lang;
    this.containerRef = React.createRef();
    this.materials = {};
    this.state = {
      flagFullscreen: false,
      progress: true
    };

    this.cubeTextureLoader = new THREE.CubeTextureLoader();
    this.backgroundImage = require('./../../../assets/textures/stars.png');
    this.rotationMatrix = new THREE.Matrix4();
    this.targetQuaternion = new THREE.Quaternion();

    this.clock = new THREE.Clock();
    this.scene = new THREE.Scene();
    this.scene.background = this.cubeTextureLoader.load([
      this.backgroundImage,
      this.backgroundImage,
      this.backgroundImage,
      this.backgroundImage,
      this.backgroundImage,
      this.backgroundImage
    ]);
    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      100
    );

    this.time = 0;
    this.camera.position.set(0, 0, 5);

    this.loadedMusicBar = true;

    this.scene.add(this.camera);

    this.modelPosZ = 0;
    this.modelPosY = 0;
    this.modelPosX = 0;
    this.lineLength = 0;

    this.renderer = new THREE.WebGLRenderer({
      antialias: false,
      powerPreference: 'high-performance'
    });
    this.renderer.setPixelRatio(window.devicePixelRatio * 0.9);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setClearColor(0xeeeeee, 1);
    this.renderer.shadowMap.enabled = true;
    this.renderer.outputEncodingd = THREE.sRGBEncoding;

    this.start_x;
    this.start_y;

    this.raycaster = new THREE.Raycaster();
    this.workingMatrix = new THREE.Matrix4();
    this.workingVector = new THREE.Vector3();
    this.origin = new THREE.Vector3();

    this.dolly = new THREE.Object3D();
    // this.dolly.position.z = 5;
    this.dolly.add(this.camera);
    this.scene.add(this.dolly);
    this.camera.add(this.dummyCam);

    const xInstructionCoord = this.props.name?.controllerCoords?.x
      ? this.props.name.controllerCoords.x
      : 1.7;
    const yInstructionCoord = this.props.name?.controllerCoords?.y
      ? this.props.name.controllerCoords.y
      : 1;
    const zInstructionCoord = this.props.name?.controllerCoords?.z
      ? this.props.name.controllerCoords.z
      : 4;

    this.ControllerInstruction = new ControllerInstruction({
      x: xInstructionCoord,
      y: yInstructionCoord,
      z: zInstructionCoord
    });
    this.ControllerInstruction.bindInstructionToScene(this.scene);

    this.model;

    var ambientLight = new THREE.AmbientLight(0xcccccc, 5);

    //right part spot light

    this.scene.add(ambientLight);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();

    this.renderer.setAnimationLoop(this.render.bind(this));

    this.initScene();
    this.setupVr(this.scene);
  }

  initScene() {
    this.addAround();
    this.scene.add(this.addObjects());
    this.addTexture();
    this.loadModel(this.scene);
  }

  loadModel(room) {
    const self = this;
    const loader = new GLTFLoader();

    loader.load(
      `${process.env.REACT_APP_AWS_LINK}/models/sunFlames.glb`,
      function (gltf) {
        gltf.scene.children[0].position.set(0, 0, 0);
        room.add(gltf.scene);
        self.model = gltf.scene;

        if (gltf?.animations.length) {
          self.mixer = new THREE.AnimationMixer(gltf.scene);
          const clips = gltf?.animations;
          clips.forEach(function (clip) {
            self?.mixer?.clipAction(clip)?.play();
          });
        }

        self.setState({ ...self.state, progress: false });
      },
      function (xhr) {
        self.setState({
          ...self.state,
          percent: (xhr.loaded / xhr.total) * 100
        });
      },
      function (err) {
        console.log('error========>', err);
      }
    );
  }

  addAround() {
    this.materialAround = new THREE.ShaderMaterial({
      extensions: {
        derivatives: '#extension GL_OES_standard_derivatives : enabled'
      },
      side: THREE.BackSide,
      uniforms: {
        time: { value: 0 },
        resolution: { value: new THREE.Vector4() },
        uPerlin: { value: null }
      },
      vertexShader: `
      uniform float time;
varying vec2 vUv;
varying vec3 vPosition;
varying vec3 vNormal;
uniform vec2 pixels;
float PI = 3.14159265359;


varying vec3 vLayer0;
varying vec3 vLayer1;
varying vec3 vLayer2;
varying vec3 eyeVector;

mat2 rotate(float a){
    float s = sin(a);
    float c = cos(a);
    return mat2(c,-s,s,c);
}

void main() {

    vNormal = normal;

    vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
    eyeVector = normalize(worldPosition.xyz - cameraPosition);
    

    float t = time*0.005;

    mat2 rot = rotate(t);

    vec3 p0 = position;
    p0.yz = rot*p0.yz;
    vLayer0 = p0;

    mat2 rot1 = rotate(t + 10.);

    vec3 p1 = position;
    p1.xz = rot1*p1.xz;
    vLayer1 = p1;

    mat2 rot2 = rotate(t + 30.);

    vec3 p2 = position;
    p2.xy = rot2*p2.xy;
    vLayer2 = p2;




    vUv = uv;
    vPosition = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
      `,
      fragmentShader: `
      uniform float time;
      uniform float progress;
      uniform sampler2D texture1;
      uniform vec4 resolution;
      uniform samplerCube uPerlin;
      varying vec2 vUv;
      varying vec3 vPosition;
      varying vec3 vNormal;
      varying vec3 vLayer0;
      varying vec3 vLayer1;
      varying vec3 vLayer2;
      varying vec3 eyeVector;
      
      float PI = 3.14159265359;
      
      vec3 brightnessToColor(float b){
          b *=0.25;
          return (vec3(b, b*b, b*b*b*b)/0.25)*0.8;
      }
      
      
      float Fresnel(vec3 eyeVector, vec3 worldNormal) {
          return pow(1.0 + dot(eyeVector, worldNormal), 3.0);
      }
      
      float supersun(){
          float sum = 0.;
          sum += textureCube(uPerlin,vLayer0).r;
          sum += textureCube(uPerlin,vLayer1).r;
          sum += textureCube(uPerlin,vLayer2).r;
          sum *=0.33;
          return sum;
      }
      
      void main() {
        float radial = 1. - vPosition.z;
        radial *=radial * radial;

        float brightness = 1. + radial*0.83;
        gl_FragColor.rgb = brightnessToColor(brightness) * radial;
        gl_FragColor.a = radial;

      }
    `
    });

    const geometry = new THREE.SphereBufferGeometry(1.1, 30, 30);
    this.sunAround = new THREE.Mesh(geometry, this.materialAround);
    this.scene.add(this.sunAround);
  }

  addObjects() {
    // const material = new THREE.MeshNormalMaterial({ color: 0xffffff });
    this.sunMaterial = new THREE.ShaderMaterial({
      extensions: {
        derivatives: '#extension GL_OES_standard_derivatives : enabled'
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        resolution: { value: new THREE.Vector4() },
        uPerlin: { value: null }
      },

      vertexShader: `
      uniform float time;
varying vec2 vUv;
varying vec3 vPosition;
varying vec3 vNormal;
uniform vec2 pixels;
float PI = 3.14159265359;


varying vec3 vLayer0;
varying vec3 vLayer1;
varying vec3 vLayer2;
varying vec3 eyeVector;

mat2 rotate(float a){
    float s = sin(a);
    float c = cos(a);
    return mat2(c,-s,s,c);
}

void main() {

    vNormal = normal;

    vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
    eyeVector = normalize(worldPosition.xyz - cameraPosition);
    

    float t = time*0.005;

    mat2 rot = rotate(t);

    vec3 p0 = position;
    p0.yz = rot*p0.yz;
    vLayer0 = p0;

    mat2 rot1 = rotate(t + 10.);

    vec3 p1 = position;
    p1.xz = rot1*p1.xz;
    vLayer1 = p1;

    mat2 rot2 = rotate(t + 30.);

    vec3 p2 = position;
    p2.xy = rot2*p2.xy;
    vLayer2 = p2;




    vUv = uv;
    vPosition = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
      `,
      fragmentShader: `
      uniform float time;
      uniform float progress;
      uniform sampler2D texture1;
      uniform vec4 resolution;
      uniform samplerCube uPerlin;
      varying vec2 vUv;
      varying vec3 vPosition;
      varying vec3 vNormal;
      varying vec3 vLayer0;
      varying vec3 vLayer1;
      varying vec3 vLayer2;
      varying vec3 eyeVector;
      
      float PI = 3.14159265359;
      
      vec3 brightnessToColor(float b){
          b *=0.25;
          return (vec3(b, b*b, b*b*b*b)/0.25)*0.8;
      }
      
      
      float Fresnel(vec3 eyeVector, vec3 worldNormal) {
          return pow(1.0 + dot(eyeVector, worldNormal), 3.0);
      }
      
      float supersun(){
          float sum = 0.;
          sum += textureCube(uPerlin,vLayer0).r;
          sum += textureCube(uPerlin,vLayer1).r;
          sum += textureCube(uPerlin,vLayer2).r;
          sum *=0.33;
          return sum;
      }
      
      void main() {
          float brightness = supersun();
          float fres = Fresnel(eyeVector, vNormal);
          brightness = brightness * 4. + 1.;
          brightness += pow(fres, 0.8);
      
          vec3 col = brightnessToColor(brightness);
          gl_FragColor = vec4(col,1.);
          // gl_FragColor = vec4(fres);
      
      }
    `
    });

    const geometry = new THREE.SphereBufferGeometry(1, 30, 30);
    return new THREE.Mesh(geometry, this.sunMaterial);
  }

  addTexture() {
    this.scene1 = new THREE.Scene();
    this.cubeRenderTarget1 = new THREE.WebGLCubeRenderTarget(256, {
      format: THREE.RGBAFormat,
      generateMipmaps: true,
      minFilter: THREE.LinearMipMapLinearFilter,
      encoding: THREE.sRGBEncoding
    });
    this.cubeCamera1 = new THREE.CubeCamera(0.1, 100, this.cubeRenderTarget1);

    this.perlinMaterial = new THREE.ShaderMaterial({
      extensions: {
        derivatives: '#extension GL_OES_standard_derivatives : enabled'
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        resolution: { value: new THREE.Vector4() }
      },
      vertexShader: `
      uniform float time;
varying vec2 vUv;
varying vec3 vPosition;
uniform vec2 pixels;
float PI = 3.14159265359;



void main() {

    vUv = uv;
    vPosition = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
      `,
      fragmentShader: `
      uniform float time;
      uniform float progress;
      uniform sampler2D texture1;
      uniform vec4 resolution;
      varying vec2 vUv;
      varying vec3 vPosition;
      float PI = 3.14159265359;
      
      
      vec4 mod289(vec4 x) {
      return x - floor(x * (1.0/289.0)) * 289.0;
      }
      
      float mod289(float x) {
      return x - floor(x * (1.0/289.0)) * 289.0;
      }
      
      vec4 permute(vec4 x) {
      return mod289(((x*34.0)+1.0)*x);
      }
      
      float permute(float x) {
      return mod289(((x*34.0)+1.0)*x);
      }
      
      vec4 taylorInvSqrt(vec4 r)
      {
      return 1.79284291400159 - 0.85373472095314 * r;
      }
      
      float taylorInvSqrt(float r)
      {
      return 1.79284291400159 - 0.85373472095314 * r;
      }
      
      vec4 grad4(float j, vec4 ip)
      {
      const vec4 ones = vec4(1.0, 1.0, 1.0, -1.0);
      vec4 p,s;
      
      p.xyz = floor( fract (vec3(j) * ip.xyz) * 7.0) * ip.z - 1.0;
      p.w = 1.5 - dot(abs(p.xyz), ones.xyz);
      s = vec4(lessThan(p, vec4(0.0)));
      p.xyz = p.xyz + (s.xyz*2.0 - 1.0) * s.www;
      
      return p;
      }
      
      
      #define F4 0.309016994374947451
      
      float snoise(vec4 v)
      {
      const vec4  C = vec4( 0.138196601125011,
      0.276393202250021,
      0.414589803375032,
      -0.447213595499958);
      vec4 i  = floor(v + dot(v, vec4(F4)) );
      vec4 x0 = v -   i + dot(i, C.xxxx);
      vec4 i0;
      vec3 isX = step( x0.yzw, x0.xxx );
      vec3 isYZ = step( x0.zww, x0.yyz );
      i0.x = isX.x + isX.y + isX.z;
      i0.yzw = 1.0 - isX;
      i0.y += isYZ.x + isYZ.y;
      i0.zw += 1.0 - isYZ.xy;
      i0.z += isYZ.z;
      i0.w += 1.0 - isYZ.z;
      
      vec4 i3 = clamp( i0, 0.0, 1.0 );
      vec4 i2 = clamp( i0-1.0, 0.0, 1.0 );
      vec4 i1 = clamp( i0-2.0, 0.0, 1.0 );
      
      vec4 x1 = x0 - i1 + C.xxxx;
      vec4 x2 = x0 - i2 + C.yyyy;
      vec4 x3 = x0 - i3 + C.zzzz;
      vec4 x4 = x0 + C.wwww;
      
      
      i = mod289(i);
      float j0 = permute( permute( permute( permute(i.w) + i.z) + i.y) + i.x);
      vec4 j1 = permute( permute( permute( permute (
      i.w + vec4(i1.w, i2.w, i3.w, 1.0 ))
      + i.z + vec4(i1.z, i2.z, i3.z, 1.0 ))
      + i.y + vec4(i1.y, i2.y, i3.y, 1.0 ))
      + i.x + vec4(i1.x, i2.x, i3.x, 1.0 ));
      
      vec4 ip = vec4(1.0/294.0, 1.0/49.0, 1.0/7.0, 0.0) ;
      
      vec4 p0 = grad4(j0,   ip);
      vec4 p1 = grad4(j1.x, ip);
      vec4 p2 = grad4(j1.y, ip);
      vec4 p3 = grad4(j1.z, ip);
      vec4 p4 = grad4(j1.w, ip);
      
      vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
      p0 *= norm.x;
      p1 *= norm.y;
      p2 *= norm.z;
      p3 *= norm.w;
      p4 *= taylorInvSqrt(dot(p4,p4));
      
      vec3 m0 = max(0.6 - vec3(dot(x0,x0), dot(x1,x1), dot(x2,x2)), 0.0);
      vec2 m1 = max(0.6 - vec2(dot(x3,x3), dot(x4,x4)            ), 0.0);
      m0 = m0 * m0;
      m1 = m1 * m1;
      return 49.0 * ( dot(m0*m0, vec3( dot( p0, x0 ), dot( p1, x1 ), dot( p2, x2 )))
      + dot(m1*m1, vec2( dot( p3, x3 ), dot( p4, x4 ) ) ) ) ;
      
      }
      
      
      float fbm(vec4 p){
          float sum = 0.;
          float amp = 1.;
          float scale = 1.;
          for(int i=0;i<6;i++) {
              sum += snoise(p*scale)*amp;
              p.w += 100.;
              amp *=0.9;
              scale *=2.;
          }
          return sum;
      }
      
      void main() {
      
          vec4 p = vec4(vPosition*3.,time*0.005);
      
          float noisy = fbm(p);
          vec4 p1 = vec4(vPosition*2.,time*0.005);
          float spots = max(snoise(p1),0.);
          gl_FragColor = vec4(noisy);
          gl_FragColor *= mix(1.,spots,0.7);
      }
    `
    });

    const geometry = new THREE.SphereBufferGeometry(1, 30, 30);
    this.perlin = new THREE.Mesh(geometry, this.perlinMaterial);
    this.scene1.add(this.perlin);
  }
  setupVr(scene) {
    this.renderer.xr.enabled = true;

    // const self = this;

    this.controllers = this.buildControllers(scene);
    const self = this;
    function onConnected(e) {
      this.gamepad = e.data.gamepad;
      self.updateControllers({
        right: { trigger: true, squeeze: true },
        left: { trigger: true, squeeze: true }
      });
    }

    const controllers = [
      this.renderer.xr.getController(0),
      this.renderer.xr.getController(1)
    ];
    controllers.forEach((element) => {
      element.addEventListener('connected', onConnected);
    });
  }

  updateControllers(info) {
    const self = this;

    function onSelectStart() {
      this.userData.selectPressed = true;
      if (this.userData.selected && !this.userData.attachedObject) {
        if (
          this.userData.selected.name == 'tabletSoundOn' &&
          self.props.description[self.props.lang] &&
          self.loadedMusicBar
        ) {
          textToSpeech(self);
          return 0;
        } else if (this.userData.selected.name == 'tabletSoundOff') {
          if (self.sound.isPlaying) {
            self.sound.pause();
            self.loadedMusicBar = true;
          }
          return 0;
        }
      }
      if (this.userData.selected !== undefined) {
        // this.posModelX = this.userData.selected.position.x;

        // }

        this.userData.attachedObject = this.userData.selected;
      }
    }

    function onSelectEnd() {
      this.userData.selectPressed = false;

      this.children[0].scale.z = 0;
      if (this.userData.attachedObject !== undefined) {
        this.remove(this.userData.attachedObject);

        this.userData.attachedObject = undefined;
        this.userData.selected = undefined;
        this.children[1].visible = false;
        this.children[0].scale.z = 10;
      }
    }

    function onSqueezeStart() {}

    function onSqueezeEnd() {
      this.userData.squeezePressed = false;
    }

    function onDisconnected() {
      const index = this.userData.index;

      if (self.controllers) {
        const obj = index == 0 ? self.controllers.right : self.controllers.left;

        if (obj) {
          if (obj.controller) {
            const controller = obj.controller;
            while (controller.children.length > 0)
              controller.remove(controller.children[0]);
            self.scene.remove(controller);
          }
          if (obj.grip) self.scene.remove(obj.grip);
        }
      }
    }

    if (info.right !== undefined) {
      const right = this.renderer.xr.getController(0);

      let trigger = false,
        squeeze = false;

      Object.keys(info.right).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
        if (key.indexOf('squeeze') != -1) squeeze = true;
      });

      if (trigger) {
        right.addEventListener('selectstart', onSelectStart);
        right.addEventListener('selectend', onSelectEnd);
      }

      if (squeeze) {
        right.addEventListener('squeezestart', onSqueezeStart);
        right.addEventListener('squeezeend', onSqueezeEnd);
      }

      right.addEventListener('disconnected', onDisconnected);
    }

    if (info.left !== undefined) {
      const left = this.renderer.xr.getController(1);

      let trigger = false,
        squeeze = false;

      Object.keys(info.right).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
      });
      if (trigger) {
        left.addEventListener('selectstart', onSelectStart);
        left.addEventListener('selectend', onSelectEnd);
      }

      if (squeeze) {
        left.addEventListener('squeezestart', onSqueezeStart);
        left.addEventListener('squeezeend', onSqueezeEnd);
      }

      left.addEventListener('disconnected', onDisconnected);
    }
  }

  buildControllers() {
    const controllerModelFactory = new XRControllerModelFactory();

    const geometrySphere = new THREE.IcosahedronBufferGeometry(this.radius, 2);

    const sphere = new THREE.Mesh(
      geometrySphere,
      new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.BackSide })
    );
    sphere.scale.set(0.01, 0.01, 0.01);

    const geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, -1)
    ]);

    const line = new THREE.Line(geometry);
    line.name = 'line';
    line.scale.z = 0;
    sphere.visible = false;
    const controllers = [];

    for (let i = 0; i <= 1; i++) {
      const controller = this.renderer.xr.getController(i);

      controller.side = i === 0 ? 'left' : 'right';

      controller.add(line.clone());
      controller.add(sphere.clone());
      controller.userData.selectPressed = false;
      this.dolly.add(controller);

      controllers.push(controller);

      const grip = this.renderer.xr.getControllerGrip(i);
      grip.add(controllerModelFactory.createControllerModel(grip));

      this.workingMatrix.identity().extractRotation(controller.matrixWorld);

      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);

      controller.children[0].scale.z = 10;
      // scene.add( grip );
      if (i == 1) {
        this.dolly.add(grip);
      } // this.cameraGroup.add(grip);
    }
    Tablet(this, controllers);

    return controllers;
  }

  handleController(controller) {
    const axes = controller.gamepad?.axes;

    this.workingMatrix.identity().extractRotation(controller.matrixWorld);
    this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    this.raycaster.ray.direction.set(0, 0, -1).applyMatrix4(this.workingMatrix);
    let intersectsModel;
    let iterationArray = [];
    if (this.tablet?.children.length > 0) {
      iterationArray = [...this.tablet.children];
    }
    intersectsModel = this.raycaster.intersectObjects(iterationArray, false);
    if (!controller.userData.attachedObject) {
      if (
        intersectsModel.length > 0 &&
        !intersectsModel[0].object.text &&
        intersectsModel[0].object.visible
      ) {
        controller.children[1].visible = true;
        controller.children[1].position.x = controller.children[0].position.x;
        controller.children[1].position.z = -intersectsModel[0].distance + 0.02;
        controller.children[0].scale.z = intersectsModel[0].distance;
        this.lineLength = intersectsModel[0].distance;
        controller.userData.selected = intersectsModel[0].object;
      } else {
        controller.children[0].scale.z = 10;
        controller.children[1].visible = false;
        controller.userData.selected = undefined;
      }
    }

    if (controller.userData.selectPressed) {
      if (intersectsModel.length > 0 && !intersectsModel[0].object.text) {
        controller.userData.selected = intersectsModel[0].object;
      } else {
        controller.children[0].scale.z = 10;
        controller.children[1].visible = false;
      }
      if (controller.userData.attachedObject) {
        controller.children[1].visible = true;
        controller.children[0].scale.z = this.lineLength;
      }
    }

    const speedGamepad = 0.2;
    let a = 0;
    let b = 0;

    if (axes?.length) {
      if (axes[2] != 0) {
        a += axes[2];
      }
      if (axes[3] != 0) {
        b += axes[3];
      }
    }

    const quaternion = this.dolly.quaternion.clone();

    if (a !== 0 || b !== 0) {
      this.dolly.quaternion.copy(this.camera?.quaternion);

      this.dolly.translateZ(b * speedGamepad);
      this.dolly.translateX(a * speedGamepad);

      this.dolly.quaternion.copy(quaternion);
    }
  }
  componentDidUpdate() {
    //Changing text mesh on change language
    if (this.vrButton?.vrButton && this.model) {
      this.vrButton.vrButton.disabled = false;
    }
  }
  componentDidMount() {
    this.containerRef?.current?.appendChild(this.renderer.domElement);
    if (!document.getElementById('vrButton'))
      this.vrButton = new VRButton(this.renderer);

    const self = this;

    self.renderer.xr.addEventListener('sessionstart', function () {
      self.vrButton.vrButton.disabled = false;
      self.scene.getObjectByName('instructionPanel').visible = true;

      self.dolly.position.z = 5;
    });

    //When user turn off the VR mode.
    self.renderer.xr.addEventListener('sessionend', function () {
      if (self.sound?.isPlaying) self.sound.pause();

      self.scene.getObjectByName('instructionPanel').visible = true;

      self.dolly.remove(self.camera);
      self.dolly.position.z = 5;
      self.dolly.position.y = 1.6;
      self.vrButton.vrButton.disabled = false;
      let adminPreviewContainer = document.getElementById('previewContainer');
      if (self.props.onsessionendAdmin && adminPreviewContainer) {
        adminPreviewContainer.appendChild(self.vrButton.vrButton);
      }
    });
    window.addEventListener('resize', this.resize());
  }

  componentWillUnmount() {
    this.containerRef?.current?.removeChild(this.renderer.domElement);
    window.removeEventListener('resize', this.resize);
    let renderer;
    !this.props.isPreview && this?.vrButton?.hideEnterVR();
    Object.keys(this).forEach((key) => {
      if (this[key] && typeof this[key].dispose === 'function') {
        if (!this[key].depth && !this[key].autoClearDepth) {
          this[key].dispose();
        } else if (this[key].depth) {
          this[key].clear(renderer, true, true, true);
        } else {
          renderer = this[key];
        }
      }
      this[key] = null;
    });
    if (renderer) {
      renderer.dispose();
    }
    location.reload();
  }

  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  render() {
    // this.controls.update();

    this.renderer.render(this.scene, this.camera);
    const dt = this.clock.getDelta();

    this.sunAround.quaternion.copy(this.camera.quaternion);

    if (this.controllers) {
      const self = this;
      this.controllers.forEach((controller, index) => {
        self.handleController(controller, dt, index);
      });
    }

    this.mixer?.update(dt);

    this?.cubeCamera1?.update(this.renderer, this.scene1);

    this.sunMaterial.uniforms.uPerlin.value = this?.cubeRenderTarget1?.texture;

    this.time += 0.05;
    this.sunMaterial.uniforms.time.value = this.time;
    this.perlinMaterial.uniforms.time.value = this.time;

    return (
      <div ref={this.containerRef} className={`${styles.sceneContainer}`}>
        {this.props.isPreviewContainer && this.state.flagFullscreen ? (
          <Link
            onClick={this.updateState}
            className={
              'px-2 absolute py-2 bg-gray-500 border-2 border-white rounded-lg text-center hover:bg-gray-600 hover hover:border-gray-100 top-5 right-5 text-white '
            }
          >
            {' '}
            <svg
              className={'self-end fill-current h-6 w-6 '}
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Exit Fullscreen</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </Link>
        ) : null}
        {this.state.progress && (
          <div
            className={
              'w-full h-full bg-black flex items-center flex-col justify-center absolute opacity-50'
            }
          >
            <p className="text-white opacity-100 font-gothic text-lg mb-2 text-center">
              Loading...
            </p>
            <div className="w-32 h-1 opacity-100 rounded-lg bg-slate-600 ">
              {this.state.percent != 0 ? (
                <div
                  style={{ width: Math.ceil(this.state.percent) + '%' }}
                  className={' rounded-full opacitiy-90 h-full bg-cyan-500'}
                ></div>
              ) : (
                <div className={'w-full h-full bg-gray-500'}></div>
              )}
            </div>
          </div>
        )}
      </div>
    );
    // }
  }
}

export default React.memo(SunCharacteristics);
