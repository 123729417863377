import { socket } from '../../common/socketConnection';

export const generalSceneSockets = (self) => {
  const checkMeshesVisibility = (i) => {
    if (i !== 0 && !self.props.name?.dividedByTwo) {
      self.modelsForSlider[i].visible = false;
    } else if (i !== 0 && i !== 1 && self.props.name?.dividedByTwo) {
      self.modelsForSlider[i].visible = false;
    } else if (i === 0) {
      self.modelsForSlider[i].visible = true;
    } else if (i === 1 && self.props.name?.dividedByTwo) {
      self.modelsForSlider[i].visible = true;
    }
  };
  socket.on('deleteMember', (socketId) => {
    if (socketId === socket.id) {
      self.props?.modelSlider.forEach((modelElement, i) => {
        if (self.modelsForSlider[i]) {
          self.modelsForSlider[i].position.set(
            modelElement.modelCoordInVrX,
            modelElement.modelCoordInVrY,
            modelElement.modelCoordInVrZ
          );
          checkMeshesVisibility(i);
        }
      });
    }
  });
  socket.on('selectedUserCamera', (selectedUserObj) => {
    if (self.controls) {
      if (selectedUserObj.selectedUserID) {
        self.selectedUser = selectedUserObj.selectedUserID;
        self.admin = selectedUserObj.admin;
      } else {
        self.selectedUser = undefined;
        self.controls.enabled = true;
      }
    }
  });
  socket.on('setModelsPosition', (currentModelFromBE) => {
    self.currentModelFromBE = currentModelFromBE;
    const lerpingStep = self.props?.name?.stepForLerp;
    const lerpDistance = self.props?.name?.lerpDistance
      ? self.props.name.lerpDistance
      : 40;
    if (self.currentModelFromBE !== 1) {
      self.modelsForSlider.forEach((sliderModel) => {
        lerpingStep
          ? (sliderModel.position.y -=
              (self.currentModelFromBE - 1) * lerpingStep * lerpDistance + 0.01)
          : (sliderModel.position.y -=
              0.01 * lerpDistance * (self.currentModelFromBE - 1));
        if (sliderModel.position.y > 0.8 || sliderModel.position.y < 0.4) {
          sliderModel.visible = false;
        } else {
          sliderModel.visible = true;
        }
      });
      self.blockerForLerp = self.currentModelFromBE;
    }
  });

  socket.on('memberData', (memberBEData) => {
    memberBEData.forEach((beMember) => {
      if (
        socket.id === self.admin &&
        beMember.id === self.selectedUser &&
        self.controls
      ) {
        self.controls.enabled = false;
        self.camera?.position.set(
          beMember.position.x,
          beMember.position.y,
          beMember.position.z
        );
        self.camera.rotation.x = beMember.pb.x;
        self.camera.rotation.y = beMember.pb.y;
        self.camera.rotation.z = beMember.pb.z;
      }
    });
  });
  socket.on('lerpingUp', ({ resetedlerpingUp, fromEdgeLerp, curModel }) => {
    if (fromEdgeLerp) {
      self.lerpingPosition = 0;
      // self.blockerForLerp = blockerForLerp;
    }
    self.resetedlerpingUp = resetedlerpingUp;
    self.curModel = curModel;
  });
  socket.on('lerpingDown', ({ resetedlerpingDown, fromEdgeLerp, curModel }) => {
    if (fromEdgeLerp) {
      self.lerpingPosition = 0;
    }
    self.resetedlerpingDown = resetedlerpingDown;
    self.curModel = curModel;
  });
};
