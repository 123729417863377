/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreOptions from '../../../../../common/moreOptions';
import { useHistory } from 'react-router-dom';

const TopicRow = ({
  topicName,
  topicId,
  options,
  setDeleteConfirmation,
  setShowPositionChanger,
  categoryId,
  categoryName,
  position
}) => {
  const history = useHistory();
  const onMoreOptionSelect = (selectedOption) => {
    switch (selectedOption.val) {
      case 1: {
        history.push({
          pathname: `/admin/content/${categoryId}/${topicId}`,
          state: {
            categoryName: categoryName,
            mode: 'edit'
          }
        });
        return;
      }
      case 2: {
        setDeleteConfirmation({
          show: true,
          topicId: topicId
        });
        return;
      }
      case 3: {
        setShowPositionChanger({
          show: true,
          topicId: topicId,
          categoryId: categoryId,
          position
        });
        return;
      }

      default:
        return;
    }
  };

  return (
    <div
      className={'w-full flex place-items-center justify-between px-6 border-t'}
    >
      <div className={'flex justify-center text-center'}>{topicName}</div>
      <div className={'flex justify-center text-center w-3.5 cursor-pointer'}>
        <MoreOptions options={options} onOptionSelect={onMoreOptionSelect} />
      </div>
    </div>
  );
};

export default TopicRow;
