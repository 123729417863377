import isPositiveInteger from './sliderFunctional/isPositiveinteger';
export const onEnterVr = (self) => {
  const loadedModel = self.scene.getObjectByName('loadedModel');
  self.room.position.set(0, 0, 0);
  self.room.rotation.set(0, 0, 0);
  self.room.scale.x = 1;
  self.room.scale.y = 1;
  self.room.scale.z = 1;
  self.camera.position.set(0, 0.8, 2.5);
  self.modelsForSlider = loadedModel.children.filter(checkForNumberInName);

  function checkForNumberInName(element) {
    return isPositiveInteger(element.name);
  }
  if (self.room.getObjectByName('environment')) {
    self.room.getObjectByName('environment').visible = true;
    self.environmentSetted = true;
  }
  self.modelsForSlider.sort((a, b) => {
    if (Number(a.name) > Number(b.name)) {
      return 1;
    } else {
      return -1;
    }
  });
  self.props?.meshes?.forEach((element, textIterator) => {
    if (self.textMeshes[textIterator]) {
      if (
        self.modelsForSlider[Number(element.attachTo) - 1].name ==
        element.attachTo
      ) {
        self.textMeshes[textIterator].material.color.setHex(0x00d6d9);
        if (element.modelType !== 'children')
          self.modelsForSlider[Number(element.attachTo) - 1].attach(
            self.textMeshes[textIterator]
          );
      }
      const textScaleInVr = {
        x: element.textScaleInVrX
          ? element.textScaleInVrX
          : self.textMeshes[textIterator]?.scale?.x,
        y: element.textScaleInVrY
          ? element.textScaleInVrY
          : self.textMeshes[textIterator]?.scale?.y,
        z: element.textScaleInVrZ
          ? element.textScaleInVrZ
          : self.textMeshes[textIterator]?.scale?.z
      };
      const textPositionInVr = {
        x: element.textPositionInVrX ? element.textPositionInVrX : 0,
        y: element.textPositionInVrY ? element.textPositionInVrY : 0,
        z: element.textPositionInVrZ ? element.textPositionInVrZ : 0
      };
      const textRotation = {
        x: element.textRotationInVrX ? element.textRotationInVrX : 0,
        y: element.textRotationInVrY ? element.textRotationInVrY : 0,
        z: element.textRotationInVrZ ? element.textRotationInVrZ : 0
      };
      self.textMeshes[textIterator]?.position.set(
        textPositionInVr.x,
        textPositionInVr.y,
        textPositionInVr.z
      );
      self.textMeshes[textIterator].scale.x = textScaleInVr.x;
      self.textMeshes[textIterator].scale.y = textScaleInVr.y;
      self.textMeshes[textIterator].scale.z = textScaleInVr.z;
      self.textMeshes[textIterator].rotation.set(
        textRotation.x,
        textRotation.y,
        textRotation.z
      );
    }
  });
  self.props.modelSlider.forEach((modelElement, i) => {
    if (i !== 0 && modelElement.visible !== false) {
      self.modelsForSlider[i].visible = false;
    }
    const modelSliderScale = {
      x: modelElement.modelScaleInVrX
        ? modelElement.modelScaleInVrX
        : self.modelsForSlider[i].scale.x,
      y: modelElement.modelScaleInVrY
        ? modelElement.modelScaleInVrY
        : self.modelsForSlider[i].scale.y,
      z: modelElement.modelScaleInVrZ
        ? modelElement.modelScaleInVrZ
        : self.modelsForSlider[i].scale.z
    };
    self.modelsForSlider[i].position.set(
      modelElement.modelCoordInVrX,
      modelElement.modelCoordInVrY,
      modelElement.modelCoordInVrZ
    );

    self.modelsForSlider[i].scale.set(
      modelSliderScale.x,
      modelSliderScale.y,
      modelSliderScale.z
    );
    self.modelsForSlider[i].rotation.set(
      modelElement.modelRotationX ? modelElement.modelRotationX : 0,
      modelElement.modelRotationY ? modelElement.modelRotationY : 0,
      modelElement.modelRotationZ ? modelElement.modelRotationZ : 0
    );
  });
  self.hiddenTextMeshes.forEach((element) => {
    element.visible = true;
  });
};
