import * as THREE from 'three';
export const remainderSolution = (
  textMeshC,
  textMeshO,
  textMeshH,
  groupObject,
  scene
) => {
  groupObject = new THREE.Mesh(new THREE.BoxGeometry(3, 3, 3), {});
  groupObject.position.set(10, 0, -20);

  const oxygenTextMesh1 = textMeshO.clone();
  const oxygenTextMesh2 = textMeshO.clone();

  const oxygenGeometry = new THREE.SphereGeometry(2, 60, 30);
  const oxygenMaterial = new THREE.MeshStandardMaterial({ color: 0xe74c3c });
  const Oxygen = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
  Oxygen.add(oxygenTextMesh1);
  oxygenTextMesh1.position.set(0, 0, 2);
  const oxygenClone = Oxygen.clone();
  oxygenClone.add(oxygenTextMesh2);
  oxygenTextMesh2.position.set(0, 0, 2);
  Oxygen.add(oxygenClone);
  oxygenClone.position.set(2, -1, 0);
  groupObject.add(Oxygen);

  const secondOxygen = Oxygen.clone();
  secondOxygen.position.set(-1, -8, 0);
  secondOxygen.rotation.z = 20;
  groupObject.add(secondOxygen);

  const thirdOxygen = Oxygen.clone();
  thirdOxygen.position.set(1, -13, 0);
  thirdOxygen.rotation.z = 19;
  groupObject.add(thirdOxygen);

  const fourthOxygen = Oxygen.clone();
  fourthOxygen.position.set(-3, -19, 0);
  fourthOxygen.rotation.z = 20;
  groupObject.add(fourthOxygen);

  const fifthOxygen = Oxygen.clone();
  fifthOxygen.position.set(4, -19, 0);
  fifthOxygen.rotation.z = 25;
  groupObject.add(fifthOxygen);

  const sixthOxygen = Oxygen.clone();
  sixthOxygen.position.set(-3, -25, 0);
  sixthOxygen.rotation.z = 20;
  groupObject.add(sixthOxygen);

  groupObject.visible = false;
  scene.add(groupObject);
  groupObject.name = 'photosyntes';
};
