import isPositiveInteger from './isPositiveinteger';

const lerpingUpModel = (self) => {
  const curObjects = self.modelsForSlider.filter((element) =>
    isPositiveInteger(element.name)
  );
  const lerpingStep = self.props.name.stepForLerp;
  if (Math.floor(self.lerpingPosition) === self.props.name.lerpDistance) {
    self.resetedlerpingUp = false;
    self.resetedlerpingDown = false;
    self.lerpingPosition = 0;
    self.blockerForLerp--;
  } else if (self.resetedlerpingUp) {
    curObjects.forEach((curObject) => {
      if (curObject.position.y > 0.8 || curObject.position.y < 0.4) {
        curObject.visible = false;
      } else {
        curObject.visible = true;
      }
      lerpingStep
        ? (curObject.position.y += lerpingStep)
        : (curObject.position.y += 0.001);
    });
    self.lerpingPosition++;
  }
};
export default lerpingUpModel;
