import React, { useState, useEffect, useCallback } from 'react';
import { useHttp } from '../../../hooks/useHttp';
import { countryList } from '../../../assets/mockData/mockData';
import { inputItems } from '../../../assets/mockData/mockData';
import Loader from '../../../common/loader';
import { useAppContext } from '../../../context/AppProvider';
import { useHistory } from 'react-router-dom';
let timeDelay;

export default function Profile() {
  const { store, setUserAction } = useAppContext();
  const { selectedLanguage, user } = store;
  const history = useHistory();

  const { request, loading, apiUrl } = useHttp();
  const token = window.localStorage.getItem('userToken');
  const { userId } = JSON.parse(window.localStorage.getItem('userData'));
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [age, setAge] = useState(0);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({ type: '', value: null });
  const [showSave, setShowSave] = useState(false);

  useEffect(() => {
    if (
      firstName !== user.firstname ||
      lastName !== user.lastname ||
      country !== user.country ||
      age !== user.age
    ) {
      setShowSave(true);
    } else {
      setShowSave(false);
    }
  }, [firstName, lastName, country, age]);

  useEffect(() => {
    if (user.email) {
      setFirstName(user.firstname);
      setLastName(user.lastname);
      setCountry(user.country);
      setAge(user.age);
    }
  }, [user]);

  const getId = useCallback(async () => {
    if (userId != null) {
      try {
        const fetched = await request(
          apiUrl + `/api/auth/${userId}`,
          'GET',
          null,
          {
            Authorization: `${token}`
          },
          history
        );

        setUserAction(fetched);
      } catch (err) {
        console.log('Error occured....', err.message);
      }
    }
  }, [token, userId, request]);
  useEffect(() => {
    if (error.type) {
      timeDelay = setTimeout(() => {
        setError({ type: '', value: null });
        clearTimeout(timeDelay);
      }, 4000);
    }
  }, [error]);
  const sentDataHandler = async () => {
    try {
      if (!checkEmptyObj()) {
        if (age > 5 && age < 100) {
          await request(
            apiUrl + `/api/user/${userId}`,
            'PATCH',
            {
              firstname: firstName,
              lastname: lastName,
              country: country,
              age: age
            },
            {
              Authorization: `${token}`
            },
            history
          );
          window.scroll(0, 0);

          setError({
            type: 'Passed',
            value: 'Your Account has been successfully updated'
          });
          setShowSave(false);
          getId();
        } else {
          setError({ type: 'Error', value: 'Age must be between 5 and 100 ' });
        }
      } else {
        setError({ type: 'Error', value: 'Please enter required data' });
      }
    } catch (err) {
      console.log('Error occured...........', err);

      setError({ type: 'Error', value: err.message });
    }
    setOpen(false);
  };

  const checkEmptyObj = () => {
    let isEmpty = true;
    if (lastName || firstName) {
      isEmpty = false;
    } else {
      return true;
    }

    return isEmpty;
  };

  return (
    <>
      <div className="flex flex-col grow">
        <div className="bg-Profile relative mt-30 w-full h-600 bg-center bg-cover">
          <div
            className={` border mt-10 transition ease-in-out smmax:w-3/4 xsmax:w-3/4  duration-100 ${
              error.value === null || open ? 'hidden ' : ''
            }  ${
              error.type === 'Passed'
                ? 'border-green-400 text-green-700 bg-green-100 '
                : 'border-red-400 text-red-700 bg-red-100 '
            } translate-x-10 right-20 absolute  flex justify-between px-6   py-3 rounded `}
            role="alert"
          >
            <div className="flex flex-col smmin:pr-10">
              <strong className="font-bold  ">
                {error.type === 'error' ? error.type : error.type}
              </strong>
              <span className="block sm:inline ">{error.value}</span>
            </div>
            <span className="justify-end self-end  flex pl-3 py-3">
              <svg
                onClick={
                  (() => setOpen(true),
                  () => setError({ type: '', value: null }))
                }
                className={`self-end fill-current h-6 w-6 ${
                  error.type === 'Passed' ? 'text-green-500' : 'text-red-500 '
                } `}
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        </div>
        <div className="h-36 min-w-full ml-1 -mt-12 bg-white -rotate-3 center"></div>
        <section className="z-10 py-5 bg-blueGray-200">
          <div className=" container mx-auto px-4">
            <div className=" flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded-lg -mt-64">
              <div className="px-6 my-5">
                <div className="">
                  <div className="w-full smmax:flex-col flex-wrap  lg:order-2 flex smmax:justify-center sm:justify-between">
                    <div className="smmax:hidden">
                      <div className=" items-center justify-center flex  h-40 w-40  align-middle self-center -mt-28   max-w-150-x"></div>
                    </div>
                    <div className=" smmax:self-center">
                      <div className="shadow-xl rounded-full items-center justify-center flex  h-40 w-40 bg-cyan-800  border-none -mt-28   max-w-150-x">
                        <h1 className=" text-5xl font-tinos">
                          {user.firstname &&
                            user.firstname[0].toUpperCase() +
                              ' ' +
                              user.lastname[0].toUpperCase()}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 sm:mt-0">
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          {inputItems.PersonalInfo[selectedLanguage]}
                        </h3>
                        <div></div>
                      </div>
                    </div>
                    <div className="mt-10   md:col-span-2">
                      <form action="#" method="POST">
                        <div className="shadow-3md overflow-hidden sm:rounded-md">
                          <div className="px-4 py-5 bg-white sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                              <div className="col-span-6 sm:col-span-6">
                                <label
                                  htmlFor="email-address"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  {inputItems.Email[selectedLanguage]}
                                </label>
                                <input
                                  type="text"
                                  name="email-address"
                                  id="email-address"
                                  disabled
                                  value={user.email}
                                  className="mt-1 block  w-full py-2 px-3 border bg-gray-200 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="first-name"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  {inputItems.Firstname[selectedLanguage]}
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  id="firstname"
                                  disabled={loading ? 'disabled' : ''}
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  autoComplete="given-name"
                                  className={`mt-1 block  w-full py-2 px-3 border ${
                                    loading ? 'bg-gray-200' : 'bg-white'
                                  } border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="last-name"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  {inputItems.Lastname[selectedLanguage]}
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  id="lastname"
                                  value={lastName}
                                  disabled={loading ? 'disabled' : ''}
                                  onChange={(e) => setLastName(e.target.value)}
                                  autoComplete="family-name"
                                  className={`mt-1 block  w-full py-2 px-3 border ${
                                    loading ? 'bg-gray-200' : 'bg-white'
                                  } border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                />
                              </div>

                              <div className="col-span-6 lgmin:col-span-3 lgmax:col-span-6">
                                <label
                                  htmlFor="country"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  {inputItems.Country[selectedLanguage]}
                                </label>
                                <select
                                  id="country"
                                  name="country"
                                  onChange={(e) => setCountry(e.target.value)}
                                  disabled={loading ? 'disabled' : ''}
                                  autoComplete="country-name"
                                  className={`mt-1 block  w-full py-2 h-10  overflow-scroll px-3 border ${
                                    loading ? 'bg-gray-200' : 'bg-white'
                                  } border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                >
                                  {countryList.map((element, index) => {
                                    return (
                                      <option
                                        className="w-16 h-40"
                                        key={index}
                                        selected={element === country}
                                      >
                                        {element}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>

                              <div className="col-span-6 sm:col-span-6 lgmin:col-span-3">
                                <label
                                  htmlFor="age"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  {inputItems.Age[selectedLanguage]}
                                </label>
                                <input
                                  type="number"
                                  name="age"
                                  id="age"
                                  disabled={loading ? 'disabled' : ''}
                                  onChange={(e) => setAge(e.target.value)}
                                  value={age}
                                  autoComplete="address-level2"
                                  className={`mt-1 block  w-full py-2 px-3 border ${
                                    loading ? 'bg-gray-200' : 'bg-white'
                                  } border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                />
                              </div>
                            </div>
                            {showSave && (
                              <div className="px-4 py-3 my-5 bg-gray-50 text-right sm:px-6">
                                {loading ? (
                                  <div className="inline-flex justify-center py-2 px-4 focus:ring-2 focus:ring-offset-2">
                                    <Loader />
                                  </div>
                                ) : (
                                  <button
                                    disabled={loading ? 'disabled' : ''}
                                    type="submit"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      sentDataHandler();
                                    }}
                                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  >
                                    {inputItems.SaveButton[selectedLanguage]}
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className=" z-10 h-32"></div>
      </div>
    </>
  );
}
