import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry';
import styles from './styles.css';
import { VRButton } from '../../../../common/VRButton';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { roundedSquare } from '../../../../common/SceneModelGeneral/roundedSquareBg';
import Generator from './Generator';
import createBox from './createBox';
import changeLink from './changelLinks';
import downloadModel from './downloadFunc';
import { instruction } from './instructionText';
import { chemElem } from './chemElements';
import imageBg from '../../../../assets/img/gradientScene.jpeg';

class MoleculeConstructor extends Component {
  constructor(props) {
    super(props);
    this.lang = props.lang;
    this.containerRef = React.createRef();
    this.materials = {};

    this.flagForChangeLinkText = false;
    this.flagForChangeDragDropText = false;
    this.flagForLinks = true;
    this.flagForDeleteFormula = true;
    this.chemFormulaForExport = new THREE.Mesh();

    this.linkForDownload = document.createElement('a');
    this.linkForDownload.style.display = 'none';
    this.linkForDownload.style.height = 100;
    this.linkForDownload.style.width = 100;
    this.linkForDownload.textContent = 'Download buttion';
    this.linkForDownload.style.border = 1;

    this.state = {
      progress: false,
      loadedFlag: false,
      percent: 0,
      stateFlag: false,
      modelPosition: [],
      modelName: '',
      language: 5,
      flagFullscreen: false,
      loadingType: props.type
    };
    this.plusArrays = [];
    this.clock = new THREE.Clock();
    this.scene = new THREE.Scene();
    this.scene.background = this.backgroundTexture;
    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    );

    this.camera.position.set(-0.2, 0.8, 2.5);

    this.scene.add(this.camera);

    const texture = new THREE.TextureLoader().load(imageBg);

    this.scene.background = texture;
    this.modelPosZ = 0;
    this.modelPosY = 0;
    this.modelPosX = 0;
    this.lineLength = 0;

    this.boxCoords = [{ x: 0, y: -1, z: -3.5, flag: true, name: 'box0' }];
    this.boxTrueCoords = [{ x: 0, y: -1, z: -3.5, flag: true, name: 'box0' }];

    this.renderer = new THREE.WebGLRenderer({
      antialias: false,
      powerPreference: 'high-performance'
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.raycasterModelBox = new THREE.Raycaster();
    this.workingModelBoxMatrix = new THREE.Matrix4();
    this.scene.add(this.chemFormulaForExport);

    // this.loadingBar = new LoadingBar();
    // this.loadingBar.visible = false;
    this.ambientLight = new THREE.HemisphereLight(0xffffff);
    this.scene.add(this.ambientLight);
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight.position.set(3, 3, 3).normalize();
    this.directionalLight2 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight2.position.set(5, 5, 5).normalize();
    this.directionalLight3 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight3.position.set(5, 5, -5).normalize();
    this.directionalLight4 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight4.position.set(-7, -5, -5).normalize();
    this.directionalLight5 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight5.position.set(-5, 0, 0).normalize();
    this.directionalLight6 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight6.position.set(0, -5, 0).normalize();

    this.scene.add(this.directionalLight);
    this.scene.add(this.directionalLight2);
    this.scene.add(this.directionalLight3);
    this.scene.add(this.directionalLight4);
    this.scene.add(this.directionalLight5);
    this.scene.add(this.directionalLight6);
    this.start_x;
    this.start_y;

    const scaleForSquareX = 0.38;
    const scaleForSquareY = 0.41;
    const scaleForSquareZ = 0.4;
    const positionForSquareX = -0.1;
    const positionForSquareY = -2;
    const positionForSquareZ = -4;
    const positionForRightSquareX = 2.15;

    roundedSquare(
      this.scene,
      props,
      scaleForSquareX,
      scaleForSquareY,
      scaleForSquareZ,
      positionForSquareX,
      positionForSquareY,
      positionForSquareZ,
      positionForRightSquareX
    );

    this.raycaster = new THREE.Raycaster();
    this.workingMatrix = new THREE.Matrix4();
    this.workingVector = new THREE.Vector3();
    this.origin = new THREE.Vector3();

    this.dolly = new THREE.Object3D();
    this.dolly.position.z = 5;
    this.scene.add(this.dolly);
    this.dummyCam = new THREE.Object3D();
    this.camera.add(this.dummyCam);

    this.model;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();
    this.controls.maxPolarAngle = Math.PI * 0.5;
    this.controls.minDistance = 1;
    this.controls.maxDistance = 10;

    this.renderer.setAnimationLoop(this.render.bind(this));

    this.initScene();
    this.setupVr(this.scene);
  }

  initScene() {
    this.room = new THREE.LineSegments(
      new BoxLineGeometry(0, 0, 0, 0, 0, 0),
      new THREE.LineBasicMaterial({ color: 0x808080 })
    );
    this.scene.add(this.room);
    this.room.name = 'room';

    chemElem.map((element) => {
      Generator(this.room, element);
    });

    createBox(this.room, this.boxCoords);
  }
  removeFormula(intersect) {
    this.delete = intersect[0].object;

    if (intersect[0].object.name.indexOf('delete') != -1) {
      for (let i = 0; i <= 3; i++) {
        this.chemFormulaForExport.children.map((element) => {
          if (element.name != 'groupForMesh') {
            this.boxCoords = [
              { x: 0, y: -1, z: -3.5, flag: true, name: 'box0' }
            ];
            this.boxTrueCoords = [
              { x: 0, y: -1, z: -3.5, flag: true, name: 'box0' }
            ];
            this.chemFormulaForExport.remove(element);
          } else {
            this.chemFormulaForExport.getObjectByName('groupForMesh').clear();
          }
        });
        for (let i = 0; i < this.room.children.length; i++) {
          if (this.room.children[i].name.indexOf('box') === 0) {
            this.room.remove(this.room.children[i]);
          }
        }
      }
      createBox(this.room, this.boxCoords);
      this.flagForDeleteFormula = false;
    }
    // this.chemFormulaForExport.getObjectByName('groupForMesh').clear();
    this.flagForDeleteFormula = false;
  }
  async loadText(room, text, index) {
    const loader = new FontLoader();
    const self = this;
    await loader.load(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/helvetiker_regular.typeface.json',
      function (font) {
        let textGeometry;
        if (text.bevel) {
          textGeometry = new TextGeometry(text.EN, {
            font: font,
            size: text.modelSize ? text.modelSize : 0.1,
            height: text.modelHeight ? text.modelHeight : 0.03,

            bevelEnabled: true,
            bevelThickness: 0.05,
            bevelSize: 0.05,
            bevelOffset: 0,
            bevelSegments: 5
          });
        } else {
          textGeometry = new TextGeometry(text.EN, {
            font: font,
            size: text.modelSize ? text.modelSize : 0.1,
            height: text.modelHeight ? text.modelHeight : 0.03
          });
        }
        let textMaterial = new THREE.MeshBasicMaterial({
          color: text.color ? text.color : 0xffffff
        });

        var mesh = new THREE.Mesh(textGeometry, textMaterial);
        room.add(mesh);
        if (index > 0 && text.visible != 'false') {
          mesh.visible = false;
        }
        if (text.name === 'plus') {
          mesh.rotation.z = Math.PI * 0.25;
          mesh.lookAt(0, 0, 0);
        }

        if (self.props.textType != 'static') {
          if (text.name) {
            mesh.name = text.name;
          } else {
            mesh.name = 'text';
          }

          if (text.modelType === 'children') {
            room.getObjectByName('parrent').add(mesh);
          }
        }
        if (text.modelCoordsX && text.modelCoordsY && text.modelCoordsZ) {
          mesh.position.set(
            text.modelCoordsX,
            text.modelCoordsY,
            text.modelCoordsZ
          );
        } else {
          mesh.position.set(0, 0, 0);
        }
        if (text.modelRotationX && text.modelRotationY && text.modelRotationZ) {
          mesh.rotation.set(
            text.modelRotationX,
            text.modelRotationY,
            text.modelRotationZ
          );
        } else {
          mesh.rotation.set(0, 0, 0);
        }
      }

      // onProgress callback

      // onError callback
    );
  }

  setupVr(scene) {
    this.renderer.xr.enabled = true;
    const self = this;
    this.controllers = this.buildControllers(scene);
    function onConnected(e) {
      this.gamepad = e.data.gamepad;
      self.updateControllers({
        right: { trigger: true, squeeze: true },
        left: { trigger: true, squeeze: true }
      });
    }

    const controllers = [
      this.renderer.xr.getController(0),
      this.renderer.xr.getController(1)
    ];

    controllers.map((element) => {
      element.addEventListener('connected', onConnected);
    });
  }

  updateControllers(info) {
    const self = this;

    function onSelectStart() {
      this.userData.squeezePressed = true;
      if (
        this.userData.selected &&
        !this.userData.attachedObject &&
        !self.posModelX &&
        !self.posModelY &&
        !self.posModelZ &&
        this.userData.selected.name.indexOf('delete') === -1
      ) {
        self.posModelX = this.userData.selected.position.x;
        self.posModelY = this.userData.selected.position.y;
        self.posModelZ = this.userData.selected.position.z;
        self.rotationModelX = this.userData.selected.rotation.x;
        self.rotationModelY = this.userData.selected.rotation.y;
        self.rotationModelZ = this.userData.selected.rotation.z;

        this.attach(this.userData.selected);
        self.setState({
          modelName: this.userData.selected.name,
          executed: true
        });
        self.selectedClone = this.userData.selected.clone();
        this.userData.attachedObject = this.userData.selected;
        self.room.add(self.selectedClone);

        self.selectedClone.position.set(
          self.posModelX,
          self.posModelY,
          self.posModelZ
        );
        self.selectedClone.rotation.set(
          self.rotationModelX,
          self.rotationModelY,
          self.rotationModelZ
        );
      }
    }

    function onSelectEnd() {
      this.userData.squeezePressed = false;
      self.flagForLinks = true;
      self.flagForDeleteFormula = true;
      if (this.userData.attachedObject !== undefined) {
        const settingDefaulutPosition = (obj) => {
          this.remove(this.userData.attachedObject);
          const clonedObject = obj.clone();

          if (self.collisionDetected) {
            self.chemFormulaForExport.add(clonedObject);
            self.collisionDetected.visible = false;
            const cubePosition = self.collisionDetected.position;
            self.collisionDetected.scale.set(0, 0, 0);
            self.boxCoords.map((element) => {
              if (
                element.x === cubePosition.x &&
                element.y === cubePosition.y &&
                element.z === cubePosition.z
              ) {
                element.flag = true;
                self.boxTrueCoords.push(element);
              }
            });
            createBox(
              self.room,
              self.boxCoords,
              true,
              cubePosition.x,
              cubePosition.y,
              cubePosition.z,
              self.boxTrueCoords,
              self.chemFormulaForExport,
              self.plusArrays,
              self
            );
            self.room.remove(self.collisionDetected);

            clonedObject.position.set(
              cubePosition.x,
              cubePosition.y,
              cubePosition.z
            );
            self.collisionDetected.position.set(0, 0, 0);
          } else {
            self.room
              .getObjectByName(self.state.modelName)
              .position.set(self.posModelX, self.posModelY, self.posModelZ);
          }

          self.room
            .getObjectByName(self.state.modelName)
            .rotation.set(
              self.rotationModelX,
              self.rotationModelY,
              self.rotationModelZ
            );
        };
        settingDefaulutPosition(self.selectedClone);

        this.userData.attachedObject = undefined;
        this.userData.selected = undefined;
        this.children[1].visible = false;
        this.children[0].scale.z = 10;
        self.posModelX = undefined;
        self.posModelY = undefined;
        self.posModelZ = undefined;
        self.posModelX = undefined;
        self.posModelY = undefined;
        self.posModelZ = undefined;
      }
    }

    function onDisconnected() {
      const index = this.userData.index;

      if (self.controllers) {
        const obj = index == 0 ? self.controllers.right : self.controllers.left;

        if (obj) {
          if (obj.controller) {
            const controller = obj.controller;
            while (controller.children.length > 0)
              controller.remove(controller.children[0]);
            self.scene.remove(controller);
          }
          if (obj.grip) self.scene.remove(obj.grip);
        }
      }
    }

    if (info.right !== undefined) {
      const right = this.renderer.xr.getController(0);

      let trigger = false;

      Object.keys(info.right).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
      });

      if (trigger) {
        right.addEventListener('selectstart', onSelectStart);
        right.addEventListener('selectend', onSelectEnd);
      }

      right.addEventListener('disconnected', onDisconnected);
    }

    if (info.left !== undefined) {
      const left = this.renderer.xr.getController(1);

      let trigger = false;

      Object.keys(info.left).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
      });

      if (trigger) {
        left.addEventListener('selectstart', onSelectStart);
        left.addEventListener('selectend', onSelectEnd);
      }

      left.addEventListener('disconnected', onDisconnected);
    }
  }

  buildControllers() {
    const controllerModelFactory = new XRControllerModelFactory();
    const geometrySphere = new THREE.IcosahedronBufferGeometry(this.radius, 2);

    const sphere = new THREE.Mesh(
      geometrySphere,
      new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.BackSide })
    );
    sphere.scale.set(0.01, 0.01, 0.01);

    const geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, -1)
    ]);

    const line = new THREE.Line(geometry);
    line.name = 'line';
    line.scale.z = 2;
    sphere.visible = false;
    const controllers = [];

    for (let i = 0; i <= 1; i++) {
      const controller = this.renderer.xr.getController(i);

      controller.add(line.clone());
      controller.add(sphere.clone());
      controller.userData.selectPressed = false;

      this.dolly.add(controller);

      controllers.push(controller);

      const grip = this.renderer.xr.getControllerGrip(i);
      grip.add(controllerModelFactory.createControllerModel(grip));

      this.workingMatrix.identity().extractRotation(controller.matrixWorld);

      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);

      controller.children[0].scale.z = 10;

      this.dolly.add(grip);
    }

    return controllers;
  }
  handleController(controller) {
    const axes = controller.gamepad?.axes;
    this.intersectsBox;
    const speed = 0.03;
    let boxObject = [];
    let intersectsModel = [];
    let a = 0;
    let b = 0;
    this.workingMatrix.identity().extractRotation(controller.matrixWorld);
    this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    this.raycaster.ray.direction.set(0, 0, -1).applyMatrix4(this.workingMatrix);
    intersectsModel = this.raycaster.intersectObjects(
      this.room.children,
      false
    );
    let intersectsLink = [];
    if (this.chemFormulaForExport.getObjectByName('groupForMesh')) {
      intersectsLink = this.raycaster.intersectObjects(
        this.chemFormulaForExport.getObjectByName('groupForMesh').children
      );
    }
    if (!controller.userData.attachedObject) {
      if (intersectsModel.length > 0 && intersectsModel[0]?.object?.visible) {
        controller.children[1].visible = true;
        controller.children[1].position.x = controller.children[0].position.x;
        controller.children[1].position.z = -intersectsModel[0].distance + 0.02;
        controller.children[0].scale.z = intersectsModel[0].distance;
        this.lineLength = intersectsModel[0].distance;
        controller.userData.selected = intersectsModel[0].object;
      } else if (
        intersectsLink.length > 0 &&
        intersectsLink[0].object.parent.name.indexOf('plus') != -1
      ) {
        controller.children[0].scale.z = intersectsLink[0].distance;
        controller.children[1].position.z = -intersectsLink[0].distance + 0.03;
        this.lineLength = intersectsLink[0].distance;
        controller.children[1].visible = true;
      } else {
        controller.children[0].scale.z = 10;
        controller.children[1].visible = false;
      }
    }
    if (controller.userData.squeezePressed) {
      let intersectsLink = [];
      if (this.chemFormulaForExport.getObjectByName('groupForMesh')) {
        intersectsLink = this.raycaster.intersectObjects(
          this.chemFormulaForExport.getObjectByName('groupForMesh').children
        );

        if (this.flagForLinks && intersectsLink.length > 0) {
          this.link = intersectsLink[0].object;
          if (intersectsLink[0].object.parent.name.indexOf('plus') != -1)
            changeLink(this.chemFormulaForExport, this.link, this);
        } else {
          this.flagForLinks = false;
        }
      }
      if (this.flagForDeleteFormula && intersectsModel.length > 0) {
        this.removeFormula(intersectsModel);
      } else {
        this.flagForDeleteFormula = false;
      }
      if (
        (intersectsModel.length > 0 &&
          !controller.userData.attachedObject &&
          intersectsModel[0].object.name.indexOf('box') === -1 &&
          intersectsModel[0].object.name != 'room' &&
          intersectsModel[0].object.name != 'DragDrop' &&
          intersectsModel[0].object.name != 'NeighbourDragDrop' &&
          intersectsModel[0].object.name != 'ChangeLinkNum') ||
        intersectsLink.length > 0
      ) {
        if (
          intersectsModel.length > 0 &&
          intersectsModel[0].object.name.indexOf('link') === -1
        ) {
          // intersectsModel[0].object.add(this.room.children);
          controller.userData.selected = intersectsModel[0].object;
        }

        if (intersectsModel.length > 0) {
          controller.children[0].scale.z = intersectsModel[0].distance;
          controller.children[1].position.z =
            -intersectsModel[0].distance + 0.03;
          controller.children[1].visible = true;
        } else if (intersectsLink[0].object.parent.name.indexOf('plus') != -1) {
          controller.children[0].scale.z = intersectsLink[0].distance;
          controller.children[1].position.z =
            -intersectsLink[0].distance + 0.03;
          controller.children[1].visible = true;
        }
        controller.children[1].position.x = controller.children[0].position.x;
      }

      if (controller.userData.attachedObject) {
        this.elementAttached =
          controller.userData.attachedObject.getObjectByName(
            this.state.modelName
          );
        controller.children[1].visible = true;
        controller.children[0].scale.z = this.lineLength;
        const detectCollisionCubes = (object1, object2) => {
          let intersectedWith;
          object1.geometry.computeBoundingBox(); //not needed if its already calculated
          object2.map((element) => {
            element.geometry.computeBoundingBox();
          });

          object1.updateMatrixWorld();
          object2.map((element) => {
            element.updateMatrixWorld();
          });

          let objectBoxForIntersection = [];

          var box1 = object1.geometry.boundingBox.clone();
          box1.applyMatrix4(object1.matrixWorld);

          object2.map((element) => {
            objectBoxForIntersection.push(element.geometry.boundingBox.clone());
          });
          objectBoxForIntersection.map((elementForObj, index) => {
            elementForObj.applyMatrix4(object2[index].matrixWorld);
          });

          objectBoxForIntersection.map((element, index) => {
            if (box1.intersectsBox(element)) {
              intersectedWith = object2[index];
            }
          });
          return intersectedWith;
        };
        this.room.children.map((element) => {
          if (element.name.indexOf('box') == 0) {
            boxObject.push(element);
          }
        });
        this.collisionDetected = detectCollisionCubes(
          this.elementAttached,
          boxObject
        );
      }
    }
    if (axes?.length) {
      //=================> movement <=================

      if (axes[2] != 0) {
        a += axes[2];
      }
      if (axes[3] != 0) {
        b += axes[3];
      }
    }

    const quaternion = this.dolly.quaternion.clone();

    if (a !== 0 || b !== 0) {
      this.dolly.quaternion.copy(this.camera?.quaternion);
      this.dolly.translateZ(b * speed);
      this.dolly.translateX(a * speed);

      this.dolly.quaternion.copy(quaternion);
    }
  }

  componentDidUpdate(prevProps) {
    const self = this;

    // Changing text mesh on change language
    if (this.vrButton?.vrButton) {
      this.vrButton.vrButton.disabled = false;
    }
    if (this.props.lang != prevProps.lang || !self.state.loadedFlag) {
      instruction.map((element, index) => {
        this.room.remove(this.room.getObjectByName('text'));
        this.room.remove(this.room.getObjectByName('parrent'));
        this.room.remove(this.room.getObjectByName('children'));
        if (this.boxCoords.length < 1) {
          this.loadText(this.room, element, index);
          self.setState({ ...self.state, loadedFlag: true });
        }
      });
    }
  }

  componentDidMount() {
    // this.button = document.getElementById('vrButton');
    this.containerRef?.current?.appendChild(this.renderer.domElement);
    if (!document.getElementById('vrButton'))
      this.vrButton = new VRButton(this.renderer);

    const self = this;
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.camera.position.set(
        this.props.cameraCoordsX,
        this.props.cameraCoordsY,
        this.props.cameraCoordsZ
      );
    }
    // Adding text mesh on mount component
    const setTextInScene = () => {
      instruction.map((element, index) => {
        this.room.remove(this.room.getObjectByName('text'));
        this.room.remove(this.room.getObjectByName('parrent'));
        this.room.remove(this.room.getObjectByName('children'));

        this.loadText(this.room, element, index);
      });
    };

    setTextInScene();
    //When user turn on the VR mode.
    self.renderer.xr.addEventListener('sessionstart', function () {
      self.vrButton.vrButton.disabled = false;

      self.dolly.add(self.camera);
      self.dolly.position.z = 2;
      self.dolly.position.y = -0.5;
      self.dolly.position.x = 0;
    });

    //When user turn off the VR mode.
    self.renderer.xr.addEventListener('sessionend', function () {
      self.vrButton.vrButton.disabled = false;

      self.dolly.remove(self.camera);
      self.dolly.position.z = 2.5;
      self.dolly.position.y = 0.8;
      self.camera.position.set(0, 0.8, 2.5);
    });
    window.addEventListener('resize', this.resize());
  }

  componentWillUnmount() {
    this.containerRef?.current?.removeChild(this.renderer.domElement);
    window.removeEventListener('resize', this.resize);

    !this.props.isPreview && this?.vrButton?.hideEnterVR();
    // Object.keys(this).forEach((key) => {
    //   // Recursively call dispose() if possible.
    //   if (typeof this[key]?.dispose === 'function') {
    //     this[key].dispose();
    //   }
    //   // Remove any reference.
    //   this[key] = null;
    // });
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.lang = nextProps.lang;

    //Replacing text from scene after translate
    if (nextProps.lang !== this.props.lang) {
      this.props.meshes.map(() => {
        this.room.remove(this.room.getObjectByName('text'));
        this.room.remove(this.room.getObjectByName('parrent'));
        this.room.remove(this.room.getObjectByName('children'));
      });

      return true;
    }

    //Setting coordinates for room (model)
    if (
      this.props.roomCoordsZ &&
      this.props.roomCoordsX &&
      this.props.roomCoordsY
    ) {
      this.room.position.set(
        this.props.roomCoordsX,
        this.props.roomCoordsY,
        this.props.roomCoordsZ
      );
    } else {
      this.room.position.set(0, 0, 0);
    }
    //Setting rotation coords for rotation (model)
    if (
      this.props.roomRotationZ &&
      this.props.roomRotationX &&
      this.props.roomRotationY
    ) {
      this.room.rotation.set(
        this.props.roomRotationX,
        this.props.roomRotationY,
        this.props.roomRotationZ
      );
    }
    //Setting scaling for room (model)
    if (
      this.props.roomScaleZ &&
      this.props.roomScaleX &&
      this.props.roomScaleY
    ) {
      this.room.scale.set(
        this.props.roomScaleX,
        this.props.roomScaleY,
        this.props.roomScaleZ
      );
    }
    //Setting camera position in scene
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.camera.position.set(
        this.props.cameraCoordsX,
        this.props.cameraCoordsY,
        this.props.cameraCoordsZ
      );
    }
    //Setting camera position in scene

    //Setting coord for text in scene

    //Setting loading
    if (nextProps.type !== this.state.loadingType) {
      this.setState({
        ...this.state,
        loadingType: nextProps.type
      });
      return true;
    }

    if (this.state.progress !== nextState) {
      return true;
    }
    if (nextProps.z !== this.props.z) {
      return true;
    } else {
      return false;
    }
  }

  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  render() {
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    const dt = this.clock.getDelta();

    if (this.vrButton) this.vrButton.vrButton.disabled = false;

    this.room.children.forEach((element) => {
      if (element.name === 'text' || element.name == 'parrent')
        element.quaternion?.copy(this.camera.quaternion);
    });
    if (this.controllers) {
      const self = this;
      this.controllers.forEach((controller, index) => {
        self.handleController(controller, dt, index);
      });
    }

    if (this.flagForChangeDragDropText) {
      const dragDropText = this.room.getObjectByName('DragDrop');
      dragDropText?.position.lerp(new THREE.Vector3(100, 0, 0), 0.005);
      if (dragDropText?.position.x > 10) this.room.remove(dragDropText);
      if (this.plusArrays.length > 0) {
        const neigbText = this.room.getObjectByName('NeighbourDragDrop');
        neigbText?.position.lerp(new THREE.Vector3(100, 0, 0), 0.005);
        if (neigbText?.position.x > 20) this.room.remove(neigbText);
        if (this.flagForChangeLinkText) {
          const changeLinkText = this.room.getObjectByName('ChangeLinkNum');
          changeLinkText?.position.lerp(new THREE.Vector3(100, 0, 0), 0.005);
          if (changeLinkText?.position.x > 20) this.room.remove(changeLinkText);
        }
      }
    }
    if (this.room) this.mixer?.update(dt);

    if (this.collisionDetected) {
      this.collisionDetected.material.color.set(0x00ff00);
    } else {
      for (let i = 0; i < this.room.children.length; i++) {
        if (this.room.children[i].name.indexOf('box') === 0) {
          this.room.children[i].material.color.set(0xffffff);
        }
      }
    }

    return (
      <div ref={this.containerRef} className={`${styles.sceneContainer}`}>
        {this.props.isPreviewContainer && this.state.flagFullscreen ? (
          <Link
            onClick={this.updateState}
            className={
              'px-2 absolute py-2 bg-gray-500 border-2 border-white rounded-lg text-center hover:bg-gray-600 hover hover:border-gray-100 top-5 right-5 text-white '
            }
          >
            {' '}
            <svg
              className={'self-end fill-current h-6 w-6 '}
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Exit Fullscreen</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </Link>
        ) : null}
        <Link
          to={'/admin/moleculeConstructor/ModelViewerPage'}
          className="px-2 absolute  py-2 rounded-lg text-center  bottom-80  right-6 xsmax:right-4  text-white"
        >
          <FontAwesomeIcon
            icon={'arrow-alt-circle-right'}
            className={'self-end  fill-current h-12 w-12 '}
            size="lg"
          />
        </Link>
        <button
          onClick={() => downloadModel(this)}
          className="px-2 absolute  py-2 rounded-lg text-center  bottom-3 mb-0.5 right-24 xsmax:right-10  text-white"
        >
          <FontAwesomeIcon
            icon={'download'}
            className={'self-end  fill-current h-6 w-6 '}
            size="lg"
          />
        </button>

        {this.state.progress && (
          <div
            className={
              'w-full h-full bg-black flex items-center flex-col justify-center absolute opacity-50'
            }
          >
            <p className="text-white opacity-100 font-gothic text-lg mb-2 text-center">
              Loading...
            </p>
            <div className="w-32 h-1 opacity-100 rounded-lg bg-slate-600 ">
              {this.state.percent != 0 ? (
                <div
                  style={{ width: Math.ceil(this.state.percent) + '%' }}
                  className={' rounded-full opacitiy-90 h-full bg-cyan-500'}
                ></div>
              ) : (
                <div className={'w-full h-full bg-gray-500'}></div>
              )}
            </div>
          </div>
        )}
      </div>
    );
    // }
  }
}
export default withRouter(MoleculeConstructor);
