import * as THREE from 'three';
export default function createH2O(textMeshO, textMeshH, groupObject) {
  const geometry = new THREE.BoxGeometry(3, 29, 3);
  // const material = new THREE.MeshStandardMaterial({color: 0xe84118 });
  const H2OBox = new THREE.Mesh(geometry, {});
  H2OBox.position.set(10, -13, 0);

  const oxygenGeometry = new THREE.SphereGeometry(2, 30, 30);
  const oxygenMaterial = new THREE.MeshStandardMaterial({
    color: 0xe74c3c,
    side: THREE.DoubleSide
  });
  const oxygen = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
  oxygen.position.set(0, 13, 0);
  const textMeshOCopy = textMeshO.clone();
  oxygen.add(textMeshOCopy);
  textMeshOCopy.position.set(-0.5, -0.5, 2);
  oxygen.name = 'HO2-1';

  const hydrogenGeometry = new THREE.SphereGeometry(1, 30, 30);
  const hydrogenMaterial = new THREE.MeshStandardMaterial({
    color: 0xecf0f1,
    side: THREE.DoubleSide
  });
  const hydrogen = new THREE.Mesh(hydrogenGeometry, hydrogenMaterial);
  const hydrogenTextCopy = textMeshH.clone();
  hydrogen.add(hydrogenTextCopy);
  hydrogenTextCopy.position.set(-0.5, -0.5, 1);
  const hydrogenCopy = hydrogen.clone();

  oxygen.add(hydrogen);
  hydrogen.position.set(-2.5, 0, 0);
  oxygen.add(hydrogenCopy);
  hydrogenCopy.position.set(2.5, 0, 0);

  H2OBox.add(oxygen);

  for (let i = 1; i <= 5; i++) {
    const oxygenCopy = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
    const oxygenTextCopy = textMeshO.clone();
    oxygenCopy.add(oxygenTextCopy);
    oxygenTextCopy.position.set(-0.5, -0.5, 2);
    const hydrogenCopy = new THREE.Mesh(hydrogenGeometry, hydrogenMaterial);
    const hydrogenClone = hydrogenCopy.clone();
    const hydrogenTextCopy = textMeshH.clone();
    const hydrogenTextClone = textMeshH.clone();
    hydrogenCopy.add(hydrogenTextCopy);
    hydrogenTextCopy.position.set(-0.5, -0.5, 1);
    hydrogenClone.add(hydrogenTextClone);
    hydrogenTextClone.position.set(-0.5, -0.5, 1);
    oxygenCopy.name = `HO2-${i + 1}`;
    oxygenCopy.add(hydrogenCopy);
    hydrogenCopy.position.set(-2.5, 0, 0);
    oxygenCopy.add(hydrogenClone);
    hydrogenClone.position.set(2.5, 0, 0);
    oxygenCopy.position.set(0, -(i * 5), 0);
    oxygen.add(oxygenCopy);
  }

  groupObject.add(H2OBox);
  H2OBox.name = 'H2OBox';
}
