export const refreshButton = (
  controller,
  self,
  arrayForSettingInvisibileOnRefresh,
  arrayForSettingVisibileOnRefresh
) => {
  const loadedModel = self.room.getObjectByName('loadedModel');

  if (controller.userData.selected && !controller.userData.attachedObject) {
    if (
      controller.userData.selected.name == 'tabletRefresh' &&
      !loadedModel.getObjectByName('Dicloropentan').visible
    ) {
      arrayForSettingInvisibileOnRefresh.forEach((element) => {
        element.visible = false;
      });
      arrayForSettingVisibileOnRefresh.forEach((element) => {
        element.visible = true;
      });
      self.stopAttaching = true;
    }
    if (controller.userData.selected.name == 'tabletRefresh') {
      self.stopAttaching = true;
    }
  }
};
