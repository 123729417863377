/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import UserRow from './../userRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditUserModal from './userEditModal';
import UserDeleteConfirmationModal from './userDeleteConfirmation';
import { AdminApi } from '../../../../api/admin.api';
import UserAddChild from './userAddChildren';
import { useAppContext } from '../../../../context/AppProvider';
import Loader from '../../../../common/loader';
import { useHistory } from 'react-router-dom';

const values = [
  {
    val: 1,
    txt: 'Edit'
  },
  {
    val: 2,
    txt: 'Delete'
  }
];

const UserManagement = () => {
  const [showModal, setShowModal] = React.useState({
    show: false,
    mode: 'add',
    user: null
  });
  const history = useHistory();
  const [showAddChildModal, setShowAddChildModal] = React.useState({
    show: false,
    parentId: null
  });
  const [deleteConfirmation, setDeleteConfirmation] = React.useState({
    show: false,
    userId: ''
  });
  const [users, setUsers] = React.useState([]);
  const [shouldUpdate, setShouldUpdate] = React.useState(false);
  const [filteredUsersList, setFilteredUsersList] = React.useState([]);
  const { store, setUsersList, setLoading } = useAppContext();
  const {
    selectedLanguage,
    user,
    usersList,
    loading
    // token,
  } = store;
  const token = window.localStorage.getItem('userToken');

  useEffect(() => {
    if (usersList?.length) {
      const newUsersList = usersList.filter((userEl) => {
        return userEl._id !== user.userId && userEl.role !== 'learner';
      });
      setFilteredUsersList(newUsersList);
    }
  }, [usersList]);

  useEffect(async () => {
    const usersData = await AdminApi.getAllUsers(token, history);
    setLoading(false);
    setUsersList(usersData.users);
  }, []);

  useEffect(async () => {
    if (shouldUpdate) {
      await setLoading(true);
      const usersData = await AdminApi.getAllUsers(token, history);
      setLoading(false);
      setUsersList(usersData.users);
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  return (
    <div className={'flex flex-col align-middle p-5 h-full '}>
      <div className={'w-full'}>
        <span className={'text-xl font-medium underline decoration-2 '}>
          Users Management
        </span>
      </div>
      <div
        className={'w-full mt-10 flex flex-col align-middle place-items-center'}
      >
        <div className="flex w-11/12 justify-end">
          <button
            onClick={() =>
              setShowModal((prevState) => {
                return {
                  ...prevState,
                  show: true,
                  mode: 'add'
                };
              })
            }
            className="w-full h-4/5 inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm place-items-center"
          >
            <FontAwesomeIcon className={'mr-1'} icon={'plus'} size="1x" />
            Add User
          </button>
        </div>
        <UserAddChild
          showModal={showAddChildModal}
          setShouldUpdate={setShouldUpdate}
          setShowModal={setShowAddChildModal}
        />
        <UserDeleteConfirmationModal
          deleteConfirmation={deleteConfirmation}
          setShouldUpdate={setShouldUpdate}
          setDeleteConfirmation={setDeleteConfirmation}
        />
        <EditUserModal
          showModal={showModal}
          setShouldUpdate={setShouldUpdate}
          mode={showModal.mode}
          setShowModal={setShowModal}
        />
        <div
          className={
            'w-11/12 mt-10 border-2 flex justify-between place-items-center'
          }
        >
          <div
            className={
              'flex justify-center text-lg font-medium text-center w-4 cursor-pointer'
            }
          ></div>
          <div
            className={
              'flex justify-center text-lg font-medium text-center w-1/6 cursor-pointer'
            }
          >
            First Name
          </div>
          <div
            className={
              'flex justify-center text-lg font-medium text-center w-1/6 cursor-pointer'
            }
          >
            Last Name
          </div>
          <div
            className={
              'flex justify-center text-lg font-medium text-center w-1/6 cursor-pointer'
            }
          >
            Email
          </div>
          <div
            className={
              'flex justify-center text-lg font-medium text-center w-1/6 cursor-pointer'
            }
          >
            Role
          </div>
          <div
            className={
              'flex justify-center text-lg font-medium text-center w-1/6 cursor-pointer'
            }
          >
            Status
          </div>
          <div
            className={
              'flex justify-center text-lg font-medium text-center w-1/6'
            }
          >
            Options
          </div>
        </div>

        {loading ? (
          <div className={'w-full flex justify-center items-center'}>
            <Loader />
          </div>
        ) : (
          <div className={'w-11/12 mb-40 border flex flex-col align-middle'}>
            {filteredUsersList?.map((user) => {
              return (
                <UserRow
                  key={user._id}
                  values={values}
                  setDeleteConfirmation={setDeleteConfirmation}
                  setShowModal={setShowModal}
                  setShowAddChildModal={setShowAddChildModal}
                  setShouldUpdate={setShouldUpdate}
                  {...user}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserManagement;
