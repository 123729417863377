import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import NotificationDropdown from './../../common/Dropdowns/NotificationDropdown.js';
import UserDropdown from './../../common/Dropdowns/UserDropdown.js';
import { useAppContext } from '../../context/AppProvider.js';
import { inputItems } from '../../assets/mockData/mockData.js';

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = useState('hidden');
  const [showUsersAdmin, setShowUserAdmin] = useState(false);

  const { store } = useAppContext();
  const { user, selectedLanguage } = store;

  useEffect(() => {
    if (user.userId) {
      if (user.role === 'superAdmin') {
        setShowUserAdmin(true);
      } else if (user.role === 'school') {
        setShowUserAdmin(false);
      }
    }
  }, [user]);
  return (
    <>
      <nav className=" min-h-full md:left-0 md:block md:top-0 md:bottom-0  md:flex-row md:flex-nowrap shadow-xl border-2 bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className=" h-full md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <span
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            {inputItems.adminPanelText[selectedLanguage]}
          </span>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Lectio
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow('hidden')}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {showUsersAdmin ? (
                <li className="items-center">
                  <Link
                    className={
                      'text-xs uppercase py-3 font-bold block ' +
                      (window.location.href.indexOf('/admin/users') !== -1
                        ? 'text-lightBlue-500 hover:text-lightBlue-600'
                        : 'text-blueGray-700 hover:text-blueGray-500')
                    }
                    to="/admin/users"
                  >
                    <i
                      className={
                        'fas fa-tv mr-2 text-sm ' +
                        (window.location.href.indexOf('/admin/users') !== -1
                          ? 'opacity-75'
                          : 'text-blueGray-300')
                      }
                    ></i>{' '}
                    Users Panel
                  </Link>
                </li>
              ) : (
                <div>
                  <li className="items-center border-b border-solid border-blueGray-200">
                    <Link
                      className={
                        'text-xs uppercase py-3 font-bold block ' +
                        (window.location.href.indexOf('/admin/content') !== -1
                          ? 'text-lightBlue-500 hover:text-lightBlue-600'
                          : 'text-blueGray-700 hover:text-blueGray-500')
                      }
                      to="/admin/content"
                    >
                      <i
                        className={
                          'fas fa-tools mr-2 text-sm ' +
                          (window.location.href.indexOf('/admin/content') !== -1
                            ? 'opacity-75'
                            : 'text-blueGray-300')
                        }
                      ></i>{' '}
                      {inputItems.contentPanel[selectedLanguage]}
                    </Link>
                  </li>
                  <li className="items-center ">
                    <Link
                      className={
                        'text-xs uppercase py-4 font-bold block ' +
                        (window.location.href.indexOf('/admin/content') !== -1
                          ? 'text-lightBlue-500 hover:text-lightBlue-600'
                          : 'text-blueGray-700 hover:text-blueGray-500')
                      }
                      to="/admin/moleculeConstructor"
                    >
                      <i
                        className={
                          'fas fa-tools mr-2 text-sm ' +
                          (window.location.href.indexOf('/admin/content') !== -1
                            ? 'opacity-75'
                            : 'text-blueGray-300')
                        }
                      ></i>{' '}
                      {inputItems.moleculeConstructor[selectedLanguage]}
                    </Link>
                  </li>
                </div>
              )}
            </ul>

            {/* Divider */}
            <hr className="my-1 md:min-w-full" />
          </div>
        </div>
      </nav>
    </>
  );
}
