import React from 'react';
import { useContext } from 'react';
import AuthContext from '../context/authContext';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { menuItems } from './../assets/mockData/mockData';

export default function Example({ data, childToParent }) {
  const auth = useContext(AuthContext);
  const navigate = useHistory();

  const loginOut = () => {
    auth.logout();
    navigate.push('/');
  };
  return (
    <div>
      <div className="origin-top-right right-0 mt-2 w-56   ring-black ring-opacity-5 focus:outline-none">
        <div>
          <div>
            <div
              className={
                'text-white  px-2  font-bold text-lg border-b-2 pt-1 border-gray-400 rounded-t-md'
              }
            >
              {data.firstname + ' ' + data.lastname}
              <p className="text-sm font-light text-gray-500 ">{data.email}</p>
            </div>
          </div>

          <Link
            to={'/Profile'}
            onClick={() => childToParent(false)}
            className={
              ' text-white font-gothic block px-3 py-2 rounded-md text-base font-semibold '
            }
          >
            {menuItems.myAccount[auth.selectedLanguage]}
          </Link>

          <Link
            to={'/helpAndSupport'}
            onClick={() => childToParent(false)}
            className={
              '  text-white block px-3 py-2 rounded-md text-base font-semibold '
            }
          >
            {menuItems.helpAndSupport[auth.selectedLanguage]}
          </Link>

          <Link
            to={'/PrivacyPolicy'}
            onClick={() => childToParent(false)}
            className={
              '  text-white block px-3 py-2 rounded-md text-base font-semibold '
            }
          >
            {menuItems.privacyPolicy[auth.selectedLanguage]}
          </Link>

          <button
            onClick={loginOut}
            className={
              '  text-white block px-3 py-2 rounded-md text-base font-semibold '
            }
          >
            {menuItems.logOut[auth.selectedLanguage]}
          </button>
        </div>
      </div>
    </div>
  );
}
