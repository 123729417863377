import { socket } from '../../../common/socketConnection';

const PrevModel = (self) => {
  if (self.props.name?.dividedByTwo) {
    if (self.blockerForLerp < self.props?.modelSlider?.length / 2) {
      if (!self.resetedlerpingDown) {
        self.curModel++;
      }
      self.resetedlerpingDown = true;
      socket.emit('lerpingDown', {
        resetedlerpingDown: true,
        roomId: socket.room,
        curModel: self.curModel
      });
    }
  } else if (self.blockerForLerp < self.props?.modelSlider?.length) {
    if (!self.resetedlerpingDown) {
      self.curModel++;
    }
    self.resetedlerpingDown = true;
    socket.emit('lerpingDown', {
      resetedlerpingDown: true,
      roomId: socket.room,
      curModel: self.curModel
    });
  }
};
export default PrevModel;
