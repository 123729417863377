import React from 'react';
import { TermsData } from '../../../assets/mockData/mockData';
import TermsText from './TermsText';
const Terms = () => {
  return (
    <div className="flex-grow smmax:w-11/12 w-10/12 m-auto space-y-10 my-20">
      {TermsData.map((element, index) => {
        return (
          <TermsText
            key={index}
            headText={element.headName}
            paragraphText={element.textContent}
          />
        );
      })}
    </div>
  );
};
export default Terms;
