/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import TableRow from './tableRow';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import EditUserModal from './userEditModal';
// import UserDeleteConfirmationModal from './userDeleteConfirmation';
import { ContentApi } from '../../../../api/content.api';
// import UserAddChild from './userAddChildren';
import { useAppContext } from '../../../../context/AppProvider';
import { useHistory } from 'react-router-dom';
import { inputItems } from '../../../../assets/mockData/mockData';
const ContentPanel = () => {
  const history = useHistory();

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    show: false,
    topicId: null
  });

  const [shouldUpdate, setShouldUpdate] = useState(false);

  const { store, setUsersList, setCategoryCollection, logOutAction } =
    useAppContext();

  const { selectedLanguage, user, usersList, categoryCollection } = store;

  const token = window.localStorage.getItem('userToken');
  const userData = JSON.parse(window.localStorage.getItem('userData'));

  const logOut = () => {
    logOutAction();
    history.push('/');
  };

  useEffect(async () => {
    const collection = await ContentApi.getCategoryCollection(
      token,
      { parentId: userData.userId },
      history
    );

    if (collection?.categories?.categories?.length) {
      setCategoryCollection(collection.categories);
      return;
    }

    if (collection.message === 'No auth') {
      logOut();
    }
  }, []);

  useEffect(async () => {
    if (shouldUpdate) {
      const collection = await ContentApi.getCategoryCollection(
        token,
        { parentId: userData.userId },
        history
      );
      if (collection?.categories?.categories?.length) {
        setCategoryCollection(collection.categories);
        setShouldUpdate(false);
        return;
      }

      if (collection.message === 'No auth') {
        logOut();
      }
    }
  }, [shouldUpdate]);

  return (
    <div className={'flex flex-col align-middle p-5 h-full '}>
      <div className={'w-full'}>
        <span className={'text-xl font-medium underline decoration-2 '}>
          {inputItems.contentManagment[selectedLanguage]}
        </span>
      </div>
      <div
        className={'w-full mt-10 flex flex-col align-middle place-items-center'}
      >
        <div className="flex w-11/12 justify-end"></div>
        <div
          className={
            'w-11/12 mt-10 border-2 flex justify-between px-10 place-items-center'
          }
        >
          <div
            className={
              'flex justify-center text-lg font-medium text-center w-3.5 cursor-pointer'
            }
          >
            {inputItems.categoryName[selectedLanguage]}
          </div>
          <div
            className={
              'flex justify-center text-lg font-medium text-center w-3.5 cursor-pointer'
            }
          >
            {inputItems.Topic[selectedLanguage]}{' '}
          </div>
        </div>

        <div className={'w-11/12 mb-40 border flex flex-col align-middle'}>
          {categoryCollection?.categories?.map((category) => {
            return (
              <TableRow
                key={category.categoryId}
                deleteConfirmation={deleteConfirmation}
                setDeleteConfirmation={setDeleteConfirmation}
                setShouldUpdate={setShouldUpdate}
                {...category}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ContentPanel;
