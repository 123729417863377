/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { AdminApi } from '../../../../../api/admin.api';
import { useHistory } from 'react-router-dom';
const rolesArray = [
  {
    txt: 'School',
    value: 'school'
  },
  {
    txt: 'Standard',
    value: 'standard'
  },
  {
    txt: 'Premium',
    value: 'premium'
  },
  {
    txt: 'Super Admin',
    value: 'superAdmin'
  }
];

export default function UserAddChild({
  showModal,
  setShowModal,
  setShouldUpdate,
  mode = 'add'
}) {
  //   const [showModal, setShowModal] = React.useState(false);
  const [login, setLogin] = useState('');
  const [count, setCount] = useState(undefined);

  const resetState = () => {
    setLogin('');
    setCount(undefined);
    setShowModal({ show: false });
  };

  useEffect(() => {
    if (showModal?.user?.login) {
      setLogin(showModal.user.login);
      setCount(showModal.user.count);
    }
  }, [showModal]);

  const onSubmit = async () => {
    const data = {
      identificatorName: login,
      count,
      parentId: showModal.parentId
    };

    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const userToken = window.localStorage.getItem('userToken');
    const result = await AdminApi.addChildrenToParent(userToken, data, history);
    if (result) {
      resetState();
      setShouldUpdate(true);
    }
  };

  return (
    <>
      {showModal.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {/* {mode === 'add' ? 'Add User' : 'User Edit' } */}
                    Add Children to Parent
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <form action="#" method="POST">
                    <div className=" overflow-hidden">
                      <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6">
                            <label className="block text-sm font-medium text-gray-700">
                              Login
                            </label>
                            <input
                              type="login"
                              value={login}
                              onChange={(e) => setLogin(e.target.value)}
                              className="mt-1 block  w-full py-2 px-3 border  border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="count"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Count of Children
                            </label>
                            <input
                              onChange={(e) => setCount(e.target.value)}
                              type={'number'}
                              value={count}
                              min={1}
                              max={20}
                              autoComplete="address-level2"
                              className={
                                'mt-1 block  w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      resetState();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => onSubmit()}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
