/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { coursesData } from '../../assets/mockData/mockData';
import SceneModelGeneral from '../../common/SceneModelGeneral';
import { useLocation } from 'react-router-dom';

export const SceneModuleManager = ({
  name,
  isPreview = false,
  isPreviewContainer,
  modelFile,
  modelName,
  uploadedModelLink,
  uploadedModelData,
  type = undefined,
  draggable,
  topicData,
  memberId,
  roomId,
  handleMembersNumber
}) => {
  // const [topicData, setTopicData] = useState({});
  const [lang, setLang] = useState('EN');
  const { categoryId, topicId } = useParams();
  const url = useRouteMatch();
  // const [exitFullscreen, setExitFullscreen] = useState(false);
  const location = useLocation();
  const [z, setZ] = useState(0);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [meshes, setMeshes] = useState([]);
  const [modelSlider, setModelSlider] = useState([]);
  const [textType, setTextType] = useState('');
  const selectedLanguage = localStorage.getItem('language');
  // useEffect(() => {
  //   if (topicId) {
  //     const topicObj = coursesData
  //       .find((course) => course.path === categoryId)
  //       .topics.find((topic) => topic.id === topicId);
  //     setTopicData(topicObj);

  //     setZ(topicObj?.cameraCoordsZ);
  //     setY(topicObj?.cameraCoordsY);
  //     setX(topicObj?.cameraCoordsX);
  //     if (topicObj?.meshes)
  //       topicObj?.meshes?.map((element) => {
  //         setMeshes((prevmeshes) => [...prevmeshes, element]);
  //       });
  //     if (topicObj?.textType) {
  //       setTextType(topicObj.textType);
  //     }
  //   }
  // }, [topicId]);

  useEffect(() => {
    if (topicId) {
      // const topicObj = coursesData
      //   .find((course) => course.path === categoryId)
      //   .topics.find((topic) => topic.id === topicId);
      // setTopicData(topicObj);

      setZ(topicData?.cameraCoordsZ);
      setY(topicData?.cameraCoordsY);
      setX(topicData?.cameraCoordsX);
      if (topicData?.meshes)
        topicData?.meshes?.map((element) => {
          setMeshes((prevmeshes) => [...prevmeshes, element]);
        });
      if (topicData?.modelSlider)
        topicData?.modelSlider?.map((element) => {
          setModelSlider((prevmeshes) => [...prevmeshes, element]);
        });
      if (topicData?.textType) {
        setTextType(topicData.textType);
      }
    }
  }, [topicData]);
  useEffect(() => {
    setLang(selectedLanguage);
  }, [selectedLanguage]);
  if (topicData?.isSpecialModule && topicData.component) {
    return topicData.component(false, url.url);
  }

  if (
    (!topicData?.isSpecialModule && type) ||
    (!topicData?.isSpecialModule && location?.state?.routeType)
  ) {
    return (
      <SceneModelGeneral
        type={type ? type : location?.state?.routeType}
        roomCoordsZ={topicData?.roomCoordsZ}
        roomCoordsY={topicData?.roomCoordsY}
        roomCoordsX={topicData?.roomCoordsX}
        roomRotationZ={topicData?.roomRotationZ}
        roomRotationY={topicData?.roomRotationY}
        roomRotationX={topicData?.roomRotationX}
        roomScaleZ={topicData?.roomScaleZ}
        roomScaleY={topicData?.roomScaleY}
        roomScaleX={topicData?.roomScaleX}
        aboutThisCourse={topicData?.aboutThisCourse}
        description={topicData?.description}
        uploadedModelLink={uploadedModelLink}
        uploadedModelData={uploadedModelData}
        modelFile={modelFile}
        modelName={modelName}
        cameraCoordsZ={z}
        cameraCoordsY={y}
        cameraCoordsX={x}
        url={url.url}
        // exitFullscreen={exitFullscreen}
        isPreviewContainer={isPreviewContainer}
        isPreview={isPreview}
        lang={lang}
        meshes={meshes}
        textType={textType}
        name={name}
        draggable={draggable}
        modelSlider={modelSlider}
        memberId={memberId}
        roomId={roomId}
      />
    );
  }

  return <div>Nothing was found</div>;
};
