import React, { useState, useEffect } from 'react';

const SceneMiniBlock = (props) => {
  const {
    sceneName,
    sceneAvatar,
    sceneId,
    setSceneId,
    selectedLanguage,
    isSelected,
    selectedSceneId,
    setShowModal,
    isSpecialModule,
    setIsSpecialModule,
    path
  } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [trimmedName, setTrimmedName] = useState('');
  useEffect(() => {
    if (sceneName[selectedLanguage]) {
      if (sceneName[selectedLanguage].length > 15) {
        const trimmedDesc = sceneName[selectedLanguage].slice(0, 15) + '...';
        setTrimmedName(trimmedDesc);
      } else {
        setTrimmedName(sceneName[selectedLanguage]);
      }
    }
  }, [sceneName]);
  return (
    <div
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
      className={` h-36 w-36 border rounded-md overflow-hidden flex flex-col m-4 ${
        isSelected && 'border-8 border-green-600'
      }`}
    >
      <div className={'h-28 w-full flex justify-center items-center relative'}>
        <img
          src={sceneAvatar}
          className={'w-full h-full'}
          alt={'Scene Background'}
        />
        {showOptions && (
          <div
            style={{
              backgroundColor: 'rgba(133,127,127, 0.5)'
            }}
            className={
              'w-full h-full absolute flex flex-col justify-center items-center'
            }
          >
            <button
              className="w-3/5 h-10 bg-green-600 hover:bg-green-700 text-green-100 border my-2 font-semibold text-md rounded"
              onClick={() =>
                setShowModal({
                  sceneId: sceneId,
                  show: true,
                  isSpecialModule: isSpecialModule,
                  path: path
                })
              }
            >
              View
            </button>
            <button
              className={` w-3/5 h-10 ${
                selectedSceneId === sceneId
                  ? 'bg-red-600 hover:bg-red-700'
                  : 'bg-green-600 hover:bg-green-700'
              } text-green-100 border my-2 font-semibold text-md rounded`}
              onClick={() => {
                if (selectedSceneId === sceneId) {
                  setSceneId('');
                  setIsSpecialModule(false);
                } else {
                  setSceneId(sceneId);
                  setIsSpecialModule(isSpecialModule);
                }
              }}
            >
              {selectedSceneId === sceneId ? 'Unselect' : 'Select'}
            </button>
          </div>
        )}
      </div>
      <div className={'flex justify-center'}>
        <span className={'text-sm font-medium'}>{trimmedName}</span>
      </div>
    </div>
  );
};

export default SceneMiniBlock;
