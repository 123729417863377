import { textToSpeech } from './textToSpeech';

export const playInstruction = async (controller, self, instructionText) => {
  if (
    controller?.userData?.selected &&
    !controller.userData?.attachedObject &&
    self.loadedMusicBar
  ) {
    if (
      controller.userData.selected.name == 'tabletInstructionSoundOn' &&
      instructionText[self.props.lang]
    ) {
      self.stopAttaching = true;
      await textToSpeech(self, instructionText);
      self.loadedMusicBar = true;
    } else if (
      controller.userData.selected.name == 'tabletInstructionSoundOff'
    ) {
      if (self.sound?.isPlaying) {
        self.sound.pause();
        self.loadedMusicBar = true;
      }

      self.stopAttaching = true;
    }
  } else if (
    controller?.userData?.selected &&
    !controller.userData?.attachedObject
  ) {
    self.stopAttaching = true;
  }
};
