export const text3H2 = [
  {
    EN: 'Synthesis gas\n   CO + 3H',
    RO: 'Gaz de sinteza\n   CO + 3H',
    modelSize: 0.1,
    modelCoordsX: -0.25,
    modelCoordsY: 0.71,
    modelCoordsZ: 0.5,
    modelType: 'parrent'
  },

  {
    EN: '2',
    RO: '2',
    modelSize: 0.07,
    modelCoordsX: 0.64,
    modelCoordsY: -0.19,
    modelCoordsZ: 0.0001,
    modelType: 'children'
  },
  {
    EN: ' Used for production \n      diesel fuel \n       methanol',
    RO: ' Folosit pentru productie \n         motorinei \n        metanolului',
    modelSize: 0.1,
    modelCoordsX: -2.4,
    modelCoordsY: 1.01,
    modelCoordsZ: 0.55
  }
];
export const text2H2O = [
  {
    EN: 'Black soot \n   CO + 2H O',
    RO: 'Funingine neagra \n   CO + 2H O',
    modelSize: 0.1,
    modelCoordsX: -0.25,
    modelCoordsY: 0.71,
    modelCoordsZ: 0.5,
    modelType: 'parrent'
  },
  {
    EN: '2',
    RO: '2',
    modelSize: 0.07,
    modelCoordsX: 0.29,
    modelCoordsY: -0.23,
    modelCoordsZ: 0.0001,
    modelType: 'children'
  },
  {
    EN: '2',
    RO: '2',
    modelSize: 0.07,
    modelCoordsX: 0.63,
    modelCoordsY: -0.23,
    modelCoordsZ: 0.0001,
    modelType: 'children'
  },
  {
    EN: '  Used for production \npigment for inks and dyes ',
    RO: '           Folosit pentru productie \npigmentului pentru cerneluri si coloranti ',
    modelSize: 0.1,
    modelCoordsX: -2.4,
    modelCoordsY: 1.01,
    modelCoordsZ: 0.55
  }
];
export const textCH3OH = [
  {
    EN: 'Methanol\nCH OH',
    RO: 'Metanol\nCH OH',
    modelSize: 0.1,
    modelCoordsX: -0.25,
    modelCoordsY: 0.71,
    modelCoordsZ: 0.5,
    modelType: 'parrent'
  },

  {
    EN: '3',
    RO: '3',
    modelSize: 0.07,
    modelCoordsX: 0.18,
    modelCoordsY: -0.21,
    modelCoordsZ: 0.0001,
    modelType: 'children'
  },
  {
    EN: '   Used for production \n       acetic acid \n      formaldehyde \n   cosmetic  foams  ',
    RO: '  Folosit pentru productie \n       acidului acetic \n        formaldehidei \n    spumelor cosmetice  ',
    modelSize: 0.1,
    modelCoordsX: -2.4,
    modelCoordsY: 1.01,
    modelCoordsZ: 0.55
  }
];
export const textCH3Cl = [
  {
    EN: 'Chloromethane\n   CH Cl',
    RO: 'Clormetan\n   CH Cl',
    modelSize: 0.1,
    modelCoordsX: -0.25,
    modelCoordsY: 0.71,
    modelCoordsZ: 0.5,
    modelType: 'parrent'
  },

  {
    EN: '   3',
    RO: '   3',
    modelSize: 0.07,
    modelCoordsX: 0.21,
    modelCoordsY: -0.22,
    modelCoordsZ: 0.0001,
    modelType: 'children'
  },
  {
    EN: '             An extractant for oils  \n      a propellant in foam production \n               a solvent in rubber  \n  manufacture and petroleum refining  ',
    RO: '          Extractant pentru uleiuri  \n    propulsor in productia de spuma \n            fabricarea cauciucului \n            si rafinarea petrolului',
    modelSize: 0.1,
    modelCoordsX: -2.6,
    modelCoordsY: 1.01,
    modelCoordsZ: 0.55
  }
];
