import ApiUtils from './api-utils';

export const ContentApi = {
  createNewTopic(token, data, history) {
    const url = `${ApiUtils.getBaseUrl()}/school/content`;
    const options = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(data)
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then((parsed) => parsed.json())
      .catch((err) => {
        console.log('Error on get me', err);
        throw err;
      });
  },

  editTopic(token, data, history) {
    const url = `${ApiUtils.getBaseUrl()}/school/content`;
    const options = {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(data)
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then((parsed) => parsed.json())
      .catch((err) => {
        console.log('Error on get me', err);
        throw err;
      });
  },

  getCategoryCollection(token, data, history) {
    const url = `${ApiUtils.getBaseUrl()}/school/content/categories/${
      data.parentId
    }`;
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: token
      }
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then((parsed) => parsed.json())
      .catch((err) => {
        console.log('Error on get me', err);
        throw err;
      });
  },

  deleteTopic(token, data, history) {
    const url = `${ApiUtils.getBaseUrl()}/school/content`;
    const options = {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(data)
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then((parsed) => parsed.json())
      .catch((err) => {
        console.log('Error on get me', err);
        throw err;
      });
  },

  getTopicById(token, topicId, history) {
    const url = `${ApiUtils.getBaseUrl()}/school/content/topics/${topicId}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: token
      }
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then((parsed) => parsed.json())
      .catch((err) => {
        console.log('Error on get me', err);
        throw err;
      });
  },

  changeTopicPosition(token, data, history) {
    const url = `${ApiUtils.getBaseUrl()}/school/content/order`;
    const options = {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(data)
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then((parsed) => parsed.json())
      .catch((err) => {
        console.log('Error on get me', err);
        throw err;
      });
  },

  resetToDefaultTopics(token, data, history) {
    const url = `${ApiUtils.getBaseUrl()}/school/content/reset`;
    const options = {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(data)
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then((parsed) => parsed.json())
      .catch((err) => {
        console.log('Error on get me', err);
        throw err;
      });
  }
};
