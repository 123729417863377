/* eslint-disable no-unused-vars */
import * as THREE from 'three';
import { instruction } from './instructionText';
const createBox = (
  room,
  boxCoords,
  fromSqueezeFlag,
  cubePosX,
  cubePosY,
  cubePosZ,
  boxTrueCoords,
  chemFormulaForExport,
  plusArrays,
  self
) => {
  const createLinkBetweenTwoMoleculas = (
    x,
    y,
    z,
    index,
    boxCoords,
    curElem
  ) => {
    const sideForLink = (
      x,
      y,
      z,
      finalMolX,
      finalMolY,
      finalMolZ,
      rotationDegreeHelper,
      rotationType
    ) => {
      if (!chemFormulaForExport.getObjectByName('groupForMesh')) {
        const groupForMesh = new THREE.Group();
        chemFormulaForExport.add(groupForMesh);
        groupForMesh.name = 'groupForMesh';
        groupForMesh.position.set(1.75, 0, 0);
      }
      const groupForLinks = new THREE.Mesh();
      chemFormulaForExport.getObjectByName('groupForMesh').add(groupForLinks);
      const geometry = new THREE.CylinderGeometry(0.02, 0.02, 0.5, 15);
      const material = new THREE.MeshBasicMaterial({
        color: 0x949494
      });
      const firstPartGeo = new THREE.BoxGeometry(0.1, 0.03, 0.03);
      const firstPartMat = new THREE.MeshBasicMaterial({
        color: 0x00ff00
      });
      const plus = new THREE.Group();
      const firstPart = new THREE.Mesh(firstPartGeo, firstPartMat);
      const secondPartGeo = new THREE.BoxGeometry(0.03, 0.1, 0.03);
      const secondPartMat = new THREE.MeshBasicMaterial({
        color: 0x00ff00
      });
      const secondPart = new THREE.Mesh(secondPartGeo, secondPartMat);
      plus.add(firstPart, secondPart);
      // secondPart.rotateZ(Math.PI * 0.5);
      groupForLinks.add(plus);
      plusArrays.push(plus);

      plus.position.set(0, 0, 0.1);
      plus.name = 'plus' + index;
      const link = new THREE.Mesh(geometry, material);
      groupForLinks.count = 1;
      groupForLinks.add(link);
      groupForLinks.position.set(x, y, z);
      // groupForLinks.rotation.z = Math.PI * rotationDegreeHelper;
      // link.position.set(x, y, z);
      groupForLinks.name = 'link' + index;
      link.rotationType = rotationType;
      link.rotation.z = Math.PI * rotationDegreeHelper;
      if (room.getObjectByName('ChangeLinkNum')) {
        room.getObjectByName('ChangeLinkNum').visible = true;
      }
    };
    let flagForLink = true;
    boxCoords.map((element) => {
      ///change to for
      if (flagForLink) {
        ///remove massive if
        if (
          element.x - 0.5 === curElem.x &&
          element.y === curElem.y &&
          element.flag
        ) {
          flagForLink = false;
          return sideForLink(
            curElem.x - 1.5,
            curElem.y,
            curElem.z,
            element.x,
            element.y,
            element.z,
            1.5,
            'x'
          );
        } else if (
          element.x + 0.5 === curElem.x &&
          element.y === curElem.y &&
          element.flag
        ) {
          flagForLink = false;

          return sideForLink(
            curElem.x - 2,
            curElem.y,
            curElem.z,
            element.x,
            element.y,
            element.z,
            0.5,
            'x'
          );
        } else if (
          element.x === curElem.x &&
          element.y - 0.5 === curElem.y &&
          element.flag
        ) {
          flagForLink = false;

          return sideForLink(
            curElem.x - 1.75,
            curElem.y + 0.2,
            curElem.z,
            element.x,
            element.y,
            element.z,
            0,
            'y'
          );
        } else if (
          element.x === curElem.x &&
          element.y + 0.5 === curElem.y &&
          element.flag
        ) {
          flagForLink = false;

          return sideForLink(
            curElem.x - 1.75,
            curElem.y - 0.2,
            curElem.z,
            element.x,
            element.y,
            element.z,
            1,
            'y'
          );
        }
      }
    });
  };

  const createBoxAsPlaceholder = (i, boxCoords, flag) => {
    const BoxPartGeometry = new THREE.BoxGeometry(0.15, 0.15, 0.15);
    const BoxPartMaterial = new THREE.MeshStandardMaterial({
      color: 0xffffff,
      wireframe: true,
      transparent: true
    });
    const BoxPart = new THREE.Mesh(BoxPartGeometry, BoxPartMaterial);

    room.add(BoxPart);

    BoxPart.name = boxCoords[i].name;
    BoxPart.position.set(boxCoords[i].x, boxCoords[i].y, boxCoords[i].z);
    if (fromSqueezeFlag) {
      boxCoords.map((element) => {
        boxTrueCoords.map((trueElement) => {
          if (
            element.x === trueElement.x &&
            element.y === trueElement.y &&
            element.z === trueElement.z
          ) {
            room.remove(room.getObjectByName(element.name));
          }
        });
      });
      if (room.getObjectByName('NeighbourDragDrop'))
        room.getObjectByName('NeighbourDragDrop').visible = true;
    }
    // self.loadText(room, instruction[1]);
  };

  if (fromSqueezeFlag) {
    let trueObjectCoordinates;
    let flag = true;
    boxCoords.map((element) => {
      if (
        cubePosX === element.x &&
        (cubePosY === element.y) & (cubePosZ === element.z)
      ) {
        trueObjectCoordinates = room.getObjectByName(element.name);
      }
    });

    let arrLength = boxCoords.length;
    let arrLengthFinal = boxCoords.length + 3;
    for (let i = arrLength - 1; i < arrLengthFinal; i++) {
      if (i < arrLengthFinal - 2) {
        boxCoords.push({
          x:
            i % 2 == 0
              ? trueObjectCoordinates.position.x - 0.5
              : trueObjectCoordinates.position.x + 0.5,
          y: trueObjectCoordinates.position.y,
          z: -3.5,
          flag: false,
          name: 'box' + (i + 1)
        });
      } else {
        boxCoords.push({
          x: trueObjectCoordinates.position.x,
          y:
            i % 2 == 0
              ? trueObjectCoordinates.position.y - 0.5
              : trueObjectCoordinates.position.y + 0.5,
          z: -3.5,
          flag: false,
          name: 'box' + (i + 1)
        });
      }
      createBoxAsPlaceholder(i + 1, boxCoords, flag);
      if (i === arrLengthFinal - 1) {
        boxTrueCoords.map((element, index) => {
          if (index === boxTrueCoords.length - 1) {
            createLinkBetweenTwoMoleculas(
              element.x,
              element.y,
              element.z,
              i,
              boxCoords,
              element
            );
            flag = false;
          }
        });
      }
    }
    self.flagForChangeDragDropText = true;
  } else {
    createBoxAsPlaceholder(0, boxCoords);
  }
};

export default createBox;
