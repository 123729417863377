export function convertBase64ToObjectURL(base64, type = 'audio/mp3') {
  const raw = window.atob(base64);
  const binaryArr = new Uint8Array(new ArrayBuffer(raw.length)).map((_, i) =>
    raw.charCodeAt(i)
  );

  const blob = new Blob([binaryArr], {
    type
  });
  return URL.createObjectURL(blob);
}
