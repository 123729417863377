import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

const CreateTopicName = (props, scene) => {
  const loadText = async (room, text) => {
    const loader = new FontLoader();

    await loader.load(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/helvetiker_regular.typeface.json',
      function (font) {
        var textGeometry = new TextGeometry(text && text[props.lang], {
          font: font,
          size: text?.modelSize ? text.modelSize : 0.1,
          height: text?.modelHeight ? text.modelHeight : 0.03
        });
        let textMaterial;

        var mesh = new THREE.Mesh(textGeometry, textMaterial);
        room.remove(room.getObjectByName('topic'));
        room.add(mesh);
        if (text.xCoords) {
          mesh.position.set(
            text.xCoords,
            text.yCoords ? text.yCoords : 1,
            text.zCoords ? text.zCoords : -3
          );
        } else {
          mesh.position.set(-1, 1, -3);
        }
        if (text.scale) {
          mesh.scale.set(text.scale.x, text.scale.y, text.scale.z);
        }
        mesh.name = 'topic';
      }

      // onProgress callback

      // onError callback
    );
  };
  if (props.name) loadText(scene, props.name);
};
export default CreateTopicName;
