import React from 'react';
import { useAppContext } from '../../../context/AppProvider';

const PrivacyPolicyParagraph = (props) => {
  const { store } = useAppContext();
  const { selectedLanguage } = store;
  const { text } = props;
  return (
    <div>
      <p className="font-gothic text-justify blockTextP p-3 text-xl">
        {text[selectedLanguage]}
      </p>
    </div>
  );
};
export default PrivacyPolicyParagraph;
