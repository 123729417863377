import React from 'react';
import { menuItems } from '../assets/mockData/mockData';

import { Link } from 'react-router-dom';
const PageNotFound = () => {
  const selectedLanguage = window.localStorage.getItem('language');
  return (
    <section className="page_404 grow flex items-center justify-center">
      <div className="w-2/4 items-center justify-center">
        <div className=" m-auto">
          <div className=" m-auto ">
            <div className="justify-center flex flex-col text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">404</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2 font-gothic font-bold text-dark">
                  {menuItems.notFound[selectedLanguage]}
                </h3>

                <p className="font-gothic font-bold text-dark">
                  {menuItems.notFound.paragraph[selectedLanguage]}
                </p>

                <Link
                  to={'/'}
                  className="link_404 font-gothic font-bold text-dark"
                >
                  {menuItems.notFound.button[selectedLanguage]}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
