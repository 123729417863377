import * as THREE from 'three';
import { socket } from '../socketConnection';
const updateMemberData = (
  camera,
  leftController,
  rightController,
  attachedObject
) => {
  const leftControllerPosition = new THREE.Vector3();

  const rightControllerPosition = new THREE.Vector3();

  leftController.getWorldPosition(leftControllerPosition);

  rightController.getWorldPosition(rightControllerPosition);

  socket.emit('update', {
    pb: {
      x: camera.rotation.x,
      y: camera.rotation.y,
      z: camera.rotation.z
    },
    position: {
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z
    },
    leftController: {
      position: {
        x: leftControllerPosition.x,
        y: leftControllerPosition.y,
        z: leftControllerPosition.z
      },
      rotation: {
        x: leftController.rotation.x,
        y: leftController.rotation.y,
        z: leftController.rotation.z
      }
    },
    rightController: {
      position: {
        x: rightControllerPosition.x,
        y: rightControllerPosition.y,
        z: rightControllerPosition.z
      },
      rotation: {
        x: rightController.rotation.x,
        y: rightController.rotation.y,
        z: rightController.rotation.z
      }
    },
    attachedObject: {
      name: attachedObject.name,
      controllerName: attachedObject.controllerName,
      modelThatWasAttached: attachedObject.modelThatWasAttached,
      controllerThatHoldModel: attachedObject.controllerThatHoldModel,
      modelThatWasAttachedCoords: attachedObject.modelThatWasAttachedCoords,
      lineLength: attachedObject.lineLength,
      model: attachedObject.model,
      position: {
        x: attachedObject.position?.x,
        y: attachedObject.position?.y,
        z: attachedObject.position?.z
      },
      rotation: {
        x: attachedObject.rotation?.x,
        y: attachedObject.rotation?.y,
        z: attachedObject.rotation?.z
      }
    }
  });
  socket.emit('updateLerping', {
    resetedlerpingDown: attachedObject.resetedlerpingDown,
    resetedlerpingUp: attachedObject.resetedlerpingUp
  });
};
export default updateMemberData;
