/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import SceneMiniBlock from './sceneMiniBlock';
import { isObject } from '../../../../../utils';
import FroalaEditor from 'react-froala-wysiwyg';
import { ContentApi } from '../../../../../api/content.api';
import { useAppContext } from '../../../../../context/AppProvider';
import { scenesData } from '../../../../../assets/mockData/scenesData';
import SceneModalView from './sceneModalView';
import { inputItems } from '../../../../../assets/mockData/mockData';
import Wrapper from '../../../../../common/SceneModelGeneral/wrappper';
import ModelViewer from '../../MoleculeConstructor/ModelViewer';
const config = {
  placeholderText: 'Edit Your Content Here!',
  charCounterCount: false
};

const EditTopicPage = () => {
  const { setShouldUpdate } = useAppContext();
  const { store } = useAppContext();

  const params = useParams();
  const history = useHistory();

  const selectedLanguage = window.localStorage.getItem('language');
  const token = window.localStorage.getItem('userToken');
  const userData = JSON.parse(window.localStorage.getItem('userData'));

  const { categoryCollection } = store;
  const [topicData, setTopicData] = useState({});
  const [topicContent, setTopicContent] = useState('');
  const [topicName, setTopicName] = useState('');
  const [sceneId, setSceneId] = useState('');
  const [isSpecialModule, setIsSpecialModule] = useState(false);
  const [uploadedModels, setUploadedModels] = useState([]);
  const [showModal, setShowModal] = useState({
    sceneId: '',
    show: false,
    isSpecialModule: false
  });
  const { categoryId, topicId } = params;
  const [selectedCategory, setSelectedCategory] = useState({});
  const [topicNameError, setTopicNameError] = useState('');

  const modelDataRef = useRef();

  const collectModelData = (data) => {
    modelDataRef.current = data;
  };

  useEffect(async () => {
    if (topicId !== 'new_topic') {
      const userToken = window.localStorage.getItem('userToken');
      const response = await ContentApi.getTopicById(
        userToken,
        topicId,
        history
      );
      setTopicData(response.topic);
      setTopicContent(response.topic.topicContent);
      setSceneId(response.topic.sceneId);
      setIsSpecialModule(response.topic.setIsSpecialModule);
      setUploadedModels([response.topic.modelName]);
      if (isObject(response.topic.topicName)) {
        setTopicName(response.topic.topicName[selectedLanguage]);
      } else {
        setTopicName(response.topic.topicName);
      }
    }
  }, []);

  useEffect(() => {
    if (categoryCollection?.categories?.length) {
      const selectedCat = categoryCollection?.categories.find(
        (cat) => cat.categoryId === categoryId
      );

      setSelectedCategory(selectedCat);
    }
  }, [categoryCollection]);

  const handleEditorChange = (e) => {
    setTopicContent(e);
  };

  const onSubmit = async () => {
    let submited = true;
    if (!topicName) {
      setTopicNameError('Topic name is required.');
      submited = false;
    }
    if (topicNameError || !submited) {
      return;
    }

    const data = {
      topicContent,
      topicName,
      sceneId,
      isSpecialModule,
      categoryId,
      modelData: modelDataRef.current,
      categoryName: selectedCategory?.categoryName,
      parentId: userData.userId,
      topicId
    };

    if (topicId === 'new_topic') {
      const createdTopic = await ContentApi.createNewTopic(
        token,
        data,
        history
      );
      if (createdTopic.status === 200) {
        setShouldUpdate(true);
        history.goBack();
      }
    }

    if (topicId !== 'new_topic') {
      const editedTopic = await ContentApi.editTopic(token, data, history);
      if (editedTopic.status === 200) {
        setShouldUpdate(true);
        history.goBack();
      }
    }
  };

  const topicNameValidation = (value) => {
    if (!value) {
      setTopicNameError('Topic name is required.');
      return;
    }
    setTopicNameError('');
  };

  return (
    <div className={'w-full'}>
      <React.Fragment>
        <h1 className="text-center text-4xl font-semibold mt-10">
          {topicId !== 'new_topic'
            ? 'Edit Topic'
            : inputItems.addNewTopic[selectedLanguage]}
        </h1>
        <div
          className=" m-auto py-10 mt-10 px-12 border"
          // onSubmit={handleSubmit(onSubmit)}
        >
          <label className="text-gray-600 font-medium">
            {inputItems.topicName[selectedLanguage]}
          </label>
          <input
            className={`border-solid ${
              topicNameError ? 'border-red-600' : 'border-gray-300'
            } border py-2 px-4 w-full rounded text-gray-700`}
            name="title"
            value={topicName}
            onChange={(e) => setTopicName(e.target.value)}
            placeholder={inputItems.topicNamePlaceholder[selectedLanguage]}
            onBlur={(event) => topicNameValidation(event.target.value)}
          />
          {topicNameError && (
            <span className={'text-red-600 text-sm'}>{topicNameError}</span>
          )}
          <label className="text-gray-600 font-medium block mt-4">
            {inputItems.topicContentDescription[selectedLanguage]}
          </label>

          <FroalaEditor
            tag="textarea"
            config={config}
            model={topicContent}
            onModelChange={handleEditorChange}
            className="border-solid border-gray-300 border py-20 px-4 w-full h-36 rounded text-gray-700"
          />

          {selectedCategory?.categoryName === 'Custom' &&
          topicData.modelName ? (
            <Wrapper>
              <div className="flex-grow h-800 overflow-hidden z-1 bg-center flex justify-center">
                <ModelViewer
                  file={topicData.modelFile}
                  modelData={topicData.modelData}
                  setFile={() => {}}
                  isAdminUrl={false}
                  modelName={topicData.modelName}
                  collectModelData={collectModelData}
                  setUploadedModels={() => {}}
                  uploadedModels={uploadedModels}
                />
              </div>
            </Wrapper>
          ) : (
            <div className={'w-full my-8 flex flex-col'}>
              <p className={'text-gray-600 font-medium block mt-4'}>
                {inputItems.topicScene[selectedLanguage]}
              </p>
              <div
                className={
                  'w-full border-2 rounded-md  flex flex-wrap justify-center'
                }
              >
                {scenesData.map((scene, index) => {
                  return (
                    <SceneMiniBlock
                      key={`${sceneId} + ${index}`}
                      setShowModal={setShowModal}
                      isSelected={scene.sceneId === sceneId}
                      sceneId={scene.sceneId}
                      selectedSceneId={sceneId}
                      setSceneId={setSceneId}
                      sceneName={scene.sceneName}
                      sceneAvatar={scene.sceneAvatar}
                      isSpecialModule={scene.isSpecialModule}
                      setIsSpecialModule={setIsSpecialModule}
                      path={scene.path}
                      selectedLanguage={selectedLanguage}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <SceneModalView setShowModal={setShowModal} showModal={showModal} />

          {/* Temporary commented */}
          {/* <FroalaEditorView
            model={topicContent}
        /> */}

          <button
            className="mt-4 w-full bg-green-600 hover:bg-green-700 text-green-100 border py-3 px-6 font-semibold text-md rounded"
            onClick={onSubmit}
          >
            {topicId !== 'new_topic'
              ? inputItems.save[selectedLanguage]
              : inputItems.create[selectedLanguage]}
          </button>
        </div>
      </React.Fragment>
    </div>
  );
};

export default EditTopicPage;
