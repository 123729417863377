import React from 'react';
import { aboutUsData } from '../../assets/mockData/mockData';
import { useAppContext } from '../../context/AppProvider';
const reveal = () => {
  let reveals = document.querySelectorAll('.reveal');
  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let elementTop = reveals[i].getBoundingClientRect().top;
    let elementVisible = 150;
    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add('active');
    } else {
      reveals[i].classList.remove('active');
    }
  }
};
document.addEventListener('scroll', reveal);
const aboutUs = () => {
  const { store } = useAppContext();
  const { selectedLanguage } = store;
  return (
    <div>
      <div className="flex reveal transition-all lgmax:flex-col-reverse lgmax:items-center duration-700 ease-in lgmax:flex-wrap w-full translate-y-32 lgmin:space-x-8 ">
        <div className="w-4/5 pt-4 lgmax:w-full mx-auto opacity-1 t">
          <h1 className="font-tinos text-center blockTextP lgmin:p-3 pt-8 text-4xl font-semibold">
            {aboutUsData.aboutUs.header[selectedLanguage]}
          </h1>
          <p className="text-justify font-gothic blockTextP lgmin:p-3 pt-3 text-xl">
            {aboutUsData.aboutUs.textAboutUs[selectedLanguage]}
          </p>
        </div>
      </div>
      <div className="w-4/5 pb-16 min-h-full mx-auto pt-16  bg-white space-y-16">
        <div className="flex reveal transition-all lgmax:flex-col-reverse lgmax:items-center duration-700 ease-in lgmax:flex-wrap w-full translate-y-32 lgmin:space-x-8 ">
          <div className="w-4/5 lgmax:w-full mx-auto opacity-1 t">
            <h1 className="font-tinos text-center blockTextP lgmin:p-3 pt-8 text-4xl font-semibold">
              {aboutUsData.firstSection.header[selectedLanguage]}
            </h1>
            <p className="text-justify font-gothic blockTextP lgmin:p-3 pt-3 text-xl">
              {aboutUsData.firstSection.info[selectedLanguage]}
            </p>
          </div>
          <div className="w-4/5 mt-6 lgmax:w-full h-96 bg-cover  bg-center bg-aboutUsBook"></div>
        </div>
        <div className="reveal duration-700 opacity-0 ease-in translate-y-32  space-y-16">
          <div className="flex ">
            <div className="flex lgmax:flex-wrap lgmax:justify-center w-full lgmin:space-x-4">
              <div className="w-4/5 mt-6 lgmax:w-full h-96 bg-cover  bg-center bg-aboutUsBookShelf"></div>
              <div className="w-4/5 lgmax:w-full mx-auto">
                <h1 className="font-tinos text-center blockTextP lgmin:p-3 pt-8 text-4xl font-semibold">
                  {aboutUsData.secondSection.header[selectedLanguage]}
                </h1>
                <p className="text-justify font-gothic blockTextP p-3 text-xl">
                  {aboutUsData.secondSection.info[selectedLanguage]}
                </p>
              </div>
            </div>
          </div>
          <div className="flex lgmax:flex-wrap lgmax:items-center lgmax:flex-col-reverse w-full lgmin:space-x-4">
            <div className="w-4/5  lgmax:w-full mx-auto">
              <h1 className="font-tinos text-center  blockTextP lgmin:p-3 pt-8 text-4xl font-semibold">
                {aboutUsData.thirdSection.header[selectedLanguage]}
              </h1>
              <p className="text-justify font-gothic blockTextP lgmin:p-3 pt-3 text-xl">
                {aboutUsData.thirdSection.info[selectedLanguage]}
              </p>
            </div>
            <div className="w-4/5 mt-6 lgmax:w-full bg-cover bg-center h-96 bg-aboutUsStudy "></div>
          </div>
          <div className="flex lgmax:flex-wrap lgmax:justify-center lgmax:items-center  w-full space-x-4">
            <div className="w-4/5 mt-6 h-96 lgmax:w-full  bg-cover bg-center self-center bg-aboutUsVr"></div>
            <div className="w-4/5 lgmax:w-full mx-auto">
              <h1 className="font-tinos text-center blockTextP lgmin:p-3 pt-8 text-4xl font-semibold">
                {aboutUsData.fourthSection.header[selectedLanguage]}
              </h1>
              <p className=" text-justify font-gothic blockTextP lgmin:p-3 pt-3 text-xl">
                {aboutUsData.fourthSection.info[selectedLanguage]}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="min-h-full smmin:ml-5 py-10 flex flex-col   space-y-32 bg-white">
        <div className="w-2/12 lgmax:w-3/12 mdmax:w-4/12 smmax:w-5/12 flex flex-col mx-auto min-h-2/5 mt-24 border-b-4 border-yellow broder-solid">
          <h1 className="font-tinos w-4/5 self-center  text-center blockTextH font-semibold text-4xl ">
            {aboutUsData.ourTeam.header[selectedLanguage]}
          </h1>
        </div>
        <div className="h-full flex flex-start items-start lgmin:space-x-4 lgmax:space-y-10 w-full lgmax:flex-col mdmax:flex-col mdmax:items-center  lgmax:flex-wrap mx-auto  justify-center">
          <div className=" lgmax:space-x-5 smmax:space-x-0 smmax:flex-col w-3/12 flex lgmin:flex-col lgmax:w-full  items-center justify-center space-y-2">
            <div className=" drop-shadow-2xl h-24 w-24 bg-CristianImage bg-center  bg-cover rounded-full mb-5"></div>
            <div className="w-10/12 lgmax:space-y-3">
              <h1 className="lgmin:text-center smmax:text-center font-tinos text-3xl">
                {aboutUsData.ourTeam.Team.Cristian.name}
              </h1>
              <p className="lgmin:text-center smmax:text-center text-gray-600">
                {aboutUsData.ourTeam.Team.Cristian.position[selectedLanguage]}
              </p>
              <p className="font-gothic smmax:text-center lgmin:text-center blockTextH text-xl lgmin:p-3">
                {
                  aboutUsData.ourTeam.Team.Cristian.description[
                    selectedLanguage
                  ]
                }
              </p>
            </div>
          </div>
          <div className=" lgmax:space-x-5 smmax:space-x-0 smmax:flex-col  w-3/12 flex lgmin:flex-col lgmax:w-full  items-center justify-center space-y-2">
            <div className="drop-shadow-2xl   h-24 w-24 bg-StanislavImage bg-center bg-cover rounded-full mb-5"></div>
            <div className="w-10/12 lgmax:space-y-3">
              <h1 className="lgmin:text-center smmax:text-center font-tinos text-3xl">
                {aboutUsData.ourTeam.Team.Stanislav.name}
              </h1>
              <p className="lgmin:text-center smmax:text-center text-gray-600">
                {aboutUsData.ourTeam.Team.Stanislav.position[selectedLanguage]}
              </p>
              <p className="font-gothic smmax:text-center lgmin:text-center blockTextH text-xl lgmin:p-3">
                {
                  aboutUsData.ourTeam.Team.Stanislav.description[
                    selectedLanguage
                  ]
                }
              </p>
            </div>
          </div>

          <div className="lgmax:space-x-5 smmax:space-x-0 smmax:flex-col w-3/12 flex lgmin:flex-col lgmax:w-full  items-center justify-center space-y-2">
            <div className="  drop-shadow-2xl h-24 w-24 bg-RustislavImage bg-cover rounded-full mb-5"></div>
            <div className="w-10/12 lgmax:space-y-3">
              <h1 className="lgmin:text-center smmax:text-center font-tinos text-3xl">
                {aboutUsData.ourTeam.Team.Rustislav.name}
              </h1>
              <p className="lgmin:text-center smmax:text-center text-gray-600">
                {aboutUsData.ourTeam.Team.Rustislav.position[selectedLanguage]}
              </p>
              <p className="font-gothic smmax:text-center lgmin:text-center blockTextH text-xl lgmin:p-3">
                {
                  aboutUsData.ourTeam.Team.Rustislav.description[
                    selectedLanguage
                  ]
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default aboutUs;
