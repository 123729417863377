import React, { useEffect, useRef } from 'react';
import styles from './styles.module.css';

const LiquidButton = ({ label, clickHandler }) => {
  const canvasRef = useRef(null);
  const pointsA = [];
  const pointsB = [];
  const points = 8;
  const viscosity = 20;
  const mouseDist = 70;
  const damping = 0.05;
  const showIndicators = false;

  let mouseX = 0,
    mouseY = 0,
    relMouseX = 0,
    relMouseY = 0,
    mouseLastX = 0,
    mouseLastY = 0,
    mouseDirectionX = 0,
    mouseDirectionY = 0,
    mouseSpeedX = 0,
    mouseSpeedY = 0;

  // Point class
  class Point {
    constructor(x, y, level) {
      this.x = this.ix = 50 + x;
      this.y = this.iy = 50 + y;
      this.vx = 0;
      this.vy = 0;
      this.cx1 = 0;
      this.cy1 = 0;
      this.cx2 = 0;
      this.cy2 = 0;
      this.level = level;
    }

    move() {
      this.vx += (this.ix - this.x) / (viscosity * this.level);
      this.vy += (this.iy - this.y) / (viscosity * this.level);

      const dx = this.ix - relMouseX;
      const dy = this.iy - relMouseY;
      const relDist = 1 - Math.sqrt(dx * dx + dy * dy) / mouseDist;

      // Move x
      if (
        (mouseDirectionX > 0 && relMouseX > this.x) ||
        (mouseDirectionX < 0 && relMouseX < this.x)
      ) {
        if (relDist > 0 && relDist < 1) {
          this.vx = (mouseSpeedX / 4) * relDist;
        }
      }
      this.vx *= 1 - damping;
      this.x += this.vx;

      // Move y
      if (
        (mouseDirectionY > 0 && relMouseY > this.y) ||
        (mouseDirectionY < 0 && relMouseY < this.y)
      ) {
        if (relDist > 0 && relDist < 1) {
          this.vy = (mouseSpeedY / 4) * relDist;
        }
      }
      this.vy *= 1 - damping;
      this.y += this.vy;
    }
  }

  // Add points
  function addPoints(x, y) {
    pointsA.push(new Point(x, y, 1));
    pointsB.push(new Point(x, y, 2));
  }

  // Mouse Direction and Speed
  const mouseDirection = (e) => {
    mouseDirectionX = mouseX < e.pageX ? 1 : mouseX > e.pageX ? -1 : 0;
    mouseDirectionY = mouseY < e.pageY ? 1 : mouseY > e.pageY ? -1 : 0;

    mouseX = e.pageX;
    mouseY = e.pageY;

    const canvasOffset = canvasRef.current.getBoundingClientRect();
    relMouseX = mouseX - canvasOffset.left;
    relMouseY = mouseY - canvasOffset.top;
  };

  const mouseSpeed = () => {
    mouseSpeedX = mouseX - mouseLastX;
    mouseSpeedY = mouseY - mouseLastY;

    mouseLastX = mouseX;
    mouseLastY = mouseY;

    setTimeout(mouseSpeed, 50);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const button = canvas.parentElement;
    const buttonWidth = button.offsetWidth;
    const buttonHeight = button.offsetHeight;

    canvas.width = buttonWidth + 100;
    canvas.height = buttonHeight + 100;

    // Add points
    const x = buttonHeight / 2;
    for (let j = 1; j < points; j++) {
      addPoints(x + ((buttonWidth - buttonHeight) / points) * j, 0);
    }
    addPoints(buttonWidth - buttonHeight / 5, 0);
    addPoints(buttonWidth + buttonHeight / 10, buttonHeight / 2);
    addPoints(buttonWidth - buttonHeight / 5, buttonHeight);
    for (let j = points - 1; j > 0; j--) {
      addPoints(x + ((buttonWidth - buttonHeight) / points) * j, buttonHeight);
    }
    addPoints(buttonHeight / 5, buttonHeight);
    addPoints(-buttonHeight / 10, buttonHeight / 2);
    addPoints(buttonHeight / 5, 0);

    const renderCanvas = () => {
      requestAnimationFrame(renderCanvas);

      // Clear scene
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = '#fff';
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Move points
      pointsA.forEach((point) => point.move());
      pointsB.forEach((point) => point.move());

      // Create dynamic gradient
      const canvasOffset = canvas.getBoundingClientRect();
      const gradientX = Math.min(
        Math.max(mouseX - canvasOffset.left, 0),
        canvas.width
      );
      const gradientY = Math.min(
        Math.max(mouseY - canvasOffset.top, 0),
        canvas.height
      );
      const distance =
        Math.sqrt(
          Math.pow(gradientX - canvas.width / 2, 2) +
            Math.pow(gradientY - canvas.height / 2, 2)
        ) /
        Math.sqrt(
          Math.pow(canvas.width / 2, 2) + Math.pow(canvas.height / 2, 2)
        );

      const gradient = context.createRadialGradient(
        gradientX,
        gradientY,
        300 + 300 * distance,
        gradientX,
        gradientY,
        0
      );
      gradient.addColorStop(0, '#bd4b76'); // #102ce5 #1F2A37 ... blue #1F2A37
      gradient.addColorStop(1, '#fb7185'); // #E406D6 #1F2A37 ... pink #fb7185

      // Draw shapes
      const groups = [pointsA, pointsB];

      groups.forEach((points, j) => {
        context.fillStyle = j === 0 ? '#1F2A37' : gradient;

        context.beginPath();
        context.moveTo(points[0].x, points[0].y);

        points.forEach((p, i) => {
          const nextP = points[i + 1] || points[0];
          p.cx1 = (p.x + nextP.x) / 2;
          p.cy1 = (p.y + nextP.y) / 2;
          context.bezierCurveTo(p.x, p.y, p.cx1, p.cy1, p.cx1, p.cy1);
        });

        context.fill();
      });

      if (showIndicators) {
        // Draw points
        context.fillStyle = '#000';
        context.beginPath();
        pointsA.forEach((p) => {
          context.rect(p.x - 1, p.y - 1, 2, 2);
        });
        context.fill();

        // Draw controls
        context.fillStyle = '#f00';
        context.beginPath();
        pointsA.forEach((p) => {
          context.rect(p.cx1 - 1, p.cy1 - 1, 2, 2);
          context.rect(p.cx2 - 1, p.cy2 - 1, 2, 2);
        });
        context.fill();
      }
    };

    renderCanvas();
    mouseSpeed();

    document.addEventListener('mousemove', mouseDirection);

    return () => {
      document.removeEventListener('mousemove', mouseDirection);
    };
  }, []);

  return (
    <button
      className={styles.btn_liquid}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        clickHandler();
      }}
    >
      <span className={styles.inner}>{label}</span>
      <canvas ref={canvasRef}></canvas>
    </button>
  );
};

export default LiquidButton;
