import React from 'react';
import { PrivacyPolicyData } from '../../../assets/mockData/mockData';
import PrivacyPolicyText from './PrivacyPolicyText';

const PrivacyPolicy = () => {
  return (
    <div className=" flex-grow smmax:w-11/12 w-10/12  m-auto space-y-10 my-20">
      {PrivacyPolicyData.map((element, index) => {
        return (
          <PrivacyPolicyText
            key={index}
            headText={element.headName}
            paragraphText={element.textContent}
          />
        );
      })}
    </div>
  );
};
export default PrivacyPolicy;
