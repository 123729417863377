import React, { useReducer } from 'react';
import createCtx from '../services/createCtx';

const SET_USER_TOKEN = 'SET_USER_TOKEN';
const SET_USER_DATA = 'SET_USER_DATA';
const SET_LANGUAGE = 'SET_LANGUAGE';
const LOG_OUT = 'LOG_OUT';
const SET_USERS_LIST = 'SET_USERS_LIST';
const SET_CATEGORY_COLLECTION = 'SET_CATEGORY_COLLECTION';
const SET_SHOULD_UPDATE = 'SET_SHOULD_UPDATE';
const SET_LOADING = 'SET_LOADING';
const [useCtx, Provider] = createCtx();

const initialState = {
  token: null,
  user: {
    lastname: '',
    firstname: '',
    phone: null,
    role: null,
    status: null,
    parentId: null,
    children: [],
    email: '',
    userId: ''
  },
  usersList: [],
  categoryCollection: {},
  isAuthenticated: false,
  selectedLanguage: 'EN',
  shouldUpdate: false,
  loading: true
};

const setUserTokenAction = (dispatch) => (userToken) => {
  dispatch({
    type: SET_USER_TOKEN,
    payload: { userToken }
  });
};

const setUserAction = (dispatch) => (user) => {
  dispatch({
    type: SET_USER_DATA,
    payload: { user }
  });
};

const setSelectedLanguage = (dispatch) => (ln) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: { ln }
  });
};

const logOutAction = (dispatch) => () => {
  dispatch({
    type: LOG_OUT,
    payload: {}
  });
};

const setUsersList = (dispatch) => (users) => {
  dispatch({
    type: SET_USERS_LIST,
    payload: { users }
  });
};
const setCategoryCollection = (dispatch) => (collection) => {
  dispatch({
    type: SET_CATEGORY_COLLECTION,
    payload: { collection }
  });
};

const setShouldUpdate = (dispatch) => (shouldUpdate) => {
  dispatch({
    type: SET_SHOULD_UPDATE,
    payload: { shouldUpdate }
  });
};

const setLoading = (dispatch) => (loading) => {
  dispatch({
    type: SET_LOADING,
    payload: loading
  });
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_TOKEN: {
      window.localStorage.setItem('userToken', payload.userToken);
      return { ...state, token: payload.userToken, isAuthenticated: true };
    }
    case SET_USER_DATA:
      window.localStorage.setItem('userData', JSON.stringify(payload.user));
      return { ...state, user: { ...payload.user } };
    case LOG_OUT: {
      window.localStorage.removeItem('userData');
      window.localStorage.removeItem('userToken');
      return {
        ...state,
        token: null,
        user: {},
        isAuthenticated: false
      };
    }
    case SET_USERS_LIST: {
      return {
        ...state,
        usersList: payload.users
      };
    }
    case SET_CATEGORY_COLLECTION: {
      return {
        ...state,
        categoryCollection: payload.collection
      };
    }
    case SET_SHOULD_UPDATE: {
      return {
        ...state,
        shouldUpdate: payload.shouldUpdate
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: payload
      };
    }
    case SET_LANGUAGE:
      return { ...state, selectedLanguage: payload.ln };
    default:
      return state;
  }
};

function AppProvider({ children }) {
  const [store, dispatch] = useReducer(reducer, initialState);

  const actions = {
    setUserTokenAction: setUserTokenAction(dispatch),
    setUserAction: setUserAction(dispatch),
    setSelectedLanguage: setSelectedLanguage(dispatch),
    logOutAction: logOutAction(dispatch),
    setUsersList: setUsersList(dispatch),
    setCategoryCollection: setCategoryCollection(dispatch),
    setShouldUpdate: setShouldUpdate(dispatch),
    setLoading: setLoading(dispatch)
  };

  return <Provider value={{ store, ...actions }}>{children}</Provider>;
}

export { useCtx as useAppContext, AppProvider };
