import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import styles from './chemistryBounds.module.css';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';

import { VRButton } from './../../../common/VRButton';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { remainderSolution } from './remainderSolution';
import createCo2 from './createCo2';
import createH2O from './createH2O';
import { createFinalSolution } from './finalSolution';
import { roundedSquare } from '../../../common/SceneModelGeneral/roundedSquareBg';
import createTopicName from '../../../common/SceneModelGeneral/createTopicName';
import ControllerInstruction from './../../../common/controllerInstruction/index';
import { textToSpeech } from '../../../common/TabletFunctionality/textToSpeech';
import { Tablet } from '../../../common/TabletFunctionality/index';
import { Pagination } from '../../../common/TabletFunctionality/Pagination';
import { pagintationLerping } from '../../../common/TabletFunctionality/paginationLerping';
import { playInstruction } from '../../../common/TabletFunctionality/playInstruction';
class ChemistryBounds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: true,
      percent: 0,
      modelPosition: [],
      modelName: '',
      language: 5,
      isVisible: false,
      loadingType: props.type
    };
    this.lang = props.lang;

    this.containerRef = React.createRef();
    this.clock = new THREE.Clock();

    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    );
    this.camera.position.set(0, 0, 40);
    this.scene.add(this.camera);
    // this.scene.background = new THREE.Color(0x505050);

    this.renderer = new THREE.WebGLRenderer({});
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.raycaster = new THREE.Raycaster();
    this.clickMouse = new THREE.Vector2();
    this.moveMouse = new THREE.Vector2();
    this.draggable = null;

    const imageBg = require('../../../assets/img/gradientScene.jpeg');
    const texture = new THREE.TextureLoader().load(imageBg);
    this.scene.background = texture;

    this.workingMatrix = new THREE.Matrix4();
    this.workingVector = new THREE.Vector3();
    this.origin = new THREE.Vector3();

    this.textMeshC = undefined;
    this.loadedMusicBar = true;
    this.sunGeometry = new THREE.IcosahedronBufferGeometry(8, 14);
    this.sunMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    this.sunMesh = new THREE.Mesh(this.sunGeometry, this.sunMaterial);
    this.scene.add(this.sunMesh);
    this.sunMesh.position.set(10, 40, -60);

    this.selectedObject = null;

    this.dolly = new THREE.Object3D();

    this.arrow = new THREE.Group();
    this.stickArrowGeometry = new THREE.ConeGeometry(0.5, 2, 3);
    this.stickArrowMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    this.stickArrow = new THREE.Mesh(
      this.stickArrowGeometry,
      this.stickArrowMaterial
    );
    this.stickGeometry = new THREE.CylinderBufferGeometry(0.1, 0.1, 5);
    this.stickMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    this.stick = new THREE.Mesh(this.stickGeometry, this.stickMaterial);
    this.arrow.add(this.stick, this.stickArrow);
    this.stickArrow.position.set(0, 2, 0);
    this.scene.add(this.arrow);
    this.arrow.rotation.z = Math.PI * 1.5;

    this.arrow.position.set(0, -28.5, -20);
    const xInstructionCoord = this.props.name?.controllerCoords?.x
      ? this.props.name.controllerCoords.x
      : 2.1;
    const yInstructionCoord = this.props.name?.controllerCoords?.y
      ? this.props.name.controllerCoords.y
      : 1;
    const zInstructionCoord = this.props.name?.controllerCoords?.z
      ? this.props.name.controllerCoords.z
      : -1;

    this.ControllerInstruction = new ControllerInstruction({
      x: xInstructionCoord,
      y: yInstructionCoord,
      z: zInstructionCoord
    });
    this.ControllerInstruction.bindInstructionToScene(this.scene);

    this.dolly.add(this.camera);
    this.scene.add(this.dolly);

    this.objectsGroup = new THREE.Group();
    this.objectsGroup.position.set(0, 5, -20);
    this.scene.add(this.objectsGroup);

    this.initScene();
    this.setupXR(this.scene);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();
    this.renderer.setAnimationLoop(this.render.bind(this));

    this.createResetButton();
  }

  createResetButton() {
    const buttonGeometry = new THREE.BoxGeometry(5, 2, 0.03);
    const buttonMaterial = new THREE.MeshBasicMaterial({ color: 0x4cd137 });
    this.buttonMesh = new THREE.Mesh(buttonGeometry, buttonMaterial);
    this.buttonMesh.name = 'resetButton';

    const loadText = async (obj, text) => {
      const loader = new FontLoader();
      await loader.load(
        'https://s3.eu-central-1.amazonaws.com/lectio.app/helvetiker_regular.typeface.json',
        function (font) {
          var textGeometry = new TextGeometry(text, {
            font: font,
            size: 1,
            height: 0.1
          });
          let textMaterial;

          var mesh = new THREE.Mesh(textGeometry, textMaterial);
          obj.add(mesh);
          mesh.position.set(2, -0.5, -0.1);
          mesh.rotateY(Math.PI * 1);
        }
      );
    };
    loadText(this.buttonMesh, 'RESET');

    this.scene.add(this.buttonMesh);
    this.buttonMesh.position.set(16, 0, 0);
    this.buttonMesh.rotateY(Math.PI * 0.5);
  }

  initScene() {
    this.radius = 2.5;
    this.sun = this.addObjects();
    this.scene.add(this.sun);
    this.sun.position.set(10, 40, -60);
    const directioanalLight = new THREE.DirectionalLight(0x404040, 20.5);
    directioanalLight.position.set(10, 40, 10);
    this.scene.add(directioanalLight);
    const hemiLight = new THREE.HemisphereLight(0xfaf9cf, 0xfaf9e8, 1.6);
    hemiLight.position.set(10, 40, 15);
    this.scene.add(hemiLight);

    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    } else {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    }
  }

  async loadText(room, text, index) {
    const loader = new FontLoader();
    const self = this;
    self.vrButton.vrButton.disabled = false;
    self.scene.remove(self.scene.getObjectByName('text'));
    loader.load(
      '/helvetiker_regular.typeface.json',

      function (font) {
        var textGeometry = new TextGeometry(text[self.props.lang], {
          font: font,
          size: text.modelSize ? text.modelSize : 0.1,
          height: text.modelHeight ? text.modelHeight : 0.005
        });
        let textMaterial;

        textMaterial = new THREE.MeshPhongMaterial({
          color: 0xffffff
        });

        let mesh = new THREE.Mesh(textGeometry, textMaterial);
        self.scene.add(mesh);
        if (text.name) {
          mesh.name = text.name;
        } else {
          mesh.name = 'text';
        }

        if (text.modelCoordsX && text.modelCoordsY && text.modelCoordsZ) {
          mesh.position.set(
            text.modelCoordsX,
            text.modelCoordsY,
            text.modelCoordsZ
          );
        } else {
          mesh.position.set(0, 0, 0);
        }
        if (text.modelRotationX && text.modelRotationY && text.modelRotationZ) {
          mesh.rotation.set(
            text.modelRotationX,
            text.modelRotationY,
            text.modelRotationZ
          );
        } else {
          mesh.rotation.set(0, 0, 0);
        }

        if (index < 1) {
          const textGeoC = new TextGeometry('C', {
            font: font,
            size: 1,
            height: 0.1
          });

          const textMaterialC = new THREE.MeshStandardMaterial({
            color: 0xf5f6fa
          });
          const textMeshC = new THREE.Mesh(textGeoC, textMaterialC);

          const textGeoO = new TextGeometry('O', {
            font: font,
            size: 1,
            height: 0.1
          });

          const textMaterialO = new THREE.MeshStandardMaterial({
            color: 0x2f3640
          });
          const textMeshO = new THREE.Mesh(textGeoO, textMaterialO);

          const textGeoH = new TextGeometry('H', {
            font: font,
            size: 1,
            height: 0.1
          });

          const textMaterialH = new THREE.MeshStandardMaterial({
            color: 0x2f3640
          });
          const textMeshH = new THREE.Mesh(textGeoH, textMaterialH);
          if (!self.scene.getObjectByName('H2OBox')) {
            remainderSolution(
              textMeshC,
              textMeshO,
              textMeshH,
              self.objectsGroup,
              self.scene
            );
            createFinalSolution(
              textMeshC,
              textMeshO,
              textMeshH,
              self.objectsGroup
            );
            createCo2(textMeshC, textMeshO, self.objectsGroup);
            createH2O(textMeshO, textMeshH, self.objectsGroup);
          }
          self.textMeshC = textMeshC;
        }
      }

      // onProgress callback
    );
  }

  addObjects() {
    // const material = new THREE.MeshNormalMaterial({ color: 0xffffff });
    this.sunMaterial = new THREE.ShaderMaterial({
      extensions: {
        derivatives: '#extension GL_OES_standard_derivatives : enabled'
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        resolution: { value: new THREE.Vector4() },
        uPerlin: { value: null }
      },

      vertexShader: `
      uniform float time;
varying vec2 vUv;
varying vec3 vPosition;
varying vec3 vNormal;
uniform vec2 pixels;
float PI = 3.14159265359;


varying vec3 vLayer0;
varying vec3 vLayer1;
varying vec3 vLayer2;
varying vec3 eyeVector;

mat2 rotate(float a){
    float s = sin(a);
    float c = cos(a);
    return mat2(c,-s,s,c);
}

void main() {

    vNormal = normal;

    vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
    eyeVector = normalize(worldPosition.xyz - cameraPosition);
    

    float t = time*0.005;

    mat2 rot = rotate(t);

    vec3 p0 = position;
    p0.yz = rot*p0.yz;
    vLayer0 = p0;

    mat2 rot1 = rotate(t + 10.);

    vec3 p1 = position;
    p1.xz = rot1*p1.xz;
    vLayer1 = p1;

    mat2 rot2 = rotate(t + 90.);

    vec3 p2 = position;
    p2.xy = rot2*p2.xy;
    vLayer2 = p2;




    vUv = uv;
    vPosition = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
      `,
      fragmentShader: `
      uniform float time;
      uniform float progress;
      uniform sampler2D texture1;
      uniform vec4 resolution;
      uniform samplerCube uPerlin;
      varying vec2 vUv;
      varying vec3 vPosition;
      varying vec3 vNormal;
      varying vec3 vLayer0;
      varying vec3 vLayer1;
      varying vec3 vLayer2;
      varying vec3 eyeVector;
      
      float PI = 3.14159265359;
      
      vec3 brightnessToColor(float b){
          b *=0.5;
          return (vec3(b, b*b, b*b*b*b)/0.25)*0.8;
      }
      
      
      float Fresnel(vec3 eyeVector, vec3 worldNormal) {
          return pow(1.0 + dot(eyeVector, worldNormal), 3.0);
      }
      
      float supersun(){
          float sum = 0.;
          sum += textureCube(uPerlin,vLayer0).r;
          sum += textureCube(uPerlin,vLayer1).r;
          sum += textureCube(uPerlin,vLayer2).r;
          sum *=0.33;
          return sum;
      }
      
      void main() {
          float brightness = supersun();
          float fres = Fresnel(eyeVector, vNormal);
          brightness = brightness * 8. + 1.;
          brightness += pow(fres, 0.95);
      
          vec3 col = brightnessToColor(brightness);
          gl_FragColor = vec4(col,1.);
          // gl_FragColor = vec4(fres);
      
      }
    `
    });

    const geometry = new THREE.SphereBufferGeometry(10, 30, 30);
    return new THREE.Mesh(geometry, this.sunMaterial);
  }

  createButtonStates(components) {
    const buttonStates = {};
    this.gamepadIndices = components;

    Object.keys(components).forEach((key) => {
      if (key.indexOf('touchpad') != -1 || key.indexOf('thumbstick') != -1) {
        buttonStates[key] = { button: 0, xAxis: 0, yAxis: 0 };
      } else {
        buttonStates[key] = 0;
      }
    });

    this.buttonStates = buttonStates;
  }

  setupXR(scene) {
    this.renderer.xr.enabled = true;
    const self = this;
    this.controllers = self.buildControllers(scene);
    function onConnected(e) {
      this.gamepad = e.data.gamepad;
      self.updateControllers({
        right: { trigger: true, squeeze: true, side: e.data.handedness },
        left: { trigger: true, squeeze: true, side: e.data.handedness }
      });
    }

    const controllers = [
      this.renderer.xr.getController(0),
      this.renderer.xr.getController(1)
    ];

    controllers.map((element) => {
      element.addEventListener('connected', onConnected);
    });
  }

  buildControllers() {
    const controllerModelFactory = new XRControllerModelFactory();
    const geometrySphere = new THREE.IcosahedronBufferGeometry(this.radius, 2);

    const sphere = new THREE.Mesh(
      geometrySphere,
      new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.BackSide })
    );
    sphere.name = 'sphereIndicator';
    sphere.scale.set(0.003, 0.004, 0.003);

    const geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, -1)
    ]);

    const line = new THREE.Line(geometry);
    line.name = 'line';
    line.scale.z = 30;
    sphere.visible = false;
    sphere.name = 'sphere';
    const controllers = [];

    for (let i = 0; i <= 1; i++) {
      const controller = this.renderer.xr.getController(i);
      controller.add(line.clone());
      controller.add(sphere.clone());

      if (i != 0) {
        line.visible = true;
      } else {
        line.visible = true;
        controller.getObjectByName('line').visible = false;

        let controllerSphere = controller.getObjectByName('sphere');
        controllerSphere.scale.x = 0;
        controllerSphere.scale.y = 0;
        controllerSphere.scale.z = 0;
      }

      controller.userData.selectPressed = false;

      this.dolly.add(controller);

      controllers.push(controller);

      const grip = this.renderer.xr.getControllerGrip(i);
      grip.add(controllerModelFactory.createControllerModel(grip));

      this.workingMatrix.identity().extractRotation(controller.matrixWorld);

      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);

      controller.children[0].scale.z = 30;

      if (i == 1) {
        this.dolly.add(grip);
      }
    }
    Tablet(this, controllers);

    return controllers;
  }

  updateControllers(info) {
    const self = this;

    function onSelectStart() {
      this.userData.selectPressed = true;
      Pagination(this, self);
      if (!self.sound?.isPlaying)
        playInstruction(
          this,
          self,
          self.props.text[self.props.text.length - 1]
        );

      if (this.userData.selected && !this.userData.attachedObject) {
        if (
          this.userData.selected.name == 'tabletSoundOn' &&
          self.props.description[self.props.lang] &&
          self.loadedMusicBar
        ) {
          textToSpeech(self);
          return 0;
        } else if (this.userData.selected.name == 'tabletSoundOff') {
          if (self.sound.isPlaying) {
            self.sound.pause();
            self.loadedMusicBar = true;
          }
          return 0;
        }
      }
      if (this.userData.selected?.name === 'tabletRefresh') {
        self.objectsGroup.getObjectByName('CO2Box').position.set(-10, -13, 0);
        self.objectsGroup.getObjectByName('CO2Box').rotation.set(0, 0, 0);
        self.objectsGroup.getObjectByName('CO2Box').visible = true;
        self.objectsGroup.getObjectByName('H2OBox').position.set(10, -13, 0);
        self.objectsGroup.getObjectByName('H2OBox').rotation.set(0, 0, 0);
        self.objectsGroup.getObjectByName('H2OBox').visible = true;
        self.scene.getObjectByName('glucozeGroup').position.set(-50, 0, -10);

        self.scene.getObjectByName('firstMolCarbon').visible = false;
        if (self.scene.getObjectByName('photosyntes'))
          self.scene.getObjectByName('photosyntes').visible = false;

        self.scene.getObjectByName('firstMolCarbon').scale.set(0, 0, 0);
        if (self.scene.getObjectByName('photosyntes')) {
          self.scene.getObjectByName('photosyntes').scale.set(0, 0, 0);
          self.scene.getObjectByName('photosyntes').position.y = 0;
        }

        self.props.text.map((element, index) => {
          if (index < 2 || index > 3) {
            self.scene.remove(self.scene.getObjectByName('text'));
            if (element.name !== 'formula')
              self.loadText(self.scene, element, index, false);
            // this.setState({ ...this.state, loadedFlag: true });
          }
        });
        return 0;
      }
      if (
        this.userData.selected &&
        !this.userData.attachedObject &&
        !self.tablet.getObjectByName(this.userData.selected.name) &&
        !self.stopAttaching
      ) {
        this.userData.attachedObject = this.userData.selected;

        this.attach(this.userData.selected);
        self.setState({
          modelName: this.userData.selected.name,
          executed: true
        });
      }
    }

    function onSelectEnd() {
      this.userData.selectPressed = false;
      if (this.userData.attachedObject) {
        if (this.children[2] && this.children[2].type === 'Mesh') {
          self.objectsGroup.attach(this.children[2]);
          this.remove(this.children[2]);
        }

        let distanceBetween;

        if (self.objectsGroup.getObjectByName('H2OBox')) {
          distanceBetween = self.objectsGroup
            .getObjectByName('H2OBox')
            .position.distanceTo(
              self.objectsGroup.getObjectByName('CO2Box').position
            );
        }
        if (distanceBetween < 4 && distanceBetween) {
          self.objectsGroup.getObjectByName('CO2Box').visible = false;
          self.objectsGroup.getObjectByName('H2OBox').visible = false;

          self.scene.getObjectByName('firstMolCarbon').visible = true;
          if (self.scene.getObjectByName('photosyntes'))
            self.scene.getObjectByName('photosyntes').visible = true;

          self.scene.getObjectByName('firstMolCarbon').scale.set(0.5, 0.5, 0.5);
          if (self.scene.getObjectByName('photosyntes')) {
            self.scene.getObjectByName('photosyntes').scale.set(0.5, 0.5, 0.5);
          }
          self.props.text.map((element, index) => {
            if (index > 1 && index <= 4) {
              self.scene.remove(self.scene.getObjectByName('text'));
              if (element.name !== 'formula')
                self.loadText(self.scene, element, index);
              self.setState({ ...self.state, loadedFlag: true });
            }
          });
        }
        if (
          this.userData.selected &&
          !self.objectsGroup.getObjectByName('CO2Box').visible &&
          this.userData.selected?.name !== 'glucozeGroup'
        ) {
          self.scene.getObjectByName('glucozeGroup').position.set(-8, -5, 10);
          self.scene.getObjectByName('glucozeGroup').rotation.set(0, 0, 0);
        } else if (
          !self.objectsGroup.getObjectByName('CO2Box').visible &&
          this.userData.selected?.name === 'glucozeGroup'
        ) {
          self.scene.getObjectByName('glucozeGroup').position.set(-8, -5, 10);
          self.scene.getObjectByName('glucozeGroup').rotation.set(0, 0, 0);
        }
        if (this.userData.attachedObject !== undefined) {
          this.children[1].visible = false;
          this.children[0].scale.z = 30;
        }
        this.userData.attachedObject = undefined;
        this.userData.selected = undefined;
        this.children[1].visible = false;
        this.children[0].scale.z = 30;
        self.selectedObject = null;
      }
    }

    function onDisconnected() {
      const index = this.userData.index;

      if (self.controllers) {
        const obj = index == 0 ? self.controllers.right : self.controllers.left;

        if (obj) {
          if (obj.controller) {
            const controller = obj.controller;
            while (controller.children.length > 0)
              controller.remove(controller.children[0]);
            self.scene.remove(controller);
          }
          if (obj.grip) self.scene.remove(obj.grip);
        }
      }
    }

    if (info.right !== undefined && info.right.side === 'right') {
      const right = this.renderer.xr.getController(0);

      let trigger = false;

      Object.keys(info.right).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
      });

      if (trigger) {
        right.addEventListener('selectstart', onSelectStart);
        right.addEventListener('selectend', onSelectEnd);
      }

      right.addEventListener('disconnected', onDisconnected);
    }

    if (info.left !== undefined && info.left.side === 'left') {
      const left = this.renderer.xr.getController(1);

      let trigger = false;

      Object.keys(info.left).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
      });

      if (trigger) {
        left.addEventListener('selectstart', onSelectStart);
        left.addEventListener('selectend', onSelectEnd);
      }

      left.addEventListener('disconnected', onDisconnected);
    }
  }

  handleController(controller, index) {
    const axes = controller.gamepad?.axes;
    let a = 0;
    let b = 0;
    const speedGamepad = 1;
    this.workingMatrix.identity().extractRotation(controller.matrixWorld);
    if (index != 0) {
      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);
    }
    let iterationArray;
    if (this.tablet?.children.length > 0) {
      this.tabletRefreshInstruction = this.tablet.getObjectByName(
        'tabletInstructionRefresh'
      ).children;
      this.tabletDescription =
        this.tablet.getObjectByName('tabletDescription').children;

      iterationArray = [
        ...this.objectsGroup.children,
        this.scene.getObjectByName('glucozeGroup'),
        ...this.tablet.children,
        ...this.tabletRefreshInstruction,
        ...this.tabletDescription
      ];
    }

    let intersectsModel = this.raycaster.intersectObjects(
      iterationArray,
      false
    );
    if (!controller.userData.attachedObject) {
      if (
        intersectsModel.length > 0 &&
        !intersectsModel[0].object.text &&
        intersectsModel[0].object.parent?.visible
      ) {
        controller.children[1].visible = true;
        controller.children[1].position.x = controller.children[0].position.x;
        controller.children[1].position.z = -intersectsModel[0].distance + 0.01;
        controller.children[0].scale.z = intersectsModel[0].distance;
        this.lineLength = intersectsModel[0].distance;
        controller.userData.selected = intersectsModel[0].object;
      } else {
        controller.children[0].scale.z = 30;
        controller.children[1].visible = false;
        controller.userData.selected = undefined;
      }
    }

    if (controller.userData.selectPressed) {
      if (
        intersectsModel.length > 0 &&
        !intersectsModel[0].object.text &&
        intersectsModel[0].object.parent?.visible
      ) {
        controller.userData.selected = intersectsModel[0].object;
      } else {
        controller.children[0].scale.z = 30;
        controller.children[1].visible = false;
      }
      if (controller.userData.attachedObject) {
        controller.children[1].visible = true;
        controller.children[0].scale.z = this.lineLength;
      }
    }

    if (axes?.length) {
      if (axes[2] != 0) {
        a += axes[2];
      }
      if (axes[3] != 0) {
        b += axes[3];
      }
    }

    const quaternion = this.dolly.quaternion.clone();

    if (a !== 0 || b !== 0) {
      this.dolly.quaternion.copy(this.camera?.quaternion);
      this.dolly.translateZ(b * speedGamepad);
      this.dolly.translateX(a * speedGamepad);

      this.dolly.quaternion.copy(quaternion);
    }
  }

  updateGamepadState() {
    const session = this.renderer.xr.getSession();

    const inputSource = session?.inputSources[0];

    if (
      inputSource &&
      inputSource.gamepad &&
      this.gamepadIndices &&
      this.ui &&
      this.buttonStates
    ) {
      const gamepad = inputSource.gamepad;
      try {
        Object.entries(this.buttonStates).forEach(([key]) => {
          const buttonIndex = this.gamepadIndices[key].button;
          if (
            key.indexOf('touchpad') != -1 ||
            key.indexOf('thumbstick') != -1
          ) {
            const xAxisIndex = this.gamepadIndices[key].xAxis;
            const yAxisIndex = this.gamepadIndices[key].yAxis;
            this.buttonStates[key].button = gamepad.buttons[buttonIndex].value;
            this.buttonStates[key].xAxis = gamepad.axes[xAxisIndex].toFixed(2);
            this.buttonStates[key].yAxis = gamepad.axes[yAxisIndex].toFixed(2);
          } else {
            this.buttonStates[key] = gamepad.buttons[buttonIndex].value;
          }

          this.updateUI();
        });
      } catch (e) {
        console.warn('An error occurred setting the ui');
      }
    }
  }

  initInstructionEvent() {
    this.scene.getObjectByName('instructionPanel').visible = true;
    this.scene.getObjectByName('resetButton').visible = false;
    this.vrButton.vrButton.disabled = false;
  }

  componentDidUpdate(prevProps) {
    const self = this;
    if (this.props.lang != prevProps.lang || this.props.lang === 'RO') {
      createTopicName(this.props, this.scene);
    }
    if (this.vrButton?.vrButton) {
      this.vrButton.vrButton.disabled = false;
    }
    // Changing text mesh on change language
    if (this.lang != prevProps.lang || !self.state.loadedFlag) {
      this.props.text.map((element, index) => {
        if (index < 2 || index >= 4) {
          this.scene.remove(this.scene.getObjectByName('text'));
          if (element.name !== 'formula')
            this.loadText(this.scene, element, index);
          self.setState({ ...self.state, loadedFlag: true });
        }
      });
    }
  }

  mouseEventUnClick() {
    this.controls.enabled = true;
    if (this.draggable) {
      this.draggable = null;

      let distanceBetween;

      if (this?.objectsGroup?.getObjectByName('H2OBox'))
        distanceBetween = this?.objectsGroup
          .getObjectByName('H2OBox')
          .position.distanceTo(
            this.objectsGroup.getObjectByName('CO2Box').position
          );

      if (distanceBetween < 2 && distanceBetween) {
        this.objectsGroup.getObjectByName('CO2Box').visible = false;
        this.objectsGroup.getObjectByName('H2OBox').visible = false;

        this.scene.getObjectByName('glucozeGroup').position.set(-8, -5, 10);

        this.scene.getObjectByName('firstMolCarbon').visible = true;
        if (this.scene.getObjectByName('photosyntes'))
          this.scene.getObjectByName('photosyntes').visible = true;

        this.scene.getObjectByName('firstMolCarbon').scale.set(0.5, 0.5, 0.5);
        if (this.scene.getObjectByName('photosyntes')) {
          this.scene.getObjectByName('photosyntes').scale.set(0.5, 0.5, 0.5);
        }
      }
    }
  }

  mouseEventClick(event) {
    this.clickMouse.x = this.props?.previewWidth
      ? (event.clientX / this.props.previewWidth) * 2 - 1
      : (event.clientX / window.innerWidth) * 2 - 1;
    this.clickMouse.y = this.props?.previewHeight
      ? -(event.clientY / this.props.previewHeight) * 2 + 1
      : -(event.clientY / window.innerHeight) * 2 + 1;
    this.raycaster.setFromCamera(this.clickMouse, this.camera);
    const mouseIntersects = this.raycaster.intersectObjects(
      this.objectsGroup.children,
      false
    );

    if (mouseIntersects.length > 0) {
      this.controls.enabled = false;
      this.draggable = mouseIntersects[0];
    }
  }

  mouseEventMove(event) {
    if (this.vrButton?.vrButton) {
      this.vrButton.vrButton.disabled = false;
    }

    this.moveMouse.x = this.props.previewWidth
      ? (event.clientX / this.props.previewWidth) * 2 - 1
      : (event.clientX / window.innerWidth) * 2 - 1;
    this.moveMouse.y = this.props.previewHeight
      ? -(event.clientY / this.props.previewHeight) * 2 + 1
      : -(event.clientY / window.innerHeight) * 2 + 1;
  }

  dragObject() {
    if (this.draggable != null) {
      this.raycaster.setFromCamera(this.moveMouse, this.camera);
      const intersects = this.raycaster.intersectObjects(
        this.objectsGroup.children
      );

      if (intersects.length > 0) {
        for (let o of intersects) {
          this.draggable.object.position.x = o?.point?.x;
        }
      }
    }
  }
  resetButtonAction(event) {
    this.clickMouse.x = this.props?.previewWidth
      ? (event.clientX / this.props.previewWidth) * 2 - 1
      : (event.clientX / window.innerWidth) * 2 - 1;
    this.clickMouse.y = this.props?.previewHeight
      ? -(event.clientY / this.props.previewHeight) * 2 + 1
      : -(event.clientY / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(this.clickMouse, this.camera);
    const mouseIntersects = this.raycaster.intersectObject(
      this.scene.children.find((child) => child.name === 'resetButton'),
      false
    );

    if (mouseIntersects.length > 0) {
      this.objectsGroup.getObjectByName('CO2Box').position.set(-10, -13, 0);
      this.objectsGroup.getObjectByName('CO2Box').rotation.set(0, 0, 0);
      this.objectsGroup.getObjectByName('CO2Box').visible = true;
      this.objectsGroup.getObjectByName('H2OBox').position.set(10, -13, 0);
      this.objectsGroup.getObjectByName('H2OBox').rotation.set(0, 0, 0);
      this.objectsGroup.getObjectByName('H2OBox').visible = true;

      this.scene.getObjectByName('firstMolCarbon').visible = false;
      if (this.scene.getObjectByName('photosyntes'))
        this.scene.getObjectByName('photosyntes').visible = false;

      this.scene.getObjectByName('firstMolCarbon').scale.set(0, 0, 0);
      if (this.scene.getObjectByName('photosyntes')) {
        this.scene.getObjectByName('photosyntes').scale.set(0, 0, 0);
        this.scene.getObjectByName('photosyntes').position.y = 0;
      }

      this.props.text.map((element, index) => {
        if (index < 2 || index > 3) {
          this.scene.remove(this.scene.getObjectByName('text'));
          if (element.name !== 'formula')
            this.loadText(this.scene, element, index, false);
          // this.setState({ ...this.state, loadedFlag: true });
        }
      });
    }
  }

  componentDidMount() {
    const self = this;
    this.containerRef?.current?.appendChild(this.renderer.domElement);
    // if (!document.getElementById('vrButton')) {
    this.vrButton = new VRButton(this.renderer);
    // }

    if (this.props) roundedSquare(this.scene, this.props);
    createTopicName(this.props, this.scene);

    window.addEventListener('mousedown', this.mouseEventClick.bind(this));
    window.addEventListener('mouseup', this.mouseEventUnClick.bind(this));
    window.addEventListener('mousemove', this.mouseEventMove.bind(this));
    window.addEventListener('click', this.resetButtonAction.bind(this));
    self.renderer.xr.addEventListener(
      'sessionstart',
      this.initInstructionEvent.bind(this)
    );

    self.renderer.xr.addEventListener('sessionend', function () {
      if (self.sound?.isPlaying) self.sound.pause();
      self.scene.getObjectByName('resetButton').visible = false;

      self.camera.position.set(0, 0, 40);
      self.scene.getObjectByName('instructionPanel').visible = false;
      let adminPreviewContainer = document.getElementById('previewContainer');
      if (self.props.onsessionendAdmin && adminPreviewContainer) {
        adminPreviewContainer.appendChild(self.vrButton.vrButton);
      }
    });
    // this.vrButton = !this.props.isPreview && new VRButton(this.renderer);

    const setTextInScene = () => {
      this.props.text.map((element, index) => {
        if (index < 2 || index >= 4) {
          this.scene.remove(this.scene.getObjectByName('text'));
          this.loadText(self.scene, element, index);
        }
      });
    };

    setTextInScene();
    window.addEventListener('resize', this.resize());
  }

  componentWillUnmount() {
    self?.renderer?.xr?.removeEventListener(
      'sessionstart',
      this.initInstructionEvent
    );
    this.containerRef?.current?.removeChild(this.renderer.domElement);
    window.removeEventListener('resize', this.resize);
    if (!this.props.isPreview) {
      this.vrButton.hideEnterVR();
    }
    Object.keys(this).forEach((key) => {
      // Recursively call dispose() if possible.
      if (
        typeof this[key]?.dispose === 'function' &&
        this[key].type != 'Scene'
      ) {
        this[key].dispose();
      }
      // Remove any reference.
      this[key] = null;
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    this.lang = nextProps.lang;

    //Replacing text from scene after translate
    if (nextProps.lang !== this.props.lang) {
      this.props?.text?.map(() => {
        this.scene.remove(this.room.getObjectByName('text'));
      });

      return true;
    }

    //Setting coord for text in scene

    //Setting loading
    if (nextProps.type !== this.state.loadingType) {
      this.setState({
        ...this.state,
        loadingType: nextProps.type
      });
      return true;
    }

    if (this.state.progress !== nextState) {
      return true;
    }
  }
  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  render() {
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    this.dragObject();
    pagintationLerping(this);

    this.scene.children.forEach((element) => {
      if (element.name === 'text' || element.name == 'parrent')
        element.quaternion?.copy(this.camera.quaternion);
    });
    const dt = this.clock.getDelta();
    if (this.scene.getObjectByName('photosyntes')?.visible) {
      this.scene.getObjectByName('photosyntes').position.y += 0.2;
    }

    if (this.renderer.xr.isPresenting) {
      if (this.controllers) {
        const self = this;

        this.controllers.forEach((controller, index) => {
          self.handleController(controller, index);
        });

        if (this.elapsedTime === undefined) this.elapsedTime = 0;
        this.elapsedTime += dt;
        if (this.elapsedTime > 0.3) {
          this.updateGamepadState();
          this.elapsedTime = 0;
        }
      }
    }
    return (
      <div
        ref={this.containerRef}
        className={`${styles.sceneContainer} ${
          this.props.isPreview
            ? styles.containerSizePreview
            : styles.containerSizeFull
        }`}
      ></div>
    );
    // }
  }
}

export default ChemistryBounds;
