/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { AdminApi } from '../../../../../api/admin.api';
import Loader from '../../../../../common/loader';
import { useHistory } from 'react-router-dom';
const validRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export default function EditUserModal({
  showModal,
  setShowModal,
  setShouldUpdate,
  mode = 'add'
}) {
  //   const [showModal, setShowModal] = React.useState(false);
  const [role, setRole] = useState('standard');
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [nameError, setNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [rolesArray, setRolesArray] = useState([
    {
      txt: 'School',
      value: 'school'
    },
    {
      txt: 'Standard',
      value: 'standard'
    },
    {
      txt: 'Premium',
      value: 'premium'
    },
    {
      txt: 'Super Admin',
      value: 'superAdmin'
    }
  ]);

  const resetState = () => {
    setEmail('');
    setFirstname('');
    setLastname('');
    setPassword('');
    setPhone(undefined);
    setShowModal({ show: false, mode: 'add' });
  };

  useEffect(() => {
    if (showModal?.user?.email) {
      setEmail(showModal.user.email);
      setFirstname(showModal.user.firstname);
      setLastname(showModal.user.lastname);
      setRole(showModal.user.role);
      setPassword('');
      setPhone(showModal.user.phone);
    }

    if (showModal?.user?.role) {
      if (showModal.user.role === 'learner') {
        setRolesArray([
          {
            txt: 'School',
            value: 'school'
          },
          {
            txt: 'Learner',
            value: 'learner'
          },
          {
            txt: 'Standard',
            value: 'standard'
          },
          {
            txt: 'Premium',
            value: 'premium'
          },
          {
            txt: 'Super Admin',
            value: 'superAdmin'
          }
        ]);
      }
    }
  }, [showModal]);

  const onSubmit = async () => {
    let submited = true;
    if (!email) {
      setEmailError('Email is required.');
      submited = false;
    }
    if (!password && mode !== 'edit') {
      setPasswordError('Password is required.');
      submited = false;
    }
    if (!firstname) {
      setNameError('First name is required.');
      submited = false;
    }
    if (!lastname) {
      setLastNameError('Last name is required.');
      submited = false;
    }
    if (
      passwordError ||
      nameError ||
      lastNameError ||
      emailError ||
      !submited
    ) {
      return;
    }
    const data = {
      email,
      role,
      firstname,
      lastname,
      password,
      phone
    };
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const userToken = window.localStorage.getItem('userToken');
    setLoading(true);
    if (mode === 'edit') {
      try {
        data.userId = showModal.user.userId;
        const result = await AdminApi.editUser(userToken, data, history);
        setLoading(false);
        if (result) {
          resetState();
          setShouldUpdate(true);
        }
      } catch (error) {
        setLoading(false);
      }
    } else if (mode === 'add') {
      try {
        const result = await AdminApi.createUser(userToken, data, history);
        setLoading(false);
        if (result) {
          resetState();
          setShouldUpdate(true);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const firstNameValidation = (value) => {
    if (!value) {
      setNameError('First name is required.');
      return;
    }
    setNameError('');
  };
  const lastNameValidation = (value) => {
    if (!value) {
      setLastNameError('Last name is required.');
      return;
    }
    setLastNameError('');
  };

  const emailValidation = (value) => {
    if (!value) {
      setEmailError('Email is required.');
      return;
    }
    if (!value.match(validRegex)) {
      setEmailError('Invalid email address.');
      return;
    }

    setEmailError('');
  };

  const passwordValidation = (value) => {
    if (!value) {
      setPasswordError('Password is required.');
      return;
    }

    setPasswordError('');
  };

  return (
    <>
      {showModal.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {mode === 'add' ? 'Add User' : 'User Edit'}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <form action="#" method="POST">
                    <div className=" overflow-hidden">
                      <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6">
                            <label className="block text-sm font-medium text-gray-700">
                              Email
                            </label>
                            <input
                              type="email"
                              value={email}
                              disabled={mode === 'edit'}
                              onBlur={(event) =>
                                emailValidation(event.target.value)
                              }
                              onChange={(e) => setEmail(e.target.value)}
                              className={`mt-1 block  w-full py-2 px-3 border  ${
                                emailError
                                  ? 'border-red-600'
                                  : 'border-gray-300'
                              } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            />
                            {emailError && (
                              <span className={'text-red-600 text-sm'}>
                                {emailError}
                              </span>
                            )}
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                              First Name
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setFirstname(e.target.value)}
                              value={firstname}
                              onBlur={(event) =>
                                firstNameValidation(event.target.value)
                              }
                              className={`mt-1 block  w-full py-2 px-3 border ${
                                nameError ? 'border-red-600' : 'border-gray-300'
                              } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            />
                            {nameError && (
                              <span className={'text-red-600 text-sm'}>
                                {nameError}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                              Last Name
                            </label>
                            <input
                              type="text"
                              onBlur={(event) =>
                                lastNameValidation(event.target.value)
                              }
                              onChange={(e) => setLastname(e.target.value)}
                              value={lastname}
                              className={`mt-1 block  w-full py-2 px-3 border ${
                                lastNameError
                                  ? 'border-red-600'
                                  : 'border-gray-300'
                              } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            />
                            {lastNameError && (
                              <span className={'text-red-600 text-sm'}>
                                {lastNameError}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 lgmin:col-span-3 lgmax:col-span-6">
                            <label className="block text-sm font-medium text-gray-700">
                              Password
                            </label>
                            <input
                              type="password"
                              onChange={(e) => setPassword(e.target.value)}
                              onBlur={(event) =>
                                passwordValidation(event.target.value)
                              }
                              value={password}
                              disabled={mode === 'edit'}
                              className={`mt-1 block  w-full py-2 px-3 border ${
                                passwordError
                                  ? 'border-red-600'
                                  : 'border-gray-300'
                              }  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            />
                            {passwordError && (
                              <span className={'text-red-600 text-sm'}>
                                {passwordError}
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-6 lgmin:col-span-3">
                            <label
                              htmlFor="role"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Role
                            </label>
                            <select
                              onChange={(e) => {
                                setRole(e.target.value);
                              }}
                              disabled={showModal?.user?.role === 'learner'}
                              value={role}
                              className={
                                'mt-1 block  w-full py-2 h-10  overflow-scroll px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                              }
                            >
                              {rolesArray.map((el, index) => {
                                return (
                                  <option
                                    key={index + el.value}
                                    selected={el.value === role}
                                    className="w-16 h-40"
                                    value={el.value}
                                  >
                                    {el.txt}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="col-span-6 sm:col-span-6 lgmin:col-span-3">
                            <label
                              htmlFor="phone"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Phone
                            </label>
                            <input
                              onChange={(e) => setPhone(e.target.value)}
                              value={phone}
                              autoComplete="address-level2"
                              className={
                                'mt-1 block  w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      resetState();
                    }}
                  >
                    Cancel
                  </button>
                  {loading ? (
                    <div
                      className={
                        ' text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded flex justify-center items-center '
                      }
                    >
                      <Loader />
                    </div>
                  ) : (
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => onSubmit()}
                    >
                      {mode === 'add' ? 'Add' : 'Save'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
