/* eslint-disable no-unused-vars */
import icon from '../assets/icons/vr-cardboard-solid.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class VRButton {
  constructor(renderer, props) {
    this.renderer = renderer;
    this.vrButton;
    if ('xr' in navigator) {
      const button = document.createElement('button');
      const el = document.getElementById('contentScene');

      this.vrButton = button;

      button.style.display = 'block';

      navigator.xr.isSessionSupported('immersive-vr').then((supported) => {
        supported ? this.showEnterVR(button) : this.showWebXRNotFound(button);
      });

      document.body.appendChild(button);
      el?.appendChild(button);
    } else {
      const message = document.createElement('a');

      if (window.isSecureContext === false) {
        message.href = document.location.href.replace(/^http:/, 'https:');
        message.innerHTML = 'WEBXR NEEDS HTTPS';
      } else {
        message.href = 'https://immersiveweb.dev/';
        message.innerHTML = 'WEBXR NOT AVAILABLE';
      }

      message.style.left = '0px';
      message.style.width = '100%';
      message.style.textDecoration = 'none';

      this.stylizeElement(message, false);
      message.style.bottom = '0px';

      document.body.appendChild(message);
    }
  }

  hideEnterVR() {
    this.vrButton.style.display = 'none';
  }

  showEnterVR(button) {
    let currentSession = null;
    const self = this;
    const vrScene = document.getElementsByTagName('div');
    this.stylizeElement(button, true, 30, true);

    function onSessionStarted(session) {
      window.scrollTo(0, 0);
      session.addEventListener('end', onSessionEnded);
      vrScene[0].appendChild(button);

      self.renderer.xr.setSession(session);
      self.stylizeElement(button, false, 12, true);

      // button.style.position = 'fixed';
      currentSession = session;
      document.body.style.overflow = 'hidden';
    }
    const el = document.getElementById('contentScene');

    function onSessionEnded() {
      currentSession.removeEventListener('end', onSessionEnded);
      self.stylizeElement(button, true, 12, true);

      document.body.style.overflow = 'auto';
      currentSession = null;
      // button.style.position = 'absolute';
      vrScene[0].removeChild(button);
      el?.appendChild(button);
      document.body.style.overflow = 'auto';
    }

    button.style.textAlign = 'left';

    button.style.padding = '8px';
    button.style.cursor = 'pointer';
    button.style.fontSize = '12px';

    button.onmouseenter = function () {
      button.style.fontSize = '12px';
    };

    button.onmouseleave = function () {
      button.style.fontSize = '12px';
    };

    button.onclick = function () {
      if (currentSession === null) {
        var sessionInit = {
          optionalFeatures: ['local-floor', 'bounded-floor']
        };
        navigator.xr
          .requestSession('immersive-vr', sessionInit)
          .then(onSessionStarted);
      } else {
        currentSession.end();
      }
    };
  }

  disableButton(button) {
    button.style.cursor = 'auto';

    button.onmouseenter = null;
    button.onmouseleave = null;

    button.onclick = null;
  }

  showWebXRNotFound(button) {
    this.stylizeElement(button, false);
    let x800 = window.matchMedia('(max-width: 500px)');

    if (x800.matches) {
      button.style.display = 'none';
    }
    this.disableButton(button);

    button.style.display = '';
    button.style.width = '20%';

    button.style.right = '40%';
    button.style.bottom = '0px';
    button.style.border = '';
    button.style.fontSize = '13px';
    button.textContent = 'VR IS NOT SUPPORTED';
  }

  stylizeElement(element, active = true, fontSize = 13, ignorePadding = false) {
    let x1200 = window.matchMedia('(min-width: 1100px)');
    let x800 = window.matchMedia('(max-width: 500px)');

    const vrButton = document.getElementById('vrButton');
    if (!vrButton) element.style.position = 'absolute';
    if (x800.matches) {
      element.style.display = 'none';
    }
    element.disabled = true;
    element.style.right = '50px';
    element.style.marginLeft = '-50px';
    element.style.top = '100%';
    element.style.marginTop = '-70px';
    element.style.bottom = '18px';
    element.style.color = '#fff';
    element.style.font = `normal ${fontSize}px sans-serif`;
    element.style.textAlign = 'center';
    element.style.outline = 'none';
    element.style.zIndex = '99999';
    element.setAttribute('id', 'vrButton');
    element.innerHTML = `<img class='vrButton' src='${icon}' color='white' height="32" width="32">`;
  }
}

export { VRButton };
