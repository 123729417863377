/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { useHttp } from '../../hooks/useHttp';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import AuthContext from '../../context/authContext';
import Loader from '../../common/loader';
import { inputItems } from '../../assets/mockData/mockData';
import { useAppContext } from '../../context/AppProvider';
import { useHistory } from 'react-router-dom';
const validRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
let timeDelay;

const contactUs = () => {
  const { store } = useAppContext();
  const { selectedLanguage } = store;
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const history = useHistory();
  const auth = useContext(AuthContext);
  const { request, loading, apiUrl } = useHttp();
  const [open, setOpen] = useState(false);
  const [topic, setTopic] = useState(inputItems.Options[0][selectedLanguage]);
  const [error, setError] = useState({ type: '', value: null });
  const [nameError, setNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const inputId = ['firstname', 'lastname', 'email-', 'message'];
  const [form, setForm] =
    auth.userId === null
      ? useState({
          email: '',
          message: '',
          firstname: '',
          lastname: '',
          topic: inputItems.Options[0][selectedLanguage]
        })
      : useState({
          email: '',
          message: '',
          topic: '',
          firstname: '',
          lastname: inputItems.Options[0][selectedLanguage],
          userId: auth.userId
        });
  const update = (event) => {
    setTopic(event.target.value);
    setForm({ ...form, topic: event.target.value });
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  useEffect(() => {
    inputId.forEach((element) => {
      if (document.getElementById(`${element}`).value) {
        setForm({
          ...form,
          [document.getElementById(`${element}`).name]: document.getElementById(
            `${element}`
          ).value
        });
      }
    });
    if (error.type) {
      timeDelay = setTimeout(() => {
        setError({ type: '', value: null });
        clearTimeout(timeDelay);
      }, 4000);
    }
  }, [error]);
  const sentDataHandler = async () => {
    try {
      if (!checkEmptyObj(form) && validateEmail(form.email)) {
        await request(
          apiUrl + '/api/contact',
          'POST',
          { ...form },
          {},
          history
        );
        setError({
          type: 'Passed',
          value: 'Your request was received. We will contact you shortly!'
        });
        setForm({
          ...form,
          email: '',
          message: '',
          firstname: '',
          lastname: '',
          topic: inputItems.Options[0][selectedLanguage]
        });
      } else {
        if (validateEmail(form.email) === null) {
          setError({ type: 'Error', value: 'Enter valid email address' });
        } else {
          setError({ type: 'Error', value: 'Please enter required data' });
        }
      }
    } catch (err) {
      setError({ type: 'Error', value: err.message });
    }
    setOpen(false);
  };
  const checkEmptyObj = () => {
    let isEmpty = true;
    for (let i in form) {
      if (form[i]) {
        isEmpty = false;
      } else {
        return true;
      }
    }
    return isEmpty;
  };

  const firstNameValidation = (value) => {
    if (!value) {
      setNameError('First name is required.');
      return;
    }
    setNameError('');
  };
  const lastNameValidation = (value) => {
    if (!value) {
      setLastNameError('Last name is required.');
      return;
    }
    setLastNameError('');
  };

  const emailValidation = (value) => {
    if (!value) {
      setEmailError('Email is required.');
      return;
    }
    if (!value.match(validRegex)) {
      setEmailError('Invalid email address.');
      return;
    }

    setEmailError('');
  };

  const messageValidation = (value) => {
    if (!value) {
      setMessageError('Message is required.');
      return;
    }
    setMessageError('');
  };

  return (
    <>
      <div>
        <div
          className={` border top-24 transition ease-in-out smmax:w-3/4 xsmax:w-3/4  duration-100  ${
            error.value === null || open ? 'hidden' : ''
          } ${
            error.type === 'Passed'
              ? 'border-green-400 text-green-700 bg-green-100 '
              : 'border-red-400 text-red-700 bg-red-100 '
          } translate-x-10 right-20 absolute  flex justify-between px-4 smmin:w-96  py-3 rounded `}
          role="alert"
        >
          <div className="flex flex-col">
            <strong className="font-bold  ">
              {error.type === 'error' ? error.type : error.type}
            </strong>
            <span className="block sm:inline ">{error.value}</span>
          </div>
          <span className="justify-end self-end  flex  py-3">
            <svg
              onClick={
                (() => setOpen(true), () => setError({ type: '', value: null }))
              }
              className={`self-end fill-current h-6 w-6 ${
                error.type === 'Passed' ? 'text-green-500' : 'text-red-500 '
              } `}
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5"></div>
        </div>
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-center text-gray-900">
              {inputItems.poweredBy[selectedLanguage]}
            </h3>
            <p className="mt-1 text-sm  text-center text-gray-600">
              IDNO: 1020600047169
            </p>
            <p className="mt-1 text-sm  text-center text-gray-600">
              {inputItems.address[selectedLanguage]}
            </p>
            <p className="mt-1 text-sm  text-center text-gray-600">
              Email: broswebmw@gmail.com, +37368054262.
            </p>
          </div>
        </div>
        <div className=" min-h-full py-16">
          <div className="md:grid md:grid-cols-3 md:gap-6  mx-auto ">
            <div className="mt-8 md:mt-0 md:col-span-3 mx-auto sm:w-3/5 ">
              <div className="md:col-span-1 ">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-center text-gray-900">
                    {inputItems.Title[selectedLanguage]}
                  </h3>
                  <p className="mt-1 text-sm  text-center text-gray-600">
                    {inputItems.Subtitle[selectedLanguage]}
                  </p>
                </div>
              </div>
              <form autoComplete="off" className="w-full">
                <div className="shadow overflow-hidden sm:rounded-md  w-full">
                  <div className="px-4 py-5 bg-white sm:p-6 w-full">
                    <div className="grid grid-cols-6 gap-6 ">
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="firstname"
                          className=" flex text-sm font-medium text-gray-700"
                        >
                          {inputItems.Firstname[selectedLanguage]}
                          <p className="text-orange">*</p>
                        </label>
                        <input
                          disabled={loading}
                          type="text"
                          name="firstname"
                          autoComplete="off"
                          onChange={changeHandler}
                          onBlur={(event) =>
                            firstNameValidation(event.target.value)
                          }
                          id="firstname"
                          className={`mt-1 block w-full py-2 px-3 border ${
                            nameError ? 'border-red-600' : 'border-gray-300'
                          } bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        />
                        {nameError && (
                          <span className={'text-red-600 text-sm'}>
                            {nameError}
                          </span>
                        )}
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="lastname"
                          className="flex text-sm font-medium text-gray-700"
                        >
                          {inputItems.Lastname[selectedLanguage]}
                          <p className="text-orange">*</p>
                        </label>
                        <input
                          disabled={loading}
                          type="text"
                          name="lastname"
                          onChange={changeHandler}
                          id="lastname"
                          autoComplete="family-name"
                          onBlur={(event) =>
                            lastNameValidation(event.target.value)
                          }
                          className={`mt-1 block w-full py-2 px-3 border ${
                            lastNameError ? 'border-red-600' : 'border-gray-300'
                          } bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        />
                        {lastNameError && (
                          <span className={'text-red-600 text-sm'}>
                            {lastNameError}
                          </span>
                        )}
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="email"
                          className="flex text-sm font-medium text-gray-700"
                        >
                          {inputItems.Email[selectedLanguage]}
                          <p className="text-orange">*</p>
                        </label>
                        <input
                          disabled={loading}
                          type="email"
                          name="email"
                          onChange={changeHandler}
                          id="email-"
                          onBlur={(event) =>
                            emailValidation(event.target.value)
                          }
                          autoComplete="email"
                          className={`mt-1 block w-full py-2 px-3 border ${
                            emailError ? 'border-red-600' : 'border-gray-300'
                          } bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        />
                        {emailError && (
                          <span className={'text-red-600 text-sm'}>
                            {emailError}
                          </span>
                        )}
                      </div>

                      <div className="col-span-6 justify-between sm:col-span-6   flex">
                        <div className="w-full ">
                          <label
                            htmlFor="topic"
                            className="flex text-sm font-medium text-gray-700"
                          >
                            {inputItems.Topic[selectedLanguage]}
                            <p className="text-orange">*</p>
                          </label>
                          <Menu as="div" name="topic" className=" self-end">
                            <div className="flex relative ">
                              <select
                                disabled={loading}
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                onChange={update}
                              >
                                {inputItems.Options.map((element) => {
                                  return (
                                    <option
                                      key={element[selectedLanguage]}
                                      className="text-lg m-1 font-gothic text-slate-500 cursor-pointer hover:text-slate-600"
                                      value={element[selectedLanguage]}
                                    >
                                      {element[selectedLanguage]}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </Menu>
                          {/* <input
                              type="search"
                              name="topic"
                              onChange={changeHandler}
                              id="topic"
                              value={topic}
                              placeholder={inputItems.TopicPlaceholder[selectedLanguage]}
                              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" /> */}
                        </div>
                      </div>

                      <div className="col-span-6 transition-all ease-in-out sm:col-span-6 lg:col-span-6">
                        <label
                          htmlFor="message"
                          className="flex text-sm font-medium text-gray-700"
                        >
                          {inputItems.Message[selectedLanguage]}
                          <p className="text-orange">*</p>
                        </label>
                        <textarea
                          disabled={loading}
                          type="text"
                          onChange={changeHandler}
                          name="message"
                          id="message"
                          onBlur={(event) =>
                            messageValidation(event.target.value)
                          }
                          placeholder={
                            inputItems.MessagePlaceholder[selectedLanguage]
                          }
                          className={`mt-1 block w-full pb-20  pt-2 px-3 border ${
                            messageError ? 'border-red-600' : 'border-gray-300'
                          } bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        />
                        {messageError && (
                          <span className={'text-red-600 text-sm'}>
                            {messageError}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 border-3  text-right sm:px-6">
                    {loading ? (
                      <div
                        className="inline-flex justify-center py-2 px-4 text-sm font-medium
                      hover:bg-gray-900 focus:outline-none focus:ring-2
                      focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <Loader />
                      </div>
                    ) : (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          sentDataHandler();
                          scrollToTop();
                        }}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {inputItems.SendButton[selectedLanguage]}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default contactUs;
