import React, { useEffect } from 'react';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SketchfabIntegration = ({
  file,
  setFile,
  setUploadedModels,
  setModelName,
  setModelLink
}) => {
  // const navigate = useHistory();

  useEffect(() => {
    var el = document.querySelector('.skfb-widget');

    // eslint-disable-next-line no-undef
    new SketchfabImporter(el, {
      onModelSelected: async function (result) {
        setModelLink(result.model.uri);
        let linkToModelGLb = result.download.glb.url;
        const uniqueName = `${result.model.name.replace(/ /g, '_')}.glb`;

        setModelName(uniqueName);
        setUploadedModels([uniqueName]);
        setFile(linkToModelGLb);
      }
    });
  }, []);

  return (
    <div className="border-dashed border-2 relative border-gray-300 rounded-lg p-4 text-center cursor-pointer">
      {file && <div className="absolute w-[98%] h-96 bg-gray-100 opacity-50" />}
      <div className="skfb-widget h-96"></div>
    </div>
  );
};

export default SketchfabIntegration;
