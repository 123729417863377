import isPositiveInteger from './isPositiveinteger';

const lerpingDownModel = (self) => {
  const curObjects = self.modelsForSlider.filter((element) =>
    isPositiveInteger(element.name)
  );
  const lerpingStep = self.props.name.stepForLerp;
  const lerpingDistance = self.props.name.lerpDistance
    ? self.props.name.lerpDistance
    : 40;

  if (Math.floor(self.lerpingPosition) === lerpingDistance) {
    self.resetedlerpingDown = false;
    self.resetedlerpingUp = false;
    self.lerpingPosition = 0;
    self.blockerForLerp++;
  } else if (self.resetedlerpingDown) {
    curObjects.map((curObject) => {
      if (curObject.position.y > 0.8 || curObject.position.y < 0.4) {
        curObject.visible = false;
      } else {
        curObject.visible = true;
      }
      lerpingStep
        ? (curObject.position.y -= lerpingStep)
        : (curObject.position.y -= 0.01);
    });
    self.lerpingPosition++;
  }
};
export default lerpingDownModel;
