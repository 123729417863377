import React, { useState, useEffect } from 'react';
import styles from '../styles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Wrapper = ({ children }) => {
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const vrButtonFullscreen = document.getElementById('vrButton');

  useEffect(() => {
    if (fullscreenMode && vrButtonFullscreen) {
      vrButtonFullscreen.style.position = 'fixed';
      vrButtonFullscreen.style.marginTop = '-54px';
    } else {
      if (vrButtonFullscreen) {
        vrButtonFullscreen.style.marginTop = '-70px';
        vrButtonFullscreen.style.position = 'absolute';
      }
    }
  });
  document.addEventListener('keydown', function (event) {
    if (event.key === 'Escape' && fullscreenMode) {
      setFullscreenMode(!fullscreenMode);
    }
  });
  return (
    <div
      className={`${styles.wrapperContainer} ${
        fullscreenMode ? styles.wrapperSizeFull : styles.wrapperSizePreview
      }`}
    >
      {fullscreenMode && (
        <button
          onClick={() => setFullscreenMode(!fullscreenMode)}
          className={
            'px-2 absolute py-2 rounded-lg text-center  bottom-2 right-5 text-white '
          }
        >
          {' '}
          <FontAwesomeIcon
            icon={'compress'}
            className={'self-end  fill-current h-6 w-6 '}
            size="lg"
          />
        </button>
      )}
      {children}

      {!fullscreenMode && (
        <div className={styles.fullScreenButtonContainer}>
          <button
            onClick={() => setFullscreenMode(!fullscreenMode)}
            className={
              ' rounded-md justify-center items-center pt-7 pr-1 -mt-4'
            }
          >
            <FontAwesomeIcon
              id="expandIcon"
              className={`${styles.iconFullscreen} -mb-1`}
              icon={'expand'}
              size="lg"
            />
          </button>
        </div>
      )}
    </div>
  );
};
export default Wrapper;
