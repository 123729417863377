/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import MoreOptions from '../../../common/moreOptions';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';

const TableRow = ({
  topicList,
  categoryName,
  deleteConfirmation,
  setDeleteConfirmation,
  setShouldUpdate,
  categoryId
}) => {
  const history = useHistory();

  return (
    <div
      onClick={() =>
        history.push({
          pathname: `/admin/content/${categoryId}`,
          state: {
            topicList: topicList,
            categoryName: categoryName
            // deleteConfirmation,
            // setDeleteConfirmation,
            // setShouldUpdate,
          },
          deleteConfirmation,
          setDeleteConfirmation,
          setShouldUpdate
        })
      }
      className={
        'w-full cursor-pointer flex place-items-center justify-between pl-3 pr-11 border-t hover:bg-emerald-500'
      }
    >
      <div className={'flex justify-left text-left w-3.5'}>{categoryName}</div>
      <div className={'flex justify-left text-center w-3.5'}>
        {topicList.length}
      </div>
    </div>
  );
};

export default TableRow;
