import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import AuthManager from '../../modules/authorizationMod/authManager';
import ContentManager from '../../modules/screens/ContentManager.js';
const NavigationRoot = () => {
  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route path="/">
            <ContentManager />
          </Route>
          <Route path={'/auth'}>
            <AuthManager />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default NavigationRoot;
