/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import NavigationRoot from './app/navigation/navigationRoot.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from './hooks/authHook';
import AuthContext from './context/authContext';
import {
  faVrCardboard,
  faCheckSquare,
  faRocket,
  faChartLine,
  faChalkboardTeacher,
  faBookReader,
  faBox,
  faEllipsisV,
  faWindowMaximize,
  faExpand,
  faCompress,
  faDownload,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import AllProviders from './context/index';
import ErrorBoundary from './common/errorBoudary/index';
library.add(
  fas,
  faVrCardboard,
  faCheckSquare,
  faRocket,
  faChartLine,
  faChalkboardTeacher,
  faBookReader,
  faBox,
  faEllipsisV,
  faWindowMaximize,
  faExpand,
  faCompress,
  faDownload,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faTrash
);

function App() {
  return (
    <Fragment>
      <Router>
        <AllProviders>
          <div>
            <NavigationRoot />
          </div>
        </AllProviders>
      </Router>
    </Fragment>
  );
}

export default App;
