import React from 'react';

const Toaster = ({ error }) => {
  return (
    <div
      className={`border transition ease-in-out smmax:w-3/4 xsmax:w-3/4 duration-100 ${
        error.type === 'Passed'
          ? 'border-green-400 text-green-700 bg-green-100'
          : 'border-red-400 text-red-700 bg-red-100'
      } translate-x-16 -mt-16 right-20 absolute flex justify-between px-6 py-3 rounded`}
      role="alert"
    >
      <div className="flex flex-col smmin:pr-32">
        <strong className="font-bold">
          {error.type === 'error' ? error.type : error.type}
        </strong>
        <span className="block sm:inline">{error.value}</span>
      </div>
      <span className="justify-end flex pl-3 py-3">
        <svg
          className={`self-end fill-current h-6 w-6 ${
            error.type === 'Passed' ? 'text-green-500' : 'text-red-500'
          }`}
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Close</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
      </span>
    </div>
  );
};

export default Toaster;
