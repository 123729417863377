/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';

const instructionImg = require('./../../assets/img/controllers_instruction.jpg');

class ControllerInstruction {
  constructor(props) {
    this.props = props;
    const instructionTexture = new THREE.TextureLoader().load(instructionImg);
    const MainPlaneGeometry = new THREE.PlaneGeometry(5, 4.5);
    const MainPlaneMaterial = new THREE.MeshBasicMaterial({
      color: 0x000000,
      side: THREE.DoubleSide
    });
    const planeGeometry = new THREE.PlaneGeometry(2.5, 2);
    const planeMaterial = new THREE.MeshBasicMaterial({
      map: instructionTexture,
      side: THREE.DoubleSide
    });
    this.planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
    this.planeMesh.name = 'instructionPanel';
    this.planeMesh.visible = false;
    this.planeMesh.scale.x = props.scaleX ? props.scaleX : 0.5;
    this.planeMesh.scale.y = props.scaleY ? props.scaleY : 0.4;
    this.planeMesh.scale.z = props.scaleZ ? props.scaleZ : 0.4;
    this.MainPlane = new THREE.Mesh(MainPlaneGeometry, MainPlaneMaterial);
    this.MainPlane.name = 'MainnstructionPanel';
    this.MainPlane.scale.x = 0.55;
    this.MainPlane.scale.y = 0.5;
    this.MainPlane.scale.z = 0.45;
    this.MainPlane.position.z = -0.01;
    this.planeMesh.add(this.MainPlane);
    // this.planeMesh.add(this.buttonMesh);
    this.rotateYCoefficient = props.rotateY ? props.rotateY : -0.49;
    this.planeMesh.rotateY(Math.PI * this.rotateYCoefficient);
    // if()
    // const buttonGeometry = new THREE.BoxGeometry(0.3, 0.2, 0.03);
    // const buttonMaterial = new THREE.MeshBasicMaterial({ color: 0x4cd137 });
    // this.buttonMesh = new THREE.Mesh(buttonGeometry, buttonMaterial);
    // this.buttonMesh.name = 'instructionButton';
    // this.buttonMesh.position.set(1.09, -0.9, 0);

    // this.loadText(this.buttonMesh, 'DONE');
  }

  // async loadText(obj, text) {
  //   const loader = new FontLoader();
  //   const self = this;
  //   await loader.load(
  //     'https://s3.eu-central-1.amazonaws.com/lectio.app/helvetiker_regular.typeface.json',
  //     function (font) {
  //       var textGeometry = new TextGeometry(text, {
  //         font: font,
  //         size: 0.07,
  //         height: 0.03
  //       });
  //       let textMaterial;

  //       var mesh = new THREE.Mesh(textGeometry, textMaterial);
  //       obj.add(mesh);
  //       mesh.position.set(-0.13, -0.03, -0.01);
  //     }

  //     // onProgress callback

  //     // onError callback
  //   );
  // }

  bindInstructionToScene(scene) {
    // camera.add(this.planeMesh);
    scene.add(this.planeMesh);
    this.planeMesh.position.set(this.props.x, this.props.y, this.props.z);
  }
}

export default ControllerInstruction;
