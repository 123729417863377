/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { VRButton } from '../../../common/VRButton';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import styles from './solarSystem.module.css';
import { solarData } from '../../../assets/mockData/mockData';
import { planetsName } from '../../../assets/mockData/mockData';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import {
  proportionalView,
  perspectiveView
} from '../../../assets/mockData/mockData';
import ControllerInstruction from '../../../common/controllerInstruction/index';
import { textToSpeech } from '../../../common/TabletFunctionality/textToSpeech';
import { Tablet } from '../../../common/TabletFunctionality/index';

const mercurySpeed = 0.005;
const venusSpeed = mercurySpeed / 1.36;
const earthSpeed = venusSpeed / 1.17;
const marsSpeed = earthSpeed / 1.23;
const jupiterSpeed = marsSpeed / 1.84;
const saturnSpeed = jupiterSpeed / 1.34;
const uranusSpeed = saturnSpeed / 1.42;
const neptuneSpeed = uranusSpeed / 1.25;

// const instructionImg = require('./../../../assets/img/controllers_instruction.png')
// import { Link } from 'react-router-dom';

// import TextSprite from '@seregpie/three.text-sprite';
// import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js';
class SolarSystem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuClose: true
    };
    this.clock = new THREE.Clock();
    this.loadedMusicBar = true;
    this.containerRef = React.createRef();
    this.scene;
    this.cubeTextureLoader = new THREE.CubeTextureLoader();
    this.mouse = new THREE.Vector2();

    this.backgroundImage = require('./../../../assets/textures/stars.png');

    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      1000000
    );
    // this.cameraGroup = new THREE.Group();
    // this.cameraGroup.position.set(0, 0, 100)
    // this.cameraGroup.position.set(-50, 20, 100);
    // this.cameraGroup.rotateY(5);

    //right part spot light

    this.ambient = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 0.3);
    this.light = new THREE.DirectionalLight(0xffffff, 1);
    this.light.castShadow = true;

    this.light.shadow.mapSize.width = 1000; // default
    this.light.shadow.mapSize.height = 1000; // default
    this.light.shadow.camera.near = 0.5; // default
    this.light.shadow.camera.far = 3200;

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.shadowMap.enabled = true;

    this.raycaster = new THREE.Raycaster();
    this.workingMatrix = new THREE.Matrix4();
    this.workingVector = new THREE.Vector3();
    this.origin = new THREE.Vector3();

    this.scene = this.initSolarSystemScene();

    this.dolly = new THREE.Object3D();
    this.dolly.position.z = 5;
    this.dolly.add(this.camera);
    this.scene.add(this.dolly);
    const xInstructionCoord = 1.5;
    const yInstructionCoord = 41;
    const zInstructionCoord = 99;

    this.ControllerInstruction = new ControllerInstruction({
      x: xInstructionCoord,
      y: yInstructionCoord,
      z: zInstructionCoord
    });
    this.ControllerInstruction.bindInstructionToScene(this.scene);

    this.dummyCam = new THREE.Object3D();
    this.camera.add(this.dummyCam);

    this.setupVr(this.scene);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();
    this.renderer.setAnimationLoop(this.render.bind(this));
    window.addEventListener('resize', this.resize.bind(this));
  }

  setupVr(scene) {
    this.renderer.xr.enabled = true;

    // const self = this;

    this.controllers = this.buildControllers(scene);
    const self = this;
    function onConnected(e) {
      this.gamepad = e.data.gamepad;
      self.updateControllers({
        right: { trigger: true, squeeze: true },
        left: { trigger: true, squeeze: true }
      });
    }

    const controllers = [
      this.renderer.xr.getController(0),
      this.renderer.xr.getController(1)
    ];
    controllers.map((element) => {
      element.addEventListener('connected', onConnected);
    });
  }

  updateControllers(info) {
    const self = this;

    function onSelectStart() {
      this.userData.selectPressed = true;
      if (this.userData.selected && !this.userData.attachedObject) {
        if (
          this.userData.selected.name == 'tabletSoundOn' &&
          self.props.description[self.props.lang] &&
          self.loadedMusicBar
        ) {
          textToSpeech(self);
          return 0;
        } else if (this.userData.selected.name == 'tabletSoundOff') {
          if (self.sound.isPlaying) {
            self.sound.pause();
            self.loadedMusicBar = true;
          }
          return 0;
        }
      }
    }

    function onSelectEnd() {
      this.userData.selectPressed = false;

      this.children[0].scale.z = 0;
      if (this.userData.attachedObject !== undefined) {
        this.remove(this.userData.attachedObject);

        this.userData.attachedObject = undefined;
        this.userData.selected = undefined;
        this.children[1].visible = false;
        this.children[0].scale.z = 10;
      }
    }

    function onSqueezeStart() {
      this.userData.squeezePressed = true;
    }

    function onSqueezeEnd() {
      this.userData.squeezePressed = false;
    }

    function onDisconnected() {
      const index = this.userData.index;

      if (self.controllers) {
        const obj = index == 0 ? self.controllers.right : self.controllers.left;

        if (obj) {
          if (obj.controller) {
            const controller = obj.controller;
            while (controller.children.length > 0)
              controller.remove(controller.children[0]);
            self.scene.remove(controller);
          }
          if (obj.grip) self.scene.remove(obj.grip);
        }
      }
    }

    if (info.right !== undefined) {
      const right = this.renderer.xr.getController(0);

      let trigger = false,
        squeeze = false;

      Object.keys(info.right).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
        if (key.indexOf('squeeze') != -1) squeeze = true;
      });

      if (trigger) {
        right.addEventListener('selectstart', onSelectStart);
        right.addEventListener('selectend', onSelectEnd);
      }

      if (squeeze) {
        right.addEventListener('squeezestart', onSqueezeStart);
        right.addEventListener('squeezeend', onSqueezeEnd);
      }

      right.addEventListener('disconnected', onDisconnected);
    }

    if (info.left !== undefined) {
      const left = this.renderer.xr.getController(1);

      let trigger = false,
        squeeze = false;

      Object.keys(info.left).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
        if (key.indexOf('squeeze') != -1) squeeze = true;
      });

      if (trigger) {
        left.addEventListener('selectstart', onSelectStart);
        left.addEventListener('selectend', onSelectEnd);
      }

      if (squeeze) {
        left.addEventListener('squeezestart', onSqueezeStart);
        left.addEventListener('squeezeend', onSqueezeEnd);
      }

      left.addEventListener('disconnected', onDisconnected);
    }
  }

  buildControllers() {
    const controllerModelFactory = new XRControllerModelFactory();

    const geometrySphere = new THREE.IcosahedronBufferGeometry(this.radius, 2);

    const sphere = new THREE.Mesh(
      geometrySphere,
      new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.BackSide })
    );
    sphere.name = 'sphereIndicator';
    sphere.scale.set(0.01, 0.01, 0.01);

    const geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, -1)
    ]);

    const line = new THREE.Line(geometry);
    line.name = 'line';
    line.scale.z = 2;
    sphere.visible = false;
    const controllers = [];

    for (let i = 0; i <= 1; i++) {
      const controller = this.renderer.xr.getController(i);
      controller.add(line.clone());
      controller.add(sphere.clone());
      controller.userData.selectPressed = false;
      // scene.add( controller );
      this.dolly.add(controller);
      // this.cameraGroup.add(controller);

      controllers.push(controller);

      const grip = this.renderer.xr.getControllerGrip(i);
      grip.add(controllerModelFactory.createControllerModel(grip));

      this.workingMatrix.identity().extractRotation(controller.matrixWorld);

      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);

      controller.children[0].scale.z = 10;
      // scene.add( grip );
      if (i == 1) {
        const lightForTablet = new THREE.DirectionalLight(0xffffff);
        controller.add(lightForTablet);
        this.dolly.add(grip);
      }
      // this.cameraGroup.add(grip);
    }
    Tablet(this, controllers);

    return controllers;
  }

  handleController(controller) {
    const speedGamepad = 5;
    const axes = controller.gamepad?.axes;

    this.workingMatrix.identity().extractRotation(controller.matrixWorld);
    this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    this.raycaster.ray.direction.set(0, 0, -1).applyMatrix4(this.workingMatrix);
    let intersectsModel;
    let iterationArray = [];
    if (this.tablet?.children.length > 0) {
      iterationArray = [...this.tablet.children];
    }
    intersectsModel = this.raycaster.intersectObjects(iterationArray, false);
    if (!controller.userData.attachedObject) {
      if (
        intersectsModel.length > 0 &&
        !intersectsModel[0].object.text &&
        intersectsModel[0].object.visible
      ) {
        controller.children[1].visible = true;
        controller.children[1].position.x = controller.children[0].position.x;
        controller.children[1].position.z = -intersectsModel[0].distance + 0.02;
        controller.children[0].scale.z = intersectsModel[0].distance;
        this.lineLength = intersectsModel[0].distance;
        controller.userData.selected = intersectsModel[0].object;
      } else {
        controller.children[0].scale.z = 10;
        controller.children[1].visible = false;
        controller.userData.selected = undefined;
      }
    }

    if (controller.userData.selectPressed) {
      if (intersectsModel.length > 0 && !intersectsModel[0].object.text) {
        controller.userData.selected = intersectsModel[0].object;
      } else {
        controller.children[0].scale.z = 10;
        controller.children[1].visible = false;
      }
      if (controller.userData.attachedObject) {
        controller.children[1].visible = true;
        controller.children[0].scale.z = this.lineLength;
      }
    }

    let a = 0;
    let b = 0;

    if (axes?.length) {
      if (axes[2] != 0) {
        a += axes[2];
      }
      if (axes[3] != 0) {
        b += axes[3];
      }
    }

    const quaternion = this.dolly.quaternion.clone();

    if (a !== 0 || b !== 0) {
      this.dolly.quaternion.copy(this.camera?.quaternion);

      this.dolly.translateZ(b * speedGamepad);
      this.dolly.translateX(a * speedGamepad);

      this.dolly.quaternion.copy(quaternion);
    }

    this.workingMatrix.identity().extractRotation(controller.matrixWorld);
    this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    this.raycaster.ray.direction.set(0, 0, -1).applyMatrix4(this.workingMatrix);
  }

  initSolarSystemScene(
    distance = perspectiveView,
    sunSizeMultiplier = 1,
    planetDivider = 1
  ) {
    const solarSystemScene = this.createScene();

    this.orbitLabels = [];
    this.camera.position.set(0, 100, 100);
    // solarSystemScene.add(this.ambient);

    //this parents needs to imitate planet rotate around their orbit
    this.mercuryParent = new THREE.Object3D();
    this.mercuryParent.name = 'mercuryParent';
    this.mercuryParent.position.set(0, 0, 0);
    solarSystemScene.add(this.mercuryParent);

    this.venusParent = new THREE.Object3D();
    this.venusParent.name = 'venusParent';
    this.venusParent.position.set(0, 0, 0);
    solarSystemScene.add(this.venusParent);

    this.earthParent = new THREE.Object3D();
    this.earthParent.name = 'earthParent';
    this.earthParent.position.set(0, 0, 0);
    solarSystemScene.add(this.earthParent);

    this.marsParent = new THREE.Object3D();
    this.marsParent.name = 'marsParent';
    this.marsParent.position.set(0, 0, 0);
    solarSystemScene.add(this.marsParent);

    this.jupiterParent = new THREE.Object3D();
    this.jupiterParent.name = 'jupiterParent';
    this.jupiterParent.position.set(0, 0, 0);
    solarSystemScene.add(this.jupiterParent);

    this.saturnParent = new THREE.Object3D();
    this.saturnParent.name = 'saturnParent';
    this.saturnParent.position.set(0, 0, 0);
    solarSystemScene.add(this.saturnParent);

    this.uranusParent = new THREE.Object3D();
    this.uranusParent.name = 'uranusParent';
    this.uranusParent.position.set(0, 0, 0);
    solarSystemScene.add(this.uranusParent);

    this.neptuneParent = new THREE.Object3D();
    this.neptuneParent.name = 'neptuneParent';
    this.neptuneParent.position.set(0, 0, 0);
    solarSystemScene.add(this.neptuneParent);

    // imitate sun light
    const sunPointLight = new THREE.PointLight(0xffffff, 1, 1000, 2.2);
    sunPointLight.name = 'sunLight';
    sunPointLight.position.set(0, 0, 0);
    solarSystemScene.add(sunPointLight);

    //SUN
    const sunImage = require('./../../../assets/textures/sun.jpg');
    this.Sun = this.createSphereObject(
      10 * sunSizeMultiplier,
      50,
      50,
      sunImage,
      true
    );
    this.Sun.name = 'Sun';
    this.Sun.position.set(0, 0, 0);
    solarSystemScene.add(this.Sun);

    //MERCURY
    const mercuryImage = require('./../../../assets/textures/mercury.png');
    this.Mercury = this.createSphereObject(
      1.5 / planetDivider,
      13,
      13,
      mercuryImage
    );
    this.Mercury.name = 'Mercury';
    // this.mercuryOrbitRing = this.createRingObject(distance.mercury, distance.mercury + 0.5, 60, null, false, 0xffffff);
    this.mercuryOrbitRing = this.createCircleObject(distance.mercury);

    this.mercuryOrbitRing.rotation.x = Math.PI / 2;
    this.mercuryLabel = this.createLinkLabel(
      distance.mercury,
      50,
      planetsName.mercury[this.props.lang]
    );
    this.orbitLabels.push(this.mercuryLabel);
    solarSystemScene.add(this.mercuryLabel);
    solarSystemScene.add(this.mercuryOrbitRing);
    this.Mercury.position.set(0, 0, distance.mercury);
    this.mercuryParent.add(this.Mercury);

    //VENUS
    const venusImage = require('./../../../assets/textures/venus.png');

    this.Venus = this.createSphereObject(
      2.5 / planetDivider,
      13,
      13,
      venusImage
    );
    this.Venus.name = 'Venus';
    this.venusOrbitRing = this.createCircleObject(distance.venus);
    this.venusOrbitRing.rotation.x = Math.PI / 2;
    this.venusLabel = this.createLinkLabel(
      distance.venus,
      70,
      planetsName.venus[this.props.lang]
    );
    this.orbitLabels.push(this.venusLabel);
    solarSystemScene.add(this.venusLabel);
    solarSystemScene.add(this.venusOrbitRing);
    this.Venus.position.set(0, 0, distance.venus);
    this.venusParent.add(this.Venus);

    //EARTH
    const earthImage = require('./../../../assets/textures/earth.png');
    this.Earth = this.createSphereObject(
      3.5 / planetDivider,
      13,
      13,
      earthImage
    );
    this.Earth.name = 'Earth';
    this.earthOrbitRing = this.createCircleObject(distance.earth);
    this.earthOrbitRing.rotation.x = Math.PI / 2;
    this.earthLabel = this.createLinkLabel(
      distance.earth,
      90,
      planetsName.earth[this.props.lang]
    );
    this.orbitLabels.push(this.earthLabel);
    solarSystemScene.add(this.earthLabel);
    solarSystemScene.add(this.earthOrbitRing);
    this.Earth.position.set(0, 0, distance.earth);
    this.earthParent.add(this.Earth);

    //MARS
    const marsImage = require('./../../../assets/textures/mars.png');
    this.Mars = this.createSphereObject(3.3 / planetDivider, 13, 13, marsImage);
    this.Mars.name = 'Mars';
    this.marsOrbitRing = this.createCircleObject(distance.mars);
    this.marsOrbitRing.rotation.x = Math.PI / 2;
    this.marsLabel = this.createLinkLabel(
      distance.mars,
      110,
      planetsName.mars[this.props.lang]
    );
    this.orbitLabels.push(this.marsLabel);
    solarSystemScene.add(this.marsLabel);
    solarSystemScene.add(this.marsOrbitRing);
    this.Mars.position.set(0, 0, distance.mars);
    this.marsParent.add(this.Mars);

    //JUPITER
    const jupiterImage = require('./../../../assets/textures/jupiter.png');

    this.Jupiter = this.createSphereObject(
      5.5 / planetDivider,
      13,
      13,
      jupiterImage
    );

    this.Jupiter.name = 'Jupiter';
    this.jupiterOrbitRing = this.createCircleObject(distance.jupiter);
    this.jupiterOrbitRing.rotation.x = Math.PI / 2;
    this.jupiterLabel = this.createLinkLabel(
      distance.jupiter,
      130,
      planetsName.jupiter[this.props.lang]
    );
    this.orbitLabels.push(this.jupiterLabel);
    solarSystemScene.add(this.jupiterLabel);
    solarSystemScene.add(this.jupiterOrbitRing);
    this.Jupiter.position.set(0, 0, distance.jupiter);
    this.jupiterParent.add(this.Jupiter);

    //SATURN
    const saturnImage = require('./../../../assets/textures/saturn.png');
    const saturnRingImage = require('./../../../assets/textures/saturn_ring.png');

    this.Saturn = this.createSphereObject(
      5.5 / planetDivider,
      30,
      30,
      saturnImage
    );
    this.Saturn.name = 'Saturn';
    this.saturnOrbitRing = this.createCircleObject(distance.saturn);
    this.saturnOrbitRing.rotation.x = Math.PI / 2;
    solarSystemScene.add(this.saturnOrbitRing);
    this.SaturnRing = this.createRingObject(8, 13, 13, saturnRingImage);
    this.SaturnRing.name = 'SaturnRing';
    this.SaturnRing.rotation.x = Math.PI / 2;
    this.saturnLabel = this.createLinkLabel(
      distance.saturn,
      150,
      planetsName.saturn[this.props.lang]
    );
    this.orbitLabels.push(this.saturnLabel);
    solarSystemScene.add(this.saturnLabel);
    this.Saturn.add(this.SaturnRing);
    this.Saturn.position.set(0, 0, distance.saturn);
    this.saturnParent.add(this.Saturn);

    //URANUS
    const uranusImage = require('./../../../assets/textures/uranus.png');

    const uranusRingImage = require('./../../../assets/textures/uranus_ring.png');

    this.Uranus = this.createSphereObject(
      3.5 / planetDivider,
      13,
      13,
      uranusImage
    );
    this.Uranus.name = 'Uranus';
    this.uranusOrbitRing = this.createCircleObject(distance.uranus);
    this.uranusOrbitRing.rotation.x = Math.PI / 2;
    solarSystemScene.add(this.uranusOrbitRing);
    this.UranusRing = this.createRingObject(8, 12, 60, uranusRingImage);
    this.UranusRing.name = 'UranusRing';
    this.UranusRing.rotation.x = Math.PI / 2;
    this.uranusLabel = this.createLinkLabel(
      distance.uranus,
      170,
      planetsName.uranus[this.props.lang]
    );
    this.orbitLabels.push(this.uranusLabel);
    solarSystemScene.add(this.uranusLabel);
    this.Uranus.add(this.UranusRing);
    this.Uranus.position.set(0, 0, distance.uranus);
    this.uranusParent.add(this.Uranus);

    //NEPTUNE
    const neptuneImage = require('./../../../assets/textures/neptune.png');

    this.Neptune = this.createSphereObject(
      3.6 / planetDivider,
      13,
      13,
      neptuneImage
    );
    this.Neptune.name = 'Neptune';
    this.neptuneOrbitRing = this.createCircleObject(distance.neptune);
    this.neptuneOrbitRing.rotation.x = Math.PI / 2;
    this.neptuneLabel = this.createLinkLabel(
      distance.neptune,
      190,
      planetsName.neptune[this.props.lang]
    );
    this.orbitLabels.push(this.neptuneLabel);
    solarSystemScene.add(this.neptuneLabel);
    solarSystemScene.add(this.neptuneOrbitRing);
    this.Neptune.position.set(0, 0, distance.neptune);
    this.neptuneParent.add(this.Neptune);

    return solarSystemScene;
  }

  initMercuryScene() {
    const mercuryScene = this.createScene();

    this.camera.position.set(0, 0, 100);

    const mercuryImage = require('./../../../assets/textures/mercury.png');
    this.Mercury = this.createSphereObject(13, 60, 60, mercuryImage);
    mercuryScene.add(this.Mercury);

    const sunPointLight = new THREE.PointLight(0xffffff, 1, 500);
    sunPointLight.name = 'sunLight';
    sunPointLight.position.set(0, 0, -80);
    const sunPointLight2 = new THREE.PointLight(0xffffff, 1, 500);
    sunPointLight2.name = 'sunLight2';
    sunPointLight2.position.set(50, 0, -50);
    const sunPointLight3 = new THREE.PointLight(0xffffff, 1, 500);
    sunPointLight3.name = 'sunLight3';
    sunPointLight3.position.set(-50, 0, -50);
    mercuryScene.add(sunPointLight);
    mercuryScene.add(sunPointLight2);
    mercuryScene.add(sunPointLight3);

    const sunImage = require('./../../../assets/textures/sun.jpg');
    this.mercurySun = this.createSphereObject(300, 60, 60, sunImage, true);
    mercuryScene.add(this.mercurySun);

    this.mercurySun.position.set(0, 0, -700);

    return mercuryScene;
  }

  initVenusScene() {
    const venusScene = this.createScene();

    this.camera.position.set(0, 0, 70);

    const venusImage = require('./../../../assets/textures/venus.png');
    this.Venus = this.createSphereObject(13, 60, 60, venusImage);
    this.Venus.position.set(0, 0, 0);
    venusScene.add(this.Venus);

    const sunPointLight = new THREE.PointLight(0xffffff, 1, 500);
    sunPointLight.name = 'sunLight';
    sunPointLight.position.set(0, 0, -100);
    venusScene.add(sunPointLight);

    // const sunImage = require('./../../../assets/textures/sun.jpg')
    // this.venusSun = this.createSphereObject(300, 60, 60, sunImage, true);
    this.Sun.position.set(0, 0, -900);
    venusScene.add(this.Sun);

    return venusScene;
  }

  initEarthScene() {
    const earthScene = this.createScene();
    // this.setupVr(earthScene);
    this.camera.position.set(0, 0, 100);
    const geometry = new THREE.IcosahedronBufferGeometry(21, 3);
    this.highlight = new THREE.Mesh(
      geometry,
      new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.BackSide })
    );
    this.highlight.scale.set(1.2, 1.2, 1.2);
    this.highlight.visible = false;
    earthScene.add(this.highlight);

    this.earthMoonParent = new THREE.Object3D();
    this.earthMoonParent.position.set(0, 0, 0);

    const earthImage = require('./../../../assets/textures/earth.png');
    this.Earth = this.createSphereObject(5, 60, 60, earthImage);
    this.Earth.position.set(0, 0, 0);
    this.Earth.receiveShadow = true;
    this.Earth.castShadow = true;
    earthScene.add(this.Earth);
    earthScene.add(this.earthMoonParent);

    const moonImage = require('./../../../assets/textures/moon.png');
    this.Moon = this.createSphereObject(1.5, 60, 60, moonImage);
    this.Moon.castShadow = true;
    this.Moon.receiveShadow = true;
    this.Moon.position.set(0, 0, 60);
    this.earthMoonParent.add(this.Moon);

    const sunPointLight = new THREE.PointLight(0xffffff, 1, 500);
    sunPointLight.name = 'sunLight';
    sunPointLight.position.set(0, 0, -100);
    earthScene.add(sunPointLight);

    this.Sun.position.set(0, 0, -3000);
    this.Sun.add(this.light);

    earthScene.add(this.Sun);

    return earthScene;
  }

  initMarsScene() {
    const marsScene = this.createScene();

    this.deimosParent = new THREE.Object3D();
    this.deimosParent.position.set(0, 0, 0);
    this.phobosParent = new THREE.Object3D();
    this.phobosParent.position.set(0, 0, 0);
    marsScene.add(this.phobosParent);
    marsScene.add(this.deimosParent);
    this.camera.position.set(5, 0, 70);

    const marsImage = require('./../../../assets/textures/mars.png');
    this.Mars = this.createSphereObject(5, 60, 60, marsImage);
    this.Mars.castShadow = true;
    this.Mars.receiveShadow = true;
    this.Mars.position.set(0, 0, 0);
    marsScene.add(this.Mars);

    const deimosImage = require('./../../../assets/textures/deimos_mars.png');
    const phobosImage = require('./../../../assets/textures/phobos_mars.png');
    this.Phobos = this.createDodecahedronObject(0.2, 1, phobosImage);
    this.Phobos.position.set(0, 0, 20);
    this.Phobos.castShadow = true;
    this.Phobos.receiveShadow = true;
    this.phobosParent.add(this.Phobos);
    this.Deimos = this.createDodecahedronObject(0.2, 1, deimosImage);
    this.Deimos.position.set(0, 0, 25);
    this.Deimos.castShadow = true;
    this.Deimos.receiveShadow = true;
    this.deimosParent.add(this.Deimos);

    const sunPointLight = new THREE.PointLight(0xffffff, 1, 500);
    sunPointLight.name = 'sunLight';
    sunPointLight.position.set(0, 0, -100);
    marsScene.add(sunPointLight);

    this.Sun.position.set(0, 0, -3000);
    this.Sun.add(this.light);
    marsScene.add(this.Sun);

    return marsScene;
  }

  initJupiterScene() {
    const jupiterScene = this.createScene();

    this.europaParent = new THREE.Object3D();
    jupiterScene.add(this.europaParent);

    this.ioParent = new THREE.Object3D();
    jupiterScene.add(this.ioParent);

    this.ganymedeParent = new THREE.Object3D();
    jupiterScene.add(this.ganymedeParent);

    this.callistoParent = new THREE.Object3D();
    jupiterScene.add(this.callistoParent);

    this.camera.position.set(5, 0, 70);

    const jupiterImage = require('./../../../assets/textures/jupiter.png');
    this.Jupiter = this.createSphereObject(5, 60, 60, jupiterImage);
    this.Jupiter.position.set(0, 0, 0);
    this.Jupiter.castShadow = true;
    this.Jupiter.receiveShadow = true;
    jupiterScene.add(this.Jupiter);

    const europaImage = require('./../../../assets/textures/europa_jupiter.png');
    this.Europa = this.createSphereObject(0.1, 60, 60, europaImage);
    this.europaParent.add(this.Europa);
    this.Europa.castShadow = true;
    this.Europa.receiveShadow = true;
    this.Europa.position.set(0, 0, 20);

    const ioImage = require('./../../../assets/textures/io_jupiter.png');
    this.Io = this.createSphereObject(0.1, 60, 60, ioImage);
    this.ioParent.add(this.Io);
    this.Io.castShadow = true;
    this.Io.receiveShadow = true;
    this.Io.position.set(0, 0, 30);

    const ganymedeImage = require('./../../../assets/textures/ganymede_jupiter.png');
    this.Ganymede = this.createSphereObject(0.1, 60, 60, ganymedeImage);
    this.ganymedeParent.add(this.Ganymede);
    this.Ganymede.castShadow = true;
    this.Ganymede.receiveShadow = true;
    this.Ganymede.position.set(0, 0, 40);

    const callistoImage = require('./../../../assets/textures/callisto_jupiter.png');
    this.Callisto = this.createSphereObject(0.1, 60, 60, callistoImage);
    this.callistoParent.add(this.Callisto);
    this.Callisto.castShadow = true;
    this.Callisto.receiveShadow = true;
    this.Callisto.position.set(0, 0, 50);

    // const sunPointLight = new THREE.PointLight(0xffffff, 1, 500);
    // sunPointLight.name = 'sunLight';
    // sunPointLight.position.set(0, 0, -100);
    // jupiterScene.add(sunPointLight);
    this.Sun.add(this.light);
    this.Sun.position.set(0, 0, -3000);

    jupiterScene.add(this.Sun);

    return jupiterScene;
  }

  initSaturnScene() {
    const saturnScene = this.createScene();

    this.mimasParent = new THREE.Object3D();
    saturnScene.add(this.mimasParent);

    this.enceladusParent = new THREE.Object3D();
    saturnScene.add(this.enceladusParent);

    this.tethysParent = new THREE.Object3D();
    saturnScene.add(this.tethysParent);

    this.camera.position.set(5, 5, 30);

    const saturnImage = require('./../../../assets/textures/saturn.png');
    this.Saturn = this.createSphereObject(5, 60, 60, saturnImage);
    this.Saturn.position.set(0, 0, 0);
    saturnScene.add(this.Saturn);

    const saturnRingImage = require('./../../../assets/textures/saturn_ring.png');
    this.SaturnRing = this.createRingObject(8, 13, 60, saturnRingImage);
    this.SaturnRing.rotation.x = Math.PI / 2;
    this.Saturn.add(this.SaturnRing);

    const mimasImage = require('./../../../assets/textures/mimas_saturn.png');
    this.Mimas = this.createSphereObject(0.3, 60, 60, mimasImage);
    this.mimasParent.add(this.Mimas);
    this.Mimas.position.set(0, 0, 20);

    const enceladusImage = require('./../../../assets/textures/enceladus_saturn.png');
    this.Enceladus = this.createSphereObject(0.3, 60, 60, enceladusImage);
    this.enceladusParent.add(this.Enceladus);
    this.Enceladus.position.set(0, 0, 30);

    const tethysImage = require('./../../../assets/textures/tethys_saturn.png');
    this.Tethys = this.createSphereObject(0.3, 60, 60, tethysImage);
    this.tethysParent.add(this.Tethys);
    this.Tethys.position.set(0, 0, 40);

    const sunPointLight = new THREE.PointLight(0xffffff, 1, 200);
    sunPointLight.name = 'sunLight';
    sunPointLight.position.set(0, 0, -100);
    saturnScene.add(sunPointLight);

    this.Sun.position.set(0, 0, -6000);
    saturnScene.add(this.Sun);

    return saturnScene;
  }

  initUranusScene() {
    const uranusScene = this.createScene();

    this.camera.position.set(30, 10, 30);

    const uranusImage = require('./../../../assets/textures/uranus.png');
    this.Uranus = this.createSphereObject(6, 60, 60, uranusImage);
    uranusScene.add(this.Uranus);

    const sunPointLight = new THREE.PointLight(0xffffff, 1, 250);
    sunPointLight.name = 'sunLight';
    sunPointLight.position.set(0, 0, -100);
    uranusScene.add(sunPointLight);

    const uranusRingImage = require('./../../../assets/textures/uranus_ring.png');

    this.UranusRing = this.createRingObject(8, 12, 60, uranusRingImage);
    this.UranusRing.rotation.x = Math.PI / 2;
    this.Uranus.add(this.UranusRing);

    this.Sun.position.set(0, 0, -6000);
    uranusScene.add(this.Sun);

    return uranusScene;
  }

  initNeptuneScene() {
    const neptuneScene = this.createScene();

    this.camera.position.set(30, 10, 30);

    const neptuneImage = require('./../../../assets/textures/neptune.png');
    this.Neptune = this.createSphereObject(6, 60, 60, neptuneImage);
    neptuneScene.add(this.Neptune);

    const sunPointLight = new THREE.PointLight(0xffffff, 1, 200);
    sunPointLight.name = 'sunLight';
    sunPointLight.position.set(0, 0, -100);
    neptuneScene.add(sunPointLight);

    this.Sun.position.set(0, 0, -6000);
    neptuneScene.add(this.Sun);

    return neptuneScene;
  }

  animateScene() {
    this.Sun.rotateY(0.0007);
    this.mercurySun && this.mercurySun.rotateY(0.007);

    this.mercuryParent.rotateY(mercurySpeed);
    this.Mercury.rotateY(0.004);

    this.venusParent.rotateY(venusSpeed);
    this.Venus.rotateY(0.002);

    this.earthParent.rotateY(earthSpeed);
    this.Earth && this.Earth.rotateY(0.03);
    this.earthMoonParent && this.earthMoonParent.rotateY(0.002);
    this.Moon && this.Moon.rotateY(0.003);

    this.marsParent.rotateY(marsSpeed);
    this.Mars.rotateY(0.018);
    this.deimosParent && this.deimosParent.rotateY(0.006);
    this.Deimos && this.Deimos.rotateY(0.003);
    this.phobosParent && this.phobosParent.rotateY(0.003);
    this.Phobos && this.Phobos.rotateY(0.003);

    this.jupiterParent.rotateY(jupiterSpeed);
    this.Jupiter.rotateY(0.004);
    this.europaParent && this.europaParent.rotateY(0.009);
    this.Europa && this.Europa.rotateY(0.009);
    this.ioParent && this.ioParent.rotateY(0.007);
    this.Io && this.Io.rotateY(0.007);
    this.ganymedeParent && this.ganymedeParent.rotateY(0.005);
    this.Ganymede && this.Ganymede.rotateY(0.007);
    this.callistoParent && this.callistoParent.rotateY(0.003);
    this.Callisto && this.Callisto.rotateY(0.007);

    this.saturnParent.rotateY(saturnSpeed);
    this.Saturn.rotateY(0.038);
    this.mimasParent && this.mimasParent.rotateY(0.03);
    this.Mimas && this.Mimas.rotateY(0.009);
    this.enceladusParent && this.enceladusParent.rotateY(0.005);
    this.Enceladus && this.Enceladus.rotateY(0.009);
    this.tethysParent && this.tethysParent.rotateY(0.009);
    this.Tethys && this.Tethys.rotateY(0.009);
    this.uranusParent.rotateY(uranusSpeed);
    this.Uranus.rotateY(0.03);

    this.neptuneParent.rotateY(neptuneSpeed);
    this.Neptune.rotateY(0.032);

    this.controls.update();
    this.renderer.render(this.scene, this.camera);
  }

  componentDidMount() {
    const self = this;
    this.containerRef?.current?.appendChild(this.renderer.domElement);
    // !this.props.isPreview && new VRButton(this.renderer);
    if (!document.getElementById('vrButton')) {
      this.vrButton = new VRButton(this.renderer);
    }
    // !this.props.isPreview && new VRButton(this.renderer);
    // When user turn on the VR mode.
    self.renderer.xr.addEventListener('sessionstart', function () {
      self.dolly.position.z = 100;
      self.dolly.position.y = 40;
      self.scene.getObjectByName('instructionPanel').visible = true;
    });
    //When user turn off the VR mode.
    self.renderer.xr.addEventListener('sessionend', function () {
      if (self.sound?.isPlaying) self.sound.pause();

      self.dolly.position.z = 0;
      self.dolly.position.y = 0;
      self.scene.getObjectByName('instructionPanel').visible = true;

      let adminPreviewContainer = document.getElementById('previewContainer');
      if (self.props.onsessionendAdmin && adminPreviewContainer) {
        adminPreviewContainer.appendChild(self.vrButton.vrButton);
      }
    });
    this.containerRef.current.addEventListener(
      'click',
      this.toggleMenu.bind(this),
      true
    );
  }

  componentWillUnmount() {
    window?.removeEventListener('resize', this.resize);
    this.containerRef?.current?.removeChild(this.renderer.domElement);
    // window.location.reload(true);
    this.containerRef?.removeEventListener &&
      this.containerRef?.removeEventListener('click', this.toggleMenu);
    Object.keys(this).forEach((key) => {
      // Recursively call dispose() if possible.
      if (
        typeof this[key]?.dispose === 'function' &&
        this[key].type != 'Scene'
      ) {
        this[key].dispose();
      }
      // Remove any reference.
      this[key] = null;
    });
  }

  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  changeScene(scene) {
    switch (scene) {
      case 'solar': {
        this.scene = this.initSolarSystemScene();
        return;
      }
      case 'earth': {
        this.scene = this.initEarthScene();
        return;
      }
      case 'mercury': {
        this.scene = this.initMercuryScene();
        return;
      }
      case 'venus': {
        this.scene = this.initVenusScene();
        return;
      }
      case 'mars': {
        this.scene = this.initMarsScene();
        return;
      }
      case 'jupiter': {
        this.scene = this.initJupiterScene();
        return;
      }
      case 'saturn': {
        this.scene = this.initSaturnScene();
        return;
      }
      case 'uranus': {
        this.scene = this.initUranusScene();
        return;
      }
      case 'neptune': {
        this.scene = this.initNeptuneScene();
        return;
      }
      case 'perspective': {
        this.scene = this.initSolarSystemScene();
        return;
      }
      case 'proportional': {
        this.scene = this.initSolarSystemScene(proportionalView, 1.9, 2);
        return;
      }
      default: {
        this.scene = this.initSolarSystemScene(perspectiveView);
      }
    }
  }

  createScene() {
    const newSceneInstance = new THREE.Scene();
    newSceneInstance.background = this.cubeTextureLoader.load([
      this.backgroundImage,
      this.backgroundImage,
      this.backgroundImage,
      this.backgroundImage,
      this.backgroundImage,
      this.backgroundImage
    ]);

    newSceneInstance.add(this.ambient);
    // newSceneInstance.add(this.light);

    return newSceneInstance;
  }

  createSphereObject(
    radius,
    widthSegments,
    heightSegments,
    image,
    isBasicMaterial = false
  ) {
    const texture = new THREE.TextureLoader().load(image);

    const geometry = new THREE.SphereBufferGeometry(
      radius,
      widthSegments,
      heightSegments
    );
    const material = isBasicMaterial
      ? new THREE.MeshBasicMaterial({ map: texture })
      : new THREE.MeshStandardMaterial({ map: texture });
    // const material = new THREE.MeshPhongMaterial({color: 0xffffff})

    const mesh = new THREE.Mesh(geometry, material);

    return mesh;
  }

  createDodecahedronObject(radius, detail, image) {
    const texture = new THREE.TextureLoader().load(image);

    const geometry = new THREE.DodecahedronGeometry(radius, detail);
    const material = new THREE.MeshStandardMaterial({ map: texture });

    const mesh = new THREE.Mesh(geometry, material);

    return mesh;
  }

  createRingObject(
    innerRadius,
    outerRadius,
    thetaSegments,
    image = null,
    transparent = true,
    color = 0xffffff,
    phiSegments = 1
  ) {
    const texture = new THREE.TextureLoader().load(image);
    const materialProps = {
      color,
      side: THREE.DoubleSide,
      transparent
    };

    if (image) {
      materialProps.map = texture;
    }

    const geometry = new THREE.RingGeometry(
      innerRadius,
      outerRadius,
      thetaSegments,
      phiSegments
    );
    const material = new THREE.MeshBasicMaterial(materialProps);

    const mesh = new THREE.Mesh(geometry, material);

    return mesh;
  }

  createCircleObject(radius) {
    let pts = new THREE.Path()
      .absarc(0, 0, radius, 0, Math.PI * 2)
      .getPoints(90);
    let geometry = new THREE.BufferGeometry().setFromPoints(pts);
    let material = new THREE.LineBasicMaterial({ color: 0x636e72 });
    let mesh = new THREE.Line(geometry, material);

    return mesh;
  }

  toggleMenu(customValue = true) {
    this.setState({
      isMenuClose: customValue
    });
  }
  roundRect(ctx, x, y, w, h, r) {
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.lineTo(x + w - r, y);
    ctx.quadraticCurveTo(x + w, y, x + w, y + r);
    ctx.lineTo(x + w, y + h - r);
    ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
    ctx.lineTo(x + r, y + h);
    ctx.quadraticCurveTo(x, y + h, x, y + h - r);
    ctx.lineTo(x, y + r);
    ctx.quadraticCurveTo(x, y, x + r, y);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  }

  makeTextSprite(message, parameters) {
    if (parameters === undefined) parameters = {};
    const footpace = parameters.hasOwnProperty('fontface')
      ? parameters['fontface']
      : 'Arial';
    const fontsize = parameters.hasOwnProperty('fontsize')
      ? parameters['fontsize']
      : 18;
    const borderThickness = parameters.hasOwnProperty('borderThickness')
      ? parameters['borderThickness']
      : 4;
    const borderColor = parameters.hasOwnProperty('borderColor')
      ? parameters['borderColor']
      : { r: 0, g: 0, b: 0, a: 1.0 };
    const backgroundColor = parameters.hasOwnProperty('backgroundColor')
      ? parameters['backgroundColor']
      : { r: 255, g: 255, b: 255, a: 1.0 };
    const textColor = parameters.hasOwnProperty('textColor')
      ? parameters['textColor']
      : { r: 0, g: 0, b: 0, a: 1.0 };

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = 'Bold ' + fontsize + 'px ' + footpace;
    const metrics = context.measureText(message);
    const textWidth = metrics.width;

    context.fillStyle =
      'rgba(' +
      backgroundColor.r +
      ',' +
      backgroundColor.g +
      ',' +
      backgroundColor.b +
      ',' +
      backgroundColor.a +
      ')';
    context.strokeStyle =
      'rgba(' +
      borderColor.r +
      ',' +
      borderColor.g +
      ',' +
      borderColor.b +
      ',' +
      borderColor.a +
      ')';

    context.lineWidth = borderThickness;
    this.roundRect(
      context,
      borderThickness / 2,
      borderThickness / 2,
      (textWidth + borderThickness) * 1.1,
      fontsize * 1.4 + borderThickness,
      8
    );

    context.fillStyle =
      'rgba(' +
      textColor.r +
      ', ' +
      textColor.g +
      ', ' +
      textColor.b +
      ', 1.0)';
    context.fillText(message, borderThickness, fontsize + borderThickness);

    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;

    const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(0.5 * fontsize, 0.25 * fontsize, 0.75 * fontsize);
    return sprite;
  }

  createLinkLabel(linePosition, lineHeight = 50, text) {
    const points = [];
    points.push(new THREE.Vector3(0, 0, 0));
    points.push(new THREE.Vector3(0, lineHeight, 0));

    const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
    const lineMaterial = new THREE.MeshBasicMaterial({ color: 0x636e72 });

    const Line = new THREE.Line(lineGeometry, lineMaterial);

    Line.position.set(linePosition, 0, 0);

    const textUI = this.makeTextSprite(text, {
      borderThickness: 0,
      textColor: { r: 255, g: 255, b: 255, a: 1 },
      backgroundColor: { r: 255, g: 255, b: 255, a: 0 },
      fontsize: 70,
      borderColor: { r: 255, g: 255, b: 255, a: 0 }
    });
    textUI.position.set(0, lineHeight, 0);
    Line.add(textUI);
    Line.name = `${text} line`;

    return Line;
  }

  render() {
    this.animateScene();
    const self = this;
    if (this.vrButton) this.vrButton.vrButton.disabled = false;

    this.orbitLabels.forEach((planetLabel) => {
      const distanceToCamera =
        planetLabel.position.distanceTo(self.camera.position) / 600;
      planetLabel.scale.set(
        ...new THREE.Vector3(
          distanceToCamera,
          distanceToCamera,
          distanceToCamera
        )
      );
      // planetLabel.children[0].scale.set(...new THREE.Vector3(distanceToCamera, distanceToCamera, distanceToCamera))
    });
    // let link;
    // if (this.props.url.split('/').indexOf('fullscreen')) {
    //   link = this.props.url.split('/').slice(0, -1).join('/');
    // }
    if (this.controllers) {
      const dt = this.clock.getDelta();

      const self = this;
      this.controllers.forEach((controller, index) => {
        self.handleController(controller, dt, index);
      });
    }

    return (
      <>
        <div
          className={`${styles.sceneContainer} ${
            this.props.isPreview
              ? styles.containerSizePreview
              : styles.containerSizeFull
          }`}
          ref={this.containerRef}
        >
          {/* {this.props.url.split('/').indexOf('fullscreen') != -1 ? (
            <Link
              to={link}
              className={
                'px-2 py-2 bg-gray-500 border-2 border-white rounded-lg text-center hover:bg-gray-600 hover hover:border-gray-100 top-5 right-5 text-white absolute'
              }
            >
              {' '}
              <svg
                className={'self-end fill-current h-6 w-6 '}
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Exit Fullscreen</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </Link>
          ) : null} */}

          <div
            className={`${styles.menuBlock} ${
              !this.state.isMenuClose ? styles.hiddenMenuBlock : ''
            }`}
          >
            <button onClick={() => this.toggleMenu(false)}>
              <MenuIcon
                style={{ color: 'white' }}
                className="block h-6 w-6"
                aria-hidden="true"
              />
            </button>
          </div>
          <div
            className={`${styles.sceneMenu} ${
              this.state.isMenuClose ? styles.closeSceneMenu : ''
            }`}
          >
            <div className={styles.closeButton}>
              <button onClick={() => this.toggleMenu()}>
                <XIcon
                  style={{ color: 'black' }}
                  className="block h-6 w-6"
                  aria-hidden="true"
                />
              </button>
            </div>
            {solarData.map((item, index) => {
              return (
                <button
                  key={index}
                  className={styles.planetButton}
                  onClick={() => self.changeScene(item.key)}
                >
                  {item.name}
                </button>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default React.memo(SolarSystem);
