/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
// import { AdminApi } from '../../../../../api/admin.api';
import { ContentApi } from '../../../../../../api/content.api';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../../../../../../context/AppProvider';
export default function ResetTopicConfirmation({
  resetConfirmation,
  setResetConfirmation,
  categoryId,
  logOut
}) {
  const { store, setShouldUpdate } = useAppContext();
  const history = useHistory();
  const { categoryCollection } = store;

  const onDeleteSubmit = async () => {
    const category = categoryCollection.categories.find(
      (cat) => cat.categoryId === categoryId
    );
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const token = window.localStorage.getItem('userToken');
    const data = {
      parentId: userData.userId,
      categoryName: category.categoryName,
      categoryId: categoryId
    };

    const deletedTopic = await ContentApi.resetToDefaultTopics(
      token,
      data,
      history
    );

    if (deletedTopic.status === 200) {
      setShouldUpdate(true);
    } else {
      logOut();
    }
    setResetConfirmation({
      show: false
    });
  };

  return (
    <>
      {resetConfirmation.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className=" relative flex flex-col w-full  outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                    {/* <!-- Modal content --> */}
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                      {/* <!-- Modal header --> */}
                      <div className="flex justify-end p-2">
                        <button
                          onClick={() => setResetConfirmation({ show: false })}
                          type="button"
                          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                          data-modal-toggle="popup-modal"
                        >
                          <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      {/* <!-- Modal body --> */}
                      <div className="p-6 pt-0 text-center">
                        <svg
                          className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          ></path>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          Are you sure you want to reset these category topics,
                          this will be drop all your progress to default.
                        </h3>
                        <button
                          onClick={() => onDeleteSubmit()}
                          data-modal-toggle="popup-modal"
                          type="button"
                          className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                        >
                          {'Yes, I am sure'}
                        </button>
                        <button
                          onClick={() => setResetConfirmation({ show: false })}
                          data-modal-toggle="popup-modal"
                          type="button"
                          className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                        >
                          No, cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
