/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { coursesData, coursesHeader } from '../../../assets/mockData/mockData';
import CategoryCard from './categoryCard/categoryCard';
import { useAppContext } from '../../../context/AppProvider';
import { ContentApi } from '../../../api/content.api';

const Categories = () => {
  const { store, setCategoryCollection } = useAppContext();
  const [categories, setCategories] = useState(coursesData.slice(0, 5));
  const [isUserData, setIsUserData] = useState(false);
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const { selectedLanguage, categoryCollection } = store;

  useEffect(() => {
    if (
      (userData?.role === 'school' || userData?.role === 'learner') &&
      categoryCollection.categories
    ) {
      const alliedCategories = categoryCollection.categories.map((cat, i) => {
        return {
          ...coursesData[i],
          ...cat
        };
      });
      setCategories(alliedCategories);
      setIsUserData(true);
    } else {
      setCategories(coursesData.slice(0, 5));
    }
  }, [userData?.role, categoryCollection.categories]);

  return (
    <div
      className={
        'md px-5 py-10 pb-40 bg-white-bg flex flex-col items-center flex-grow'
      }
    >
      <div className={'columns-12 flex justify-center my-20'}>
        <p className={'text-5xl  text-center font-tinos font-bold text-dark'}>
          {coursesHeader[0].courseHeader[selectedLanguage]}
        </p>
      </div>
      <div
        className={
          'grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-rows-1 gap-6'
        }
      >
        {categories.map((course, index) => {
          return (
            <CategoryCard
              key={index}
              courseName={course.courseName[selectedLanguage]}
              coursePath={course.path}
              cardDescription={course.cardDescription[selectedLanguage]}
              courseBackground={course.courseBackground}
              courseDescription={course.courseDescription[selectedLanguage]}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Categories;
