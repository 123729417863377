import ApiUtils from './api-utils';

export const AdminApi = {
  getAllUsers(token, history) {
    const url = `${ApiUtils.getBaseUrl()}/admin/users`;
    const options = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: token
      }
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then((parsed) => parsed.json())
      .catch((err) => {
        console.log('Error on get me', err);
        throw err;
      });
  },

  createUser(token, data, history) {
    const url = `${ApiUtils.getBaseUrl()}/admin/user`;
    const options = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(data)
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then((parsed) => parsed.json())
      .catch((err) => {
        console.log('Error on get me', err);
        throw err;
      });
  },

  editUser(token, data, history) {
    const url = `${ApiUtils.getBaseUrl()}/admin/user`;
    const options = {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(data)
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then((parsed) => parsed.json())
      .catch((err) => {
        console.log('Error on get me', err);
        throw err;
      });
  },

  deleteUser(token, userId, history) {
    const url = `${ApiUtils.getBaseUrl()}/admin/user`;
    const options = {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify({ userId })
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then(() => {
        return { deleted: true };
      })
      .catch((err) => {
        console.log('Error on get me', err);
        throw err;
      });
  },

  addChildrenToParent(token, data, history) {
    const url = `${ApiUtils.getBaseUrl()}/admin/user/learner`;
    const options = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(data)
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then(() => {
        return { deleted: true };
      })
      .catch((err) => {
        console.log('Error on get me', err);
        throw err;
      });
  }
};
