import React, { lazy, Suspense } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory
} from 'react-router-dom';
import AppFooter from '../../app/footer/footer';
import AppHeader from '../../app/header/header';
import Categories from './Categories/categories';
import { coursesData } from '../../assets/mockData/mockData';
import CategoryDetails from './Categories/categoryDetails/categoryDetails';
import CategoryTopic from './Categories/categoryTopic/categoryTopic';
import AboutUs from './aboutUs';
import ContactUs from './contactUs';
import HelpSupport from './HelpSupport/helpSupport';
import Terms from './Terms/Terms';
import PrivacyPolicy from './privacyPolicy/PrivacyPolicy';
import ScrollToTop from './scrollToTop';
import { SceneModuleManager } from '../sceneModuleManager/sceneModuleManager';
import NotFound from '../../common/PageNotFound';
import FormPopUp from '../../common/FormPopUp';
import SignUp from '../authorizationMod/signUp';
import SignIn from '../authorizationMod/signIn';
import Loader from '../../common/loader';
import Profile from './Profile/Profile';
import Pricing from './Pricing/pricing';
import PricingForm from './Pricing/pricingForm';
import AdminPanel from './Admin';
import ForgotPassword from '../authorizationMod/forgotPassword/indes';
import ErrorBoundary from '../../common/errorBoudary';
import ModelViewerPage from './Admin/MoleculeConstructor/ModelViewerPage';

const Home = lazy(() => import('./Home/Home'));

const ContentManager = () => {
  const history = useHistory();
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  let rootCategory = useRouteMatch('/categories/:categoryId/:pageNum');
  let rootTopic = useRouteMatch('/categories/:categoryId/:pageNum/:topicId');
  if (rootCategory != null) {
    rootCategory = rootCategory.params.categoryId;
  }
  if (rootTopic != null) {
    rootTopic = rootTopic.params.topicId;
  }
  let linkFlagCategory = false;
  let linkFlagTopic = false;

  coursesData.map((element, index) => {
    if (element.path === rootCategory) {
      linkFlagCategory = true;
      coursesData[index].topics.map((elementTopic) => {
        if (elementTopic.id === rootTopic) {
          linkFlagTopic = false;
          linkFlagTopic = true;
        }
      });
    }
  });

  if (rootTopic && userData?.role) {
    if (userData.role === 'school' || userData.role === 'learner') {
      linkFlagTopic = true;
    }
  }
  return (
    <div className="min-h-screen flex flex-col">
      <AppHeader />
      <Suspense fallback={<Loader />}>
        <ScrollToTop>
          <ErrorBoundary history={history}>
            <Switch>
              <Route exact path={'/categories/:categoryId/:pageNum'}>
                {linkFlagCategory ? <CategoryDetails /> : <NotFound />}
              </Route>
              <Route exact path={'/categories/:categoryId/:pageNum/:topicId'}>
                {linkFlagTopic ? <CategoryTopic /> : <NotFound />}
              </Route>

              <Route exact path={'/'}>
                <Home />
              </Route>
              <Route path={'/aboutUs'}>
                <AboutUs />
              </Route>
              <Route path={'/pricing'}>
                <Pricing />
              </Route>
              <Route path={'/pricingForm'}>
                <PricingForm />
              </Route>

              <Route path={'/404'}>
                <NotFound />
              </Route>
              <Route path={'/FormPopUp'}>
                <FormPopUp />
              </Route>
              <Route path={'/contactUs'}>
                <ContactUs />
              </Route>
              <Route path={'/helpAndSupport'}>
                <HelpSupport />
              </Route>

              <Route path={'/Terms'}>
                <Terms />
              </Route>
              <Route path={'/PrivacyPolicy'}>
                <PrivacyPolicy />
              </Route>
              <Route path={'/Profile'}>
                <Profile />
              </Route>
              <Route
                exact
                path={'/categories/:categoryId/:pageNum/:topicId/fullscreen'}
              >
                <SceneModuleManager />
              </Route>
              <Route path={'/signin'}>
                <SignIn />
              </Route>
              <Route path={'/signup'}>
                <SignUp />
              </Route>
              <Route path={'/forgot_password'}>
                <ForgotPassword />
              </Route>
              <Route exact path={'/categories'}>
                <Categories />
              </Route>
              <Route path={'/admin'}>
                <AdminPanel />
              </Route>
              <Route path={'/modelViewerPage'}>
                <ModelViewerPage />
              </Route>
              <Route path={'/error'}>
                <ErrorBoundary />
              </Route>
              <Redirect to="/404" />
            </Switch>
          </ErrorBoundary>
        </ScrollToTop>
      </Suspense>
      <AppFooter />
    </div>
  );
};

export default ContentManager;
