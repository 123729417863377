/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { coursesData, inputItems } from '../../../../assets/mockData/mockData';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import PopUp from '../../../../common/popUp';
import AuthContext from '../../../../context/authContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from '../../../../context/AppProvider';
import { ContentApi } from '../../../../api/content.api';
import { isObject } from '../../../../utils';

const CategoryDetails = () => {
  let { url } = useRouteMatch();
  const history = useHistory();
  let { categoryId, pageNum } = useParams();
  let [selected, setSelected] = useState(0);
  const [course] = useState(() =>
    coursesData.find((mocoCourse) => mocoCourse.path === categoryId)
  );

  const [state, setState] = useState(false);
  // const [topics, setTopics] = useState([])
  const [topicList, setTopicList] = useState([]);
  const [useLocalData, setUseLocalData] = useState(true);
  const [shortDescription, setShortDescription] = useState('');
  const { store, setCategoryCollection } = useAppContext();
  const [nrOfPages, setNrOfPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const { selectedLanguage, user, categoryCollection } = store;
  let splitedUrl = url.split('/');
  splitedUrl.pop();
  splitedUrl = splitedUrl.join('/');

  pageNum = pageNum.split('=').pop();

  // let nrOfPages = Math.ceil(course.topics.length / 7);
  const scroller = document.querySelector('#scroller');
  const output = document.querySelector('#output');

  useEffect(() => {
    if (userData) {
      if (categoryCollection?.categories?.length) {
        if (userData.role === 'school' || userData.role === 'learner') {
          const currentCategory = categoryCollection.categories.find((cat) => {
            return cat.categoryName.toLowerCase() === categoryId;
          });
          setUseLocalData(false);
          setNrOfPages(Math.ceil(currentCategory.topicList.length / 7));
          setTopicList(currentCategory.topicList);
        }
      } else {
        const currentCategory = coursesData.find(
          (mocoCourse) => mocoCourse.path === categoryId
        );
        setUseLocalData(true);
        setNrOfPages(Math.ceil(currentCategory.topics.length / 7));
        setTopicList(currentCategory.topics);
      }
    } else {
      const currentCategory = coursesData.find(
        (mocoCourse) => mocoCourse.path === categoryId
      );
      setUseLocalData(true);
      setNrOfPages(Math.ceil(currentCategory.topics.length / 7));
      setTopicList(currentCategory.topics);
    }
  }, [categoryCollection]);

  useEffect(() => {
    window.scrollTo(100, 100);
    if (course.courseDescription[selectedLanguage]) {
      if (course.courseDescription[selectedLanguage].length > 400) {
        const trimmedDesc = course.courseDescription[selectedLanguage].slice(
          0,
          350
        );
        setShortDescription(trimmedDesc);
      }
    }
  }, [course.courseDescription[selectedLanguage]]);
  const getListArray = () => {
    selected = pageNum - 1;
    return topicList.slice(7 * selected, 7 * (selected + 1));
  };
  const childToParent = (childData) => {
    setState(childData);
  };
  const changeState = () => {
    setState(true);
  };

  return (
    <div className={'flex flex-grow flex-col w-full'}>
      <main>
        <div className=" mx-auto flex flex-col">
          {/* Replace with your content */}
          <div
            className={` h-278 relative w-full bg-no-repeat bg-center bg-cover  ${course.courseBackground}`}
          >
            <div className="w-full ">
              <div className="w-full  h-278 opacity-50 absolute flex flex-col justifty-center bg-black"></div>
              <div className="opacity-100  top-16 absolute cursor-pointer  flex flex-col justify-center items-center w-full mx-auto">
                <button
                  onClick={history.goBack}
                  className=" font-tinos text-white text-center blockTextH font-semibold text-4xl p-3"
                >
                  {course.courseName[selectedLanguage]}
                </button>
                <p className=" font-gothic cursor-pointer  text-white text-justify blockTextP p-3 xlmax:w-3/5 mdmax:w-full w-2/5 text-xl">
                  {shortDescription == ''
                    ? course.courseDescription[selectedLanguage]
                    : shortDescription}
                </p>
              </div>
            </div>
          </div>
          {/* /End replace */}
        </div>
      </main>

      <div
        className={
          '  flex justy items-start w-full relative  mx-auto overflow-hidden'
        }
      >
        <div
          className={`h-600 absolute smmax:right-3/4 -mt-5 -bottom-32 -rotate-45 -top-2/4 right-2/3 w-1000 ${course.courseLeftBackground} bg-90% bg-center`}
        ></div>
        <div
          className={
            'w-4/5 items-center space-y-16 justify-between flex-col flex mx-auto mt-10 overflow-hidden'
          }
        >
          <div className="min-h-full min w-full">
            <p
              className={
                'text-4xl tracking-widest text-center ml-5  font-bold text-dark'
              }
            >
              {inputItems.Topics[selectedLanguage]}
            </p>
            <ul className={'grid-cols-1  text-center grid-rows-4 my-6'}>
              {useLocalData
                ? getListArray().map((topic, index) => {
                    return topic.type ||
                      topic?.multipleModels?.length ||
                      topic.isSpecialModule ? (
                      <Link
                        onClick={changeState}
                        to={`${splitedUrl}/page=${selected + 1}/${topic.id}`}
                        key={index}
                        className="flex mdmin:w-96 mx-auto   flex-col"
                      >
                        <p className="mb-3   text-gray-500 hover:text-black">
                          {topic.name[selectedLanguage]}
                        </p>
                      </Link>
                    ) : (
                      <div key={index}>
                        {!topic.textType ? (
                          <div className={' mb-3 flex justify-center  '}>
                            <FontAwesomeIcon
                              className={'mx-2 mt-1'}
                              icon={'lock'}
                            />
                            <div
                              onClick={changeState}
                              key={topic.id}
                              className="flex cursor-pointer items-center"
                            >
                              <p className=" text-gray-400 hover:text-black">
                                {topic.name[selectedLanguage]}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className={
                              'text-lg font-tinos  font-bold border-b-2 roudned border-black w-1/3 mx-auto p-0 mb-2 text-black '
                            }
                          >
                            {topic.name[selectedLanguage]}
                          </p>
                        )}
                      </div>
                    );
                  })
                : getListArray().map((topic, index) => {
                    return (
                      <Link
                        onClick={changeState}
                        to={`${splitedUrl}/page=${selected + 1}/${
                          topic.topicId
                        }`}
                        key={topic.id}
                        className="flex flex-col"
                      >
                        <p className="mb-3 text-gray-500 hover:text-black">
                          {isObject(topic.topicName)
                            ? topic.topicName[selectedLanguage]
                            : topic.topicName}
                        </p>
                      </Link>
                    );
                  })}

              {state ? <PopUp childToParent={childToParent} /> : null}
            </ul>
            <div className="bg-white xsmin:px-1 smmin:px-2 mx-auto  flex   justify-between pb-5 sm:px-6">
              <div className="sm:flex-1 sm:flex items-center mx-auto  sm:justify-center">
                <div>
                  <nav
                    className="relative z-0 inline-flex mx-auto rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    {[...Array(nrOfPages)].map((element, index) => {
                      return (
                        <Link
                          to={`${splitedUrl}/page=${index + 1}`}
                          onClick={
                            (getListArray(),
                            () => setSelected(index),
                            window.scrollTo(
                              window.pageXOffset,
                              window.pageYOffset
                            ))
                          }
                          key={index}
                          aria-current="page"
                          className={`z-10 ${
                            pageNum == index + 1 ? 'bg-indigo-50' : 'bg-white'
                          } hover:border-2 border-2  border-white hover:border-indigo-50 cursor-pointer text-gray-500 relative inline-flex items-center px-4 py-2  text-sm font-medium`}
                        >
                          {index + 1}
                        </Link>
                      );
                    })}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`h-600 absolute mt-52 -rotate-45 smmax:left-3/4 left-3/4 w-1000 ${course.courseRightBackground} bg-40% bg-top`}
        ></div>
      </div>
    </div>
  );
};

export default CategoryDetails;
