import ApiUtils from './api-utils';

export const UserApi = {
  forgotPassowrd(data, history) {
    const url = `${ApiUtils.getBaseUrl()}/auth/forgot_password`;
    const options = {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    return fetch(url, options)
      .then(async (response) => {
        const parsed = await ApiUtils.checkStatus(response, history);
        return parsed;
      })
      .then((parsed) => parsed.json())
      .catch((err) => {
        console.log('Error on get me', err);
        return err;
      });
  }
};
