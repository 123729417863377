import * as THREE from 'three';
export const createFinalSolution = (textMeshC, textMeshO, textMeshH, scene) => {
  const oxygenGeometry = new THREE.SphereGeometry(2, 60, 30);
  const oxygenMaterial = new THREE.MeshStandardMaterial({ color: 0xe74c3c });

  const carbonGeometry = new THREE.SphereGeometry(2, 60, 30);
  const carbonMaterial = new THREE.MeshStandardMaterial({ color: 0x2980b9 });

  const glucozeGroup = new THREE.Mesh(new THREE.BoxGeometry(6, 15, 6), {});

  const hydrogenGeometry = new THREE.SphereGeometry(1, 60, 30);
  const hydrogenMaterial = new THREE.MeshStandardMaterial({
    color: 0xecf0f1
  });
  scene.add(glucozeGroup);
  glucozeGroup.position.set(-50, -5, -20);
  glucozeGroup.name = 'glucozeGroup';
  const firstMolCarbon = new THREE.Mesh(carbonGeometry, carbonMaterial);

  const firstMolOxygen = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
  const firstMolHydrogen = new THREE.Mesh(hydrogenGeometry, hydrogenMaterial);
  const firstMolHydrogenCopy = firstMolHydrogen.clone();

  const textMeshCCopy = textMeshC.clone();
  const textMeshOCopy = textMeshO.clone();
  const textMeshHCopy = textMeshH.clone();
  const textMeshHCopy2 = textMeshH.clone();

  firstMolCarbon.add(firstMolOxygen);
  firstMolCarbon.add(firstMolHydrogen);
  firstMolCarbon.add(firstMolHydrogenCopy);
  firstMolCarbon.add(textMeshCCopy);
  firstMolOxygen.add(textMeshOCopy);
  firstMolHydrogen.add(textMeshHCopy);
  firstMolHydrogenCopy.add(textMeshHCopy2);

  textMeshCCopy.position.set(0, 0, 2);
  textMeshOCopy.position.set(0, 0, 2);
  textMeshHCopy.position.set(0, 0, 2);
  textMeshHCopy2.position.set(0, 0, 2);

  firstMolOxygen.position.set(2, 2, -1);
  firstMolHydrogen.position.set(-2, 1, 0);
  firstMolHydrogenCopy.position.set(2, -1, 1);

  const secondMolCarbon = new THREE.Mesh(carbonGeometry, carbonMaterial);
  const secondMolOxygen = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
  const secondMolOxygenCopy = secondMolOxygen.clone();
  const secondMolHydrogen = new THREE.Mesh(hydrogenGeometry, hydrogenMaterial);

  const textMeshCCopySecond = textMeshCCopy.clone();
  const textMeshOCopySecond = textMeshOCopy.clone();
  const textMeshOCopy2Second = textMeshOCopy.clone();
  const textMeshHCopySecond = textMeshHCopy.clone();

  secondMolCarbon.add(textMeshCCopySecond);
  secondMolOxygen.add(textMeshOCopySecond);
  secondMolOxygenCopy.add(textMeshOCopy2Second);
  secondMolHydrogen.add(textMeshHCopySecond);

  secondMolCarbon.add(secondMolOxygen);
  secondMolCarbon.add(secondMolOxygenCopy);
  secondMolCarbon.add(secondMolHydrogen);

  secondMolCarbon.position.set(0, -3.5, 0);
  secondMolOxygen.position.set(-3, 1, 0);
  secondMolOxygenCopy.position.set(3, -1, 0);
  secondMolHydrogen.position.set(-2.5, -1, 1);

  firstMolCarbon.add(secondMolCarbon);

  const thirdMolCarbon = new THREE.Mesh(carbonGeometry, carbonMaterial);
  const thirdMolOxygen = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
  const thirdMolHydrogen = new THREE.Mesh(hydrogenGeometry, hydrogenMaterial);
  const thirdMolHydrogenCopy = thirdMolHydrogen.clone();
  const thirdMolHydrogenCopy2 = thirdMolHydrogen.clone();

  const textMeshCCopyThird = textMeshCCopy.clone();
  const textMeshOCopyThird = textMeshOCopy.clone();
  const textMeshHCopyThird = textMeshHCopy.clone();
  const textMeshHCopy2Third = textMeshOCopy.clone();
  const textMeshHCopy3Third = textMeshOCopy.clone();

  thirdMolCarbon.add(textMeshCCopyThird);
  thirdMolOxygen.add(textMeshOCopyThird);
  thirdMolHydrogenCopy.add(textMeshHCopyThird);
  thirdMolHydrogen.add(textMeshHCopy2Third);
  thirdMolHydrogenCopy2.add(textMeshHCopy3Third);

  thirdMolCarbon.add(thirdMolOxygen);
  thirdMolCarbon.add(thirdMolHydrogenCopy);
  thirdMolCarbon.add(thirdMolHydrogen);
  thirdMolOxygen.add(thirdMolHydrogenCopy2);

  thirdMolCarbon.position.set(0, -3.5, 0);
  thirdMolOxygen.position.set(3, -2, 0);
  thirdMolHydrogen.position.set(-2.5, 0, 0);
  thirdMolHydrogenCopy.position.set(2, -1, 1);
  thirdMolHydrogenCopy2.position.set(0, -1, 2);

  secondMolCarbon.add(thirdMolCarbon);

  const fourthMolCarbon = new THREE.Mesh(carbonGeometry, carbonMaterial);
  const fourthMolOxygen = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
  const fourthMolHydrogen = new THREE.Mesh(hydrogenGeometry, hydrogenMaterial);
  const fourthMolHydrogenCopy = fourthMolHydrogen.clone();

  const textMeshCCopyFourth = textMeshCCopy.clone();
  const textMeshOCopyFourth = textMeshOCopy.clone();
  const textMeshHCopyFourth = textMeshHCopy.clone();
  const textMeshHCopy2Fourth = textMeshHCopy.clone();

  fourthMolCarbon.add(textMeshCCopyFourth);
  fourthMolOxygen.add(textMeshOCopyFourth);
  fourthMolHydrogenCopy.add(textMeshHCopy2Fourth);
  fourthMolHydrogen.add(textMeshHCopyFourth);

  fourthMolCarbon.add(fourthMolOxygen);
  fourthMolCarbon.add(fourthMolHydrogenCopy);
  fourthMolCarbon.add(fourthMolHydrogen);

  fourthMolCarbon.position.set(-1, -3, 0);
  fourthMolOxygen.position.set(-3, 0, 0);
  fourthMolHydrogen.position.set(0, 0, 2);
  fourthMolHydrogenCopy.position.set(-3, 2, 1);

  thirdMolCarbon.add(fourthMolCarbon);

  const fifthMolCarbon = new THREE.Mesh(carbonGeometry, carbonMaterial);
  const fifthMolHydrogen = new THREE.Mesh(hydrogenGeometry, hydrogenMaterial);
  const fifthMolHydrogenCopy = fifthMolHydrogen.clone();

  const textMeshCCopyFifth = textMeshCCopy.clone();
  const textMeshHCopyFifth = textMeshHCopy.clone();
  const textMeshHCopy2Fifth = textMeshHCopy.clone();

  fifthMolCarbon.add(textMeshCCopyFifth);
  fifthMolHydrogenCopy.add(textMeshHCopyFifth);
  fifthMolHydrogen.add(textMeshHCopy2Fifth);

  fifthMolCarbon.add(fifthMolHydrogenCopy);
  fifthMolCarbon.add(fifthMolHydrogen);

  fifthMolCarbon.position.set(1, -3, 0);
  fifthMolHydrogen.position.set(-2, 0.5, 0);
  fifthMolHydrogenCopy.position.set(2, -0.5, 0);

  fourthMolCarbon.add(fifthMolCarbon);

  const sixthMolCarbon = new THREE.Mesh(carbonGeometry, carbonMaterial);
  const sixthMolOxygen = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
  const sixthMolHydrogen = new THREE.Mesh(hydrogenGeometry, hydrogenMaterial);
  const sixthMolHydrogenCopy = sixthMolHydrogen.clone();

  const textMeshCCopySixth = textMeshCCopy.clone();
  const textMeshOCopySixth = textMeshOCopy.clone();
  const textMeshHCopySixth = textMeshHCopy.clone();
  const textMeshHCopy2Sixth = textMeshHCopy.clone();

  sixthMolCarbon.add(textMeshCCopySixth);
  sixthMolOxygen.add(textMeshOCopySixth);
  sixthMolHydrogenCopy.add(textMeshHCopySixth);
  sixthMolHydrogen.add(textMeshHCopy2Sixth);

  sixthMolCarbon.add(sixthMolOxygen);
  sixthMolCarbon.add(sixthMolHydrogenCopy);
  sixthMolCarbon.add(sixthMolHydrogen);

  sixthMolCarbon.position.set(-1, -3.5, 0);
  sixthMolOxygen.position.set(2, -2.5, 1);
  sixthMolHydrogen.position.set(-2, 0, 1);
  sixthMolHydrogenCopy.position.set(2, -4, 2);

  fifthMolCarbon.add(sixthMolCarbon);

  glucozeGroup.add(firstMolCarbon);

  firstMolCarbon.visible = false;
  firstMolCarbon.name = 'firstMolCarbon';
};
