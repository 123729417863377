import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry';
import styles from './organyHydrocarbs.css';
import { VRButton } from '../../../common/VRButton';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { Link } from 'react-router-dom';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { withRouter } from 'react-router-dom';
import { roundedSquare } from '../../../common/SceneModelGeneral/roundedSquareBg.js';
import { coursesData } from '../../../assets/mockData/mockData';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import createTopicName from '../../../common/SceneModelGeneral/createTopicName';
import ControllerInstruction from './../../../common/controllerInstruction/index';
import { DirectionalLightSet } from '../../../common/SceneModelGeneral/directionalLightset';
import { SpotLightSet } from '../../../common/SceneModelGeneral/spotLightSet';
import { colissionDetecter } from '../../../common/collisionDetector';
import { loader } from '../../../common/loaderForEnvironment';
import { textToSpeech } from '../../../common/TabletFunctionality/textToSpeech';
import { Tablet } from '../../../common/TabletFunctionality/index';
import { Pagination } from '../../../common/TabletFunctionality/Pagination';
import { playInstruction } from '../../../common/TabletFunctionality/playInstruction';
import { pagintationLerping } from '../../../common/TabletFunctionality/paginationLerping';

// import isPositiveInteger from '../../../common/SceneModelGeneral/isPositiveinteger';

class SceneModelGeneral extends Component {
  constructor(props) {
    super(props);
    this.loader = {
      EN: 'Loading...',
      RO: 'Se incarca...',
      modelSize: 0.09,
      modelCoordsX: 2.7,
      modelCoordsY: 0.27,
      modelCoordsZ: -2.6,
      textScaleInVrX: 1,
      textScaleInVrY: 1,
      textScaleInVrZ: 1,

      name: 'loader'
    };
    this.lang = props.lang;
    this.containerRef = React.createRef();

    this.state = {
      progress: true,
      loadedFlag: false,
      percent: 0,
      stateFlag: false,
      modelPosition: [],
      modelName: '',
      language: 5,
      flagFullscreen: false,
      loadingType: props.type,
      chemReaction: false
    };
    this.containerRef = React.createRef();
    const imageBg = require('../../../assets/img/gradientScene.jpeg');
    this.clock = new THREE.Clock();
    this.scene = new THREE.Scene();
    this.scene.background = this.backgroundTexture;
    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    );
    this.positionForCamera = [];
    this.camera.position.set(0, 0.8, 2.2);
    this.scene.add(this.camera);

    const texture = new THREE.TextureLoader().load(imageBg);
    // TEXT LOADING

    this.scene.background = texture;
    this.modelPosZ = 0;
    this.modelPosY = 0;
    this.modelPosX = 0;
    this.lineLength = 0;
    this.renderer = new THREE.WebGL1Renderer({ antialias: true, alpha: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.ambientLight = new THREE.HemisphereLight(0xffffff);
    this.scene.add(this.ambientLight);
    DirectionalLightSet(this);

    this.start_x;
    this.start_y;

    roundedSquare(this.scene, props);

    this.raycaster = new THREE.Raycaster();
    this.raycasterFire = new THREE.Raycaster();
    this.workingMatrix = new THREE.Matrix4();
    this.workingVector = new THREE.Vector3();
    this.origin = new THREE.Vector3();

    this.dolly = new THREE.Object3D();
    this.dolly.position.z = 5;
    this.scene.add(this.dolly);
    this.dummyCam = new THREE.Object3D();
    this.camera.add(this.dummyCam);

    const xInstructionCoord = this.props.name?.controllerCoords?.x
      ? this.props.name.controllerCoords.x
      : 0.7;
    const yInstructionCoord = this.props.name?.controllerCoords?.y
      ? this.props.name.controllerCoords.y
      : 0.5;
    const zInstructionCoord = this.props.name?.controllerCoords?.z
      ? this.props.name.controllerCoords.z
      : -1.9;

    this.ControllerInstruction = new ControllerInstruction({
      x: xInstructionCoord,
      y: yInstructionCoord,
      z: zInstructionCoord,
      scaleX: 0.25,
      scaleY: 0.2,
      scaleZ: 0.15,
      rotateY: 0.001
    });
    this.ControllerInstruction.bindInstructionToScene(this.scene);
    this.colorSetted = false;
    this.coolerSpiral = false;
    // const instructionIndex = this.dolly.children.findIndex(
    //   (child) => child.name === 'instructionPanel'
    // );

    // this.dolly.children[instructionIndex].position.set(0, 0, -1);

    this.model;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();
    this.controls.maxPolarAngle = Math.PI * 0.5;
    this.controls.minDistance = 1;
    this.controls.maxDistance = 10;

    this.loadedMusicBar = true;

    this.scene.add(new THREE.AmbientLight(0x404040));

    this.composer = new EffectComposer(this.renderer);
    this.composer.addPass(new RenderPass(this.scene, this.camera));
    this.composer.addPass(
      new UnrealBloomPass({ x: 1024, y: 1024 }, 2.0, 0.0, 0.75)
    );

    this.renderer.setAnimationLoop(this.render.bind(this));

    this.initScene();
    this.setupVr(this.scene);
  }

  random(min, max) {
    return Math.random() * (max - min) + min;
  }

  initScene() {
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    } else {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    }
    this.loadModel(this.room);
    this.loadEnvironment(this.room);
  }

  loadModel(room) {
    const draco = new DRACOLoader();

    const self = this;
    const loader = new GLTFLoader();

    const spiral = new THREE.CatmullRomCurve3([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(1, 0.5, 1),
      new THREE.Vector3(2, 1, 0.5),
      new THREE.Vector3(2, 1.5, -0.5),
      new THREE.Vector3(1, 2, -1),
      new THREE.Vector3(0, 2.5, 0),
      new THREE.Vector3(1, 3, 1),
      new THREE.Vector3(2, 3.5, 0.5),
      new THREE.Vector3(2, 4, -0.5),
      new THREE.Vector3(1, 4.5, -1),
      new THREE.Vector3(0, 5, 0),
      new THREE.Vector3(1, 5.5, 1),
      new THREE.Vector3(2, 6, 0.5),
      new THREE.Vector3(2, 6.5, -0.5),
      new THREE.Vector3(1, 7, -1),
      new THREE.Vector3(0, 7.5, 0),
      new THREE.Vector3(1, 8, 1),
      new THREE.Vector3(2, 8.5, 0.5),
      new THREE.Vector3(2, 9, -0.5),
      new THREE.Vector3(1, 9.5, -1),
      new THREE.Vector3(0, 10, 0)
    ]);
    const pointsSpiral = spiral.getPoints(300);
    const materialCurve = new THREE.LineBasicMaterial({
      color: 0xc7720a,
      linewidth: 3
    });
    const geometrySpiral = new THREE.BufferGeometry().setFromPoints(
      pointsSpiral
    );
    this.spiralCurve = new THREE.Line(geometrySpiral, materialCurve);

    this.spiralCurve.scale.x = 0.4;
    this.spiralCurve.scale.y = 0.02;
    this.spiralCurve.scale.z = 0.4;
    this.spiralCurve.position.z = -0.03;
    this.spiralCurve.position.y = -0.991;
    this.spiralCurve.position.x = -0.3;
    this.spiralCurve.name = 'copperSpiral';

    const model =
      'https://s3.eu-central-1.amazonaws.com/lectio.app/models/oxygenHydrocarbur.glb';
    draco.setDecoderConfig({ type: 'js' });
    draco.setDecoderPath(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/draco/'
    );
    loader.setDRACOLoader(draco);
    loader.load(
      model,
      function (gltf) {
        gltf.scene.children[0].position.set(0, 0, 0);
        room.add(gltf.scene);
        self.model = gltf.scene;
        self.animationsOfSphere = gltf?.animations;
        if (gltf?.animations.length) {
          self.mixer = new THREE.AnimationMixer(gltf.scene);
          const clips = gltf?.animations;
          clips.forEach(function (clip) {
            if (
              clip.name !== 'Action' &&
              clip.name !== 'Action.001' &&
              clip.name !== 'Action.002' &&
              clip.name !== 'Action.003' &&
              clip.name !== 'Action.004'
            ) {
              self.composer.render();
              self?.mixer?.clipAction(clip)?.play();
            }
          });
        }
        self.cube = self.model.getObjectByName('Cube002');
        self.cube.add(self.spiralCurve);

        self.setState({ ...self.state, progress: false });
      },
      function (xhr) {
        self.setState({
          ...self.state,
          percent: (xhr.loaded / xhr.total) * 100
        });
      },
      function (err) {
        console.log('error========>', err);
      }
    );
  }

  async loadText(room, text) {
    const loader = new FontLoader();
    const self = this;
    this.textArray = [];
    await loader.load(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/helvetiker_regular.typeface.json',
      function (font) {
        var textGeometry = new TextGeometry(text[self.props.lang], {
          font: font,
          size: text.modelSize ? text.modelSize : 0.1,
          height: text.modelHeight ? text.modelHeight : 0.03
        });

        var textMaterial = new THREE.MeshPhongMaterial({
          color: 0xffffff,
          specular: 0xffffff
        });

        var mesh = new THREE.Mesh(textGeometry, textMaterial);

        room.add(mesh);
        mesh.visible = false;
        if (text.name === 'loader') {
          mesh.name = text.name;
        } else {
          mesh.name = text.name;
          self.textArray.push(mesh);
        }
        if (text.modelCoordsX && text.modelCoordsY && text.modelCoordsZ) {
          mesh.position.set(
            text.modelCoordsX,
            text.modelCoordsY,
            text.modelCoordsZ
          );
        } else {
          mesh.position.set(0, 0, 0);
        }
        if (text.modelRotationX && text.modelRotationY && text.modelRotationZ) {
          mesh.rotation.set(
            text.modelRotationX,
            text.modelRotationY,
            text.modelRotationZ
          );
        } else {
          mesh.rotation.set(0, 0, 0);
        }
      }

      // onError callback
    );
  }

  setupVr(scene) {
    this.renderer.xr.enabled = true;
    const self = this;
    this.controllers = this.buildControllers(scene);
    function onConnected(e) {
      this.gamepad = e.data.gamepad;
      self.updateControllers({
        right: { trigger: true, squeeze: true, side: e.data.handedness },
        left: { trigger: true, squeeze: true, side: e.data.handedness }
      });
    }

    const controllers = [
      this.renderer.xr.getController(0),
      this.renderer.xr.getController(1)
    ];

    controllers.map((element) => {
      element.addEventListener('connected', onConnected);
    });
  }
  loadEnvironment(room) {
    // this.model.position.set(0.7, 0.27, -1.6);
    // this.model.scale.x = 0.45;
    // this.model.scale.y = 0.45;
    // this.model.scale.z = 0.45;
    // this.cube.position.y += 0.3;
    // this.cube.position.z -= 0.285;
    // this.cube.position.x += 0.305;
    // this.scene.getObjectByName('loader').visible = true;
    // this.scene.getObjectByName('loader').position.set(0.6, 0.27, -1.55);

    const draco = new DRACOLoader();

    const self = this;
    const loader = new GLTFLoader();

    draco.setDecoderConfig({ type: 'js' });
    const environment = require('../../../assets/EnvironmentWithoutTable.glb');
    draco.setDecoderPath(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/draco/'
    );

    loader.setDRACOLoader(draco);
    loader.load(
      environment,
      function (gltf) {
        // self.scene.getObjectByName('loader').visible = false;
        room.add(gltf.scene);
        self.environment = gltf.scene;
        gltf.scene.position.set(0, 1.3, 0);
        gltf.scene.name = 'environment';

        if (gltf?.animations.length) {
          self.mixer = new THREE.AnimationMixer(gltf.scene);
          const clips = gltf?.animations;
          clips.forEach(function (clip) {
            self?.mixer?.clipAction(clip)?.play();
          });
        }
        gltf.scene.visible = false;
      },
      function (xhr) {
        self.setState({
          ...self.state,
          percent: (xhr.loaded / xhr.total) * 100
        });
      },
      function (err) {
        console.log('error========>', err);
      }
    );
  }

  updateControllers(info) {
    const self = this;

    function onSelectStart() {
      Pagination(this, self);
      // if (!self.sound?.isPlaying) {
      if (self.room?.getObjectByName('1')) {
        playInstruction(
          this,
          self,
          coursesData[2].topics[17].meshes[
            coursesData[2].topics[17].meshes.length - 3
          ]
        );
      } else if (self.room?.getObjectByName('2')) {
        playInstruction(
          this,
          self,
          coursesData[2].topics[17].meshes[
            coursesData[2].topics[17].meshes.length - 2
          ]
        );
      } else if (self.room?.getObjectByName('3')) {
        playInstruction(
          this,
          self,
          coursesData[2].topics[17].meshes[
            coursesData[2].topics[17].meshes.length - 1
          ]
        );
      }
      // }
      if (this.userData.selected && !this.userData.attachedObject) {
        if (
          this.userData.selected.name == 'tabletSoundOn' &&
          self.props.description[self.props.lang] &&
          self.loadedMusicBar
        ) {
          textToSpeech(self);
          return 0;
        } else if (this.userData.selected.name == 'tabletSoundOff') {
          if (self.sound?.isPlaying) {
            self.sound.pause();
            self.loadedMusicBar = true;
          }
          return 0;
        }
      }
      this.userData.squeezePressed = true;
      if (
        this.userData.selected &&
        !this.userData.attachedObject &&
        !self.posModelX &&
        !self.posModelY &&
        !self.posModelZ &&
        this.userData.selected.name !== 'tabletRefresh' &&
        !self.stopAttaching
      ) {
        self.posModelX = this.userData.selected.position.x;
        self.posModelY = this.userData.selected.position.y;
        self.posModelZ = this.userData.selected.position.z;
        self.scaleModelX = this.userData.selected.scale.x;
        self.scaleModelY = this.userData.selected.scale.y;
        self.scaleModelZ = this.userData.selected.scale.z;

        self.rotationModelX = this.userData.selected.rotation.x;
        self.rotationModelY = this.userData.selected.rotation.y;
        self.rotationModelZ = this.userData.selected.rotation.z;
        this.userData.attachedObject = this.userData.selected;
        this.attach(this.userData.selected);
        self.setState({
          modelName: this.userData.selected.name,
          executed: true
        });
        // }
      }
    }

    function onSelectEnd() {
      // this.children[0].scale.z = 0;
      // this.userData.selectPressed = false;
      // this.userData.selected = undefined;

      this.userData.squeezePressed = false;

      if (this.userData.attachedObject !== undefined) {
        this.remove(this.userData.attachedObject);

        self.model.add(this.userData.attachedObject);
        self.model
          .getObjectByName(self.state.modelName)
          .position.set(self.posModelX, self.posModelY, self.posModelZ);
        self.model
          .getObjectByName(self.state.modelName)
          .rotation.set(
            self.rotationModelX,
            self.rotationModelY,
            self.rotationModelZ
          );

        self.model.getObjectByName(self.state.modelName).scale.x =
          self.scaleModelX;
        self.model.getObjectByName(self.state.modelName).scale.y =
          self.scaleModelY;
        self.model.getObjectByName(self.state.modelName).scale.z =
          self.scaleModelZ;
        this.userData.attachedObject = undefined;
        this.userData.selected = undefined;
        this.children[1].visible = false;
        this.children[0].scale.z = 10;
        self.posModelX = undefined;
        self.posModelY = undefined;
        self.posModelZ = undefined;
        self.rotationModelX = undefined;
        self.rotationModelY = undefined;
        self.rotationModelZ = undefined;
        if (self.model?.getObjectByName('copperSpiral')) {
          for (let i = 1; i < 4; i++) {
            if (i != 1) {
              self.scene.getObjectByName(`${i}`).visible = false;
            } else {
              self.scene.getObjectByName(`${i}`).visible = true;
            }
          }
          self.model
            ?.getObjectByName('copperSpiral')
            .material.color.setHex(0xc7720a);
        }
      }
      self.colorSetted = false;
      self.coolerSpiral = false;
    }

    function onSqueezeStart() {}

    function onSqueezeEnd() {}

    function onDisconnected() {
      const index = this.userData.index;

      if (self.controllers) {
        const obj = index == 0 ? self.controllers.right : self.controllers.left;

        if (obj) {
          if (obj.controller) {
            const controller = obj.controller;
            while (controller.children.length > 0)
              controller.remove(controller.children[0]);
            self.scene.remove(controller);
          }
          if (obj.grip) self.scene.remove(obj.grip);
        }
      }
    }

    if (info.right !== undefined && info.right.side === 'right') {
      const right = this.renderer.xr.getController(0);

      let trigger = false,
        squeeze = false;

      Object.keys(info.right).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
        if (key.indexOf('squeeze') != -1) squeeze = true;
      });

      if (trigger) {
        right.addEventListener('selectstart', onSelectStart);
        right.addEventListener('selectend', onSelectEnd);
      }

      if (squeeze) {
        right.addEventListener('squeezestart', onSqueezeStart);
        right.addEventListener('squeezeend', onSqueezeEnd);
      }

      right.addEventListener('disconnected', onDisconnected);
    }

    if (info.left !== undefined && info.left.side === 'left') {
      const left = this.renderer.xr.getController(1);

      let trigger = false,
        squeeze = false;

      Object.keys(info.left).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
        if (key.indexOf('squeeze') != -1) squeeze = true;
      });

      if (trigger) {
        left.addEventListener('selectstart', onSelectStart);
        left.addEventListener('selectend', onSelectEnd);
      }

      if (squeeze) {
        left.addEventListener('squeezestart', onSqueezeStart);
        left.addEventListener('squeezeend', onSqueezeEnd);
      }

      left.addEventListener('disconnected', onDisconnected);
    }
  }

  buildControllers() {
    const controllerModelFactory = new XRControllerModelFactory();
    const geometrySphere = new THREE.IcosahedronBufferGeometry(this.radius, 2);

    const sphere = new THREE.Mesh(
      geometrySphere,
      new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.BackSide })
    );
    sphere.name = 'sphereIndicator';
    sphere.scale.set(0.01, 0.01, 0.01);

    const geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, -1)
    ]);

    const line = new THREE.Line(geometry);
    line.name = 'line';
    line.scale.z = 2;
    sphere.visible = false;
    const controllers = [];

    for (let i = 0; i <= 1; i++) {
      const controller = this.renderer.xr.getController(i);

      controller.add(line.clone());
      controller.add(sphere.clone());
      controller.userData.selectPressed = false;

      this.dolly.add(controller);

      controllers.push(controller);

      const grip = this.renderer.xr.getControllerGrip(i);
      grip.add(controllerModelFactory.createControllerModel(grip));

      this.workingMatrix.identity().extractRotation(controller.matrixWorld);
      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);

      controller.children[0].scale.z = 10;

      if (i == 1) {
        this.dolly.add(grip);
      }
    }
    Tablet(this, controllers);

    return controllers;
  }
  onEnterVr(self) {
    self.model.position.set(0.7, 0.27, -1.6);
    self.model.scale.x = 0.45;
    self.model.scale.y = 0.45;
    self.model.scale.z = 0.45;
    self.cube.position.y += 0.3;
    self.cube.position.z -= 0.285;
    self.cube.position.x += 0.305;
    self.room.getObjectByName('environment').visible = true;
  }
  handleController(controller, dt, index) {
    const self = this;
    const axes = controller.gamepad?.axes;
    const speed = 0.03;
    // const spiralColorAfterFire = 0x402300;
    let a = 0;
    let b = 0;
    // let startColor = new THREE.Color(0xff8800);
    let endColor = new THREE.Color(0x402300);
    if (controller.userData.selectPressed) {
      const speed = index === 0 ? 5 : -5;
      const quaternion = this.dolly.quaternion.clone();
      this.dolly.quaternion.copy(this.camera?.quaternion);
      this.dolly.translateZ(-dt * speed);

      this.dolly.quaternion.copy(quaternion);
    }
    if (this.model) {
      this.workingMatrix.identity().extractRotation(controller.matrixWorld);

      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);

      let intersectsModel = [this.model.getObjectByName('copperSpiral')];
      if (this.tablet?.children.length > 0 && this.cube) {
        const tabletRefreshInstruction = this.tablet.getObjectByName(
          'tabletInstructionRefresh'
        ).children;
        const tabletDescription =
          this.tablet.getObjectByName('tabletDescription').children;
        intersectsModel = this.raycaster.intersectObjects(
          [
            ...this.tablet.children,
            ...tabletRefreshInstruction,
            ...tabletDescription,
            this.cube
          ],
          false
        );
      }
      if (!controller.userData.attachedObject) {
        if (
          intersectsModel.length > 0 &&
          intersectsModel[0]?.object?.visible &&
          !intersectsModel[0].object.text
        ) {
          controller.children[1].visible = true;
          controller.children[1].position.x = controller.children[0].position.x;
          controller.children[1].position.z =
            -intersectsModel[0].distance + 0.02;
          controller.children[0].scale.z = intersectsModel[0].distance;
          this.lineLength = intersectsModel[0].distance;
          controller.userData.selected = intersectsModel[0].object;
        } else {
          controller.children[0].scale.z = 10;
          controller.children[1].visible = false;
          controller.userData.selected = undefined;
        }
      }

      if (controller.userData.squeezePressed) {
        if (
          intersectsModel.length > 0 &&
          intersectsModel[0].object.visible &&
          !intersectsModel[0].object.text
        ) {
          if (intersectsModel[0]?.object?.name === 'instructionButton') {
            this.dolly.children[0].visible = false;
            return;
          }

          controller.children[0].scale.z = intersectsModel[0].distance;
          this.lineLength = intersectsModel[0].distance;
          controller.userData.selected = intersectsModel[0].object;
        } else {
          controller.children[0].scale.z = 10;
          controller.children[1].visible = false;
          controller.userData.selected = undefined;
        }

        if (controller.userData.attachedObject) {
          this.spiralAttached =
            controller.userData.attachedObject.getObjectByName('copperSpiral');
          if (this.spiralAttached) {
            this.scene.getObjectByName('1').visible = false;

            if (!this.colorSetted)
              this.scene.getObjectByName('2').visible = true;

            this.raycasterFire.ray.origin.setFromMatrixPosition(
              this.spiralAttached?.matrixWorld
            );

            if (
              colissionDetecter(
                this.spiralAttached,
                this.model?.getObjectByName('Cone')
              ) &&
              !this.colorSetted
            ) {
              // let t = clock.getElapsedTime();
              // let s = Math.sin(t * 2.0) * 0.5 + 0.5;
              this.spiralAttached?.material.color.lerp(endColor, 0.1);
              setTimeout(() => {
                this.colorSetted = true;
                this.scene.getObjectByName('2').visible = false;

                this.scene.getObjectByName('3').visible = true;
              }, '500');
              // }, '800');
            }

            if (
              self.colorSetted &&
              colissionDetecter(
                this.spiralAttached,
                this.model?.getObjectByName('Cylinder002')
              )
            ) {
              // setTimeout(() => {
              //   this.spiralAttached?.material.color.setHex(0xbf3802);
              // }, '500');

              if (self.animationsOfSphere?.length && !this.coolerSpiral) {
                // let t = clock.getElapsedTime();
                // let s = Math.sin(t * 1.1) * 0.5 + 0.5;
                this.spiralAttached?.material.color.setHex(0xff2200);
                const clips = this.animationsOfSphere;
                for (let i = 0; i < clips.length; i++) {
                  self?.mixer?.clipAction(clips[i])?.play();
                  setTimeout(() => {
                    if (i >= 10 && i <= 16)
                      self?.mixer?.clipAction(clips[i])?.stop();
                  }, '1800');
                  this.coolerSpiral = true;
                }

                if (!self.coolerSpiral) {
                  this.coolerSpiral = false;
                  this.colorSetted = false;
                }
              }
            }
          }

          controller.children[1].visible = true;
          controller.children[0].scale.z = this.lineLength;
        }
      }
    }
    //=================> movement <=================

    if (axes?.length) {
      if (axes[2] != 0) {
        a += axes[2];
      }
      if (axes[3] != 0) {
        b += axes[3];
      }
    }

    const quaternion = this.dolly.quaternion.clone();

    if (a !== 0 || b !== 0) {
      this.dolly.quaternion.copy(this.camera?.quaternion);
      this.dolly.translateZ(b * speed);
      this.dolly.translateX(a * speed);
      this.dolly.translateY(b * speed);

      this.dolly.position.y = -0.5;
      this.dolly.quaternion.copy(quaternion);
    }
  }

  componentDidUpdate(prevProps) {
    const self = this;

    //Changing text mesh on change language
    if (this.props.lang != prevProps.lang || this.props.lang === 'RO') {
      createTopicName(this.props, this.scene);
    }
    if (this.vrButton?.vrButton && this.model) {
      this.vrButton.vrButton.disabled = false;
    }
    if (this.props.lang != prevProps.lang || !self.state.loadedFlag) {
      coursesData[2].topics[17].meshes.map((element) => {
        this.room.remove(this.room.getObjectByName('text'));
        this.loadText(this.room, element);
        self.setState({ ...self.state, loadedFlag: true });
      });
    }
  }

  componentDidMount() {
    this.containerRef?.current?.appendChild(this.renderer.domElement);
    this.loadText(this.room, loader);
    if (!document.getElementById('vrButton'))
      this.vrButton = new VRButton(this.renderer);

    const self = this;
    createTopicName(this.props, this.scene);

    // Adding text mesh on mount component

    //When user turn on the VR mode.
    self.renderer.xr.addEventListener('sessionstart', function () {
      SpotLightSet(self);
      self.scene.getObjectByName('instructionPanel').visible = true;

      self.vrButton.vrButton.disabled = false;

      self.textArray.forEach((element) => {
        element.material.color.setHex(0x00d6d9);
      });
      self.scene.getObjectByName('1').visible = true;

      self.scene.remove(self.directionalLight1);
      self.scene.remove(self.directionalLight2);
      self.scene.remove(self.directionalLight3);
      self.scene.remove(self.directionalLight4);
      self.scene.remove(self.directionalLight5);
      self.scene.remove(self.directionalLight6);
      self.onEnterVr(self);
      // self.loadEnvironment(self.room);

      if (self.props.url.split('/').indexOf('fullscreen') == -1) {
        self.props.history.push(self.link);
      }
      self.dolly.add(self.camera);
      self.dolly.position.z = -0.5;
      self.dolly.position.y = -0.5;
      self.dolly.position.x = 0.4;
    });

    //When user turn off the VR mode.
    self.renderer.xr.addEventListener('sessionend', function () {
      if (self.sound?.isPlaying) self.sound.pause();

      self.vrButton.vrButton.disabled = false;
      self.textArray.forEach((element) => {
        element.material.visible = false;
        element.material.color.setHex(0xffffff);
      });

      self.scene.getObjectByName('instructionPanel').visible = false;
      self.model.position.set(0, 0, 0);
      self.cube.position.set(0.4, 0.25, 0);
      self.room.getObjectByName('environment').visible = false;
      DirectionalLightSet(self);
      self.scene.remove(self.spotLight);
      self.scene.remove(self.spotLight2);
      self.scene.remove(self.centerPartSpotLightTarger);
      self.scene.remove(self.directionalLight);

      self.dolly.remove(self.camera);
      self.camera.position.set(0.2, 0.5, 0.8);
      let adminPreviewContainer = document.getElementById('previewContainer');
      if (self.props.onsessionendAdmin && adminPreviewContainer) {
        adminPreviewContainer.appendChild(self.vrButton.vrButton);
      }
    });
    window.addEventListener('resize', this.resize());
  }

  componentWillUnmount() {
    this.containerRef?.current?.removeChild(this.renderer.domElement);
    window.removeEventListener('resize', this.resize);
    !this.props.isPreview && this?.vrButton?.hideEnterVR();

    Object.keys(this).forEach((key) => {
      // Recursively call dispose() if possible.
      if (
        typeof this[key]?.dispose === 'function' &&
        this[key].type != 'Scene'
      ) {
        this[key].dispose();
      }
      // Remove any reference.
      this[key] = null;
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.room.name = 'room';
    this.lang = nextProps.lang;

    //Replacing text from scene after translate

    //Setting coordinates for room (model)
    if (
      this.props.roomCoordsZ &&
      this.props.roomCoordsX &&
      this.props.roomCoordsY
    ) {
      this.room.position.set(
        this.props.roomCoordsX,
        this.props.roomCoordsY,
        this.props.roomCoordsZ
      );
    } else {
      this.room.position.set(0, 0, 0);
    }
    //Setting rotation coords for rotation (model)
    if (
      this.props.roomRotationZ &&
      this.props.roomRotationX &&
      this.props.roomRotationY
    ) {
      this.room.rotation.set(
        this.props.roomRotationX,
        this.props.roomRotationY,
        this.props.roomRotationZ
      );
    }
    //Setting scaling for room (model)
    if (
      this.props.roomScaleZ &&
      this.props.roomScaleX &&
      this.props.roomScaleY
    ) {
      this.room.scale.set(
        this.props.roomScaleX,
        this.props.roomScaleY,
        this.props.roomScaleZ
      );
    }
    //Setting camera position in scene
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.camera.position.set(
        this.props.cameraCoordsX,
        this.props.cameraCoordsY,
        this.props.cameraCoordsZ
      );
    }

    //Setting coord for text in scene

    this.scene.add(this.room);

    //Setting loading
    if (nextProps.type !== this.state.loadingType) {
      this.setState({
        ...this.state,
        loadingType: nextProps.type
      });
      return true;
    }

    if (this.state.progress !== nextState) {
      return true;
    }
    if (nextProps.z !== this.props.z) {
      return true;
    } else {
      return false;
    }
  }

  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  render() {
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    const dt = this.clock.getDelta();
    pagintationLerping(this);

    if (this.renderer.xr.isPresenting && this.positionForCamera.length < 3) {
      this.positionForCamera.push(this.camera.position);
      this.dolly.position.y = -this.positionForCamera[0].y;
    }

    if (this.controllers) {
      const self = this;
      this.controllers.forEach((controller, index) => {
        self.handleController(controller, dt, index, this.clock);
      });
    }
    this.textArray?.forEach((element) => {
      element.lookAt(this.camera.position);
    });
    this.mixer?.update(dt);
    return (
      <div ref={this.containerRef} className={`${styles.sceneContainer}`}>
        {this.props.isPreviewContainer && this.state.flagFullscreen ? (
          <Link
            onClick={this.updateState}
            className={
              'px-2 absolute py-2 bg-gray-500 border-2 border-white rounded-lg text-center hover:bg-gray-600 hover hover:border-gray-100 top-5 right-5 text-white '
            }
          >
            {' '}
            <svg
              className={'self-end fill-current h-6 w-6 '}
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Exit Fullscreen</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </Link>
        ) : null}

        {this.state.progress && (
          <div
            className={
              'w-full h-full bg-black flex items-center flex-col justify-center absolute opacity-50'
            }
          >
            <p className="text-white opacity-100 font-gothic text-lg mb-2 text-center">
              Loading...
            </p>
            <div className="w-32 h-1 opacity-100 rounded-lg bg-slate-600 ">
              {this.state.percent != 0 ? (
                <div
                  style={{ width: Math.ceil(this.state.percent) + '%' }}
                  className={' rounded-full opacitiy-90 h-full bg-cyan-500'}
                ></div>
              ) : (
                <div className={'w-full h-full bg-gray-500'}></div>
              )}
            </div>
          </div>
        )}
      </div>
    );
    // }
  }
}

export default withRouter(SceneModelGeneral);
