import * as THREE from 'three';

export const SpotLightSet = (self) => {
  const rightPartSpotLightTarger = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.5, 5),
    new THREE.MeshBasicMaterial({ color: 0x00ff00 })
  );
  self.scene.add(rightPartSpotLightTarger);
  rightPartSpotLightTarger.position.set(0, 1.8, 2.2);
  const rightSpotLightMesh1 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.5, 5),
    new THREE.MeshBasicMaterial({ color: 0xfffff })
  );
  self.scene.add(rightSpotLightMesh1);
  rightSpotLightMesh1.position.set(0.05, 3.3, 2.8);
  self.spotLight = new THREE.SpotLight(0xffffff, 10);
  self.spotLight.castShadow = true;
  self.scene.add(self.spotLight);
  self.spotLight.position.copy(rightSpotLightMesh1.position);
  self.spotLight.target = rightPartSpotLightTarger;
  //center sportlight
  const centerPartSpotLightTarger = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.5, 5),
    new THREE.MeshBasicMaterial({ color: 0x00ff00 })
  );
  self.scene.add(centerPartSpotLightTarger);
  centerPartSpotLightTarger.position.set(0, 1.3, 0);

  self.spotLight2 = new THREE.SpotLight(0xffffff, 10);
  self.spotLight2.castShadow = true;
  self.scene.add(self.spotLight2);
  self.spotLight2.position.set(
    rightSpotLightMesh1.position.x,
    rightSpotLightMesh1.position.y,
    rightSpotLightMesh1.position.z - 3.3
  );
  self.spotLight2.target = centerPartSpotLightTarger;
  self.spotLight2.target.position.copy(centerPartSpotLightTarger.position);

  centerPartSpotLightTarger.visible = false;
  rightSpotLightMesh1.visible = false;
  rightPartSpotLightTarger.visible = false;

  self.directionalLight = new THREE.DirectionalLight(0xffffff, 2);
  self.scene.add(self.directionalLight);
  const targetObject = new THREE.Object3D();
  self.scene.add(targetObject);
  self.directionalLight.target = targetObject;
  // targetObject.position.set()
  self.directionalLight.position.set(0, -0.5, 0);
  self.directionalLight.target.position.set(0, 10, 0);
  self.lightSetted = true;
};
