import * as THREE from 'three';

import { socket } from '../socketConnection';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

const memberConnection = (scene, camera, dolly, membersIdsInScene) => {
  const draco = new DRACOLoader();
  const loader = new GLTFLoader();
  let attachedModel;
  let controllerToAttach;
  draco.setDecoderConfig({ type: 'js' });

  const memberModelGLB = require('../../assets/userFace.glb');
  const leftControllerGLB = require('../../assets/leftOculusController.glb');
  const rightControllerGLB = require('../../assets/rightOculusController.glb');

  draco.setDecoderPath(
    'https://s3.eu-central-1.amazonaws.com/lectio.app/draco/'
  );
  loader.setDRACOLoader(draco);

  const onConnect = (memberBEInitialStats) => {
    if (socket.id === memberBEInitialStats.id) {
      let id = memberBEInitialStats.id;
      scene.mainMemberId = id;
      let roomName = memberBEInitialStats.roomName;

      socket.emit('storeMembers', {
        id,
        pb: { x: 0, y: 0, z: 0 },
        name: id,
        color: memberBEInitialStats.color,
        roomName
      });
    }
  };

  const uploadMemberModel = (model, memberBEDataId, color = 0xff00ff) => {
    loader.load(model, function (gltf) {
      const memberModel = gltf.scene;
      scene.add(memberModel);
      if (memberBEDataId === scene.mainMemberId) {
        scene.mainMember = memberModel;
      }
      memberModel.memberId = memberBEDataId;
      scene.members = [...scene.members, memberModel];
      memberModel.name = 'memberModel';
      memberModel.controllers = [];
      if (scene.mainMemberId !== memberBEDataId) {
        uploadLeftController(
          leftControllerGLB,
          memberModel,
          color,
          memberBEDataId
        );
      }
    });
  };

  const uploadLeftController = async (model, memberModel, color, name) => {
    await loader.load(model, function (gltf) {
      const geometrySphere = new THREE.IcosahedronBufferGeometry(1, 2);
      const materialForController = new THREE.MeshBasicMaterial({
        color: Number('0x' + color),
        side: THREE.BackSide
      });
      const sphere = new THREE.Mesh(geometrySphere, materialForController);
      sphere.name = 'sphereIndicator';
      sphere.scale.set(0.01, 0.01, 0.01);

      const geometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, 0, -1)
      ]);

      const line = new THREE.Line(geometry, materialForController);
      line.name = 'line';
      line.scale.z = 2;
      sphere.visible = false;
      const leftController = gltf.scene;
      leftController.name = 'left' + name;
      leftController.add(sphere.clone());
      leftController.add(line.clone());

      leftController.children[0].children[0].children[0].material =
        materialForController;
      leftController.children = leftController.children.reverse();
      scene.controllers = [...scene.controllers, leftController];
      scene.add(leftController);
      memberModel.controllers = [...memberModel.controllers, leftController];
      uploadRightController(rightControllerGLB, memberModel, color, name);
    });
  };

  const uploadRightController = async (model, memberModel, color, name) => {
    await loader.load(model, function (gltf) {
      const geometrySphere = new THREE.IcosahedronBufferGeometry(1, 2);
      const materialForController = new THREE.MeshBasicMaterial({
        color: Number('0x' + color),
        side: THREE.BackSide
      });
      const sphere = new THREE.Mesh(geometrySphere, materialForController);
      sphere.name = 'sphereIndicator';
      sphere.scale.set(0.01, 0.01, 0.01);

      const geometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, 0, -1)
      ]);

      const line = new THREE.Line(geometry, materialForController);
      line.name = 'line';
      line.scale.z = 2;
      sphere.visible = false;

      const rightController = gltf.scene;
      rightController.name = 'right' + name;
      rightController.add(sphere.clone());
      rightController.add(line.clone());
      rightController.children[1].children[0].children[0].material =
        materialForController;

      rightController.children = rightController.children.reverse();
      scene.controllers = [...scene.controllers, rightController];

      memberModel.controllers = [...memberModel.controllers, rightController];
      scene.add(rightController);
    });
  };

  const hideMainMemberAvatar = (memberToHide) => {
    if (memberToHide.visible) {
      memberToHide.visible = false;
      if (memberToHide.controllers[0]) {
        memberToHide.controllers[0].visible = false;
        memberToHide.controllers[1].visible = false;
      }
    }
  };

  const removeMembersFromScene = (memberToRemove, fromMainMemberLeaveRoom) => {
    scene.remove(memberToRemove);
    camera.remove(memberToRemove);
    scene.remove(memberToRemove.controllers[0], memberToRemove.controllers[1]);
    if (fromMainMemberLeaveRoom) {
      membersIdsInScene = [];
      scene.members = [];
    } else {
      membersIdsInScene.splice(
        membersIdsInScene.indexOf(memberToRemove.memberId),
        1
      );

      scene.members.splice(scene.members.indexOf(memberToRemove.memberId), 1);
    }
  };

  const deleteMember = (id) => {
    let memberToRemove;
    if (id === scene.mainMemberId) {
      scene.members.forEach((memberAvatar) => {
        removeMembersFromScene(memberAvatar, true);
      });
    } else {
      memberToRemove = scene.members.find(function (member) {
        if (member.memberId === id) {
          return member;
        }
      });
    }
    if (memberToRemove) {
      removeMembersFromScene(memberToRemove);
    }
  };

  const createMember = (BEMembersData) => {
    if (BEMembersData.length !== membersIdsInScene.length) {
      BEMembersData.forEach((memberBEData) => {
        if (!membersIdsInScene.includes(memberBEData.id)) {
          uploadMemberModel(
            memberModelGLB,
            memberBEData.id,
            memberBEData.color
          );
          membersIdsInScene.push(memberBEData.id);
        }
      });
    } else if (scene.members && BEMembersData) {
      scene.members.forEach((sceneMember) => {
        if (sceneMember.memberId === scene.mainMemberId) {
          hideMainMemberAvatar(sceneMember);
          return;
        }
      });
      BEMembersData.forEach((BEclassMember) => {
        scene.members.forEach((sceneMember) => {
          if (BEclassMember.id !== socket.id) {
            if (BEclassMember.id === sceneMember.memberId) {
              sceneMember?.position.set(
                BEclassMember.position.x,
                BEclassMember.position.y,
                BEclassMember.position.z
              );
              sceneMember?.rotation.set(
                BEclassMember.pb.x,
                BEclassMember.pb.y,
                BEclassMember.pb.z
              );

              if (BEclassMember.leftController && sceneMember?.controllers) {
                sceneMember.controllers[0]?.position.set(
                  BEclassMember.leftController.position.x,
                  BEclassMember.leftController.position.y,
                  BEclassMember.leftController.position.z
                );
                sceneMember?.controllers[0]?.rotation.set(
                  BEclassMember.leftController.rotation.x,
                  BEclassMember.leftController.rotation.y,
                  BEclassMember.leftController.rotation.z
                );
              }
              if (BEclassMember.rightController && sceneMember?.controllers) {
                sceneMember?.controllers[1]?.position.set(
                  BEclassMember.rightController.position.x,
                  BEclassMember.rightController.position.y,
                  BEclassMember.rightController.position.z
                );
                sceneMember?.controllers[1]?.rotation.set(
                  BEclassMember.rightController.rotation.x,
                  BEclassMember.rightController.rotation.y,
                  BEclassMember.rightController.rotation.z
                );
              }
              if (
                !controllerToAttach &&
                BEclassMember.attachedObject?.controllerThatHoldModel
              ) {
                controllerToAttach = scene.getObjectByName(
                  BEclassMember.attachedObject?.controllerThatHoldModel
                );
              }

              if (BEclassMember.attachedObject?.name && controllerToAttach) {
                if (!attachedModel) {
                  attachedModel = scene.getObjectByName(
                    BEclassMember.attachedObject?.name
                  );
                }
                try {
                  controllerToAttach.attach(attachedModel);
                } catch (e) {
                  console.log(
                    'there appeared to be some issues with attaching',
                    e
                  );
                }
                controllerToAttach.children[1].visible = true;
                scene.modelWasAttached = true;
                controllerToAttach.children[0].scale.z =
                  BEclassMember.attachedObject.lineLength;
                sceneMember.attachedModel = attachedModel;
              } else {
                const attachedMeshToModel = controllerToAttach?.getObjectByName(
                  BEclassMember.attachedObject?.modelThatWasAttached
                );
                if (
                  controllerToAttach &&
                  BEclassMember.attachedObject?.modelThatWasAttached &&
                  attachedMeshToModel &&
                  BEclassMember.attachedObject?.modelThatWasAttachedCoords
                ) {
                  controllerToAttach.remove(attachedMeshToModel);
                  const model = scene.getObjectByName(
                    BEclassMember.attachedObject.model
                  );
                  const { position, rotation } =
                    BEclassMember.attachedObject.modelThatWasAttachedCoords;
                  model?.add(attachedMeshToModel);
                  attachedMeshToModel.position.set(
                    position.x,
                    position.y,
                    position.z
                  );
                  attachedMeshToModel.rotation.set(
                    rotation.x,
                    rotation.y,
                    rotation.z
                  );
                  attachedModel = undefined;
                  controllerToAttach = undefined;
                  scene.modelWasAttached = false;
                }
              }
            } else if (
              scene.mainMemberId === sceneMember.memberId &&
              !camera.getObjectByProperty('memberId', sceneMember.memberId)
            ) {
              camera.add(sceneMember);
            }
          }
        });
      });
    }
  };
  socket.on('memberData', (members) => {
    createMember(members);
  });
  socket.on('deleteMember', deleteMember);
  socket.on('setId', onConnect);
};

export default memberConnection;
