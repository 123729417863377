import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { menuItems } from './../assets/mockData/mockData';
import { useAppContext } from '../context/AppProvider';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example({ data }) {
  const { store, logOutAction } = useAppContext();
  const [showAdmin, setShowAdmin] = useState(false);
  const { selectedLanguage, user } = store;

  useEffect(() => {
    if (user.userId) {
      if (user.role === 'superAdmin' || user.role === 'school') {
        setShowAdmin(true);
      }
    }
  }, [user]);

  const navigate = useHistory();

  const loginOut = () => {
    logOutAction();
    navigate.push('/');
  };

  return (
    <div>
      <Menu as="div" className="z-10 relative inline-block text-left">
        <div>
          <Menu.Button className="flex flex-col justify-center rounded-full   border w-12 h-12 border-gray-300 shadow-sm  text-center pt-2  bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 ">
            <h1 className="mx-auto ">
              {data.firstname &&
                data.firstname[0].toUpperCase() +
                  data.lastname[0].toUpperCase()}
            </h1>
            <ChevronDownIcon className="mx-auto h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute  right-0  mt-2 w-56  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="z-40">
              <Menu.Item>
                {() => (
                  <div>
                    <div
                      href="#"
                      className={classNames(
                        'text-gray-900  px-4 font-bold text-lg border-b-2 pt-1 border-gray-400 rounded-t-md'
                      )}
                    >
                      {data.firstname + ' ' + data.lastname}
                      <p className="text-sm font-light text-gray-500 ">
                        {data.email}
                      </p>
                    </div>
                  </div>
                )}
              </Menu.Item>
              <div className="h-full z-40">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={'/Profile'}
                      href="#"
                      className={classNames(
                        active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {menuItems.myAccount[selectedLanguage]}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={'/helpAndSupport'}
                      href="#"
                      className={classNames(
                        active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {menuItems.helpAndSupport[selectedLanguage]}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={'/PrivacyPolicy'}
                      className={classNames(
                        active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {menuItems.privacyPolicy[selectedLanguage]}
                    </Link>
                  )}
                </Menu.Item>
                {showAdmin && (
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={'/admin'}
                        className={classNames(
                          active
                            ? 'bg-gray-200 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        {menuItems.adminPanel[selectedLanguage]}
                      </Link>
                    )}
                  </Menu.Item>
                )}
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={loginOut}
                      className={classNames(
                        active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                        'block  w-full text-left px-4 py-2 text-sm rounded-b-md'
                      )}
                    >
                      {menuItems.logOut[selectedLanguage]}
                    </button>
                  )}
                </Menu.Item>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
