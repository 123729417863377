/* eslint-disable no-unused-vars */
import React from 'react';
import styles from './styles.module.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log('error in getDerivedStateFromError', error);

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('error in componentDidCatch', error);

    if (this.props.history.location.pathname.indexOf('error') === -1) {
      this.props.history.push('/error');
    }
  }
  componentDidUpdate() {
    if (
      this.state.hasError &&
      this.props.history.location.pathname.indexOf('error') === -1
    )
      this.setState({ hasError: false });
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className={`${styles.errorBoundaryWrapper}`}>
          <h1 className={styles.title}>
            Oops!Seems that something went wrong <br /> We are working on a fix!
          </h1>
          <p>
            You may also contact us and report this issue at{' '}
            <a className={styles.mail} href="mailto:stanislav.maslow@gmail.com">
              support@lectio.app
            </a>
            . We will fix it asap!
          </p>
          <a
            onClick={() => this.setState({ hasError: false })}
            href={'https://lectio.app/'}
            className="btn bg-rose-400 content-center flex justify-center items-center text-white text-center rounded-lg px-4 mt-5 h-10"
          >
            Reload
          </a>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
