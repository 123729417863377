import React from 'react';
import SolarSystem from '../categoryModels/astronomy/solarSystem/solarSystem';
import ElectricCircuit from '../categoryModels/physics/electricCircuit/electricCircuit';
import ChemistryBounds from '../categoryModels/chemistry/chemistryBounds';
import GeoFigures from '../categoryModels/geometry/geoFigures';
import { chemBoundsName } from './constantsModelSpecific';
const SceneModelSpecific = ({
  sceneId,
  isPreview = false,
  url,
  lang,
  previewWith = 0,
  previewHeight = 0
}) => {
  switch (sceneId) {
    case 'solarSystem':
      return <SolarSystem url={url} isPreview={isPreview} lang={lang} />;
    case 'electriccircuit':
      return <ElectricCircuit url={url} isPreview={isPreview} lang={lang} />;
    case 'chemicalBond':
      return (
        <ChemistryBounds
          url={url}
          isPreview={isPreview}
          text={chemBoundsName.meshes}
          lang={lang}
          name={chemBoundsName.name}
          previewWith={previewWith}
          previewHeight={previewHeight}
        />
      );
    case 'planeGeometry':
      return (
        <GeoFigures
          url={url}
          isPreview={isPreview}
          text={chemBoundsName.meshes}
          lang={lang}
          name={chemBoundsName.name}
          previewWith={previewWith}
          previewHeight={previewHeight}
        />
      );
    default:
      return <h1>Nothing ======</h1>;
  }
};

export default SceneModelSpecific;
