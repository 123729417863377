import * as THREE from 'three';

export const DirectionalLightSet = (self) => {
  const directionalLight1 = new THREE.DirectionalLight(0xffffff, 3);
  directionalLight1.position.set(3, 3, 3).normalize();
  const directionalLight2 = new THREE.DirectionalLight(0xffffff, 3);
  directionalLight2.position.set(5, 5, 5).normalize();
  const directionalLight3 = new THREE.DirectionalLight(0xffffff, 3);
  directionalLight3.position.set(5, 5, -5).normalize();
  const directionalLight4 = new THREE.DirectionalLight(0xffffff, 3);
  directionalLight4.position.set(-7, -5, -5).normalize();
  const directionalLight5 = new THREE.DirectionalLight(0xffffff, 3);
  directionalLight5.position.set(-5, 0, 0).normalize();
  const directionalLight6 = new THREE.DirectionalLight(0xffffff, 3);
  directionalLight6.position.set(0, -5, 0).normalize();

  self.directionalLight1 = directionalLight1;
  self.directionalLight2 = directionalLight2;
  self.directionalLight3 = directionalLight3;
  self.directionalLight4 = directionalLight4;
  self.directionalLight5 = directionalLight5;
  self.directionalLight6 = directionalLight6;

  self.scene?.add(directionalLight1);
  self.scene?.add(directionalLight2);
  self.scene?.add(directionalLight3);
  self.scene?.add(directionalLight4);
  self.scene?.add(directionalLight5);
  self.scene?.add(directionalLight6);
};
