import React from 'react';
import PrivacyPolicyParagraph from './PrivacyPolicyParagraph';
import { useAppContext } from '../../../context/AppProvider';
const PrivacyPolicyText = (props) => {
  const { headText, paragraphText } = props;

  const { store } = useAppContext();
  const { selectedLanguage } = store;

  return (
    <div className="space-y-5 ">
      <h1 className="font-tinos bg-black-900 text-3xl  pb-7 pl-5 pt-4 ">
        {headText[selectedLanguage]}
      </h1>
      {paragraphText.map((text, i) => {
        return (
          <div key={i}>
            <PrivacyPolicyParagraph key={i} text={text.textContentP} />
          </div>
        );
      })}
    </div>
  );
};
export default PrivacyPolicyText;
