// import * as THREE from 'three'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';

const downloadModel = (self) => {
  // Instantiate a exporter
  const exporter = new GLTFExporter();

  self.plusArrays.map((element) => {
    if (self.chemFormulaForExport.getObjectByName(element.name))
      self.chemFormulaForExport.getObjectByName(element.name).visible = false;
  });
  exporter.parse(
    self.chemFormulaForExport,
    // called when the gltf has been generated
    function (result) {
      if (result instanceof ArrayBuffer) {
        saveArrayBuffer(result, 'scene.glb', self);
      } else {
        const output = JSON.stringify(result, null, 2);
        saveString(output, 'scene.gltf', self);
      }
    }
  );
  self.plusArrays.map((element) => {
    if (self.chemFormulaForExport.getObjectByName(element.name))
      self.chemFormulaForExport.getObjectByName(element.name).visible = true;
  });

  const save = (blob, filename, self) => {
    self.linkForDownload.href = URL.createObjectURL(blob);
    self.linkForDownload.download = filename;
    self.linkForDownload.click();
  };

  const saveString = (text, filename, self) => {
    save(new Blob([text], { type: 'text/plain' }), filename, self);
  };

  const saveArrayBuffer = (buffer, filename, self) => {
    save(
      new Blob([buffer], { type: 'application/octet-stream' }),
      filename,
      self
    );
  };
};

export default downloadModel;
