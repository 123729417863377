export const instructions = [
  {
    EN: '  Voice  \n settings',
    RO: '  Setari \n  audio',
    modelCoordsX: 0.54,
    modelCoordsY: 0.19,
    modelCoordsZ: -0.92,
    modelRotationX: -1.59,
    modelRotationY: 0.00001,
    modelRotationZ: 0.00001,
    modelSize: 0.015,
    modelHeight: 0.001
    // attachTo: ''
  },
  {
    EN: '    Model \n    slider',
    RO: '    Glisor \nde modele',
    modelCoordsX: 0.62,
    modelCoordsY: 0.19,
    modelCoordsZ: -0.92,
    modelRotationX: -1.59,
    modelRotationY: 0.00001,
    modelRotationZ: 0.00001,
    modelSize: 0.015,
    modelHeight: 0.001
    // attachTo: ''
  }
];
export const instructionsVoiceSettings = [
  {
    EN: '  Voice  \n settings',
    RO: '  Setari \n  audio',
    modelCoordsX: 0.595,
    modelCoordsY: 0.19,
    modelCoordsZ: -0.93,
    modelRotationX: -1.59,
    modelRotationY: 0.00001,
    modelRotationZ: 0.00001,
    modelSize: 0.015,
    modelHeight: 0.001
    // attachTo: ''
  }
];
export const instructionsWithoudSlider = [
  {
    EN: '  Instruction  \n    settings',
    RO: '  Instructiuni \n      audio',
    modelCoordsX: 0.615,
    modelCoordsY: 0.19,
    modelCoordsZ: -0.915,
    modelRotationX: -1.59,
    modelRotationY: 0.00001,
    modelRotationZ: 0.00001,
    modelSize: 0.013,
    modelHeight: 0.001,
    attachTo: 'tabletInstructionRefresh'
  },
  {
    EN: 'Refresh',
    RO: 'Refresh',
    modelCoordsX: 0.55,
    modelCoordsY: 0.19,
    modelCoordsZ: -0.9,
    modelRotationX: -1.59,
    modelRotationY: 0.00001,
    modelRotationZ: 0.00001,
    modelSize: 0.011,
    modelHeight: 0.001,
    attachTo: 'tabletInstructionRefresh'
  },
  {
    EN: '  General \n      info',
    RO: 'Informatie \n generala',
    modelCoordsX: 0.58,
    modelCoordsY: 0.19,
    modelCoordsZ: -0.75,
    modelRotationX: -1.59,
    modelRotationY: 0.00001,
    modelRotationZ: 0.00001,
    modelSize: 0.013,
    modelHeight: 0.001,
    attachTo: 'tabletDescription'
  }
];
