export const chemElem = [
  { element: 'H', color: 0xffffff, x: -2.2, y: 1.7, z: -4 },
  { element: 'He', color: 0xd9ffff, x: 2.05, y: 1.7, z: -4 },
  { element: 'Li', color: 0xcc80ff, x: -2.2, y: 1.45, z: -4 },
  { element: 'Be', color: 0xc2ff00, x: -1.95, y: 1.45, z: -4 },
  { element: 'B', color: 0xffb5b5, x: 0.8, y: 1.45, z: -4 },
  { element: 'C', color: 0x909090, x: 1.05, y: 1.45, z: -4 },
  { element: 'N', color: 0x3050f8, x: 1.3, y: 1.45, z: -4 },
  { element: 'O', color: 0xff0d0d, x: 1.55, y: 1.45, z: -4 },
  { element: 'F', color: 0x90e050, x: 1.8, y: 1.45, z: -4 },
  { element: 'Ne', color: 0xb3e3f5, x: 2.05, y: 1.45, z: -4 },
  { element: 'Na', color: 0xab5cf2, x: -2.2, y: 1.2, z: -4 },
  { element: 'Mg', color: 0x8aff00, x: -1.95, y: 1.2, z: -4 },
  { element: 'Al', color: 0xbfa6a6, x: 0.8, y: 1.2, z: -4 },
  { element: 'Si', color: 0xf0c8a0, x: 1.05, y: 1.2, z: -4 },
  { element: 'P', color: 0xff8000, x: 1.3, y: 1.2, z: -4 },
  { element: 'S', color: 0xffff30, x: 1.55, y: 1.2, z: -4 },
  { element: 'Cl', color: 0x1ff01f, x: 1.8, y: 1.2, z: -4 },
  { element: 'Ar', color: 0x80d1e3, x: 2.05, y: 1.2, z: -4 },

  { element: 'K', color: 0x8f40d4, x: -2.2, y: 0.95, z: -4 },
  { element: 'Ca', color: 0x3dff00, x: -1.95, y: 0.95, z: -4 },
  { element: 'Sc', color: 0xe6e6e6, x: -1.7, y: 0.95, z: -4 },
  { element: 'Ti', color: 0xbfc2c7, x: -1.45, y: 0.95, z: -4 },
  { element: 'V', color: 0xa6a6ab, x: -1.2, y: 0.95, z: -4 },
  { element: 'Cr', color: 0x8a99c7, x: -0.95, y: 0.95, z: -4 },
  { element: 'Mn', color: 0x9c7ac7, x: -0.7, y: 0.95, z: -4 },
  { element: 'Fe', color: 0xe06633, x: -0.45, y: 0.95, z: -4 },
  { element: 'Co', color: 0xf090a0, x: -0.2, y: 0.95, z: -4 },
  { element: 'Ni', color: 0x50d050, x: 0.05, y: 0.95, z: -4 },
  { element: 'Cu', color: 0xc88033, x: 0.3, y: 0.95, z: -4 },
  { element: 'Zn', color: 0x7d80b0, x: 0.55, y: 0.95, z: -4 },
  { element: 'Ga', color: 0xc28f8f, x: 0.8, y: 0.95, z: -4 },
  { element: 'Ge', color: 0x668f8f, x: 1.05, y: 0.95, z: -4 },
  { element: 'As', color: 0xbd80e3, x: 1.3, y: 0.95, z: -4 },
  { element: 'Se', color: 0xffa100, x: 1.55, y: 0.95, z: -4 },
  { element: 'Br', color: 0xa62929, x: 1.8, y: 0.95, z: -4 },
  { element: 'Kr', color: 0x5cb8d1, x: 2.05, y: 0.95, z: -4 },

  { element: 'Rb', color: 0x702eb0, x: -2.2, y: 0.7, z: -4 },
  { element: 'Sr', color: 0x00ff00, x: -1.95, y: 0.7, z: -4 },
  { element: 'Y', color: 0x94ffff, x: -1.7, y: 0.7, z: -4 },
  { element: 'Zr', color: 0x94e0e0, x: -1.45, y: 0.7, z: -4 },
  { element: 'Nb', color: 0x73c2c9, x: -1.2, y: 0.7, z: -4 },
  { element: 'Mo', color: 0x54b5b5, x: -0.95, y: 0.7, z: -4 },
  { element: 'Tc', color: 0x3b9e9e, x: -0.7, y: 0.7, z: -4 },
  { element: 'Ru', color: 0x248f8f, x: -0.45, y: 0.7, z: -4 },
  { element: 'Rh', color: 0x0a7d8c, x: -0.2, y: 0.7, z: -4 },
  { element: 'Pd', color: 0x006985, x: 0.05, y: 0.7, z: -4 },
  { element: 'Ag', color: 0xc0c0c0, x: 0.3, y: 0.7, z: -4 },
  { element: 'Cd', color: 0xffd98f, x: 0.55, y: 0.7, z: -4 },
  { element: 'In', color: 0xa67573, x: 0.8, y: 0.7, z: -4 },
  { element: 'Sn', color: 0x668080, x: 1.05, y: 0.7, z: -4 },
  { element: 'Sb', color: 0x9e63b5, x: 1.3, y: 0.7, z: -4 },
  { element: 'Te', color: 0xd47a00, x: 1.55, y: 0.7, z: -4 },
  { element: 'I', color: 0x940094, x: 1.8, y: 0.7, z: -4 },
  { element: 'Xe', color: 0x429eb0, x: 2.05, y: 0.7, z: -4 },
  //   { element: 'Xe', color: 0xc88033, x: 2.05, y: 0.7, z: -4 },

  { element: 'Cs', color: 0x57178f, x: -2.2, y: 0.45, z: -4 },
  { element: 'Ba', color: 0x00c900, x: -1.95, y: 0.45, z: -4 },
  { element: 'Hf', color: 0x4dc2ff, x: -1.45, y: 0.45, z: -4 },
  { element: 'Ta', color: 0x4da6ff, x: -1.2, y: 0.45, z: -4 },
  { element: 'W', color: 0x2194d6, x: -0.95, y: 0.45, z: -4 },
  { element: 'Re', color: 0x267dab, x: -0.7, y: 0.45, z: -4 },
  { element: 'Os', color: 0x266696, x: -0.45, y: 0.45, z: -4 },
  { element: 'Ir', color: 0x175487, x: -0.2, y: 0.45, z: -4 },
  { element: 'Pt', color: 0xd0d0e0, x: 0.05, y: 0.45, z: -4 },
  { element: 'Au', color: 0xffd123, x: 0.3, y: 0.45, z: -4 },
  { element: 'Hg', color: 0xb8b8d0, x: 0.55, y: 0.45, z: -4 },
  { element: 'Tl', color: 0xa6544d, x: 0.8, y: 0.45, z: -4 },
  { element: 'Pb', color: 0x575961, x: 1.05, y: 0.45, z: -4 },
  { element: 'Bi', color: 0x9e4fb5, x: 1.3, y: 0.45, z: -4 },
  { element: 'Po', color: 0xab5c00, x: 1.55, y: 0.45, z: -4 },
  { element: 'At', color: 0x754f45, x: 1.8, y: 0.45, z: -4 },
  { element: 'Rn', color: 0x428296, x: 2.05, y: 0.45, z: -4 },

  { element: 'Fr', color: 0x420066, x: -2.2, y: 0.2, z: -4 },
  { element: 'Ra', color: 0x007d00, x: -1.95, y: 0.2, z: -4 },
  { element: 'Rf', color: 0xcc0059, x: -1.45, y: 0.2, z: -4 },
  { element: 'Db', color: 0xd1004f, x: -1.2, y: 0.2, z: -4 },
  { element: 'Sg', color: 0xd90045, x: -0.95, y: 0.2, z: -4 },
  { element: 'Bh', color: 0xe00038, x: -0.7, y: 0.2, z: -4 },
  { element: 'Hs', color: 0xe6002e, x: -0.45, y: 0.2, z: -4 },
  { element: 'Mt', color: 0xeb0026, x: -0.2, y: 0.2, z: -4 },
  { element: 'Ds', color: 0xffffff, x: 0.05, y: 0.2, z: -4 },
  { element: 'Rg', color: 0xffffff, x: 0.3, y: 0.2, z: -4 },
  { element: 'Cn', color: 0xffffff, x: 0.55, y: 0.2, z: -4 },
  { element: 'Nh', color: 0xffffff, x: 0.8, y: 0.2, z: -4 },
  { element: 'Fl', color: 0xffffff, x: 1.05, y: 0.2, z: -4 },
  { element: 'Mc', color: 0xffffff, x: 1.3, y: 0.2, z: -4 },
  { element: 'Lv', color: 0xffffff, x: 1.55, y: 0.2, z: -4 },
  { element: 'Ts', color: 0xffffff, x: 1.8, y: 0.2, z: -4 },
  { element: 'Og', color: 0xffffff, x: 2.05, y: 0.2, z: -4 },

  { element: 'La', color: 0x70d4ff, x: -1.6, y: -0.45, z: -4 },
  { element: 'Ce', color: 0xffffc7, x: -1.35, y: -0.45, z: -4 },
  { element: 'Pr', color: 0xd9ffc7, x: -1.1, y: -0.45, z: -4 },
  { element: 'Nd', color: 0xc7ffc7, x: -0.85, y: -0.45, z: -4 },
  { element: 'Pm', color: 0xa3ffc7, x: -0.6, y: -0.45, z: -4 },
  { element: 'Sm', color: 0x8fffc7, x: -0.35, y: -0.45, z: -4 },
  { element: 'Eu', color: 0x61ffc7, x: -0.1, y: -0.45, z: -4 },
  { element: 'Gd', color: 0x45ffc7, x: 0.15, y: -0.45, z: -4 },
  { element: 'Tb', color: 0x30ffc7, x: 0.4, y: -0.45, z: -4 },
  { element: 'Dy', color: 0x1fffc7, x: 0.65, y: -0.45, z: -4 },
  { element: 'Ho', color: 0x00ff9c, x: 0.9, y: -0.45, z: -4 },
  { element: 'Er', color: 0x00e675, x: 1.15, y: -0.45, z: -4 },
  { element: 'Tm', color: 0x00d452, x: 1.4, y: -0.45, z: -4 },
  { element: 'Yb', color: 0x00bf38, x: 1.65, y: -0.45, z: -4 },
  { element: 'Lu', color: 0x00ab24, x: 1.9, y: -0.45, z: -4 },

  { element: 'Ac', color: 0x70abfa, x: -1.6, y: -0.7, z: -4 },
  { element: 'Th', color: 0x00baff, x: -1.35, y: -0.7, z: -4 },
  { element: 'Pa', color: 0x00a1ff, x: -1.1, y: -0.7, z: -4 },
  { element: 'U', color: 0x008fff, x: -0.85, y: -0.7, z: -4 },
  { element: 'Np', color: 0x0080ff, x: -0.6, y: -0.7, z: -4 },
  { element: 'Pu', color: 0x006bff, x: -0.35, y: -0.7, z: -4 },
  { element: 'Am', color: 0x545cf2, x: -0.1, y: -0.7, z: -4 },
  { element: 'Cm', color: 0x785ce3, x: 0.15, y: -0.7, z: -4 },
  { element: 'Bk', color: 0x8a4fe3, x: 0.4, y: -0.7, z: -4 },
  { element: 'Cf', color: 0xa136d4, x: 0.65, y: -0.7, z: -4 },
  { element: 'Es', color: 0xb31fd4, x: 0.9, y: -0.7, z: -4 },
  { element: 'Fm', color: 0xb31fba, x: 1.15, y: -0.7, z: -4 },
  { element: 'Md', color: 0xb30da6, x: 1.4, y: -0.7, z: -4 },
  { element: 'No', color: 0xbd0d87, x: 1.65, y: -0.7, z: -4 },
  { element: 'Lr', color: 0xc70066, x: 1.9, y: -0.7, z: -4 }
];
