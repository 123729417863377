import * as THREE from 'three';

export const updatePhysics = (delta, controllers, self) => {
  if (self.physicsWorld) {
    self.physicsWorld.stepSimulation(delta, 10);
    for (let i = 0; i < self.rigidBodies.length; i++) {
      let threeObject = self.rigidBodies[i];
      let ammoObject = threeObject.userData.physicsBody;
      let ms = ammoObject.getMotionState();
      let attachedObjectSelected;
      if (ms) {
        ms.getWorldTransform(self.tempTransform);
        let pos = self.tempTransform.getOrigin();
        let quat = self.tempTransform.getRotation();
        attachedObjectSelected = controllers.find(
          (element) =>
            element.userData.attachedObject?.name === 'Flask' ||
            element.userData.attachedObject?.name === 'Pippete'
        );

        let controllerQuaternion = new THREE.Quaternion();
        attachedObjectSelected?.getWorldQuaternion(controllerQuaternion);
        attachedObjectSelected = attachedObjectSelected?.userData.selected;
        if (threeObject.name === 'controllerSphere') {
          threeObject.getWorldPosition(self.tmpPos);
          threeObject.getWorldQuaternion(self.tmpQuat);
          let physicsBody = threeObject.userData.physicsBody;
          let ms = physicsBody.getMotionState();
          if (ms) {
            self.ammoTmpPos.setValue(
              self.tmpPos.x,
              self.tmpPos.y,
              self.tmpPos.z
            );
            self.ammoTmpQuat.setValue(
              self.tmpQuat.x,
              self.tmpQuat.y,
              self.tmpQuat.z,
              self.tmpQuat.w
            );
            self.tempTransform.setIdentity();
            self.tempTransform.setOrigin(self.ammoTmpPos);
            self.tempTransform.setRotation(self.ammoTmpQuat);
            ms.setWorldTransform(self.tempTransform);
          }
        } else {
          if (threeObject.name == 'Pippete' || threeObject.name === 'Flask') {
            if (
              attachedObjectSelected?.name === 'Pippete' ||
              attachedObjectSelected?.name === 'Flask'
            ) {
              threeObject.getWorldPosition(self.tmpPos);

              if (threeObject.position.z === pos.z())
                self.createListenerForStuckPippete(self.ammoClone, {
                  x: pos.x(),
                  y: pos.y(),
                  z: pos.z()
                });
              threeObject.position.set(pos.x(), pos.y(), pos.z());
              if (threeObject.name === attachedObjectSelected.name) {
                ms.setWorldTransform(self.tempTransform);
                threeObject.quaternion.set(
                  controllerQuaternion.x,
                  controllerQuaternion.y,
                  controllerQuaternion.z,
                  controllerQuaternion.w
                );
              } else {
                threeObject.quaternion.set(
                  quat.x(),
                  quat.y(),
                  quat.z(),
                  quat.w()
                );
              }
            } else {
              threeObject.getWorldPosition(self.tmpPos);
              threeObject.getWorldQuaternion(self.tmpQuat);
              let physicsBody = threeObject.userData.physicsBody;
              let ms = physicsBody.getMotionState();
              if (ms) {
                self.ammoTmpPos.setValue(
                  self.tmpPos.x,
                  self.tmpPos.y,
                  self.tmpPos.z
                );
                self.ammoTmpQuat.setValue(
                  self.tmpQuat.x,
                  self.tmpQuat.y,
                  self.tmpQuat.z,
                  self.tmpQuat.w
                );
                self.tempTransform.setIdentity();
                self.tempTransform.setOrigin(self.ammoTmpPos);
                self.tempTransform.setRotation(self.ammoTmpQuat);
                ms.setWorldTransform(self.tempTransform);
              }
            }
          } else {
            if (
              threeObject.name === 'chemFlaskCollision' &&
              attachedObjectSelected?.name === 'Flask'
            ) {
              let physicsBody = threeObject.userData.physicsBody;
              let ms = physicsBody.getMotionState();
              if (ms) {
                self.physicsWorld.removeRigidBody(physicsBody);
                // self.ammoClone.destroy(ms);
              }
              threeObject.position.set(pos.x(), pos.y(), pos.z());
              threeObject.quaternion.set(
                quat.x(),
                quat.y(),
                quat.z(),
                quat.w()
              );
            } else {
              if (
                threeObject.name === 'chemPippete' &&
                attachedObjectSelected?.name === 'Pippete'
              ) {
                let physicsBody = threeObject.userData.physicsBody;
                let ms = physicsBody.getMotionState();
                if (ms) {
                  self.physicsWorld.removeRigidBody(physicsBody);
                  // self.ammoClone.destroy(ms);
                }
                threeObject.position.set(pos.x(), pos.y(), pos.z());
                threeObject.quaternion.set(
                  quat.x(),
                  quat.y(),
                  quat.z(),
                  quat.w()
                );
              } else {
                threeObject.position.set(pos.x(), pos.y(), pos.z());
                threeObject.quaternion.set(
                  quat.x(),
                  quat.y(),
                  quat.z(),
                  quat.w()
                );
              }
            }
          }
        }
      }
    }
  }
};
