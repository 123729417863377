/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { withRouter } from 'react-router-dom';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
// import * as CANNON from 'cannon-es';
// import CannonDebugger from 'cannon-es-debugger';
import Ammo from '../../../Ammo.js';

// import { AmmoDebugDrawer, DefaultBufferSize } from './debug-drawer';
import { pagintationLerping } from '../../../common/TabletFunctionality/paginationLerping';
import styles from '../../../common/styles.module.css';
import { VRButton } from '../../../common/VRButton';
import { Link } from 'react-router-dom';
import { roundedSquare } from '../../../common/SceneModelGeneral/roundedSquareBg';
import ControllerInstruction from '../../../common/controllerInstruction/index';
import createTopicName from '../../../common/SceneModelGeneral/createTopicName';
import { colissionDetecter } from '../../../common/collisionDetector';
import { updatePhysics } from './updatePhysics';
import { handleController } from './handleController';
import { updateControllers } from './updateControllers';
import { Tablet } from '../../../common/TabletFunctionality/index';

class Aldehids extends Component {
  constructor(props) {
    super(props);
    this.lang = props.lang;
    this.containerRef = React.createRef();
    this.materials = {};
    this.rigidBodies = [];

    this.debugDrawer;

    this.state = {
      progress: true,
      loadedFlag: false,
      percent: 0,
      stateFlag: false,
      modelPosition: [],
      modelName: '',
      language: 5,
      flagFullscreen: false,
      loadingType: props.type
    };
    const imageBg = require('../../../assets/img/gradientScene.jpeg');
    this.clock = new THREE.Clock();
    this.scene = new THREE.Scene();
    this.scene.background = this.backgroundTexture;
    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    );

    this.loadedMusicBar = true;

    this.shapesArray = [];
    this.textArray = [];
    this.camera.position.set(0, 0.8, 2.5);
    this.flagForCollision = false;
    this.flagForFullPippete = false;
    this.tmpPos = new THREE.Vector3();
    this.tmpQuat = new THREE.Quaternion();
    this.customShape;

    this.scene.add(this.camera);

    const texture = new THREE.TextureLoader().load(imageBg);

    this.scene.background = texture;
    this.modelPosZ = 0;
    this.modelPosY = 0;
    this.modelPosX = 0;
    this.lineLength = 0;

    this.renderer = new THREE.WebGLRenderer({
      antialias: false,
      powerPreference: 'high-performance'
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.dropletCounts = 0;
    this.ambientLight = new THREE.HemisphereLight(0xffffff, 5);
    this.scene.add(this.ambientLight);
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 5);
    this.directionalLight.position.set(3, 3, 3).normalize();
    this.directionalLight2 = new THREE.DirectionalLight(0xffffff, 5);
    this.directionalLight2.position.set(5, 5, 5).normalize();
    this.directionalLight3 = new THREE.DirectionalLight(0xffffff, 5);
    this.directionalLight3.position.set(5, 5, -5).normalize();
    this.directionalLight4 = new THREE.DirectionalLight(0xffffff, 5);
    this.directionalLight4.position.set(-7, -5, -5).normalize();
    this.directionalLight5 = new THREE.DirectionalLight(0xffffff, 5);
    this.directionalLight5.position.set(-5, 0, 0).normalize();
    this.directionalLight6 = new THREE.DirectionalLight(0xffffff, 5);
    this.directionalLight6.position.set(0, -5, 0).normalize();

    this.scene.add(this.directionalLight);
    this.scene.add(this.directionalLight2);
    this.scene.add(this.directionalLight3);
    this.scene.add(this.directionalLight4);
    this.scene.add(this.directionalLight5);
    this.scene.add(this.directionalLight6);
    this.start_x;
    this.start_y;

    this.raycaster = new THREE.Raycaster();
    this.raycasterPippete = new THREE.Raycaster();
    this.raycasterForAmoniac = new THREE.Raycaster();

    this.workingMatrix = new THREE.Matrix4();
    this.workingMatrixForPippete = new THREE.Matrix4();
    this.workingMatrixForAmoniac = new THREE.Matrix4();

    this.workingVector = new THREE.Vector3();
    this.origin = new THREE.Vector3();

    this.dolly = new THREE.Object3D();
    this.dolly.position.z = 5;
    this.scene.add(this.dolly);
    this.dummyCam = new THREE.Object3D();
    this.camera.add(this.dummyCam);

    const xInstructionCoord = this.props.name?.controllerCoords?.x
      ? this.props.name.controllerCoords.x
      : 1.7;
    const yInstructionCoord = this.props.name?.controllerCoords?.y
      ? this.props.name.controllerCoords.y
      : 0.5;
    const zInstructionCoord = this.props.name?.controllerCoords?.z
      ? this.props.name.controllerCoords.z
      : 2.5;

    this.ControllerInstruction = new ControllerInstruction({
      x: xInstructionCoord,
      y: yInstructionCoord,
      z: zInstructionCoord
    });
    this.ControllerInstruction.bindInstructionToScene(this.scene);

    this.model;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();
    // this.controls.maxPolarAngle = Math.PI * 0.5;
    // this.controls.minDistance = 1;
    // this.controls.maxDistance = 10;

    this.renderer.setAnimationLoop(this.render.bind(this));

    this.initScene();
    this.setupVr(this.scene);
  }

  initScene() {
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    } else {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    }
    this.loadModel(this.room);
    this.startAmmo();
  }

  startAmmo(models) {
    Ammo().then((Ammo) => {
      Ammo;
      this.ammoClone = Ammo;

      this.createAmmo(Ammo, models);
      this.tmpTrans = new Ammo.btTransform();
      this.ammoTmpPos = new Ammo.btVector3();
      this.ammoTmpQuat = new Ammo.btQuaternion();
    });
  }
  createAmmo(Ammo = this.ammoClone, models) {
    this.tempTransform = new Ammo.btTransform();
    this.tempTransformForDynamic = new Ammo.btTransform();

    // let volumeMass = 7;

    this.setupPhysics(Ammo);
    function createPhysicSphere(
      sphere,
      Ammo = this.ammoClone,
      physicsWorld,
      rigidBodies,
      self
    ) {
      let spherePos = new THREE.Vector3();
      let sphereQuat = new THREE.Quaternion();
      // sphere.getWorldPosition(spherePos);
      // sphere.getWorldQuaternion(sphereQuat);
      sphere.name = 'controllerSphere';

      let position = {
          x: spherePos.x,
          y: spherePos.y,
          z: spherePos.z
        },
        radius = 0.05,
        quat = {
          x: sphereQuat.x,
          y: sphereQuat.y,
          z: sphereQuat.z,
          w: sphereQuat.w
        },
        mass = 0;

      let shape = new Ammo.btSphereShape(radius);
      shape.setMargin(0);

      let transform = new Ammo.btTransform();
      transform.setIdentity();
      transform.setOrigin(
        new Ammo.btVector3(position.x, position.y, position.z)
      );
      transform.setRotation(
        new Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w)
      );

      let motionState = new Ammo.btDefaultMotionState(transform);

      let localInertia = new Ammo.btVector3(0, 0, 0);

      shape.calculateLocalInertia(mass, localInertia);

      let rigidBodyInfo = new Ammo.btRigidBodyConstructionInfo(
        mass,
        motionState,
        shape,
        localInertia
      );
      self.sphereBody = new Ammo.btRigidBody(rigidBodyInfo);

      physicsWorld.addRigidBody(self.sphereBody);
      self.sphereBody.setFriction(4);
      // sphereBody.setRollingFriction(10);

      self.sphereBody.setActivationState(4);
      self.sphereBody.setCollisionFlags(2);
      sphere.userData.physicsBody = self.sphereBody;
      rigidBodies.push(sphere);
    }
    this.controllers.map((controller) => {
      let sphere = controller.children[1];
      createPhysicSphere(
        sphere,
        Ammo,
        this.physicsWorld,
        this.rigidBodies,
        this
      );
    });
    const symbols = ['-', '+', '+', '-'];
    for (let i = 0; i < 4; i++) {
      this.createChemFlask(
        Ammo,
        { x: 0, y: i % 2 === 0 ? 0 : 1, z: 0, w: 1 }, //quat
        {
          x: i % 2 === 0 ? symbols[i] + 0.09 : 0,
          y: 0.15,
          z: i % 2 !== 0 ? symbols[i] + 0.09 : 0
        }, //pos //-0.15
        { x: 0, y: 0, z: 0 }, //rotation
        { x: 0.02, y: 0.65, z: 0.2 },
        0xffff00,
        'chemFlaskCollision'
      );
    }
    this.createChemFlask(
      Ammo,
      { x: 0, y: 0, z: 1, w: 1 }, //quat
      {
        x: 0,
        y: -0.12,
        z: 0
      }, //pos //-0.15
      { x: 0, y: 0, z: 0 }, //rotation
      { x: 0.05, y: 0.15, z: 0.15 },
      0xffff00,
      'chemFlaskCollision'
      // scale
    );
    for (let i = 0; i < 4; i++) {
      this.createAmoniac(
        ////////////////////////////////////////////////
        Ammo,
        { x: 0, y: i % 2 === 0 ? 0 : 1, z: 0, w: 1 }, //quat////////////////////////////////////////////////
        {
          ////////////////////////////////////////////////
          x: i % 2 === 0 ? Number(symbols[i] + 0.2) + 0.55 : 0.55,
          y: 0.07,
          z: i % 2 !== 0 ? Number(symbols[i] + 0.15) + 0.01 : 0.01
        }, //pos //-0.15
        { x: 0, y: 0, z: 0 }, //rotation
        { x: 0.05, y: 0.45, z: 0.17 }, // scale
        0x0f0f0f0
      );
    }
    for (let i = 0; i < 4; i++) {
      this.createAmoniac(
        Ammo,
        { x: 0, y: i % 2 !== 0 ? 0.5 : 1.8, z: 0, w: 1 }, //quat
        {
          x:
            i % 2 === 0
              ? Number(symbols[i] + 0.11) + 0.55
              : -Number(symbols[i] + 0.15) + 0.55,
          y: 0.07,
          z: i % 2 !== 0 ? Number(symbols[i] + 0.11) : Number(symbols[i] + 0.11)
        }, //pos //-0.15
        { x: 0, y: 0, z: 0 }, //rotation
        { x: 0.05, y: 0.45, z: 0.17 },
        0xffff00
        // scale
      );
    }

    this.createAmoniac(
      Ammo,
      { x: 0, y: 0, z: 1, w: 1 }, //quat
      {
        x: 0.55,
        y: -0.12,
        z: 0
      }, //pos //-0.15
      { x: 0, y: 0, z: 0 }, //rotation
      { x: 0.05, y: 0.33, z: 0.3 },
      0xffff00
      // scale
    );
  }

  createFlask(Ammo = this.ammoClone, quat, position, rotation, scale, model) {
    let mass;
    if (model.name === 'Pippete' || model.name == 'Flask') {
      mass = 1;
    } else {
      mass = 0;
    }
    let mesh = new Ammo.btTriangleMesh(true, true);
    let shapeForPippete;
    const vertices = model.geometry.attributes.position?.array || [];
    const indices = model.geometry.index?.array || [];
    // if (model.name !== 'Pippete') {
    for (let i = 0; i * 3 < indices.length; i += 1) {
      mesh.addTriangle(
        new Ammo.btVector3(
          vertices[indices[i * 3] * 3],
          vertices[indices[i * 3] * 3 + 1],
          vertices[indices[i * 3] * 3 + 2]
        ),
        new Ammo.btVector3(
          vertices[indices[i * 3 + 1] * 3],
          vertices[indices[i * 3 + 1] * 3 + 1],
          vertices[indices[i * 3 + 1] * 3 + 2]
        ),
        new Ammo.btVector3(
          vertices[indices[i * 3 + 2] * 3],
          vertices[indices[i * 3 + 2] * 3 + 1],
          vertices[indices[i * 3 + 2] * 3 + 2]
        ),
        false
      );
    }

    mesh.setScaling(new Ammo.btVector3(scale.x * 0.5, scale.y, scale.z * 0.5));

    let globalPosition = new THREE.Vector3();
    model.localToWorld(globalPosition);
    var transform = new Ammo.btTransform();
    transform.setIdentity();
    transform.setOrigin(
      new Ammo.btVector3(globalPosition.x, globalPosition.y, globalPosition.z)
    );
    transform.setRotation(
      new Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w)
    );

    let motionState = new Ammo.btDefaultMotionState(transform);
    let shape;

    shape = new Ammo.btBvhTriangleMeshShape(mesh, true, true);

    shape.setMargin(0.05);

    let localInertia = new Ammo.btVector3(0, 0, 0);
    shape.calculateLocalInertia(mass, localInertia);

    let rigidBodyInfo = new Ammo.btRigidBodyConstructionInfo(
      mass,
      motionState,
      shape,
      localInertia
    );
    this.flaskBody = new Ammo.btRigidBody(rigidBodyInfo);

    this.physicsWorld.addRigidBody(this.flaskBody);
    model.userData.physicsBody = this.flaskBody;

    this.rigidBodies.push(model);
    this.customShape = model;
  }

  setupPhysics(Ammo = this.ammoClone) {
    const collisionConfiguration =
      new Ammo.btSoftBodyRigidBodyCollisionConfiguration();
    const dispatcher = new Ammo.btCollisionDispatcher(collisionConfiguration);
    const broadphase = new Ammo.btDbvtBroadphase();
    const solver = new Ammo.btSequentialImpulseConstraintSolver();
    const softBodySolver = new Ammo.btDefaultSoftBodySolver();
    this.physicsWorld = new Ammo.btSoftRigidDynamicsWorld(
      dispatcher,
      broadphase,
      solver,
      collisionConfiguration,
      softBodySolver
    );
    this.physicsWorld.setGravity(new Ammo.btVector3(0, -5.8, 0));
    this.physicsWorld
      .getWorldInfo()
      .set_m_gravity(new Ammo.btVector3(0, -5.8, 0));
  }
  createChemFlask(
    Ammo = this.ammoClone,
    quat,
    position,
    rotation,
    scale,
    color,
    name
  ) {
    let mass = 0;
    let blockPlane = new THREE.Mesh(
      new THREE.BoxGeometry(scale.x, scale.y, scale.z),
      new THREE.MeshStandardMaterial({
        color: 0xffff00,
        transparent: true
      })
    );
    blockPlane.position.set(position.x, position.y, position.z);
    blockPlane.castShadow = true;
    blockPlane.receiveShadow = true;
    blockPlane.name = name;
    let shape = new Ammo.btBoxShape(
      new Ammo.btVector3(scale.x * 0.5, scale.y * 0.5, scale.z * 0.5)
    );
    shape.setMargin(0.04);
    var transform = new Ammo.btTransform();
    transform.setIdentity();
    transform.setOrigin(
      new Ammo.btVector3(position.x - 0.05, position.y, position.z)
    );
    transform.setRotation(
      new Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w)
    );

    let motionState = new Ammo.btDefaultMotionState(transform);

    let localInertia = new Ammo.btVector3(0, 0, 0);

    shape.calculateLocalInertia(mass, localInertia);

    let rigidBodyInfo = new Ammo.btRigidBodyConstructionInfo(
      mass,
      motionState,
      shape,
      localInertia
    );
    let rBody = new Ammo.btRigidBody(rigidBodyInfo);

    // this.scene.add(blockPlane);

    if (mass > 0) {
      this.rigidBodies.push(blockPlane);
      // Disable deactivation
      rBody.setActivationState(4);
    }
    this.physicsWorld.addRigidBody(rBody);
    blockPlane.userData.physicsBody = rBody;
    this.rigidBodies.push(blockPlane);
  }
  createListenerForStuckPippete(Ammo = this.ammoClone, position) {
    let radius = 0.0005,
      quat = { x: 9, y: 0, z: 0, w: 1 },
      mass = 0.5;

    let transform = new Ammo.btTransform();
    transform.setIdentity();
    transform.setOrigin(new Ammo.btVector3(position.x, position.y, position.z));
    transform.setRotation(
      new Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w)
    );

    let motionState = new Ammo.btDefaultMotionState(transform);

    let localInertia = new Ammo.btVector3(0, 0, 0);

    let shape = new Ammo.btSphereShape(radius);
    shape.setMargin(0.05);
    shape.calculateLocalInertia(mass, localInertia);

    let rigidBodyInfo = new Ammo.btRigidBodyConstructionInfo(
      mass,
      motionState,
      shape,
      localInertia
    );
    let rBody = new Ammo.btRigidBody(rigidBodyInfo);

    this.physicsWorld.addRigidBody(rBody);
  }

  createAmoniac(Ammo = this.ammoClone, quat, position, rotation, scale, color) {
    let mass = 0;
    let blockPlane = new THREE.Mesh(
      new THREE.BoxGeometry(scale.x, scale.y, scale.z),
      new THREE.MeshStandardMaterial({
        color: color ? +color : 0xff00ff,
        transparent: true
      })
    );
    blockPlane.position.set(position.x, position.y, position.z);
    blockPlane.castShadow = true;
    blockPlane.receiveShadow = true;
    let shape = new Ammo.btBoxShape(
      new Ammo.btVector3(scale.x * 0.5, scale.y * 0.5, scale.z * 0.5)
    );
    shape.setMargin(0.04);
    var transform = new Ammo.btTransform();
    transform.setIdentity();
    transform.setOrigin(new Ammo.btVector3(position.x, position.y, position.z));
    transform.setRotation(
      new Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w)
    );

    let motionState = new Ammo.btDefaultMotionState(transform);

    let localInertia = new Ammo.btVector3(0, 0, 0);

    shape.calculateLocalInertia(mass, localInertia);

    let rigidBodyInfo = new Ammo.btRigidBodyConstructionInfo(
      mass,
      motionState,
      shape,
      localInertia
    );
    let rBody = new Ammo.btRigidBody(rigidBodyInfo);

    // this.scene.add(blockPlane);

    if (mass > 0) {
      this.rigidBodies.push(blockPlane);
      // Disable deactivation
      rBody.setActivationState(4);
    }
    this.physicsWorld.addRigidBody(rBody);
    blockPlane.userData.physicsBody = rBody;
    this.rigidBodies.push(blockPlane);
  }
  loadModel(room) {
    this.arrayForSettingVisibileOnRefresh = [];
    this.arrayForSettingInvisibileOnRefresh = [];
    const draco = new DRACOLoader();
    const self = this;
    const loader = new GLTFLoader();
    draco.setDecoderConfig({ type: 'js' });
    const model =
      'https://s3.eu-central-1.amazonaws.com/lectio.app/models/Aldehids.glb';
    draco.setDecoderPath(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/draco/'
    );

    loader.setDRACOLoader(draco);
    loader.load(
      model,
      function (gltf) {
        room.add(gltf.scene);
        self.model = gltf.scene;
        self.model.name = 'loadedModel';
        self.model.getObjectByName('RefractionAmoniac').visible = false;
        self.model.getObjectByName('pippeteAmoniac').visible = false;
        self.model.getObjectByName('Droplet').visible = false;
        self.model.getObjectByName('RefractionAmoniac').visible = false;
        self.arrayForSettingInvisibileOnRefresh.push(
          self.model.getObjectByName('RefractionAmoniac')
        );
        self.arrayForSettingVisibileOnRefresh.push(
          self.model.getObjectByName('Flask').getObjectByName('flaksAmoniac')
        );

        if (gltf?.animations.length) {
          self.mixer = new THREE.AnimationMixer(gltf.scene);
          const clips = gltf?.animations;
          clips.forEach(function (clip) {
            self?.mixer?.clipAction(clip)?.play();
          });
        }

        self.setState({ ...self.state, progress: false });
        const setTextInScene = () => {
          self.props?.text?.map((element, index) => {
            self.model.remove(self.model.getObjectByName('text'));
            self.model.remove(self.model.getObjectByName('static'));
            self.model.remove(self.model.getObjectByName('parrent'));
            self.model.remove(self.model.getObjectByName('children'));
            self.loadText(self.model, element, index);
          });
        };

        setTextInScene();
      },
      function (xhr) {
        self.setState({
          ...self.state,
          percent: (xhr.loaded / xhr.total) * 100
        });
      },
      function (err) {
        console.log('error========>', err);
      }
    );
  }
  loadEnvironment(room) {
    // this.model.position.set(0.5, 0.3, -1.7);
    // this.model.scale.x = 0.45;
    // this.model.scale.y = 0.45;
    // this.model.scale.z = 0.45;

    // this.directionalLight = new THREE.SpotLight(0xffffff, 1);
    // this.directionalLight.position.set(0.5, 0.3, -1.6).normalize();
    // this.scene.add(this.directionalLight);

    const rightPartSpotLightTarger = new THREE.Mesh(
      new THREE.SphereBufferGeometry(0.5, 5),
      new THREE.MeshBasicMaterial({ color: 0x00ff00 })
    );
    this.scene.add(rightPartSpotLightTarger);
    rightPartSpotLightTarger.position.set(0, 1.8, 2.2);
    const rightSpotLightMesh1 = new THREE.Mesh(
      new THREE.SphereBufferGeometry(0.5, 5),
      new THREE.MeshBasicMaterial({ color: 0xfffff })
    );
    this.scene.add(rightSpotLightMesh1);
    rightSpotLightMesh1.position.set(0.05, 3.3, 2.8);
    const spotLight = new THREE.SpotLight(0xffffff, 12);
    spotLight.castShadow = true;
    this.scene.add(spotLight);
    spotLight.position.copy(rightSpotLightMesh1.position);
    spotLight.target = rightPartSpotLightTarger;
    //center sportlight
    const centerPartSpotLightTarger = new THREE.Mesh(
      new THREE.SphereBufferGeometry(0.5, 5),
      new THREE.MeshBasicMaterial({ color: 0x00ff00 })
    );
    this.scene.add(centerPartSpotLightTarger);
    centerPartSpotLightTarger.position.set(0, 1.3, 0);

    const spotLight2 = new THREE.SpotLight(0xffffff, 12);
    spotLight2.castShadow = true;
    this.scene.add(spotLight2);
    spotLight2.position.set(
      rightSpotLightMesh1.position.x,
      rightSpotLightMesh1.position.y,
      rightSpotLightMesh1.position.z - 3.3
    );
    spotLight2.target = centerPartSpotLightTarger;
    spotLight2.target.position.copy(centerPartSpotLightTarger.position);

    centerPartSpotLightTarger.visible = false;
    rightSpotLightMesh1.visible = false;
    rightPartSpotLightTarger.visible = false;

    const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
    this.scene.add(directionalLight);
    const targetObject = new THREE.Object3D();
    this.scene.add(targetObject);
    directionalLight.target = targetObject;
    // targetObject.position.set()
    directionalLight.position.set(0, -0.5, 0);
    directionalLight.target.position.set(0, 10, 0);

    const draco = new DRACOLoader();
    const self = this;
    const loadedModel = self.scene.getObjectByName('loadedModel');
    const loader = new GLTFLoader();

    draco.setDecoderConfig({ type: 'js' });
    const environment = require('../../../assets/EnvironmentWithoutTableForAldehids.glb');
    draco.setDecoderPath(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/draco/'
    );

    loader.setDRACOLoader(draco);
    loader.load(
      environment,
      function (gltf) {
        room.add(gltf.scene);
        self.environment = gltf.scene;
        gltf.scene.position.set(0, 1.3, 0);
        gltf.scene.name = 'environment';

        if (gltf?.animations.length) {
          self.mixer = new THREE.AnimationMixer(gltf.scene);
          const clips = gltf?.animations;
          clips.forEach(function (clip) {
            self?.mixer?.clipAction(clip)?.play();
          });
        }

        self.setState({ ...self.state, progress: false });
      },
      function (xhr) {
        self.setState({
          ...self.state,
          percent: (xhr.loaded / xhr.total) * 100
        });
      },
      function (err) {
        console.log('error========>', err);
      }
    );
  }
  async loadText(model, text, index) {
    this.textArrays = [];
    const loader = new FontLoader();
    const self = this;
    if (
      model.getObjectByName(text.name) ||
      model.getObjectByName(text.modelType)
    ) {
      model.remove(model.getObjectByName(text.name));
      model.remove(model.getObjectByName(text.modelType));
    }
    await loader.load(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/helvetiker_regular.typeface.json',
      function (font) {
        var textGeometry = new TextGeometry(text[self.props.lang], {
          font: font,
          size: text.modelSize ? text.modelSize : 0.1,
          height: text.modelHeight ? text.modelHeight : 0.03
        });
        let textMaterial;

        var mesh = new THREE.Mesh(textGeometry, textMaterial);
        model.add(mesh);
        self.textArray.push(mesh);
        // this.tex
        if (text.modelType === 'parrent') {
          mesh.name = 'parrent';
        } else if (text.modelType === 'children') {
          mesh.name === 'children';
          model.getObjectByName('parrent').add(mesh);
        } else if (text.name) {
          mesh.name = text.name;
          if (index > 4) {
            mesh.visible = false;
          }
        } else {
          mesh.name = 'text';
        }

        if (text.modelCoordsX && text.modelCoordsY && text.modelCoordsZ) {
          mesh.position.set(
            text.modelCoordsX,
            text.modelCoordsY,
            text.modelCoordsZ
          );
        } else {
          mesh.position.set(0, 0, 0);
        }
        if (text.modelRotationX && text.modelRotationY && text.modelRotationZ) {
          mesh.rotation.set(
            text.modelRotationX,
            text.modelRotationY,
            text.modelRotationZ
          );
        } else {
          mesh.rotation.set(0, 0, 0);
        }
      }
    );
  }

  setupVr(scene) {
    this.renderer.xr.enabled = true;
    const self = this;
    this.controllers = this.buildControllers(scene);
    function onConnected(e) {
      this.gamepad = e.data.gamepad;
      updateControllers(
        {
          right: { trigger: true, squeeze: true, side: e.data.handedness },
          left: { trigger: true, squeeze: true, side: e.data.handedness }
        },
        self,
        this
      );
    }

    const controllers = [
      this.renderer.xr.getController(0),
      this.renderer.xr.getController(1)
    ];

    controllers.map((element) => {
      element.addEventListener('connected', onConnected);
    });
  }

  buildControllers() {
    const controllerModelFactory = new XRControllerModelFactory();
    const geometrySphere = new THREE.IcosahedronBufferGeometry(this.radius, 2);

    const sphere = new THREE.Mesh(
      geometrySphere,
      new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.BackSide })
    );
    sphere.scale.set(0.01, 0.01, 0.01);

    const geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, -1)
    ]);

    const line = new THREE.Line(geometry);
    line.name = 'line';
    line.scale.z = 2;
    sphere.visible = false;

    const controllers = [];

    for (let i = 0; i <= 1; i++) {
      const controller = this.renderer.xr.getController(i);

      controller.add(line.clone());
      controller.add(sphere.clone());
      controller.userData.selectPressed = false;

      this.dolly.add(controller);

      controllers.push(controller);

      const grip = this.renderer.xr.getControllerGrip(i);
      grip.add(controllerModelFactory.createControllerModel(grip));

      this.workingMatrix.identity().extractRotation(controller.matrixWorld);

      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);
      controller.children[0].scale.z = 10;
      if (i == 1) {
        this.dolly.add(grip);
      }
    }

    Tablet(this, controllers);

    return controllers;
  }

  componentDidUpdate(prevProps) {
    const self = this;
    //Changing text mesh on change language
    if (this.vrButton?.vrButton && this.model) {
      this.vrButton.vrButton.disabled = false;
    }
    if (this.props.lang != prevProps.lang) {
      this.props?.text?.map((element, index) => {
        this.model.remove(this.model.getObjectByName('text'));
        this.model.remove(this.model.getObjectByName('static'));
        this.model.remove(this.model.getObjectByName('parrent'));
        this.model.remove(this.model.getObjectByName('children'));
        if (this.state.progress) this.loadText(this.model, element, index);

        this.loadText(this.model, element, index);
        self.setState({ ...self.state, loadedFlag: true });
      });
    }
    if (this.props.lang != prevProps.lang || this.props.lang === 'RO') {
      createTopicName(this.props, this.scene);
    }
  }

  componentDidMount() {
    // this.button = ;
    this.containerRef?.current?.appendChild(this.renderer.domElement);
    if (!document.getElementById('vrButton'))
      this.vrButton = new VRButton(this.renderer);

    if (this.props) roundedSquare(this.scene, this.props);
    const self = this;
    // Adding text mesh on mount component

    //When user turn on the VR mode.
    self.renderer.xr.addEventListener('sessionstart', function () {
      //INTRODUCTION FOR ENVIRONMENT SETTING
      // self.loadEnvironment(self.room);
      // self.scene.remove(self.directionalLight);
      // self.scene.remove(self.directionalLight2);
      // self.scene.remove(self.directionalLight3);
      // self.scene.remove(self.directionalLight4);
      // self.scene.remove(self.directionalLight5);
      // self.scene.remove(self.directionalLight6);
      // self.textArray.forEach((element) => {
      //   element.material.color.setHex(0x00d6d9);
      // });
      self.vrButton.vrButton.disabled = false;

      self.scene.getObjectByName('instructionPanel').visible = true;
      if (self.props.url.split('/').indexOf('fullscreen') == -1) {
        self.props.history.push(self.link);
      }
      self.dolly.add(self.camera);
      self.dolly.position.z = 4;
      self.dolly.position.y = -0.5;
      self.dolly.position.x = 0;
    });

    //When user turn off the VR mode.
    self.renderer.xr.addEventListener('sessionend', function () {
      self.room.remove(self.room.getObjectByName('environment'));
      if (self.sound?.isPlaying) self.sound.pause();
      self.scene.getObjectByName('instructionPanel').visible = false;
      //INTRODUCTION FOR ENVIRONMENT SETTING

      self.vrButton.vrButton.disabled = false;

      self.dolly.remove(self.camera);
      self.dolly.position.z = 3;
      self.dolly.position.y = 1.6;
      let adminPreviewContainer = document.getElementById('previewContainer');
      if (self.props.onsessionendAdmin && adminPreviewContainer) {
        adminPreviewContainer.appendChild(self.vrButton.vrButton);
      }
    });
    window.addEventListener('resize', this.resize());
  }

  componentWillUnmount() {
    // this.containerRef?.current?.removeChild(this.renderer.domElement);
    window.removeEventListener('resize', this.resize);

    !this.props.isPreview && this?.vrButton?.hideEnterVR();
    Object.keys(this).forEach((key) => {
      // Recursively call dispose() if possible.
      if (
        typeof this[key]?.dispose === 'function' &&
        this[key].type != 'Scene'
      ) {
        this[key].dispose();
      }
      // Remove any reference.
      this[key] = null;
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.room.name = 'room';
    this.lang = nextProps.lang;

    //Replacing text from scene after translate
    if (nextProps.lang !== this.props.lang) {
      this.props?.text?.map(() => {
        this.model.remove(this.model.getObjectByName('text'));
        this.model.remove(this.model.getObjectByName('parrent'));
        this.model.remove(this.model.getObjectByName('static'));
        this.model.remove(this.model.getObjectByName('children'));
      });

      return true;
    }

    //Setting coordinates for room (model)
    if (
      this.props.roomCoordsZ &&
      this.props.roomCoordsX &&
      this.props.roomCoordsY
    ) {
      this.room.position.set(
        this.props.roomCoordsX,
        this.props.roomCoordsY,
        this.props.roomCoordsZ
      );
    } else {
      this.room.position.set(0, 0, 0);
    }
    //Setting rotation coords for rotation (model)
    if (
      this.props.roomRotationZ &&
      this.props.roomRotationX &&
      this.props.roomRotationY
    ) {
      this.room.rotation.set(
        this.props.roomRotationX,
        this.props.roomRotationY,
        this.props.roomRotationZ
      );
    }
    //Setting scaling for room (model)
    if (
      this.props.roomScaleZ &&
      this.props.roomScaleX &&
      this.props.roomScaleY
    ) {
      this.room.scale.set(
        this.props.roomScaleX,
        this.props.roomScaleY,
        this.props.roomScaleZ
      );
    }
    //Setting camera position in scene
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.camera.position.set(
        this.props.cameraCoordsX,
        this.props.cameraCoordsY,
        this.props.cameraCoordsZ
      );
    }

    //Setting coord for text in scene

    this.scene.add(this.room);

    //Setting loading
    if (nextProps.type !== this.state.loadingType) {
      this.setState({
        ...this.state,
        loadingType: nextProps.type
      });
      return true;
    }

    if (this.state.progress !== nextState) {
      return true;
    }
    if (nextProps.z !== this.props.z) {
      return true;
    } else {
      return false;
    }
  }

  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  render() {
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    // if (this.controllers) {
    //   // const self = this;
    //   this.controllers.forEach((controller) => {
    //     this.animatePhysics(controller);
    //   });
    // }
    const dt = this.clock.getDelta();
    if (this.physicsWorld) {
      updatePhysics(dt, this.controllers, this);
    }
    // this.moveKinematic(this.controllers, this.ammoClone);
    this.model?.children.forEach((element) => {
      if (
        (element.name === 'text' ||
          element.name == 'parrent' ||
          element.name == 'Acetic' ||
          element.name == '1' ||
          element.name == '2' ||
          element.name == '3') &&
        element.name
      )
        element.quaternion?.copy(this.camera.quaternion);
    });
    if (this.controllers) {
      const self = this;

      this.controllers.forEach((controller, index) => {
        handleController(controller, this);
      });
    }
    pagintationLerping(this);

    this.mixer?.update(dt);
    if (this.model) {
      if (
        !this.model.getObjectByName('RefractionAmoniac')?.visible &&
        this.model.getObjectByName('parrent') &&
        this.model.getObjectByName('Acetic')
      ) {
        this.model.getObjectByName('parrent').visible = false;
        this.model.getObjectByName('Acetic').visible = true;
      } else {
        if (
          this.model.getObjectByName('parrent') &&
          this.model.getObjectByName('Acetic')
        ) {
          this.model.getObjectByName('parrent').visible = true;
          this.model.getObjectByName('Acetic').visible = false;
        }
      }
    }
    return (
      <div ref={this.containerRef} className={`${styles.sceneContainer}`}>
        {this.props.isPreviewContainer && this.state.flagFullscreen ? (
          <Link
            onClick={this.updateState}
            className={
              'px-2 absolute py-2 bg-gray-500 border-2 border-white rounded-lg text-center hover:bg-gray-600 hover hover:border-gray-100 top-5 right-5 text-white '
            }
          >
            {' '}
            <svg
              className={'self-end fill-current h-6 w-6 '}
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Exit Fullscreen</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </Link>
        ) : null}

        {this.state.progress && (
          <div
            className={
              'w-full h-full bg-black flex items-center flex-col justify-center absolute opacity-50'
            }
          >
            <p className="text-white opacity-100 font-gothic text-lg mb-2 text-center">
              Loading...
            </p>
            <div className="w-32 h-1 opacity-100 rounded-lg bg-slate-600 ">
              {this.state.percent != 0 ? (
                <div
                  style={{ width: Math.ceil(this.state.percent) + '%' }}
                  className={' rounded-full opacitiy-90 h-full bg-cyan-500'}
                ></div>
              ) : (
                <div className={'w-full h-full bg-gray-500'}></div>
              )}
            </div>
          </div>
        )}
      </div>
    );
    // }
  }
}

export default withRouter(Aldehids);
