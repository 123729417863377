export const pageUp = (self) => {
  if (Math.round(self.counterForPagination) === 17) {
    self.nextPage = false;
    self.counterForPagination = 0;
    self.indexOfBlockerPage++;
  } else if (Math.round(self.counterForPagination) === 10) {
    self.indexOfVisiblePage++;
  }
  if (self.nextPage) {
    self.tabletGroups.forEach((element) => {
      element.position.z -= 0.01;
    });
    self.counterForPagination++;
  }
};
