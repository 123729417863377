import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../../common/loader';
import { menuItems } from '../../../assets/mockData/mockData';
import { useAppContext } from '../../../context/AppProvider';
import { UserApi } from '../../../api/user.api';
const validRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
let timeDelay;
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const { store } = useAppContext();
  const { selectedLanguage } = store;
  const [error, setError] = useState({ type: '', value: null });
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const navigate = useHistory();

  useEffect(() => {
    if (error.type) {
      timeDelay = setTimeout(() => {
        setError({ type: '', value: null });
        clearTimeout(timeDelay);
      }, 4000);
    }
  }, [error]);
  const onSubmit = async () => {
    if (email && !emailError) {
      setLoading(true);
      const response = await UserApi.forgotPassowrd({ email }, navigate);
      if (response.status === 200) {
        setError({
          type: 'Passed',
          value: 'Please check your email for confirmation link.'
        });
        setLoading(false);
      } else if (response === 404) {
        setError({
          type: 'Error',
          value: 'User with such email does not exist.'
        });
        setLoading(false);
      } else {
        setError({
          type: 'Error',
          value: 'Something went wrong'
        });
        setLoading(false);
      }
    } else {
      setError({ type: 'Error', value: 'Please enter the email.' });
      setLoading(false);
    }
  };

  const emailValidation = (value) => {
    if (!value) {
      setEmailError('Email is required.');
      return;
    }
    if (!value.match(validRegex)) {
      setEmailError('Invalid email address.');
      return;
    }

    setEmailError('');
  };
  return (
    <>
      <div className=" relative grow min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div
            className={` border  transition ease-in-out smmax:w-3/4 xsmax:w-3/4  duration-100 ${
              error.value === null || open ? 'hidden ' : ''
            }
                  ${
                    error.type === 'Passed'
                      ? 'border-green-400 text-green-700 bg-green-100 '
                      : 'border-red-400 text-red-700 bg-red-100 '
                  } translate-x-16 -mt-16 right-20 absolute  flex justify-between px-6   py-3 rounded `}
            role="alert"
          >
            <div className="flex flex-col pr-32">
              <strong className="font-bold  ">
                {error.type === 'error' ? error.type : error.type}
              </strong>
              <span className="block sm:inline ">{error.value}</span>
            </div>
            <span className="justify-end self-end  flex  py-3">
              <svg
                onClick={
                  (() => setOpen(true),
                  () => setError({ type: '', value: null }))
                }
                className={`self-end fill-current h-6 w-6 ${
                  error.type === 'Passed' ? 'text-green-500' : 'text-red-500 '
                } `}
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>

          <div>
            <h1 className="mx-auto h-12 font-extrabold text-gray-700 text-5xl text-center">
              Lectio
            </h1>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Reset Your password
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600"></p>
          </div>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="space-y-3 rounded-md shadow-sm ">
            <div>
              <label
                htmlFor="email-address"
                className=" p-1 text-gray-700 font-bold text-sm"
              >
                {menuItems.email[selectedLanguage]}
              </label>
              <input
                disabled={loading}
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onBlur={(event) => emailValidation(event.target.value)}
                onChange={(e) => setEmail(e.target.value)}
                className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${
                  emailError ? 'border-red-600' : 'border-gray-300'
                } placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                placeholder={menuItems.email[selectedLanguage]}
              />
              {emailError && (
                <span className={'text-red-600 text-sm'}>{emailError}</span>
              )}
            </div>
          </div>
          <div className="flex items-center w-full  justify-between">
            <div className="text-sm"></div>
            <Link to={'/signin'} className="text-sm">
              <p className="font-medium self-end text-indigo-600 mr-5 hover:text-indigo-500">
                {menuItems.signIn[selectedLanguage]}
              </p>
            </Link>
          </div>
          <div>
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSubmit();
                }}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900  "
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                Continue
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
