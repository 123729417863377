import React from 'react';
import MoleculeConstructor from './index';
import Wrapper from '../../../../common/SceneModelGeneral/wrappper';

const MoleculeConstructorContainer = () => {
  return (
    <div className={'bg-white-bg h-fit'}>
      <div className={'h-4/6 flex justify-center'}>
        <div
          className={' w-full  md:w-11/12 py-5  flex flex-col items-center  '}
        >
          <div
            id="contentScene"
            className={
              'border  w-full p-1 my-4 flex relative  justify-center items-end '
            }
          >
            <Wrapper>
              <MoleculeConstructor />
            </Wrapper>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MoleculeConstructorContainer;
