export const chemBoundsName = {
  name: {
    EN: 'Theoretical bases of organic chemistry',
    RO: 'Bazele teoretice ale chimiei organice.',
    xCoords: -11,
    yCoords: 2.3,
    zCoords: -25,

    scale: {
      x: 10,
      y: 10,
      z: 10
    },
    scaleCounter: 6.5
  },
  meshes: [
    {
      EN: 'Uranium',
      RO: 'Uraniu',
      modelSize: 0.09,

      modelCoordsX: 1.2,
      modelCoordsY: -0.5,
      modelCoordsZ: -0.5,
      modelType: 'parrent'
    },
    {
      EN: 'U',
      RO: 'U',
      modelSize: 0.08,
      modelCoordsX: 0.14,
      modelCoordsY: -0.15,
      modelCoordsZ: 0.0001,
      modelType: 'children'
    },
    {
      EN: 'Salt',
      RO: 'Sare',
      modelSize: 0.09,
      modelCoordsX: -1.19,
      modelCoordsY: 0.8,
      modelCoordsZ: -0.5
    },
    {
      EN: 'NaCl',
      RO: 'NaCl',
      modelSize: 0.07,
      modelCoordsX: -1.18,
      modelCoordsY: 0.6,
      modelCoordsZ: -0.5
    },
    {
      EN: 'Silver',
      RO: 'Argint',
      modelSize: 0.09,
      modelCoordsX: 1.22,
      modelCoordsY: 0.8,
      modelCoordsZ: -0.5,
      modelType: 'parrent'
    },
    {
      EN: 'Ag S',
      RO: 'Ag S',
      modelSize: 0.08,
      modelCoordsX: 0.04,
      modelCoordsY: -0.2,
      modelCoordsZ: 0.0001,
      modelType: 'children'
    },
    {
      EN: '2',
      RO: '2',
      modelSize: 0.05,
      modelCoordsX: 0.18,
      modelCoordsY: -0.25,
      modelCoordsZ: 0.0001,
      modelType: 'children'
    },
    {
      EN: 'Sand',
      RO: 'Nisip',
      modelSize: 0.09,
      modelCoordsX: -1.18,
      modelCoordsY: -0.5,
      modelCoordsZ: -0.5,
      modelType: 'parrent'
    },
    {
      EN: 'SiO',
      RO: 'SiO',

      modelSize: 0.08,
      modelCoordsX: 0.001,
      modelCoordsY: -0.15,
      modelCoordsZ: 0.0001,
      modelType: 'children'
    },
    {
      EN: '2',
      RO: '2',
      modelSize: 0.08,
      modelCoordsX: 0.19,
      modelCoordsY: -0.15,
      modelCoordsZ: 0.0001,
      modelType: 'children'
    }
  ]
};
