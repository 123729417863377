export const calcFormula = (self) => {
  const hypotenuse = Math.sqrt(
    Math.pow(self.positionsTriangle[7], 2) +
      Math.pow(5 - self.positionsTriangle[6], 2)
  );
  let formulaForAngle1 =
    Math.asin(self.positionsTriangle[7] / hypotenuse) * (180 / Math.PI);
  let formulaForAngle2 = 180 - 90 - formulaForAngle1;
  self.objectForGetWorldCoordOfTopVertice.position.set(
    self.positionsTriangle[6],
    self.positionsTriangle[7],
    0
  );
  const formula = [
    {
      EN: '+',
      RO: '+',
      modelCoordsX: 0.05,
      modelCoordsY: -0.5,
      modelCoordsZ: 0.0001,
      modelSize: 0.09,
      modelHeight: 0.01,
      name: 'onceUpload'
    },
    {
      EN: `   ${formulaForAngle1.toFixed(1)}°  `,
      RO: `   ${formulaForAngle1.toFixed(1)}° `,
      modelCoordsX: 0.05,
      modelCoordsY: -0.5,
      modelCoordsZ: 0.0001,
      modelSize: 0.09,
      modelHeight: 0.01,
      name: 'remove'
    },
    {
      EN: ' = 180°',
      RO: ' = 180°',
      modelCoordsX: 0.47,
      modelCoordsY: -0.5,
      modelCoordsZ: 0.0001,
      modelSize: 0.09,
      modelHeight: 0.01,
      name: 'onceUpload'
    },
    {
      EN: '+',
      RO: '+',
      modelCoordsX: -0.33,
      modelCoordsY: -0.5,
      modelCoordsZ: 0.0001,
      modelSize: 0.09,
      modelHeight: 0.01,
      name: 'onceUpload'
    },
    {
      EN: `   ${formulaForAngle2.toFixed(1)}°`,
      RO: `   ${formulaForAngle2.toFixed(1)}°`,
      modelCoordsX: -0.33,
      modelCoordsY: -0.5,
      modelCoordsZ: 0.0001,
      modelSize: 0.09,
      modelHeight: 0.01,
      name: 'remove'
    },
    {
      EN: 'A',
      RO: 'A',
      color: '0x088ff00',
      name: 'cloneA',
      modelCoordsX: self.positionsTriangle[6] - 0.1,
      modelCoordsY: self.positionsTriangle[7] + 0.15,
      modelCoordsZ: 0.001,
      modelSize: 0.23,
      modelHeight: 0.01
    },
    {
      EN: '90°',
      RO: '90°',
      modelCoordsX: -0.51,
      modelCoordsY: -0.5,
      modelCoordsZ: 0.0001,
      modelSize: 0.09,
      modelHeight: 0.01,
      name: 'onceUpload'
    }
  ];
  return formula;
};
