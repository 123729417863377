import * as THREE from 'three';

export const roundedSquare = (
  scene,
  props,
  scaleX,
  scaleY,
  scaleZ,
  posX,
  posY,
  posZ,
  posRightSquareX
) => {
  const curve = new THREE.CatmullRomCurve3([
    new THREE.Vector3(0, 0.6, 0),
    new THREE.Vector3(1, 0.6, 0),
    new THREE.Vector3(5, 1, 0),
    new THREE.Vector3(6, 2, 0),
    new THREE.Vector3(6, 9, 0),
    new THREE.Vector3(5, 9.8, 0),
    new THREE.Vector3(0, 10.2, 0),
    new THREE.Vector3(-5, 9.8, 0),
    new THREE.Vector3(-6, 9, 0),
    new THREE.Vector3(-6, 2, 0),
    new THREE.Vector3(-5, 1, 0),
    new THREE.Vector3(-1, 0.6, 0),
    new THREE.Vector3(0, 0.6, 0)
  ]);
  const points = curve.getPoints(300);
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  const material = new THREE.LineBasicMaterial({ color: 0xff0000 });

  const LeftBigSqaure = new THREE.Line(geometry, material);
  const RightBigSqaure = new THREE.Line(geometry, material);
  const LeftSmallSquare = new THREE.Line(geometry, material);
  const RightSmallSquare = new THREE.Line(geometry, material);
  const CenterSquare = new THREE.Line(geometry, material);

  scene.add(LeftBigSqaure);
  scene.add(RightBigSqaure);
  scene.add(LeftSmallSquare);
  scene.add(RightSmallSquare);
  scene.add(CenterSquare);

  if (!props.cameraCoordsX) {
    if (props.name) {
      LeftBigSqaure.scale.x = props.name.scaleCounter
        ? props.name.scaleCounter * 0.5
        : 0.5;
      LeftBigSqaure.scale.y = props.name.scaleCounter
        ? props.name.scaleCounter * 0.5
        : 0.5;
      LeftBigSqaure.scale.z = props.name.scaleCounter
        ? props.name.scaleCounter * 0.5
        : 0.5;
      LeftBigSqaure.position.x = props.name.scaleCounter
        ? props.name.scaleCounter * -2.3
        : -2.3;
      LeftBigSqaure.position.y = props.name.scaleCounter
        ? props.name.scaleCounter * -3
        : -2.5;
      LeftBigSqaure.rotation.y = 1.57;

      RightBigSqaure.scale.x = props.name.scaleCounter
        ? props.name.scaleCounter * 0.5
        : 0.5;
      RightBigSqaure.scale.y = props.name.scaleCounter
        ? props.name.scaleCounter * 0.5
        : 0.5;
      RightBigSqaure.scale.z = props.name.scaleCounter
        ? props.name.scaleCounter * 0.5
        : 0.5;
      RightBigSqaure.position.x = posRightSquareX
        ? posRightSquareX
        : props.name.scaleCounter
        ? props.name.scaleCounter * 2.3
        : 2.3;
      RightBigSqaure.position.y = props.name.scaleCounter
        ? props.name.scaleCounter * -3
        : -2.5;
      RightBigSqaure.rotation.y = 1.57;

      RightSmallSquare.scale.x = props.name.scaleCounter
        ? props.name.scaleCounter * 0.45
        : 0.45;
      RightSmallSquare.scale.y = props.name.scaleCounter
        ? props.name.scaleCounter * 0.45
        : 0.45;
      RightSmallSquare.scale.z = props.name.scaleCounter
        ? props.name.scaleCounter * 0.45
        : 0.45;
      RightSmallSquare.position.x = posRightSquareX
        ? posRightSquareX
        : props.name.scaleCounter
        ? props.name.scaleCounter * 2.3
        : 2.3;
      RightSmallSquare.position.y = props.name.scaleCounter
        ? props.name.scaleCounter * -2.8
        : -2.25;
      RightSmallSquare.rotation.y = 1.57;

      LeftSmallSquare.scale.x = props.name.scaleCounter
        ? props.name.scaleCounter * 0.45
        : 0.45;
      LeftSmallSquare.scale.y = props.name.scaleCounter
        ? props.name.scaleCounter * 0.45
        : 0.45;
      LeftSmallSquare.scale.z = props.name.scaleCounter
        ? props.name.scaleCounter * 0.45
        : 0.45;
      LeftSmallSquare.position.x = props.name.scaleCounter
        ? props.name.scaleCounter * -2.3
        : -2.3;
      LeftSmallSquare.position.y = props.name.scaleCounter
        ? props.name.scaleCounter * -2.8
        : -2.25;
      LeftSmallSquare.rotation.y = 1.57;

      CenterSquare.scale.x = scaleX
        ? scaleX
        : props.name.scaleCounter
        ? props.name.scaleCounter * 0.3
        : 0.2;
      CenterSquare.scale.y = scaleY
        ? scaleY
        : props.name.scaleCounter
        ? props.name.scaleCounter * 0.3
        : 0.2;
      CenterSquare.scale.z = scaleZ
        ? scaleZ
        : props.name.scaleCounter
        ? props.name.scaleCounter * 0.3
        : 0.2;
      CenterSquare.position.x = posX ? posX : 0;
      CenterSquare.position.z = posZ
        ? posZ
        : props.name.scaleCounter
        ? props.name.scaleCounter * -4
        : -3;
      CenterSquare.position.y = posY
        ? posY
        : props.name.scaleCounter
        ? props.name.scaleCounter * -1
        : 0;
    } else {
      LeftBigSqaure.scale.x = 0.5;
      LeftBigSqaure.scale.y = 0.5;
      LeftBigSqaure.scale.z = 0.5;
      LeftBigSqaure.position.x = -2.3;
      LeftBigSqaure.position.y = -2.5;
      LeftBigSqaure.rotation.y = 1.57;

      RightBigSqaure.scale.x = 0.5;
      RightBigSqaure.scale.y = 0.5;
      RightBigSqaure.scale.z = 0.5;
      RightBigSqaure.position.x = posRightSquareX ? posRightSquareX : 2.3;
      RightBigSqaure.position.y = -2.5;
      RightBigSqaure.rotation.y = 1.57;

      RightSmallSquare.scale.x = 0.45;
      RightSmallSquare.scale.y = 0.45;
      RightSmallSquare.scale.z = 0.45;
      RightSmallSquare.position.x = posRightSquareX ? posRightSquareX : 2.3;
      RightSmallSquare.position.y = -2.25;
      RightSmallSquare.rotation.y = 1.57;

      LeftSmallSquare.scale.x = 0.45;
      LeftSmallSquare.scale.y = 0.45;
      LeftSmallSquare.scale.z = 0.45;
      LeftSmallSquare.position.x = -2.3;
      LeftSmallSquare.position.y = -2.25;
      LeftSmallSquare.rotation.y = 1.57;

      CenterSquare.scale.x = scaleX ? scaleX : 0.2;
      CenterSquare.scale.y = scaleY ? scaleY : 0.2;
      CenterSquare.scale.z = scaleZ ? scaleZ : 0.2;
      CenterSquare.position.x = posX ? posX : 0;
      CenterSquare.position.z = posZ ? posZ : -3;
      CenterSquare.position.y = posY ? posY : 0;
    }
  } else {
    LeftBigSqaure.scale.x = 1;
    LeftBigSqaure.scale.y = 1;
    LeftBigSqaure.scale.z = 1;
    LeftBigSqaure.position.x = -8.3;
    LeftBigSqaure.position.y = -2.5;
    LeftBigSqaure.rotation.y = 1.57;

    RightBigSqaure.scale.x = 1;
    RightBigSqaure.scale.y = 1;
    RightBigSqaure.scale.z = 1;
    RightBigSqaure.position.x = 8.3;
    RightBigSqaure.position.y = -2.5;
    RightBigSqaure.rotation.y = 1.57;

    LeftSmallSquare.scale.x = 0.9;
    LeftSmallSquare.scale.y = 0.9;
    LeftSmallSquare.scale.z = 0.9;
    LeftSmallSquare.position.x = 8.3;
    LeftSmallSquare.position.y = -2.25;
    LeftSmallSquare.rotation.y = 1.57;

    RightSmallSquare.scale.x = 0.9;
    RightSmallSquare.scale.y = 0.9;
    RightSmallSquare.scale.z = 0.9;
    RightSmallSquare.position.x = -8.3;
    RightSmallSquare.position.y = -2.25;
    RightSmallSquare.rotation.y = 1.57;

    CenterSquare.scale.x = 0.5;
    CenterSquare.scale.y = 0.5;
    CenterSquare.scale.z = 0.5;
    CenterSquare.position.z = -7;
  }
};
