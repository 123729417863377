import React from 'react';
// import { Link } from 'react-router-dom';
// import linkedin from '../../assets/icons/linkedin.png';
// import facebook from '../../assets/icons/facebook.png';
// import instagram from '../../assets/icons/instagram.png';

// import { menuItems } from '../../assets/mockData/mockData';
// import { useAppContext } from '../../context/AppProvider';

const AppFooter = () => {
  // const { store } = useAppContext();
  // const { selectedLanguage } = store;

  return (
    <div>
      {/* <footer className="h-58 w-full bg-black  ">
        <div className="flex px-5 pt-10 sm:space-x-32 smmax:space-x-16">
          <div className="h-full w-64 flex  flex-col justify-end space-y-2 ">
            <div>
              <Link to={'/categories'} className="text-white ">
                {menuItems.categories[selectedLanguage]}
              </Link>
            </div>
            <div className="w-full text-white">
              <Link to={'/aboutUs'}>{menuItems.aboutUs[selectedLanguage]}</Link>
            </div>
            <div>
              <Link to={'/contactUs'} className="text-white">
                {menuItems.contactUs[selectedLanguage]}
              </Link>
            </div>
            <div>
              <Link to={'/helpAndSupport'} className="text-white ">
                {menuItems.helpAndSupport[selectedLanguage]}
              </Link>
            </div>
            <div className="flex flex-col mt-10">
              <div className="flex  w-full items-center">
                <Link
                  to={'/'}
                  className="underline decoration-rose-400 font-tinos bg-black-900 text-3xl text-white  pb-7 pt-4"
                >
                  Lectio
                </Link>
              </div>
              <div className="space-x-5 xsmax:space-x-2 ">
                <div className="text-blueGray-500 text-center inline-flex items-center justify-center w-10 h-10 mb-6 shadow-lg rounded-full bg-white">
                  <a
                    target="_blank"
                    className=""
                    href="https://md.linkedin.com/company/brosweb"
                    rel="noreferrer"
                  >
                    <img src={linkedin} alt="Logo" className="w-full h-full" />
                  </a>
                </div>
                <div className="text-blueGray-500 text-center inline-flex items-center justify-center w-10 h-10 mb-6 shadow-lg rounded-full bg-white">
                  <a
                    target="_blank"
                    className=""
                    href="https://www.facebook.com/profile.php?id=100063569321747"
                    rel="noreferrer"
                  >
                    <img src={facebook} alt="Logo" className="w-full h-full" />
                  </a>
                </div>
                <div className="text-blueGray-500 text-center inline-flex items-center justify-center w-10 h-10 mb-6 shadow-lg rounded-full bg-white">
                  <a
                    target="_blank"
                    className=""
                    href="https://www.instagram.com/lectio.vr/?next=%2F"
                    rel="noreferrer"
                  >
                    <img src={instagram} alt="Logo" className="w-full h-full" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" h-12 w-64 flex flex-col mb-2  space-y-2">
            <div>
              <Link to={'/Terms'} className="text-white">
                {menuItems.terms[selectedLanguage]}
              </Link>
            </div>
            <div>
              <Link to={'/PrivacyPolicy'} className="text-white mt-2">
                {menuItems.privacyPolicy[selectedLanguage]}
              </Link>
            </div>
          </div>
        </div>
        <div className="space-y-1">
          <p className="text-white p-0 text-center ">©2022</p>

          <div className="w-full text-center flex flex-row items-center justify-center">
            <a
              target="_blank"
              className="text-white p-0 flex "
              href="https://brosweb.site"
              rel="noreferrer"
            >
              {menuItems.poweredBy[selectedLanguage]} &nbsp;
              <p className="underline"> BrosWeb</p>
            </a>
          </div>
          <div className="w-full pb-4 text-center">
            <a
              className="text-white p-0 text-center"
              href="https://www.flaticon.com/free-icons/linkedin"
              title="linkedin and Facebook icons"
            >
              Linkedin and Facebook icons created by Google - Flaticon
            </a>
          </div>
        </div>
      </footer> */}
    </div>
  );
};

export default AppFooter;
