/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import ModelViewer from './ModelViewer';
import './styles.css';
import Wrapper from '../../../../common/SceneModelGeneral/wrappper';
import SketchfabIntegration from '../../../../common/SketchfabIntegration';
import { useLocation } from 'react-router-dom';
import { ContentApi } from '../../../../api/content.api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useHttp } from '../../../../hooks/useHttp';
import Loader from '../../../../common/loader'; // Assuming you have a loader component
import Toaster from '../../../../common/toaster';
import { useAppContext } from '../../../../context/AppProvider';

const ModelViewerPage = () => {
  const { setCategoryCollection } = useAppContext();
  const [modelFile, setModelFile] = useState('');
  const [modelLink, setModelLink] = useState('');
  const [modelName, setModelName] = useState('');
  const [uploadedModels, setUploadedModels] = useState([]);
  const [draggingModel, setDraggingModel] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState({ type: '', value: null });
  const [loading, setLoading] = useState(false); // Add loading state
  const [topicCreated, setTopicCreated] = useState(false);
  const history = useHistory();
  const modelData = useRef();
  const { apiUrl } = useHttp();
  const navigate = useHistory();

  const token = window.localStorage.getItem('userToken');
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const location = useLocation();
  const isAdminUrl = location.pathname.includes('admin');
  const userToken = window.localStorage.getItem('userToken');

  // Refs for form fields
  const topicNameRef = useRef();

  const requestCategories = async (userId) => {
    const response = await ContentApi.getCategoryCollection(
      userToken,
      { parentId: userId },
      navigate
    );
    setCategoryCollection(response.categories);
  };

  const collectModelData = (data) => {
    modelData.current = data;
  };

  const handleSaveTopic = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const ref = topicNameRef.current.value;

      const collection = await ContentApi.getCategoryCollection(
        token,
        { parentId: userData.userId },
        history
      );

      const collectionId = collection?.categories?.categories?.find(
        (element) => element.categoryName === 'Custom'
      ).categoryId;

      const data = {
        topicContent: 'Test content',
        topicName: {
          EN: ref,
          RO: ref
        },
        modelLink,
        sceneId: '',
        isSpecialModule: false,
        categoryId: collectionId,
        categoryName: 'Custom',
        parentId: userData.userId,
        topicId: '',
        modelName,
        modelData: modelData.current,
        modelFile
      };
      const form = new FormData();

      const response = await fetch(modelFile);
      if (!response.ok) {
        throw new Error('Failed to download the file');
      }
      const blob = await response.blob();

      // Prepare the form data for uploading
      setModelName(modelName);
      form.append('file', blob, `${modelName}`);

      try {
        await fetch(apiUrl + '/api/school/content/upload', {
          method: 'POST',
          headers: {
            Authorization: `${token}`
          },
          body: form
        });
        await ContentApi.createNewTopic(token, data, history);
        setTopicCreated(true);
        setError({
          type: 'Passed',
          value: 'Model uploaded successfully!'
        });
        requestCategories(userData.userId);
      } catch (error) {
        setError({
          type: 'Error',
          value: 'Failed to upload the model.'
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!topicNameRef.current.value) {
      errors.topicName = 'Topic Name is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFileDrop = (event, setFile, setFileName, setDragging) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        setFile(reader.result);
        setFileName(files[0].name);
      };
    }
    setDragging(false);
  };

  const handleDragOver = (event, setDragging) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (event, setDragging) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleInputChange = (event, setFile, setFileName) => {
    const files = event.target.files;
    if (files && files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        setFile(reader.result);
        setFileName(files[0].name);
      };
    }
  };

  const handleClick = (inputId) => {
    document.getElementById(inputId).click();
  };

  useEffect(() => {
    if (error.type) {
      const timeDelay = setTimeout(() => {
        setError({ type: '', value: null });
        clearTimeout(timeDelay);
      }, 4000);
    }
  }, [error]);

  return (
    <div className="w-full overflow-hidden relative flex flex-col flex-grow p-10">
      {error.type && <Toaster error={error} />}
      {/* Show loader when loading is true */}
      {!isAdminUrl ? (
        <SketchfabIntegration
          file={modelFile}
          setModelLink={setModelLink}
          setFile={setModelFile}
          setModelName={setModelName}
          setUploadedModels={setUploadedModels}
        />
      ) : (
        <>
          <h1 className="font-gothic text-2xl text-center py-2">
            Drag and drop your model here
          </h1>
          <h1 className="font-gothic text-2xl text-center py-2">
            You can upload only one model
          </h1>

          <div
            onDrop={(e) =>
              handleFileDrop(e, setModelFile, setModelName, setDraggingModel)
            }
            onDragOver={(e) => handleDragOver(e, setDraggingModel)}
            onDragLeave={(e) => handleDragLeave(e, setDraggingModel)}
            onClick={() => handleClick('modelFileInput')}
            className={`border-dashed border-2 p-6 text-center cursor-pointer ${
              draggingModel ? 'border-green-500' : 'border-gray-300'
            }`}
          >
            <p>
              Drag &apos;n&apos; drop a model file here, or click to select one
            </p>
            <input
              type="file"
              id="modelFileInput"
              onChange={(e) => handleInputChange(e, setModelFile, setModelName)}
              accept=".gltf, .glb, .fbx, .obj, .stl"
              className="hidden"
            />
          </div>
          {formErrors.modelFile && (
            <p className="text-red-500 text-sm">{formErrors.modelFile}</p>
          )}

          {modelFile && (
            <div className="mt-2">
              <p className="text-sm">Uploaded Model: {modelName}</p>
            </div>
          )}
        </>
      )}
      {/* <div className="flex ">
        
      </div> */}
      <div
        id="contentScene"
        className="border w-11/12 mx-auto p-1 my-4 flex relative justify-center items-end"
      >
        <Wrapper>
          <div className="flex-grow h-800  z-1 bg-center flex justify-center">
            <ModelViewer
              file={modelFile}
              setFile={setModelFile}
              isAdminUrl={isAdminUrl}
              isCreateTopic={true}
              modelName={modelName}
              collectModelData={collectModelData}
              setUploadedModels={setUploadedModels}
              uploadedModels={uploadedModels}
            />
          </div>
        </Wrapper>
      </div>
      <form onSubmit={handleSaveTopic} className="mt-4">
        <div className="mb-4">
          <label
            htmlFor="topicName"
            className="block text-sm font-medium text-gray-700"
          >
            Enter Topic Name
          </label>
          <input
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
            type="text"
            id="topicName"
            ref={topicNameRef}
            required
          />
          {formErrors.topicName && (
            <p className="text-red-500 text-sm">{formErrors.topicName}</p>
          )}
        </div>
        {loading ? (
          <Loader />
        ) : (
          <button
            type="submit"
            className={`px-4 py-2  ${
              topicCreated ? 'bg-gray-300' : 'bg-green-500'
            }  text-white rounded-lg mt-4`}
            disabled={topicCreated}
          >
            {topicCreated ? 'Topic created' : 'Save Topic'}
          </button>
        )}
      </form>
    </div>
  );
};

export default ModelViewerPage;
