/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect, useState, useRef } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import { coursesData } from '../../../../assets/mockData/mockData';
import { SceneModuleManager } from '../../../sceneModuleManager/sceneModuleManager';
import Wrapper from '../../../../common/SceneModelGeneral/wrappper';
import Loader from '../../../../common/loader';
import { menuItems } from '../../../../assets/mockData/mockData';
import { useAppContext } from '../../../../context/AppProvider';
import { ContentApi } from '../../../../api/content.api';
import { isObject } from '../../../../utils';
import SceneModelSpecific from '../../../../common/sceneModelSpecific';
import { useHistory } from 'react-router-dom';
import { socket } from '../../../../common/socketConnection';
import vrIcon from '../../../../assets/icons/vr-cardboard-solid.svg';
import webIcon from '../../../../assets/icons/world-wide-web-svgrepo-com.svg';

const CategoryTopic = () => {
  const { categoryId, topicId } = useParams();
  const history = useHistory();
  let { url } = useRouteMatch();
  const { store } = useAppContext();
  const { selectedLanguage, user } = store;
  const containerRef = useRef(null);
  const roomIdRef = useRef();
  const [error, setError] = useState({ type: '', value: null });
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [visibleCloseButton, setVisibleCloseButton] = useState(false);
  const [name, setName] = useState('');
  const [roomId, setRoomId] = useState('');
  const [joinRoomIsOpen, setJoinRoomIsOpen] = useState(false);
  const [showConnectionError, setShowConnectionError] = useState(false);
  const [membersNumber, setMembersNumber] = useState(0);
  const [isWebVr, setIsWebVr] = useState(false);
  const [topicData, setTopicData] = useState({});
  const [isLocalData, setIsLocalData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedModel, setSelectedModel] = useState('');
  const [externalLoading, setExternalLoading] = useState(false);
  const userToken = window.localStorage.getItem('userToken');
  const lang = window.localStorage.getItem('language');

  const createRoom = () => {
    let max = 9;
    let min = 0;
    var n = [];
    for (var i = 0; i < 3; i++) {
      n.push(Math.floor(Math.random() * max) + min);
    }
    socket.emit('newRoom', { room: n.join(''), url });
    socket.room = n.join('');
    socket.admin = socket.id;
    setName('Admin');
    setRoomId(n.join(''));
  };

  const selectUser = (selectedUserID) => {
    socket.emit('selectedUser', { roomId, selectedUserID });
  };

  const checkIfItIsCustomTopic = () => {
    let isSpecialModule = false;
    topicData.isSpecialModule && topicData.component;
    if (topicData.isSpecialModule) {
      isSpecialModule = true;
    }
    if (isSpecialModule && topicData.component) {
      return true;
    }
    return false;
  };

  const nameValidation = (name) => {
    if (name.toLowerCase().indexOf('admin') !== -1) {
      setError({
        type: 'Error',
        value: 'You are not allowed to use "admin" in your name'
      });
      setTimeout(() => {
        setError({ type: '', value: '' });
      }, 6000);
      window.scrollTo(0, 0);
      return false;
    }
    if (!name.toLowerCase().trim().length) {
      setError({
        type: 'Error',
        value: 'You need to fulfill name input'
      });
      setTimeout(() => {
        setError({ type: '', value: '' });
      }, 6000);
      window.scrollTo(0, 0);
      return false;
    }
    return true;
  };
  const joinRoom = () => {
    if (nameValidation(name)) {
      setJoinRoomIsOpen(false);
      socket.emit('joinRoom', { roomId, name });
      socket.room = roomId;
    }
  };
  socket.on('fullRoom', (text) => {
    setError({ type: 'Error', value: text });
    setTimeout(() => {
      setError({ type: '', value: '' });
    }, 6000);
    setConnectedUsers([]);
    setJoinRoomIsOpen(false);
    setRoomId('');
  });

  socket.on('selectedUserCamera', (selectedUserObj) => {
    if (selectedUserObj.selectedUserID && selectedUserObj.admin === socket.id) {
      setVisibleCloseButton(true);
    }
  });

  const exitScreenCastMode = () => {
    socket.emit('deselectUserCamera', roomId);
    setVisibleCloseButton(false);
  };

  const exitRoom = (roomIdOnExitRoom = roomId) => {
    socket.emit('leaveRoom', roomIdOnExitRoom);
    setRoomId('');
    setName('');
    setConnectedUsers([]);
    setJoinRoomIsOpen(false);
  };
  socket.on('connect_error', () => {
    setShowConnectionError(true);
  });
  socket.on('connect', () => {
    setShowConnectionError(false);
  });
  // Event listener for Socket.IO disconnect event
  useEffect(() => {
    if (topicData?.multipleModels?.length) {
      setSelectedModel(topicData.multipleModels[0]);
    } else if (topicData?.type) {
      setSelectedModel(topicData?.type);
    } else if (topicData?.modelFile) {
      setSelectedModel(topicData?.modelFile);
    } else if (topicData?.sceneId) {
      setSelectedModel(topicData.sceneId);
    }
  }, [topicData]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        joinRoom();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [roomId]);

  useEffect(async () => {
    if (user?.role) {
      if (user.role === 'school' || user.role === 'learner') {
        const response = await ContentApi.getTopicById(
          userToken,
          topicId,
          history
        );

        setIsLocalData(false);
        const data = coursesData
          .find((course) => course.path === categoryId)
          .topics.find((topic) => topic.type === response.topic.sceneId);
        setTopicData({
          ...data,
          ...response?.topic
        });
      } else {
        const topicObj = coursesData
          .find((course) => course.path === categoryId)
          .topics.find((topic) => topic.id === topicId);
        setIsLocalData(true);
        setTopicData(topicObj);
      }
    } else {
      const topicObj = coursesData
        .find((course) => course.path === categoryId)
        .topics.find((topic) => topic.id === topicId);
      setIsLocalData(true);
      setTopicData(topicObj);
    }
  }, [user]);
  navigator.xr.isSessionSupported('immersive-vr').then((supported) => {
    supported ? setIsWebVr(false) : setIsWebVr(true);
  });

  useEffect(() => {
    if (topicData?.name || topicData?.topicName) {
      setLoading(false);
    }
    socket.on('connect_failed', function () {
      console.log('Sorry, there seems to be an issue with the connection!');
    });
    socket.on('membersNumber', ({ num, roomId }) => {
      if (roomId) {
        if (localStorage.getItem('isUrlModified')) {
          localStorage.removeItem('isUrlModified');
        }
        setRoomId(roomId);
      }
      setMembersNumber(num);
    });

    socket.on('storeWebUsers', (users) => {
      setConnectedUsers(users);
    });
    socket.on('isRoomExists', ({ isRoomExists, room, url, users }) => {
      if (!isRoomExists) {
        socket.emit('leaveRoom', room);
        setRoomId('');
        setJoinRoomIsOpen(false);
        setError({ type: 'Error', value: 'You introduced wrong data' });
        setTimeout(() => {
          setError({ type: '', value: '' });
        }, 6000);
        window.scrollTo(0, 0);
      } else {
        if (users) {
          setConnectedUsers(users);
        }
        if (url !== history.location.pathname) {
          localStorage.setItem('isUrlModified', true);
          history.push(url.slice(1));
        }
      }
    });
  }, [topicData]);

  useEffect(() => {
    if (showConnectionError) {
      setError({ type: 'Error', value: 'At present, rooms are not available' });
    } else {
      setError({ type: '', value: '' });
    }
  }, [showConnectionError]);

  useEffect(() => {
    return () => {
      if (!localStorage.getItem('isUrlModified')) {
        exitRoom(roomIdRef.current);
        localStorage.removeItem('isUrlModified');
      }
    };
  }, []);

  useEffect(() => {
    roomIdRef.current = roomId;
  }, [roomId]);

  return (
    <div className={'bg-white-bg h-fit'}>
      <div
        className={
          'h-80   bg-gradient-to-r overflow-hidden z-1 from-gray-500 to-gray-700 bg-no-repeat bg-cover bg-center flex justify-center'
        }
      >
        {error.type && (
          <div
            className={` border  transition ease-in-outz-1 smmax:w-3/4 xsmax:w-3/4  duration-100 
                ${
                  error.type === 'Passed'
                    ? 'border-green-400 text-green-700 bg-green-100 '
                    : 'border-red-400 text-red-700 bg-red-100 '
                } translate-x-16 mt-5 right-20 absolute  z-10  flex justify-between px-6   py-3 rounded `}
            role="alert"
          >
            <div className="flex flex-col  smmin:pr-32">
              <strong className="font-bold  ">
                {error.type === 'error' ? error.type : error.type}
              </strong>
              <span className="block sm:inline ">{error.value}</span>
            </div>
            <span className="justify-end   flex pl-3  py-3">
              <svg
                onClick={() => setError({ type: '', value: null })}
                className={`self-end fill-current h-6 w-6 ${
                  error.type === 'Passed' ? 'text-green-500' : 'text-red-500 '
                } `}
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        )}
        <div className="smmax:w-11/12 my-auto  m-auto h-full chalkboard">
          <div
            className={
              ' w-full smmin:px-16 p-4 h-full flex flex-col justify-center text-center md:text-center '
            }
          >
            {loading ? (
              <div className={'w-full flex justify-center items-center'}>
                <Loader />
              </div>
            ) : (
              <p
                className={
                  'text-white font-chalk smmin:text-4xl smmax:text-2xl tracking-widest font-bold'
                }
              >
                {isLocalData
                  ? topicData?.name[selectedLanguage]
                  : isObject(topicData?.topicName)
                  ? topicData?.topicName[selectedLanguage]
                  : topicData?.topicName}
              </p>
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <div className={'w-full p-10 h-screen flex justify-center items-start'}>
          <Loader />
        </div>
      ) : (
        <div className={'h-4/6 grid-cols-12 mb-20 flex justify-center'}>
          <div
            className={' w-full md:w-10/12 pt-5  flex flex-col items-center  '}
          >
            <div
              className={'w-full md:w-2/3 p-4 my-4 text-justify '}
              dangerouslySetInnerHTML={{ __html: topicData.topicContent }}
            ></div>

            {joinRoomIsOpen && !showConnectionError ? (
              <div className="w-96 h-full p-2  flex self-end  ">
                <div className="w-full flex-col justify-between	m-2 items-center ">
                  <label htmlFor="user-name">Name</label>
                  <input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    className="rounded border-1 ml-2 p-2 shadow-md"
                    name="user-name"
                    type="text"
                    autoFocus
                  ></input>
                  <label htmlFor="room-name" className="mt-2">
                    RoomId
                  </label>
                  <input
                    onChange={(e) => setRoomId(e.target.value)}
                    className="rounded border-1 ml-2 p-2 shadow-md"
                    value={roomId}
                    name="room-name"
                    type="text"
                    autoFocus
                  ></input>
                </div>

                <button
                  className={
                    'p-2 m-2 text-white rounded	font-gothic font-bold self-end bg-blue-500'
                  }
                  onClick={() => {
                    joinRoom();
                  }}
                >
                  Join
                </button>
                <button
                  className={
                    'p-2 m-2 text-white rounded	font-gothic font-bold self-end bg-red-500'
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setJoinRoomIsOpen(false);
                    setRoomId('');
                  }}
                >
                  Close
                </button>
              </div>
            ) : roomId && !showConnectionError ? (
              <div className={'flex  w-full items-end flex-col'}>
                <p className="font-gothic m-2">{`Room name: ${roomId}`}</p>
                <p className="font-gothic m-2">{`Members: ${membersNumber}/4`}</p>
                <button
                  className={
                    'p-2 m-2 text-white rounded	font-gothic font-bold self-end bg-red-500'
                  }
                  onClick={() => {
                    setJoinRoomIsOpen(false);
                    exitRoom();
                  }}
                >
                  Exit
                </button>
                {visibleCloseButton && (
                  <button
                    className={
                      'p-2 m-2 text-white rounded	font-gothic font-bold self-end bg-red-500'
                    }
                    onClick={() => {
                      exitScreenCastMode();
                    }}
                  >
                    Stop screen view
                  </button>
                )}
              </div>
            ) : (
              <div className={'flex  w-full justify-end'}>
                <button
                  className={`p-2 m-2 text-white rounded	font-gothic font-bold self-end  ${
                    checkIfItIsCustomTopic() || showConnectionError
                      ? 'bg-slate-400'
                      : 'bg-gradient-to-r from-purple-500 to-fuchsia-500'
                  } `}
                  disabled={showConnectionError || checkIfItIsCustomTopic()}
                  onClick={() => {
                    setJoinRoomIsOpen(true);
                  }}
                >
                  + Join room
                </button>
                <button
                  className={`p-2 m-2 text-white rounded	font-gothic font-bold self-end ${
                    checkIfItIsCustomTopic() || showConnectionError
                      ? 'bg-slate-400'
                      : 'bg-gradient-to-r from-purple-500 to-fuchsia-500'
                  } `}
                  disabled={showConnectionError || checkIfItIsCustomTopic()}
                  onClick={createRoom}
                >
                  + Create room
                </button>
              </div>
            )}

            {false &&
              topicData?.multipleModels?.length &&
              topicData?.multipleModels.map((model) => {
                return (
                  <div
                    className={`cursor-pointer ${
                      selectedModel === model && 'decoration-1 font-bold'
                    }`}
                    onClick={() => {
                      setExternalLoading(true);
                      setSelectedModel(model);
                      setInterval(() => {
                        setExternalLoading(false);
                      }, 500);
                    }}
                    key={model}
                  >
                    {model}
                  </div>
                );
              })}
            <div
              id="contentScene"
              className={
                'border  w-full p-1 my-4 flex relative  justify-center items-end  '
              }
              ref={containerRef}
            >
              <Wrapper
                legend={topicData.legend}
                moduleType={topicData.isSpecialModule}
              >
                {topicData.isSpecialModule &&
                  topicData.component &&
                  topicData.component(
                    true,
                    url,
                    lang,
                    containerRef?.current?.clientWidth,
                    containerRef?.current?.clientHeight
                  )}
                {topicData.isSpecialModule &&
                  !topicData.component &&
                  topicData.sceneId && (
                    <SceneModelSpecific
                      isPreview={true}
                      sceneId={topicData.sceneId}
                      url={url}
                      lang={lang}
                      previewWidth={containerRef?.current?.clientWidth}
                      previewHeight={containerRef?.current?.clientHeight}
                    />
                  )}
                {!externalLoading &&
                  selectedModel &&
                  !topicData?.isSpecialModule && (
                    <SceneModuleManager
                      topicData={topicData}
                      uploadedModelLink={topicData.modelLink}
                      uploadedModelData={topicData.modelData}
                      modelFile={topicData.modelFile}
                      modelName={topicData.modelName}
                      type={selectedModel}
                      url={url}
                      roomZ={topicData?.roomZ}
                      roomY={topicData?.roomY}
                      roomX={topicData?.roomX}
                      z={topicData?.z}
                      y={topicData?.y}
                      x={topicData?.x}
                      aboutThisCourse={topicData?.aboutThisCourse}
                      description={topicData?.description}
                      name={topicData.name}
                      isPreview
                      draggable={topicData?.draggable}
                      legend={topicData.legend}
                      lang={lang}
                      previewWidth={containerRef?.current?.clientWidth}
                      previewHeight={containerRef?.current?.clientHeight}
                      memberId={socket?.id}
                      // isPreviewContainer={state}
                    />
                  )}

                {!externalLoading &&
                  !selectedModel &&
                  !topicData?.isSpecialModule && (
                    <h1>No scene attached to this topic yet</h1>
                  )}

                {externalLoading && (
                  <div
                    style={{ height: '80vh' }}
                    className={
                      'w-full bg-black flex items-center flex-col justify-center opacity-50'
                    }
                  >
                    <p className="text-white opacity-100 font-gothic text-lg mb-2 text-center">
                      Loading...
                    </p>
                    <div className="w-32 h-1 opacity-100 rounded-lg bg-slate-600 ">
                      <div
                        style={{ width: '100%' }}
                        className={
                          ' rounded-full opacitiy-90 h-full bg-cyan-500'
                        }
                      ></div>
                    </div>
                  </div>
                )}
              </Wrapper>
            </div>
            <div className="flex w-full justify-start ">
              {connectedUsers.map((user) => {
                if (user.id !== socket.id) {
                  return (
                    <div
                      onClick={() => selectUser(user.id)}
                      key={user.id}
                      disabled={!socket.admin}
                      className="flex cursor-pointer relative items-center break-words justify-start rounded-md w-36 h-24 border-1 border-grey m-2 "
                      style={{ background: '#222222' }}
                    >
                      {user.vrMode ? (
                        <div className="absolute -bottom-2 left-0 h-6 w-10 rounded-bl-lg pl-1 ">
                          <img
                            className="vrButton"
                            src={vrIcon}
                            color="white"
                            height="16"
                            width="16"
                          />
                        </div>
                      ) : (
                        <div className="absolute -bottom-2 left-0 h-6 w-10 rounded-bl-lg pl-1 ">
                          <img
                            className="vrButton"
                            src={webIcon}
                            color="white"
                            height="12"
                            width="12"
                          />
                        </div>
                      )}
                      <span className="text-white text-xl flex w-full justify-center font-gothic font-bold  p-2">
                        {user.name}
                      </span>
                    </div>
                  );
                }
                return <div key={user.id} />;
              })}
            </div>
          </div>
        </div>
      )}
      {isWebVr ? (
        <div className="font-gothic w-5/6 border-cyan-400 rounded-lg border-2 mb-5 p-2  mx-auto ">
          <h1 className="text-center font-bold text-xl py-1">
            {menuItems.controls.header[selectedLanguage]}
          </h1>
          <ul className="pl-2  pb-2">
            <li>
              <strong>{menuItems.controls.leftMouse[selectedLanguage]}</strong>{' '}
              {menuItems.controls.leftMouseInstruction[selectedLanguage]}
            </li>
            <li>
              <strong>{menuItems.controls.rightMouse[selectedLanguage]}</strong>{' '}
              {menuItems.controls.rightMouseInstruction[selectedLanguage]}
            </li>
            <li>
              <strong>
                {' '}
                {menuItems.controls.scrollWheel[selectedLanguage]}
              </strong>{' '}
              {menuItems.controls.scrollWheelInstruction[selectedLanguage]}
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default CategoryTopic;
