import { pageDown } from './pageDown';
import { pageUp } from './pageUp';

export const pagintationLerping = (self) => {
  self.tabletGroups.forEach((element, i) => {
    if (self.indexOfVisiblePage === i) {
      element.visible = true;
    } else {
      element.visible = false;
    }

    if (
      self.nextPage &&
      self.indexOfBlockerPage + 1 !== self.tabletGroups.length
    ) {
      pageUp(self);
      return 0;
    }
    if (self.prevPage && self.indexOfBlockerPage !== 0) {
      pageDown(self);
      return 0;
    }
    self.prevPage = false;
    self.prevPage = false;
  });
};
