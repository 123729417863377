export const Pagination = (controller, self) => {
  if (controller.userData.selected && !controller.userData.attachedObject) {
    if (controller.userData.selected.name == 'NextPage') {
      self.nextPage = true;
      self.stopAttaching = true;
    } else if (controller.userData.selected.name == 'PrevPage') {
      self.stopAttaching = true;
      self.prevPage = true;
    } else {
      self.stopAttaching = false;
    }
  }
};
