/* eslint-disable no-constant-condition */
/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry';
import styles from './styles.css';
import { VRButton } from '../../../common/VRButton';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { Link } from 'react-router-dom';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { withRouter } from 'react-router-dom';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import ControllerInstruction from '../../../common/controllerInstruction/index';
import { roundedSquare } from '../../../common/SceneModelGeneral/roundedSquareBg';
import createTopicName from '../../../common/SceneModelGeneral/createTopicName';
import { DirectionalLightSet } from '../../../common/SceneModelGeneral/directionalLightset';
import { SpotLightSet } from '../../../common/SceneModelGeneral/spotLightSet';
import { loader } from '../../../common/loaderForEnvironment';
// import { onEnterVr } from '../../../common/SceneModelGeneral/onEnterVr';
import { textToSpeech } from '../../../common/TabletFunctionality/textToSpeech';
import { Tablet } from '../../../common/TabletFunctionality/index';
import { colissionDetecter } from '../../../common/collisionDetector';
import { Pagination } from '../../../common/TabletFunctionality/Pagination';
import { pagintationLerping } from '../../../common/TabletFunctionality/paginationLerping';
import { refreshButton } from '../../../common/refreshButton';
import { playInstruction } from '../../../common/TabletFunctionality/playInstruction';
// import { textSprite } from '../canvasText/textSprite';
class Cycloalkanes extends Component {
  constructor(props) {
    super(props);
    this.lang = props.lang;
    this.containerRef = React.createRef();
    this.state = {
      progress: false,
      progressEnvironment: false,
      loadedFlag: false,
      percent: 0,
      stateFlag: false,
      modelPosition: [],
      modelName: '',
      language: 5,
      flagFullscreen: false,
      loadingType: props.type
    };

    const xInstructionCoord = this.props.name?.controllerCoords?.x
      ? this.props.name.controllerCoords.x
      : 2.1;
    const yInstructionCoord = this.props.name?.controllerCoords?.y
      ? this.props.name.controllerCoords.y
      : 0.7;
    const zInstructionCoord = this.props.name?.controllerCoords?.z
      ? this.props.name.controllerCoords.z
      : 1.5;

    this.loadedMusicBar = true;

    const imageBg = require('../../../assets/img/gradientScene.jpeg');
    this.modelsForSlider = [];
    this.positionForCamera = [];
    this.clock = new THREE.Clock();
    this.scene = new THREE.Scene();
    this.scene.background = this.backgroundTexture;
    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    );
    this.scene.add(this.camera);

    this.camera.position.set(0, 0.8, 2.5);

    this.counterForMolecule = 0;

    this.arrayForSettingVisibileOnRefresh = [];
    this.arrayForSettingInvisibileOnRefresh = [];

    const texture = new THREE.TextureLoader().load(imageBg);

    this.scene.background = texture;
    this.modelPosZ = 0;
    this.modelPosY = 0;
    this.modelPosX = 0;
    this.lineLength = 0;

    this.renderer = new THREE.WebGLRenderer({
      antialias: false,
      powerPreference: 'high-performance'
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.xr.setFramebufferScaleFactor(2.0);
    var ambientLight = new THREE.AmbientLight(0xcccccc);

    //right part spot light

    this.scene.add(ambientLight);
    DirectionalLightSet(this);

    this.start_x;
    this.start_y;

    this.raycaster = new THREE.Raycaster();
    this.workingMatrix = new THREE.Matrix4();
    this.workingVector = new THREE.Vector3();
    this.origin = new THREE.Vector3();

    this.dolly = new THREE.Object3D();
    this.dolly.position.z = 5;
    this.scene.add(this.dolly);
    this.dummyCam = new THREE.Object3D();
    this.camera.add(this.dummyCam);

    this.ControllerInstruction = new ControllerInstruction({
      x: xInstructionCoord,
      y: yInstructionCoord,
      z: zInstructionCoord
    });
    this.ControllerInstruction.bindInstructionToScene(this.scene);

    this.model;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();

    this.renderer.setAnimationLoop(this.render.bind(this));

    this.initScene();
    this.setupVr(this.scene);
  }

  initScene() {
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    } else {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    }
    this.loadModel(this.room);
    if (
      (this.props.location.pathname.indexOf('chemistry') !== -1 ||
        this.props.location.pathname.indexOf('new_topic') !== -1) &&
      this.props.path?.indexOf('chemistry') !== -1
    ) {
      this.loadEnvironment(this.room);
    }
  }

  loadEnvironment(room) {
    const draco = new DRACOLoader();
    const self = this;
    const loader = new GLTFLoader();
    draco.setDecoderConfig({ type: 'js' });
    let environment = require('../../../assets/EnvironmentWithoutSliderDesk.glb');

    draco.setDecoderPath(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/draco/'
    );
    loader.setDRACOLoader(draco);

    loader.load(environment, function (gltf) {
      room.add(gltf.scene);
      self.environment = gltf.scene;
      gltf.scene.position.set(0, 1.3, 0);
      gltf.scene.name = 'environment';
      gltf.scene.visible = false;
      if (gltf?.animations.length) {
        self.mixer = new THREE.AnimationMixer(gltf.scene);
        const clips = gltf?.animations;
        clips.forEach(function (clip) {
          self?.mixer?.clipAction(clip)?.play();
        });
      }
      self.setState({ ...self.state, progressEnvironment: true });
    });
  }
  loadModel(room) {
    const draco = new DRACOLoader();
    const self = this;
    const loader = new GLTFLoader();
    draco.setDecoderConfig({ type: 'js' });
    // const s3BucketUrl = `https://s3.eu-central-1.amazonaws.com/lectio.app/models/${this.props.type}`;
    const model = require('../../../assets/Dicloropentan.glb');
    draco.setDecoderPath(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/draco/'
    );
    loader.setDRACOLoader(draco);
    loader.load(
      //   s3BucketUrl,
      model,
      function (gltf) {
        room.add(gltf.scene);
        self.model = gltf.scene;
        gltf.scene.name = 'loadedModel';
        self.model.getObjectByName('Cyclopentan').visible = false;
        self.model.getObjectByName('NaCl').visible = false;
        self.model.getObjectByName('NaClCopy').visible = false;
        self.arrayForSettingInvisibileOnRefresh.push(
          self.model.getObjectByName('Cyclopentan'),
          self.model.getObjectByName('NaCl'),
          self.model.getObjectByName('NaClCopy')
        );
        self.arrayForSettingVisibileOnRefresh.push(
          self.model.getObjectByName('Dicloropentan'),
          self.model.getObjectByName('Na')
        );

        if (gltf?.animations.length) {
          self.mixer = new THREE.AnimationMixer(gltf.scene);
          const clips = gltf?.animations;
          clips.forEach(function (clip) {
            self.mixer?.clipAction(clip)?.play();
          });
        }

        self.setState({ ...self.state, progress: true });
      },
      function (xhr) {
        self.setState({
          ...self.state,
          percent: (xhr.loaded / xhr.total) * 100
        });
      },
      function (err) {
        console.log('error========>', err);
      }
    );
  }

  async loadText(room, text) {
    const loader = new FontLoader();
    const self = this;
    this.textMeshes = [];
    this.hiddenTextMeshes = [];
    this.textMeshesWorldRotation = [];
    this.quaternion = new THREE.Quaternion();
    await loader.load(
      '/helvetiker_regular.typeface.json',
      function (font) {
        var textGeometry = new TextGeometry(text[self.props.lang], {
          font: font,
          size: text.modelSize ? text.modelSize : 0.1,
          height: text.modelHeight ? text.modelHeight : 0.03
        });
        let textMaterial;

        var mesh = new THREE.Mesh(textGeometry, textMaterial);

        room.add(mesh);

        if (self?.props?.textType != 'static') {
          if (room.getObjectByName('parrent') && text.modelType == 'parrent') {
            room.getObjectByName('parrent').name = 'text';
            if (room.getObjectByName('children'))
              room.getObjectByName('children').name = 'text';
            mesh.name = 'parrent';
          } else if (
            text.modelType == 'parrent' &&
            !room.getObjectByName('parrent')
          ) {
            mesh.name = 'parrent';
          } else if (text.modelType == 'children') {
            mesh.name = 'children';
          } else if (text.name) {
            mesh.name = text.name;
          } else {
            mesh.name = 'text';
          }
          if (
            text.name === 'CyclopentanText' ||
            text.name === 'NaClText' ||
            text.name === '2' ||
            text.name === '1'
          ) {
            self.hiddenTextMeshes.push(mesh);
            mesh.visible = false;
          }
          if (text.modelType === 'children') {
            room.getObjectByName('parrent').add(mesh);
          }
        } else {
          mesh.name = 'static';
        }
        if (text.name !== 'loader') {
          self.textMeshes.push(mesh);
          self.textMeshesWorldRotation.push(self.quaternion);
        } else {
          mesh.name = text.name;
          mesh.visible = false;
        }
        mesh.getWorldQuaternion(self.quaternion);

        if (text.modelCoordsX && text.modelCoordsY && text.modelCoordsZ) {
          mesh.position.set(
            text.modelCoordsX,
            text.modelCoordsY,
            text.modelCoordsZ
          );
        } else {
          mesh.position.set(0, 0, 0);
        }
        if (text.modelRotationX && text.modelRotationY && text.modelRotationZ) {
          mesh.rotation.set(
            text.modelRotationX,
            text.modelRotationY,
            text.modelRotationZ
          );
        } else {
          mesh.rotation.set(0, 0, 0);
        }
      }

      // onError callback
    );
  }

  setupVr(scene) {
    this.renderer.xr.enabled = true;
    const self = this;
    this.controllers = this.buildControllers(scene);
    function onConnected(e) {
      this.gamepad = e.data.gamepad;
      self.updateControllers({
        right: { trigger: true, squeeze: true, side: e.data.handedness },
        left: { trigger: true, squeeze: true, side: e.data.handedness }
      });
    }

    const controllers = [
      this.renderer.xr.getController(0),
      this.renderer.xr.getController(1)
    ];

    controllers.forEach((element) => {
      element.addEventListener('connected', onConnected);
    });
  }

  updateControllers(info) {
    const self = this;
    this.stopAttaching = false;

    function onSelectStart() {
      this.children[0].scale.z = 0;
      this.userData.selectPressed = true;
      const loadedModel = self.room.getObjectByName('loadedModel');

      Pagination(this, self);
      refreshButton(
        this,
        self,
        self.arrayForSettingInvisibileOnRefresh,
        self.arrayForSettingVisibileOnRefresh
      );
      if (!self.sound?.isPlaying) {
        if (loadedModel.getObjectByName('Dicloropentan').visible)
          playInstruction(this, self, self.props.meshes[0]);
        else if (!loadedModel.getObjectByName('Dicloropentan').visible) {
          playInstruction(
            this,
            self,
            self.props.meshes[self.props.meshes.length - 1]
          );
        }
      }
      if (this.userData.selected && !this.userData.attachedObject) {
        if (
          this.userData.selected.name == 'tabletSoundOn' &&
          self.props.description[self.props.lang]
        ) {
          if (self.loadedMusicBar) {
            textToSpeech(self);
          }
          return 0;
        } else if (this.userData.selected.name == 'tabletSoundOff') {
          if (self.sound.isPlaying) {
            self.sound.pause();
            self.loadedMusicBar = true;
          }
          return 0;
        }
      }
      if (
        this.userData.selected &&
        !this.userData.attachedObject &&
        !self.posModelX &&
        !self.posModelY &&
        !self.posModelZ &&
        !self.stopAttaching
      ) {
        self.posModelX = this.userData.selected.position.x;
        self.posModelY = this.userData.selected.position.y;
        self.posModelZ = this.userData.selected.position.z;
        self.scaleModelX = this.userData.selected.scale.x;
        self.scaleModelY = this.userData.selected.scale.y;
        self.scaleModelZ = this.userData.selected.scale.z;
        self.rotationModelX = this.userData.selected.rotation.x;
        self.rotationModelY = this.userData.selected.rotation.y;
        self.rotationModelZ = this.userData.selected.rotation.z;

        this.userData.attachedObject = this.userData.selected;

        this.attach(this.userData.selected);
        self.setState({
          modelName: this.userData.selected.name,
          executed: true
        });
      }
    }

    function onSelectEnd() {
      this.userData.selectPressed = false;
      const loadedModel = self.room.getObjectByName('loadedModel');

      if (this.userData.attachedObject !== undefined) {
        this.remove(this.userData.attachedObject);
        self.model.add(this.userData.attachedObject);
        self.model
          .getObjectByName(self.state.modelName)
          .position.set(self.posModelX, self.posModelY, self.posModelZ);
        self.model
          .getObjectByName(self.state.modelName)
          .rotation.set(
            self.rotationModelX,
            self.rotationModelY,
            self.rotationModelZ
          );
        self.model.getObjectByName(self.state.modelName).scale.x =
          self.scaleModelX;
        self.model.getObjectByName(self.state.modelName).scale.y =
          self.scaleModelY;
        self.model.getObjectByName(self.state.modelName).scale.z =
          self.scaleModelZ;

        if (this.collisionDetected) {
          self.counterForMolecule++;
          if (self.counterForMolecule >= 2) {
            self.textMeshes.forEach((element, index) => {
              if (index > 3) {
                element.visible = true;
              } else {
                element.visible = false;
              }
            });
            loadedModel.getObjectByName('Cyclopentan').visible = true;
            loadedModel.getObjectByName('NaCl').visible = true;
            loadedModel.getObjectByName('NaClCopy').visible = true;
            loadedModel.getObjectByName('Dicloropentan').visible = false;
            loadedModel.getObjectByName('Na').visible = false;
            self.counterForMolecule = 0;
          }
        }
        this.userData.attachedObject = undefined;
        this.userData.selected = undefined;
        this.children[1].visible = false;
        this.children[0].scale.z = 10;
        self.posModelX = undefined;
        self.posModelY = undefined;
        self.posModelZ = undefined;
      }
    }

    function onDisconnected() {
      const index = this.userData.index;

      if (self.controllers) {
        const obj = index == 0 ? self.controllers.right : self.controllers.left;

        if (obj) {
          if (obj.controller) {
            const controller = obj.controller;
            while (controller.children.length > 0)
              controller.remove(controller.children[0]);
            self.scene.remove(controller);
          }
          if (obj.grip) self.scene.remove(obj.grip);
        }
      }
    }

    if (info.right !== undefined && info.right.side === 'right') {
      const right = this.renderer.xr.getController(0);

      let trigger = false;

      Object.keys(info.right).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
      });
      if (trigger) {
        right.addEventListener('selectstart', onSelectStart);
        right.addEventListener('selectend', onSelectEnd);
      }

      right.addEventListener('disconnected', onDisconnected);
    }

    if (info.left !== undefined && info.left.side === 'left') {
      const left = this.renderer.xr.getController(1);

      let trigger = false;
      Object.keys(info.left).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
      });
      if (trigger) {
        left.addEventListener('selectstart', onSelectStart);
        left.addEventListener('selectend', onSelectEnd);
      }

      left.addEventListener('disconnected', onDisconnected);
    }
  }

  buildControllers() {
    const controllerModelFactory = new XRControllerModelFactory();
    const geometrySphere = new THREE.IcosahedronBufferGeometry(this.radius, 2);

    const sphere = new THREE.Mesh(
      geometrySphere,
      new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.BackSide })
    );
    sphere.name = 'sphereIndicator';
    sphere.scale.set(0.01, 0.01, 0.01);

    const geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, -1)
    ]);

    const line = new THREE.Line(geometry);
    line.name = 'line';
    line.scale.z = 2;
    sphere.visible = false;
    const controllers = [];

    for (let i = 0; i <= 1; i++) {
      const controller = this.renderer.xr.getController(i);

      controller.side = i === 0 ? 'left' : 'right';
      controller.add(line.clone());
      controller.add(sphere.clone());
      controller.userData.selectPressed = false;

      this.dolly.add(controller);

      controllers.push(controller);

      const grip = this.renderer.xr.getControllerGrip(i);
      grip.add(controllerModelFactory.createControllerModel(grip));

      this.workingMatrix.identity().extractRotation(controller.matrixWorld);

      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);

      controller.children[0].scale.z = 10;

      if (i == 1) {
        this.dolly.add(grip);
      }
    }
    Tablet(this, controllers);

    return controllers;
  }

  handleController(controller) {
    const axes = controller.gamepad?.axes;
    let selectedItem;
    const speed = 0.03;
    // const loadedModel = self.room.getObjectByName('loadedModel');

    let a = 0;
    let b = 0;
    if (this.model) {
      this.workingMatrix.identity().extractRotation(controller.matrixWorld);
      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);
      let intersectsModel;
      let interationArray = [];
      if (this.tablet.children.length > 0) {
        const tabletRefreshInstruction = this.tablet.getObjectByName(
          'tabletInstructionRefresh'
        ).children;
        const tabletDescription =
          this.tablet.getObjectByName('tabletDescription').children;

        interationArray = [
          ...this.tablet.children,
          ...tabletRefreshInstruction,
          ...tabletDescription
        ];
      }

      interationArray = [...interationArray, ...this.model.children];
      intersectsModel = this.raycaster.intersectObjects(interationArray, false);
      if (!controller.userData.attachedObject) {
        if (
          intersectsModel.length > 0 &&
          intersectsModel.find((element) => element.object.visible) &&
          !intersectsModel[0].object.text
        ) {
          selectedItem = intersectsModel.find(
            (element) => element.object.visible
          );
          controller.children[1].visible = true;
          controller.children[1].position.x = controller.children[0].position.x;
          controller.children[1].position.z =
            -intersectsModel[0].distance + 0.015;
          controller.children[0].scale.z = selectedItem.distance;
          this.lineLength = selectedItem.distance;
          controller.userData.selected = selectedItem.object;
        } else {
          controller.children[0].scale.z = 10;
          controller.children[1].visible = false;
          controller.userData.selected = undefined;
        }
      }

      if (controller.userData.selectPressed) {
        if (intersectsModel.length > 0 && !intersectsModel[0].object.text) {
          controller.userData.selected = intersectsModel[0].object;
        } else {
          controller.children[0].scale.z = 10;
          controller.children[1].visible = false;
        }
        if (controller.userData.attachedObject) {
          if (
            controller.userData.attachedObject.name === 'Na' &&
            colissionDetecter(
              controller.userData.attachedObject,
              this.room
                .getObjectByName('loadedModel')
                .getObjectByName('Dicloropentan')
            )
          )
            controller.collisionDetected = true;
          else {
            controller.collisionDetected = false;
          }
          controller.children[1].visible = true;
          controller.children[0].scale.z = this.lineLength;
        }
      }
    }
    //=================> movement <=================

    if (axes?.length) {
      if (axes[2] != 0) {
        a += axes[2];
      }
      if (axes[3] != 0) {
        b += axes[3];
      }
    }

    const quaternion = this.dolly.quaternion.clone();

    if (a !== 0 || b !== 0) {
      this.dolly.translateZ(b * speed);
      this.dolly.translateX(a * speed);
      this.dolly.quaternion.copy(quaternion);
    }
  }

  componentDidUpdate(prevProps) {
    const self = this;
    //Changing tex  t mesh on change language
    if (this.vrButton?.vrButton && this.model) {
      this.vrButton.vrButton.disabled = false;
    }

    if (this.props.lang != prevProps.lang || !self.state.loadedFlag) {
      this.props?.meshes?.forEach((element, index) => {
        this.room.remove(this.room.getObjectByName('text'));
        this.room.remove(this.room.getObjectByName('static'));
        this.room.remove(this.room.getObjectByName('parrent'));
        this.room.remove(this.room.getObjectByName('children'));
        this.loadText(this.room, element, index);
        self.setState({ ...self.state, loadedFlag: true });
      });
    }

    if (this.props.lang) {
      createTopicName(this.props, this.scene);
    }
  }

  componentDidMount() {
    // this.button = ;
    this.loadText(this.room, loader);
    if (this.props) roundedSquare(this.scene, this.props);

    this.containerRef?.current?.appendChild(this.renderer.domElement);
    if (!document.getElementById('vrButton'))
      this.vrButton = new VRButton(this.renderer);

    const self = this;

    //When user turn on the VR mode.
    self.renderer.xr.addEventListener('sessionstart', function () {
      const leftController = self.controllers[0];
      leftController.visible = false;

      self.scene.getObjectByName('instructionPanel').visible = true;
      const firstInstruction = self.hiddenTextMeshes[0];
      firstInstruction.visible = true;
      self.vrButton.vrButton.disabled = false;
      self.textMeshes.forEach((element, index) => {
        if (index > 3) {
          self.arrayForSettingInvisibileOnRefresh.push(element);
        } else {
          self.arrayForSettingVisibileOnRefresh.push(element);
        }

        element.material.color.setHex(0x00d6d9);
        element.position.set(
          self.props.meshes[index].textPositionInVrX,
          self.props.meshes[index].textPositionInVrY,
          self.props.meshes[index].textPositionInVrZ
        );
      });

      if (
        (self.props.location.pathname.indexOf('chemistry') !== -1 ||
          self.props.location.pathname.indexOf('new_topic') !== -1) &&
        self.props.path?.indexOf('chemistry') !== -1
      ) {
        // onEnterVr(self);
        const loadedModel = self.room.getObjectByName('loadedModel');

        self.room.getObjectByName('environment').visible = true;
        loadedModel.position.set(0, 0.8, 1.9);
        loadedModel.scale.x = 0.5;
        loadedModel.scale.y = 0.5;
        loadedModel.scale.z = 0.5;

        SpotLightSet(self);
        self.scene.remove(self.directionalLight1);
        self.scene.remove(self.directionalLight2);
        self.scene.remove(self.directionalLight3);
        self.scene.remove(self.directionalLight4);
        self.scene.remove(self.directionalLight5);
        self.scene.remove(self.directionalLight6);
      }
      self.dolly.add(self.camera);
    });

    //When user turn off the VR mode.

    self.renderer.xr.addEventListener('sessionend', function () {
      let adminPreviewContainer = document.getElementById('previewContainer');

      self.vrButton.vrButton.disabled = false;
      self.scene.getObjectByName('instructionPanel').visible = false;

      if (self.sound?.isPlaying) self.sound.pause();

      if (
        (self.props.location.pathname.indexOf('chemistry') !== -1 ||
          self.props.location.pathname.indexOf('new_topic') !== -1) &&
        self.props.path?.indexOf('chemistry') !== -1
      ) {
        self.room.remove(self.room.getObjectByName('environment'));
        DirectionalLightSet(self);
        self.scene.remove(self.spotLight);
        self.scene.remove(self.spotLight2);
        self.scene.remove(self.centerPartSpotLightTarger);
        self.scene.remove(self.directionalLight);

        self.room.remove(self.room.getObjectByName('environment'));
        self.room.getObjectByName('loadedModel').position.set(0, 0, 0);
        self.room.getObjectByName('loadedModel').scale.set(1, 1, 1);
        window.location.reload();
      }
      self.dolly.remove(self.camera);
      self.dolly.position.z = 3;
      self.dolly.position.y = 1.6;
      if (self.props.onsessionendAdmin && adminPreviewContainer) {
        adminPreviewContainer.appendChild(this.vrButton.vrButton);
      }
    });
    window.addEventListener('resize', this.resize());
  }

  componentWillUnmount() {
    this.containerRef?.current?.removeChild(this.renderer.domElement);
    window.removeEventListener('resize', this.resize);

    !this.props.isPreview && this?.vrButton?.hideEnterVR();
    Object.keys(this).forEach((key) => {
      // Recursively call dispose() if possible.
      if (
        typeof this[key]?.dispose === 'function' &&
        this[key].type != 'Scene'
      ) {
        this[key].dispose();
      }
      // Remove any reference.
      this[key] = null;
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.room.name = 'room';
    this.lang = nextProps.lang;

    //Replacing text from scene after translate
    if (nextProps.lang !== this.props.lang) {
      this.props?.meshes?.forEach(() => {
        this.room.remove(this.room.getObjectByName('text'));
        this.room.remove(this.room.getObjectByName('parrent'));
        this.room.remove(this.room.getObjectByName('static'));
        this.room.remove(this.room.getObjectByName('children'));
      });

      return true;
    }

    //Setting coordinates for room (model)
    if (
      this.props.roomCoordsZ &&
      this.props.roomCoordsX &&
      this.props.roomCoordsY
    ) {
      this.room.position.set(
        this.props.roomCoordsX,
        this.props.roomCoordsY,
        this.props.roomCoordsZ
      );
    } else {
      this.room.position.set(0, 0, 0);
    }
    //Setting rotation coords for rotation (model)
    if (
      this.props.roomRotationZ &&
      this.props.roomRotationX &&
      this.props.roomRotationY
    ) {
      this.room.rotation.set(
        this.props.roomRotationX,
        this.props.roomRotationY,
        this.props.roomRotationZ
      );
    }
    //Setting scaling for room (model)
    if (
      this.props.roomScaleZ &&
      this.props.roomScaleX &&
      this.props.roomScaleY
    ) {
      this.room.scale.set(
        this.props.roomScaleX,
        this.props.roomScaleY,
        this.props.roomScaleZ
      );
    }
    //Setting camera position in scene
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.camera.position.set(
        this.props.cameraCoordsX,
        this.props.cameraCoordsY,
        this.props.cameraCoordsZ
      );
    }

    //Setting coord for text in scene

    this.scene.add(this.room);

    //Setting loading
    if (nextProps.type !== this.state.loadingType) {
      this.setState({
        ...this.state,
        loadingType: nextProps.type
      });
      return true;
    }

    if (this.state.progress !== nextState) {
      return true;
    }
    if (nextProps.z !== this.props.z) {
      return true;
    } else {
      return false;
    }
  }

  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  render() {
    if (this.renderer.xr.isPresenting && this.positionForCamera.length < 4) {
      this.positionForCamera.push(this.camera.position);
      this.dolly.position.y = -this.positionForCamera[0].y;
    }

    const position = new THREE.Vector3();

    position.copy(this.camera.position);

    this.controls.update();

    this.renderer.render(this.scene, this.camera);
    const dt = this.clock.getDelta();
    pagintationLerping(this);
    this.hiddenTextMeshes?.forEach((element) => {
      element.lookAt(this.camera.position);
    });
    this.textMeshes?.forEach((element) => {
      if (element.name === 'text' || element.name == 'parrent') {
        element.lookAt(this.camera.position);
      }
    });
    if (this.controllers) {
      const self = this;
      this.controllers.forEach((controller, index) => {
        if (index == 0) controller.visible = false;
        self.handleController(controller, dt, index);
      });
    }

    this.mixer?.update(dt);

    return (
      <div ref={this.containerRef} className={`${styles.sceneContainer}`}>
        {this.props.isPreviewContainer && this.state.flagFullscreen ? (
          <Link
            onClick={this.updateState}
            className={
              'px-2 absolute py-2 bg-gray-500 border-2 border-white rounded-lg text-center hover:bg-gray-600 hover hover:border-gray-100 top-5 right-5 text-white '
            }
          >
            {' '}
            <svg
              className={'self-end fill-current h-6 w-6 '}
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Exit Fullscreen</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </Link>
        ) : null}

        {!this.state.progress && (
          <div
            className={
              'w-full h-full bg-black flex items-center flex-col justify-center absolute opacity-50'
            }
          >
            <p className="text-white opacity-100 font-gothic text-lg mb-2 text-center">
              Loading...
            </p>
            <div className="w-32 h-1 opacity-100 rounded-lg bg-slate-600 ">
              {this.state.percent != 0 ? (
                <div
                  style={{ width: Math.ceil(this.state.percent) + '%' }}
                  className={' rounded-full opacitiy-90 h-full bg-cyan-500'}
                ></div>
              ) : (
                <div className={'w-full h-full bg-gray-500'}></div>
              )}
            </div>
          </div>
        )}
      </div>
    );
    // }
  }
}

export default withRouter(Cycloalkanes);
