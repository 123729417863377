/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import { hsData } from './../../../assets/mockData/mockData';
import { hsTopic } from './../../../assets/mockData/mockData';
import { hsHeaders } from './../../../assets/mockData/mockData';
import HelpSupportFAQCard from './helpSupportFAQCard';
import HelpSupportTopicCard from './helpSupportTopicCard';
import AuthContext from '../../../context/authContext';
import DetailedInfo from './detailedInfo';
import { useAppContext } from '../../../context/AppProvider';
const helpSupport = () => {
  const { store } = useAppContext();
  const { selectedLanguage } = store;
  const [topic, setTopic] = useState({ hsTopic });
  const [id, setId] = useState('t0');

  const childToParent = (childData) => {
    setTopic(childData);
    setId(childData.helpSupportId);
  };
  return (
    <div className=" flex flex-col">
      <div className="w-full py-10 bg-close-blue  flex-col  space-y-5 flex justify-center  items-center ">
        <h1 className="font-tinos bg-black-900 text-3xl text-center text-white  pb-7 pt-4">
          {hsHeaders[0].hsHeaderFAQ[selectedLanguage]}
        </h1>
      </div>

      <div className={'md px-5 pt-20   flex flex-col items-center'}>
        <div
          className={
            'flex  items-center justify-center flex-wrap w-3/5 m-auto '
          }
        >
          {hsTopic.map((element, i) => {
            return (
              <HelpSupportTopicCard
                key={i}
                helpSupportId={element.hsName.id}
                childToParent={childToParent}
                helpSupportName={element.hsName}
                helpSupportParagraph={element.hsName.about}
                helpSupportInstructions={element.hsName.instructionHeader}
                helpSupportSteps={element.hsName.steps}
                helpSupportIcon={element.hsIcon}
              />
            );
          })}
        </div>
        <div
          id="detailed"
          className={
            'py-20  px-10 mt-20 w-10/12 smmax:w-full border-2 rounded-xl border-close-blue '
          }
        >
          <DetailedInfo
            topicData={id.split('')[0] == 't' ? hsTopic : hsData}
            id={id}
            selected={selectedLanguage}
          />
        </div>
      </div>
      <div className="h-24"></div>
    </div>
  );
};
export default helpSupport;
