import * as THREE from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';

export default function Generator(room, element) {
  const molecule = new THREE.SphereBufferGeometry(0.1, 15, 15);
  const moleculeMaterial = new THREE.MeshBasicMaterial({
    color: element.color
  });
  const moleculeFinal = new THREE.Mesh(molecule, moleculeMaterial);
  room.add(moleculeFinal);
  loadText();
  moleculeFinal.position.set(element.x, element.y, element.z);
  moleculeFinal.name = element.element;

  async function loadText() {
    const loader = new FontLoader();
    await loader.load(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/helvetiker_regular.typeface.json',
      function (font) {
        var textGeometry = new TextGeometry(element.element, {
          font: font,
          size: 0.08,
          height: 0.02
        });
        let textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });

        var mesh = new THREE.Mesh(textGeometry, textMaterial);
        moleculeFinal.add(mesh);

        if (element.x) {
          mesh.position.set(-0.055, -0.05, element.z / 2 + 2.1);
        } else {
          mesh.position.set(0, 0, 0);
        }
      },

      // onProgress callback

      // onError callback
      function (err) {
        console.log('An error happened', err);
      }
    );
  }
}
