/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import SceneModelGeneral from '../../../../../common/SceneModelGeneral';
import { coursesData } from '../../../../../assets/mockData/mockData';

export default function SceneModalView({ showModal, setShowModal }) {
  const [topicData, setTopicData] = useState({});

  const selectedLanguage = window.localStorage.getItem('language');
  let { url } = useRouteMatch();
  const lang = window.localStorage.getItem('language');
  let vrButtonFullscreen;
  let adminPreviewContainer;
  useEffect(() => {
    const data = coursesData.find((course) => course.path === showModal.path);
    if (data) {
      data.topics.find((topic) => {
        if (
          topic.type === showModal.sceneId ||
          topic.id === showModal.sceneId
        ) {
          setTopicData(topic);
        }
      });
    }
  }, [showModal]);
  useEffect(() => {
    vrButtonFullscreen = document.getElementById('vrButton');
    adminPreviewContainer = document.getElementById('previewContainer');

    if (showModal.show && vrButtonFullscreen) {
      adminPreviewContainer.appendChild(vrButtonFullscreen);
    }
  });
  return (
    <>
      {showModal.show &&
      (topicData.type === showModal.sceneId ||
        topicData.id === showModal.sceneId) ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto w-9/12 h-3/4 h-4/5">
              {/*content*/}
              <div className="border-0 w-full h-full rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Scene Preview</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div
                  id={'previewContainer'}
                  className="relative p-6 flex-auto flex justify-center items-center overflow-hidden "
                >
                  {showModal.isSpecialModule
                    ? topicData.component(true, url, lang, { ...topicData })
                    : !showModal.isSpecialModule && (
                        <SceneModelGeneral
                          isPreview={true}
                          lang={selectedLanguage}
                          path={showModal.path}
                          {...topicData}
                          onsessionendAdmin
                        />
                      )}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() =>
                      setShowModal({
                        sceneId: '',
                        show: false,
                        isSpecialModule: false
                      })
                    }
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
