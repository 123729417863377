import React, { useEffect, Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

function ScrollToTop({ history, location, children }) {
  let rootCategory = useRouteMatch('/categories/:categoryId/:pageNum');
  const [isCategory, setCategory] = useState(false);

  useEffect(() => {
    setCategory(false);
    if (history.action === 'POP') {
      return;
    }

    if (rootCategory != null) {
      setCategory(true);
    }
    let { hash } = location;
    if (hash) {
      let element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    } else if (!isCategory) {
      window.scrollTo(0, 0);
    }
  });

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
