import { convertBase64ToObjectURL } from './convetBase64ToObjectUrl';
import * as THREE from 'three';

export const textToSpeech = async (self, textToTranslate) => {
  if (!self.sound?.isPlaying && self.loadedMusicBar) {
    self.loadedMusicBar = false;
    const url =
      'https://texttospeech.googleapis.com/v1/text:synthesize?key=AIzaSyCED-wr9z7UANw56mCeGXba-3BDzk5tQoo';
    const data = {
      input: {
        text: textToTranslate
          ? textToTranslate[self.props.lang]
          : self.props.description[self.props.lang]
      },

      voice: {
        languageCode: self.props.lang === 'EN' ? 'en-US' : 'ro',
        name: self.props.lang === 'EN' ? 'en-US-Neural2-A' : 'ro-RO-Wavenet-A',
        ssmlGender: self.props.lang === 'EN' ? 'MALE' : 'FEMALE'
      },
      audioConfig: {
        audioEncoding: 'MP3'
      }
    };
    const otherparam = {
      headers: {
        'content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(data),
      method: 'POST'
    };
    const res = await fetch(url, otherparam);
    const audioData = await res.json();

    const listener = new THREE.AudioListener();
    self.camera.add(listener);
    // create a global audio source
    self.sound = new THREE.Audio(listener);

    // load a sound and set it as the Audio object's buffer
    const audioLoader = new THREE.AudioLoader();
    const base64ConvertedToMP3 = convertBase64ToObjectURL(
      audioData.audioContent
    );

    audioLoader.load(base64ConvertedToMP3, function (buffer) {
      self.sound.setBuffer(buffer);
      self.sound.setLoop(false);
      self.sound.setVolume(0.5);
      self.sound.play();
      self.loadedMusicBar = true;
    });
    self.soundRespReceived = true;
    return audioData;
  }
};
