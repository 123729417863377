import socketIO from 'socket.io-client';

export const socket = socketIO.connect(
  process.env.REACT_APP_ENV === 'development'
    ? 'https://lectio-staging-api.herokuapp.com'
    : 'https://lectio-staging-api.herokuapp.com',
  { transports: ['websocket'] }
  // {
  //   withCredentials: true
  // }
);
