import React, { useState } from 'react';
import { PricingData } from '../../../assets/mockData/mockData';
import PricingType from './pricingType';
import { useAppContext } from '../../../context/AppProvider';
const Pricing = () => {
  const { store } = useAppContext();
  const { selectedLanguage } = store;

  const [selected, setSelected] = useState(1);
  const [period, setPeriod] = useState('m');

  const setType = (e, index) => {
    e.persist();
    setSelected(index);
  };

  return (
    <div className="xlmin:mx-auto grow  xlmin:container py-20 2xl:px-0 px-6">
      <div className="xlmin:flex xlmax:flex-col items-center justify-between">
        <div className="  w-full">
          <p className="text-base leading-4 font-gothic xlmax:text-center text-gray-600">
            {PricingData[0].PriceChooseText[selectedLanguage]}
          </p>
          <h1 className="md:text-5xl font-tinos xlmax:text-center text-3xl font-bold leading-10 mt-3 text-gray-800">
            {PricingData[0].OurPriceText[selectedLanguage]}
          </h1>
          <p
            role="contentinfo"
            className="font-gothic text-base xlmax:text-center leading-5 mt-5 text-gray-600"
          >
            {PricingData[0].PriceWorkingText[selectedLanguage]}
          </p>
          <div className="w-full flex-col flex xlmax:justify-center ">
            <div className="bg-gray-100 relative shadow rounded-full flex items-center w-48  mt-10">
              <button
                onClick={() => setPeriod('m')}
                className={`${
                  period === 'm' ? ' text-white' : 'text-gray-700 '
                } my-2  bg-gray-100 text-base leading-none  rounded-full py-4 pl-2 pr-8 mr-1`}
                id="monthly"
              >
                <p className="font-gothic  text-lg absolute text-center w-2/5 -mt-4 z-10 ">
                  {PricingData[0].period[0].Monthly[selectedLanguage]}
                </p>
              </button>
              <div
                className={`w-24  h-12 ${
                  period === 'y' ? 'translate-x-24 right-0' : null
                } left-0  rounded-full bg-rose-500 absolute transition ease-linear duration-1s`}
              ></div>
              <button
                onClick={() => setPeriod('y')}
                className={`${
                  period === 'y' ? ' text-white' : 'text-gray-700 '
                }  bg-gray-100 text-base leading-none  rounded-full pt-4 pb-4 px-6`}
                id="annually"
              >
                <p className="font-gothic text-lg text-center w-2/5 absolute ml-10 -mt-4">
                  {PricingData[0].period[0].Annually[selectedLanguage]}
                </p>
              </button>
            </div>
          </div>
        </div>
        <div
          className=" xlmin relative w-full xlmax:justify-items-center mt-12 md:px-8"
          role="list"
        >
          <img
            src="https://i.ibb.co/0n6DSS3/bgimg.png"
            className="absolute w-full -ml-12 mt-24"
            alt="background circle images"
          />
          {[...Array(3)].map((element, index) => {
            return (
              <div
                role="presentation"
                key={index}
                onClick={(e) => setType(e, index)}
              >
                <PricingType
                  key={index}
                  selected={selected}
                  period={period}
                  index={index}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
