import React from 'react';
import { useAppContext } from '../../../context/AppProvider';
const DetailedInfo = (props) => {
  const { store } = useAppContext();
  const { selectedLanguage } = store;

  const { topicData, id } = props;
  return (
    <div>
      {topicData.map((element, i) => {
        return (
          <div key={i}>
            {i == id.split('')[1] ? (
              <div className={'space-y-10'}>
                <h1 className="text-center font-tinos text-2xl font-bold">
                  {element.hsName[selectedLanguage]}
                </h1>
                <p className="font-gothic text-justify text-lg">
                  {element.hsName.about[selectedLanguage]}
                </p>
                <h1 className="text-center  font-tinos text-2xl font-bold">
                  {element.hsName.instructionHeader[selectedLanguage]}
                </h1>
                <div>
                  {element.hsName.steps.map((elementSteps, index) => {
                    return (
                      <div key={index}>
                        <li key={index} className="font-gothic text-lg mt-2">
                          {elementSteps[selectedLanguage]}
                        </li>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default DetailedInfo;
