/* eslint-disable no-unused-vars */
import { useState, useCallback } from 'react';
import ApiUtils from '../api/api-utils';
export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resStatus, setResStatus] = useState(null);
  let apiUrl = '';
  if (process.env.REACT_APP_ENV === 'development') {
    apiUrl = 'http://localhost:8000';
  } else {
    apiUrl = 'https://lectio-staging-api.herokuapp.com';
  }

  const request = useCallback(
    async (url, method = 'GET', body = null, headers = {}, history = {}) => {
      setLoading(true);
      setResStatus(null);
      try {
        if (body) {
          body = JSON.stringify(body);
          headers['Content-Type'] = 'application/json';
        }
        const response = await fetch(url, {
          method,
          body,
          mode: 'cors',
          headers
        });
        ApiUtils.checkStatus(response, history);
        setResStatus(response.status);
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Something went wrong');
        }
        setLoading(false);

        return data;
      } catch (e) {
        setLoading(false);
        setError(e.message);
        throw e;
      }
    },
    []
  );

  const clearError = useCallback(() => {
    setError(null);
  }, []);
  return { loading, request, error, clearError, resStatus, apiUrl };
};
