/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { VRButton } from '../../../common/VRButton';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import styles from '../../astronomy/solarSystem/solarSystem.module.css';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { coursesData } from '../../../assets/mockData/mockData';
import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry';
import { roundedSquare } from '../../../common/SceneModelGeneral/roundedSquareBg';
import createTopicName from '../../../common/SceneModelGeneral/createTopicName';
import ControllerInstruction from './../../../common/controllerInstruction/index';
import { textToSpeech } from '../../../common/TabletFunctionality/textToSpeech';
import { Tablet } from '../../../common/TabletFunctionality/index';

class ElectricCircuit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: true,
      percent: 0,
      language: 5
    };
    this.lang = props.lang;

    this.clock = new THREE.Clock();
    this.camera = new THREE.PerspectiveCamera(
      50,
      window.innerWidth / window.innerHeight,
      0.1,
      200
    );

    this.camera.position.set(0, 1.6, 5);
    this.containerRef = React.createRef();
    this.scene = new THREE.Scene();
    const imageBg = require('../../../assets/img/gradientScene.jpeg');
    const texture = new THREE.TextureLoader().load(imageBg);
    this.scene.background = texture;

    this.ambient = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 0.3);
    this.scene.add(this.ambient);

    const light = new THREE.DirectionalLight();
    light.position.set(0.5, 1, 1);
    this.scene.add(light);

    this.loadedMusicBar = true;

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.raycaster = new THREE.Raycaster();
    this.workingMatrix = new THREE.Matrix4();
    this.workingVector = new THREE.Vector3();
    this.origin = new THREE.Vector3();

    this.dolly = new THREE.Object3D();
    this.dolly.position.z = 3;
    // this.dolly.add(this.camera);
    this.scene.add(this.dolly);
    const xInstructionCoord = this.props.name.controllerCoords?.x
      ? this.props.name.controllerCoords.x
      : 1.8;
    const yInstructionCoord = this.props.name.controllerCoords?.y
      ? this.props.name.controllerCoords.y
      : 0.8;
    const zInstructionCoord = this.props.name.controllerCoords?.z
      ? this.props.name.controllerCoords.z
      : 4.5;

    this.ControllerInstruction = new ControllerInstruction({
      x: xInstructionCoord,
      y: yInstructionCoord,
      z: zInstructionCoord
    });
    this.ControllerInstruction.bindInstructionToScene(this.scene);

    this.dummyCam = new THREE.Object3D();
    this.camera.add(this.dummyCam);
    this.initScene();

    this.setupVr(this.scene);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.target.set(0, 1.6, 0);
    this.controls.update();

    this.renderer.setAnimationLoop(this.render.bind(this));
    window.addEventListener('resize', this.resize.bind(this));
  }

  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }
  workingScene() {
    // if(this.controllers){
    //   const self =this;
    //   this.controllers.forEach((controller)=>{
    //     self.handleController(controller)
    //   });
    // }
    this.renderer.render(this.scene, this.camera);
    // this.controls.update()
  }

  initScene() {
    this.loadGLTF();
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    } else {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    }
    this.scene.add(this.room);

    // this.scene.add(this.highlight)
  }

  loadGLTF() {
    const draco = new DRACOLoader();

    const loader = new GLTFLoader();

    const s3BucketUrl =
      'https://s3.eu-central-1.amazonaws.com/lectio.app/models/';

    draco.setDecoderConfig({ type: 'js' });

    draco.setDecoderPath(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/draco/'
    );
    loader.setDRACOLoader(draco);
    const bulb = s3BucketUrl + 'bulb.glb';

    const electric_circuit = s3BucketUrl + 'electricCircuit.glb';
    const self = this;
    const battery = s3BucketUrl + 'Battery.glb';

    // Load a glTF resource
    loader.load(bulb, function (gltf) {
      gltf.scene.children[0].position.set(1.47, 1.57, 0.762);
      gltf.scene.children[1].position.set(1.57, 2.887, -1.015);

      self.group = gltf.scene;
      self.group.castShadow = true;
      self.receiveShadow = true;
      gltf.scene.children[0].scale.set(0.02, 0.02, 0.02);

      self.group.rotateY(1.9);
      self.group.rotateX(0.75);
      self.room.add(gltf.scene.children[1]);
      self.room.add(gltf.scene.children[0]);
    });
    loader.load(battery, function (gltf) {
      gltf.scene.children[0].position.set(-0.94, 1.535, 0.762);
      self.group = gltf.scene.children[0];
      self.group.scale.set(0.0027, 0.0027, 0.0027);
      self.group.rotateZ(4.7);
      self.room.add(gltf.scene.children[0]);
    });
    loader.load(
      electric_circuit,
      function (gltf) {
        gltf.scene.position.set(0.3, 1.3, 0.799);
        gltf.scene.rotateY(1.57);
        self.room.add(gltf.scene);
        self.setState({ ...self.state, progress: false });

        self.electric_circuit = gltf.scene.children[3];

        self.mixer = new THREE.AnimationMixer(gltf.scene);
        const clips = gltf.animations;
        clips.forEach(function (clip) {
          const action = self.mixer.clipAction(clip);
          action.play();
        });
        self.renderer.setAnimationLoop(self.render.bind(self));
      },
      function (xhr) {
        self.setState({
          ...self.state,
          percent: (xhr.loaded / xhr.total) * 100
        });
      }
    );
  }
  async loadText(room, text, index) {
    const loader = new FontLoader();
    const self = this;
    await loader.load(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/helvetiker_regular.typeface.json',
      function (font) {
        var textGeometry = new TextGeometry(text[self.props.lang], {
          font: font,
          size: text.modelSize ? text.modelSize : 0.1,
          height: text.modelHeight ? text.modelHeight : 0.005
        });
        let textMaterial;
        if (index == 0) {
          textMaterial = new THREE.MeshPhongMaterial({
            color: 0x4d8c9f,
            specular: 0xffffff
          });
        } else {
          textMaterial = new THREE.MeshPhongMaterial({
            color: 0xffffff,
            specular: 0xffffff
          });
        }

        var mesh = new THREE.Mesh(textGeometry, textMaterial);
        room.add(mesh);

        mesh.name = 'text';

        if (text.modelCoordsX && text.modelCoordsY && text.modelCoordsZ) {
          mesh.position.set(
            text.modelCoordsX,
            text.modelCoordsY,
            text.modelCoordsZ
          );
        } else {
          mesh.position.set(0, 0, 0);
        }
        if (text.modelRotationX && text.modelRotationY && text.modelRotationZ) {
          mesh.rotation.set(
            text.modelRotationX,
            text.modelRotationY,
            text.modelRotationZ
          );
        } else {
          mesh.rotation.set(0, 0, 0);
        }
      }

      // onError callback
    );
  }
  setupVr(scene) {
    this.renderer.xr.enabled = true;
    const self = this;
    this.controllers = this.buildControllers(scene);
    function onConnected(e) {
      this.gamepad = e.data.gamepad;
      self.updateControllers({
        right: { trigger: true, squeeze: true },
        left: { trigger: true, squeeze: true }
      });
    }

    const controllers = [
      this.renderer.xr.getController(0),
      this.renderer.xr.getController(1)
    ];
    controllers.map((element) => {
      element.addEventListener('connected', onConnected);
    });

    // const self = this;

    this.controllers = this.buildControllers(scene);
  }
  updateControllers(info) {
    const self = this;

    function onSelectStart() {
      this.userData.selectPressed = true;
      this.userData.selectPressed = true;
      if (this.userData.selected && !this.userData.attachedObject) {
        if (
          this.userData.selected.name == 'tabletSoundOn' &&
          self.props.description[self.props.lang] &&
          self.loadedMusicBar
        ) {
          textToSpeech(self);
          return 0;
        } else if (this.userData.selected.name == 'tabletSoundOff') {
          if (self.sound.isPlaying) {
            self.sound.pause();
            self.loadedMusicBar = true;
          }
          return 0;
        }
      }
    }

    function onSelectEnd() {
      this.userData.selectPressed = false;
      if (this.userData.attachedObject !== undefined) {
        this.remove(this.userData.attachedObject);

        this.userData.attachedObject = undefined;
        this.userData.selected = undefined;
        this.children[1].visible = false;
        this.children[0].scale.z = 10;
      }
    }

    function onSqueezeStart() {
      this.userData.squeezePressed = true;
      if (this.userData.selected !== undefined) {
        self.posModelX = this.userData.selected.position.x;
        self.posModelY = this.userData.selected.position.y;
        self.posModelZ = this.userData.selected.position.z;

        // this.posModelX = this.userData.selected.position.x;
        this.attach(this.userData.selected);
        self.setState({
          modelName: this.userData.selected.name,
          executed: true
        });
        // }

        this.userData.attachedObject = this.userData.selected;
      }
    }

    function onSqueezeEnd() {
      this.userData.squeezePressed = false;

      if (this.userData.attachedObject !== undefined) {
        this.remove(this.userData.attachedObject);

        self.model.add(this.userData.attachedObject);
        self.model
          .getObjectByName(self.state.modelName)
          .position.set(self.posModelX, self.posModelY, self.posModelZ);
        this.userData.attachedObject = undefined;
        this.userData.selected = undefined;
        this.children[1].visible = false;
        this.children[0].scale.z = 10;
      }
    }

    function onDisconnected() {
      const index = this.userData.index;

      if (self.controllers) {
        const obj = index == 0 ? self.controllers.right : self.controllers.left;

        if (obj) {
          if (obj.controller) {
            const controller = obj.controller;
            while (controller.children.length > 0)
              controller.remove(controller.children[0]);
            self.scene.remove(controller);
          }
          if (obj.grip) self.scene.remove(obj.grip);
        }
      }
    }

    if (info.right !== undefined) {
      const right = this.renderer.xr.getController(0);

      let trigger = false,
        squeeze = false;

      Object.keys(info.right).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
        if (key.indexOf('squeeze') != -1) squeeze = true;
      });

      if (trigger) {
        right.addEventListener('selectstart', onSelectStart);
        right.addEventListener('selectend', onSelectEnd);
      }

      if (squeeze) {
        right.addEventListener('squeezestart', onSqueezeStart);
        right.addEventListener('squeezeend', onSqueezeEnd);
      }

      right.addEventListener('disconnected', onDisconnected);
    }

    if (info.left !== undefined) {
      const left = this.renderer.xr.getController(1);

      let trigger = false,
        squeeze = false;

      Object.keys(info.left).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
        if (key.indexOf('squeeze') != -1) squeeze = true;
      });

      if (trigger) {
        left.addEventListener('selectstart', onSelectStart);
        left.addEventListener('selectend', onSelectEnd);
      }

      if (squeeze) {
        left.addEventListener('squeezestart', onSqueezeStart);
        left.addEventListener('squeezeend', onSqueezeEnd);
      }

      left.addEventListener('disconnected', onDisconnected);
    }
  }

  buildControllers() {
    const controllerModelFactory = new XRControllerModelFactory();
    const geometrySphere = new THREE.IcosahedronBufferGeometry(this.radius, 2);

    const sphere = new THREE.Mesh(
      geometrySphere,
      new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.BackSide })
    );
    sphere.scale.set(0.01, 0.01, 0.01);
    sphere.name = 'sphereIndicator';
    const geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, -1)
    ]);

    const line = new THREE.Line(geometry);
    line.name = 'line';
    line.scale.z = 0;
    sphere.visible = false;

    const controllers = [];

    for (let i = 0; i <= 1; i++) {
      const controller = this.renderer.xr.getController(i);
      controller.add(line.clone());
      controller.add(sphere.clone());
      controller.userData.selectPressed = false;
      this.dolly.add(controller);

      controllers.push(controller);

      const grip = this.renderer.xr.getControllerGrip(i);
      grip.add(controllerModelFactory.createControllerModel(grip));

      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);

      controller.children[0].scale.z = 10;

      if (i == 1) {
        this.dolly.add(grip);
      }
    }
    Tablet(this, controllers);

    return controllers;
  }
  handleController(controller) {
    const axes = controller.gamepad?.axes;

    this.workingMatrix.identity().extractRotation(controller.matrixWorld);
    this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    this.raycaster.ray.direction.set(0, 0, -1).applyMatrix4(this.workingMatrix);
    let intersectsModel;
    let iterationArray = [];
    if (this.tablet?.children.length > 0) {
      iterationArray = [...this.tablet.children];
    }
    intersectsModel = this.raycaster.intersectObjects(iterationArray, false);
    if (!controller.userData.attachedObject) {
      if (
        intersectsModel.length > 0 &&
        intersectsModel[0].object.visible &&
        !intersectsModel[0].object.text
      ) {
        controller.children[1].visible = true;
        controller.children[1].position.x = controller.children[0].position.x;
        controller.children[1].position.z = -intersectsModel[0].distance + 0.02;
        controller.children[0].scale.z = intersectsModel[0].distance;
        this.lineLength = intersectsModel[0].distance;
        controller.userData.selected = intersectsModel[0].object;
      } else {
        controller.children[0].scale.z = 10;
        controller.children[1].visible = false;
        controller.userData.selected = undefined;
      }
    }

    if (controller.userData.selectPressed) {
      if (intersectsModel.length > 0 && !intersectsModel[0].object.text) {
        controller.userData.selected = intersectsModel[0].object;
      } else {
        controller.children[0].scale.z = 10;
        controller.children[1].visible = false;
      }
      if (controller.userData.attachedObject) {
        controller.children[1].visible = true;
        controller.children[0].scale.z = this.lineLength;
      }
    }

    const speed = 0.03;
    let a = 0;
    let b = 0;

    if (axes?.length) {
      if (axes[2] != 0) {
        a += axes[2];
      }
      if (axes[3] != 0) {
        b += axes[3];
      }
    }

    const quaternion = this.dolly.quaternion.clone();

    if (a !== 0 || b !== 0) {
      this.dolly.quaternion.copy(this.camera?.quaternion);

      this.dolly.translateZ(b * speed);
      this.dolly.translateX(a * speed);

      this.dolly.quaternion.copy(quaternion);
    }
  }

  componentDidUpdate(prevProps) {
    const self = this;
    if (this.vrButton?.vrButton && this.group) {
      this.vrButton.vrButton.disabled = false;
    }
    // Changing text mesh on change language
    if (this.props.lang != prevProps.lang || this.props.lang === 'RO') {
      createTopicName(this.props, this.scene);
    }

    if (this.props) roundedSquare(this.scene, this.props);

    if (this.lang != prevProps.lang || !self.state.loadedFlag) {
      coursesData[1].topics[2].meshes?.map((element) => {
        this.room?.remove(this.room.getObjectByName('text'));
        this.loadText(this.room, element);
        self.setState({ ...self.state, loadedFlag: true });
      });
    }
  }
  componentDidMount() {
    const self = this;
    this.containerRef?.current?.appendChild(this.renderer.domElement);
    // if()
    createTopicName(this.props, this.scene);

    if (!document.getElementById('vrButton'))
      this.vrButton = new VRButton(this.renderer);
    self.renderer.xr.addEventListener('sessionstart', function () {
      self.scene.getObjectByName('instructionPanel').visible = true;

      self.vrButton.vrButton.disabled = false;

      self.dolly.add(self.camera);
      self.dolly.position.z = 6;
      self.dolly.position.y = -0.5;
      self.dolly.position.x = 0;
    });

    //When user turn off the VR mode.
    self.renderer.xr.addEventListener('sessionend', function () {
      if (self.sound?.isPlaying) self.sound.pause();

      self.dolly.remove(self.camera);
      self.scene.getObjectByName('instructionPanel').visible = false;

      self.dolly.position.z = 3;
      self.dolly.position.y = 1.6;

      self.vrButton.vrButton.disabled = false;
      let adminPreviewContainer = document.getElementById('previewContainer');
      if (self.props.onsessionendAdmin && adminPreviewContainer) {
        adminPreviewContainer.appendChild(self.vrButton.vrButton);
      }
    });
    const setTextInScene = () => {
      coursesData[1].topics[2].meshes.map((element) => {
        this.room.remove(this.room.getObjectByName('text'));
        this.loadText(self.room, element);
      });
    };

    setTextInScene();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    this.containerRef?.current?.removeChild(this.renderer.domElement);
    !this.props.isPreview && this?.vrButton?.hideEnterVR();
    Object.keys(this).forEach((key) => {
      // Recursively call dispose() if possible.
      if (
        typeof this[key]?.dispose === 'function' &&
        this[key].type != 'Scene'
      ) {
        this[key].dispose();
      }
      // Remove any reference.
      this[key] = null;
    });
    // window.location.reload(true);
  }
  shouldComponentUpdate(nextProps, nextState) {
    this.room.name = 'room';
    this.lang = nextProps.lang;

    //Replacing text from scene after translate
    if (nextProps.lang !== this.props.lang) {
      coursesData[1].topics[2].meshes.map(() => {
        this.room.remove(this.room.getObjectByName('text'));
      });

      return true;
    }

    //Setting coord for text in scene

    this.room.position.set(-0.2, -1, 0);
    this.room.scale.set(0.8, 0.8, 0.8);
    //Setting loading
    if (nextProps.type !== this.state.loadingType) {
      this.setState({
        ...this.state,
        loadingType: nextProps.type
      });
      return true;
    }

    if (this.state.progress !== nextState) {
      return true;
    }
  }
  render() {
    this.mixer?.update(this.clock.getDelta());
    this.workingScene();

    if (this.controllers) {
      const dt = this.clock.getDelta();
      const self = this;
      this.controllers.forEach((controller, index) => {
        self.handleController(controller, dt, index);
      });
    }
    return (
      <div
        className={`${styles.sceneContainer} ${
          this.props.isPreview
            ? styles.containerSizePreview
            : styles.containerFullSize
        }`}
        ref={this.containerRef}
      >
        {this.state.progress && (
          <div
            className={
              'w-full h-full bg-black flex items-center flex-col justify-center absolute opacity-50'
            }
          >
            <p className="text-white font-gothic opacity-100 text-lg mb-2 text-center">
              Loading...
            </p>
            <div className="w-32 h-1 opacity-100 rounded-lg bg-slate-600 ">
              {this.state.percent != 0 ? (
                <div
                  style={{ width: Math.ceil(this.state.percent) + '%' }}
                  className={' rounded-full h-full bg-cyan-500'}
                ></div>
              ) : (
                <div className={'w-full h-full bg-gray-500'}></div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default React.memo(ElectricCircuit);
