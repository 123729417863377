/* eslint-disable no-unused-vars */
import React, { useState, useContext, useCallback, useEffect } from 'react';

import { PricingData } from '../../../assets/mockData/mockData';
import { useHttp } from '../../../hooks/useHttp';
import AuthContext from '../../../context/authContext';
import { inputItems } from '../../../assets/mockData/mockData';
import { CodeTelList } from '../../../assets/mockData/mockData';
import { countryList } from '../../../assets/mockData/mockData';
import { useAppContext } from '../../../context/AppProvider';
import { useHistory } from 'react-router-dom';
const PricingForm = (props) => {
  const { store } = useAppContext();
  const { selectedLanguage, user, token } = store;
  const history = useHistory();
  const { userId } = user;

  const { request, loading, apiUrl } = useHttp();
  // const {token,userId}  = useContext(AuthContext)
  const [userC, setUserC] = useState({});
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [telephone, setTelephone] = useState('');
  const [telCode, setTelephoneCode] = useState(
    CodeTelList[0].code.split(' ').join('')
  );
  const [completePhone, setCompletePhone] = useState('');
  const [error, setError] = useState({ type: '', value: null });
  const [open, setOpen] = useState(false);
  const [disableEmail, setDisableEmail] = useState(true);

  const { index, period } = props;
  const getId = useCallback(async () => {
    if (userId != null) {
      try {
        const fetched = await request(
          apiUrl + `/api/auth/${userId}`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`
          },
          history
        );
        // setUser(fetched)
        setFirstName(fetched.firstname);
        setLastName(fetched.lastname);
        setCountry(fetched.country);
      } catch (err) {
        console.log('Error occured....', err.message);
      }
    }
  }, [token, userId, request]);

  const sentDataHandler = async () => {
    setCompletePhone(telCode.trim() + telephone.trim());
    try {
      if (!checkEmptyObj()) {
        await request(
          apiUrl + `/api/user/${userId}`,
          'PATCH',
          { firstname: firstName, lastname: lastName, country: country },
          {
            Authorization: `Bearer ${token}`
          },
          history
        );
        setError({
          type: 'Passed',
          value: 'Your Account has been successfully updated'
        });
        getId();
      } else {
        setError({ type: 'Error', value: 'Please enter required data' });
      }
    } catch (err) {
      console.log('Error occured...........', err);

      setError({ type: 'Error', value: err.message });
    }
    setOpen(false);
  };
  const checkEmptyObj = () => {
    let isEmpty = true;
    if (lastName && firstName && telCode && telephone && email) {
      isEmpty = false;
    } else {
      return true;
    }

    return isEmpty;
  };
  useEffect(() => {
    getId();
  }, [getId]);
  return (
    <div className="grow h-full   flex-col flex items-center justify-center md:col-span-2 bg-Payment h-1000 bg-cover">
      <div>
        <h1 className="md:text-3xl font-tinos xlmax:text-center text-xl font-bold leading-10 mt-1 mb-5 text-gray-800">
          {PricingData[0].PayFormHeader[selectedLanguage]}
        </h1>
      </div>
      <form action="#" method="POST">
        <div className=" grow w-full bg-gray-50 h-400  flex  my-auto mx-auto h-full rounded-lg flex-col overflow-hidden sm:rounded-md">
          <div className="px-4 py-5  bg-gray-50  sm:p-6">
            <div className="grid grid-cols-6 gap-6  ">
              <div className="col-span-6 sm:col-span-6">
                <label
                  htmlFor="email-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  {inputItems.Email[selectedLanguage]}
                </label>
                <input
                  type="text"
                  name="email-address"
                  id="email-address"
                  value={user.email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block  w-full py-2 px-3 border border-gray-300  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  {inputItems.Firstname[selectedLanguage]}
                </label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  autoComplete="given-name"
                  className="mt-1 block  w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  {inputItems.Lastname[selectedLanguage]}
                </label>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  autoComplete="family-name"
                  className="mt-1 block  w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 lgmin:col-span-3 lgmax:col-span-6">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  {inputItems.Country[selectedLanguage]}
                </label>
                <select
                  id="country"
                  name="country"
                  onChange={(e) => setCountry(e.target.value)}
                  autoComplete="country-name"
                  className="mt-1 block  w-full pt-3 pb-2 px-3 border bg-white border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  {countryList.map((element, index) => {
                    return (
                      <option className="w-16 h-40" key={index}>
                        {element}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  {inputItems.Phone[selectedLanguage]}
                </label>
                <div className="flex space-x-0">
                  <select
                    id="country"
                    name="country"
                    onChange={(e) => setTelephoneCode(e.target.value)}
                    autoComplete="country-name"
                    className="mt-1 block mr-4  pt-3 pb-2 px-2 border bg-white border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    {CodeTelList.map((element, index) => {
                      return (
                        <option className="w-16 h-40" key={index}>
                          {element.code}
                        </option>
                      );
                    })}
                  </select>
                  <input
                    type="number"
                    name="tel"
                    id="tel"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                    className="mt-1 block  w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 my-5 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                sentDataHandler();
              }}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {inputItems.SendButton[selectedLanguage]}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PricingForm;
