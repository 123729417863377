/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
// import { AdminApi } from '../../../../../api/admin.api';
import { ContentApi } from '../../../../../../api/content.api';
import { useAppContext } from '../../../../../../context/AppProvider';
import { useHistory } from 'react-router-dom';
export default function PositionChangerModal({
  showPositionChanger,
  setTopics,
  setShowPositionChanger,
  categoryId,
  logOut,
  topicList
}) {
  const { store, setShouldUpdate } = useAppContext();
  const [position, setPosition] = useState(1);
  const history = useHistory();
  const [error, setError] = useState('');
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const userToken = window.localStorage.getItem('userToken');
  useEffect(() => {
    if (showPositionChanger.position) {
      setPosition(showPositionChanger.position);
    }
  }, [showPositionChanger]);

  const closeModal = () => {
    setShowPositionChanger({
      show: false,
      topicId: null,
      categoryId: null
    });
  };

  function arrayMove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  const onSubmit = async () => {
    if (position < 1) {
      setError('Topic order cannot be under 0.');
      return;
    }
    if (position > 100) {
      setError('Topic order cannot be upper 100');
      return;
    }

    const topicsCopy = [...topicList];

    arrayMove(topicsCopy, showPositionChanger.position - 1, position - 1);

    const data = {
      categoryId,
      parentId: userData.userId,
      newTopicList: topicsCopy
    };

    const updatedCategoryCollection = await ContentApi.changeTopicPosition(
      userToken,
      data,
      history
    );
    setShouldUpdate(true);

    setShowPositionChanger({ show: false, topicId: null, categoryId: null });
  };

  return (
    <>
      {showPositionChanger.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className=" relative flex flex-col w-full  outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                    {/* <!-- Modal content --> */}
                    <div className="relative bg-white rounded-lg shadow ">
                      {/* <!-- Modal header --> */}
                      <div className="flex justify-end p-2">
                        <div className="flex w-full items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                          <h3 className="text-2xl font-semibold">
                            Change Topic Position
                          </h3>
                        </div>
                        {/* <button onClick={() => setShowPositionChanger({show: false, topicId: null,categoryId: null,})}
                                 type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="popup-modal">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>  
                                </button> */}
                      </div>
                      {/* <!-- Modal body --> */}
                      <div className="p-6 pt-0 text-center">
                        <div className="w-full h-28 flex flex-col items-start">
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Position
                          </label>
                          <input
                            onChange={(e) => setPosition(e.target.value)}
                            value={position}
                            type={'number'}
                            min={1}
                            max={100}
                            autoComplete="address-level2"
                            className={
                              'mt-1 block  w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            }
                          />
                        </div>
                        <div className={'w-full flex justify-end'}>
                          {error && (
                            <span className={' text-red-600 text-sm'}>
                              {error}
                            </span>
                          )}
                        </div>
                        <div className={'w-full flex justify-end'}>
                          <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => {
                              closeModal();
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => onSubmit()}
                            data-modal-toggle="popup-modal"
                            type="button"
                            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
