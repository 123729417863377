import React from 'react';
import { PricingData } from '../../../assets/mockData/mockData';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/AppProvider';

const PricingType = (props) => {
  const { store } = useAppContext();
  const { selectedLanguage } = store;

  const { index, period } = props;

  return (
    <div
      className="xlmin:w-full lg:w-7/12 my-10 mx-auto relative w-full lg:mt-0 mt-12 md:px-8"
      role="list"
    >
      <div
        role="presentation"
        className={'bg-white cursor-pointer shadow rounded-lg p-8 relative '}
      >
        <div className={'w-10 h-full  rounded-tl-md rounded-bl-md'} />

        <div className="md:flex items-center justify-between">
          <h2 className="text-2xl font-tinos font-semibold leading-6 text-gray-800">
            {PricingData[0].types[index].type[selectedLanguage]}
          </h2>
          <p className="text-2xl font-tinos font-semibold md:mt-0 mt-4 leading-6 text-gray-800">
            {period === 'm'
              ? PricingData[0].types[index].priceMonth
              : PricingData[0].types[index].priceYear}
          </p>
        </div>
        <p className="md:w-full font-gothic text-base leading-6 mt-4 text-gray-600">
          {PricingData[0].types[index].subText[selectedLanguage]}
        </p>
        <div className="flex justify-end">
          <Link
            to={'pricingForm'}
            className="font-gothic rounded-full flex self-center  bg-green-600 text-white pb-3 py-2 w-28   text-center  text-lg mt-6"
          >
            <p className="text-center w-full ">
              {PricingData[0].BuyButton[selectedLanguage]}
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PricingType;
