/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { menuItems } from './../assets/mockData/mockData';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MoreOptions = ({ options = [], onOptionSelect = () => {} }) => {
  return (
    <div>
      <Menu as="div" className=" relative inline-block text-left">
        <div>
          <Menu.Button className="flex justify-center place-items-center w-10 h-10  text-center bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 ">
            {/* <ChevronDownIcon className="mx-auto h-5 w-5" aria-hidden="true" /> */}
            <FontAwesomeIcon icon="ellipsis-v" size="1x" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            style={{ zIndex: 10000 }}
            className="origin-top-right overflow-hidden absolute right-0  mt-2 w-56  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          >
            {options.map((option, index) => {
              return (
                <div key={index + option.val} className="">
                  <div className="h-full ">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={() => onOptionSelect(option)}
                          className={classNames(
                            active
                              ? 'bg-gray-200 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          {option.txt}
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default MoreOptions;
