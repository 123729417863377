import * as THREE from 'three';

const changeLink = (room, link, self) => {
  link = link.parent.parent.children[1];
  const curLink = room
    .getObjectByName('groupForMesh')
    .getObjectByName(link.parent.name);

  if (link.parent.count === 3) {
    link.parent.count = 1;

    link.parent.clear();
    const firstPartGeo = new THREE.BoxGeometry(0.1, 0.03, 0.03);
    const firstPartMat = new THREE.MeshBasicMaterial({
      color: 0x00ff00
    });
    const plus = new THREE.Group();
    const firstPart = new THREE.Mesh(firstPartGeo, firstPartMat);
    const secondPartGeo = new THREE.BoxGeometry(0.03, 0.1, 0.03);
    const secondPartMat = new THREE.MeshBasicMaterial({
      color: 0x00ff00
    });
    const secondPart = new THREE.Mesh(secondPartGeo, secondPartMat);
    plus.add(firstPart, secondPart);
    // secondPart.rotateZ(Math.PI * 0.5);
    curLink.add(plus);
    self.plusArrays.push(plus);
    plus.position.set(0, 0, 0.1);
    const indexForPlus = +plus.name.split('plus')[1] + 1;
    plus.name = 'plus' + indexForPlus;
    curLink.add(link);
    // curLink.position.set(x, y, z);
  } else {
    link.parent.count++;
  }

  const count = curLink.count;

  for (let i = 0; i < count; i++) {
    const linkForMesh = link.clone();
    curLink.add(linkForMesh);
    if (link.rotationType === 'y') {
      if (count == 1) {
        linkForMesh.position.set(
          link.position.x,
          link.position.y,
          link.position.z
        );
      } else if (count == 2) {
        linkForMesh.position.set(
          link.position.x - 0.05 + 0.1,
          link.position.y,
          link.position.z
        );
      } else {
        linkForMesh.position.set(
          link.position.x - 0.05,
          link.position.y,
          link.position.z
        );
      }
      linkForMesh.rotationType = 'y';
    } else {
      if (count == 1) {
        linkForMesh.position.set(
          link.position.x,
          link.position.y,
          link.position.z
        );
      } else if (count == 2) {
        linkForMesh.position.set(
          link.position.x,
          link.position.y - 0.05 + 0.1,
          link.position.z
        );
      } else {
        linkForMesh.position.set(
          link.position.x,
          link.position.y - 0.05,
          link.position.z
        );
      }
      linkForMesh.rotationType = 'x';
    }

    linkForMesh.scale.x = 1;
    // linkForMesh.scale.y = 0.2;
    linkForMesh.scale.z = 1;

    linkForMesh.rotation.z = link.rotation.z;
  }

  self.flagForChangeLinkText = true;
  self.flagForLinks = false;
};
export default changeLink;
