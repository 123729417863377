import * as THREE from 'three';
import { colissionDetecter } from '../../../common/collisionDetector';

export const handleController = (controller, self) => {
  const axes = controller.gamepad?.axes;

  const speed = 0.03;

  let a = 0;
  let b = 0;
  if (self.model) {
    self.workingMatrix.identity().extractRotation(controller.matrixWorld);
    self.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    self.raycaster.ray.direction.set(0, 0, -1).applyMatrix4(self.workingMatrix);

    self.intersectsModel;
    //   let intersectsDroplet;
    let interationArray = [];
    //   if (self.props?.draggable || ) {
    if (self.tablet?.children.length > 0) {
      const tabletRefreshInstruction = self.tablet.getObjectByName(
        'tabletInstructionRefresh'
      ).children;
      const tabletDescription =
        self.tablet.getObjectByName('tabletDescription').children;
      interationArray = [
        ...self.tablet.children,
        ...tabletRefreshInstruction,
        ...tabletDescription
      ];
    }
    interationArray = [...interationArray, ...self.model.children];
    self.intersectsModel = self.raycaster.intersectObjects(
      interationArray,
      false
    );
    if (!controller.userData.attachedObject) {
      if (
        self.intersectsModel.length > 0 &&
        self.intersectsModel[0].object.visible &&
        !self.intersectsModel[0].object.text
      ) {
        controller.children[0].scale.z = self.intersectsModel[0].distance;
        self.lineLength = self.intersectsModel[0].distance;
        controller.children[1].visible = true;
        controller.children[1].position.x = controller.children[0].position.x;
        controller.children[1].position.z =
          -self.intersectsModel[0].distance + 0.02;
        controller.userData.selected = self.intersectsModel[0].object;
      } else {
        controller.children[0].scale.z = 10;
        controller.children[1].visible = false;
      }
    }
    if (controller.userData.selectPressed) {
      if (
        self.intersectsModel.length > 0 &&
        self.intersectsModel[0].object.visible &&
        !self.intersectsModel[0].object.text
      ) {
        if (self.intersectsModel[0]?.object?.name === 'instructionButton') {
          self.dolly.children[0].visible = false;
        } else if (
          !controller.userData.attachedObject &&
          (self.intersectsModel[0].object.name === 'Pippete' ||
            self.intersectsModel[0].object.name === 'Flask' ||
            self.intersectsModel[0].object.name === 'Refresh')
        ) {
          // self.intersectsModel[0].object.add(self.model.children);
          controller.children[0].scale.z = self.intersectsModel[0].distance;
          self.lineLength = self.intersectsModel[0].distance;
          controller.children[1].visible = true;
          controller.children[1].position.x = controller.children[0].position.x;
          controller.children[1].position.z = -self.intersectsModel[0].distance;
          controller.userData.selected = self.intersectsModel[0].object;
        }
      } else {
        controller.children[0].scale.z = 10;
        controller.children[1].visible = false;
      }
      if (controller.userData.attachedObject) {
        controller.children[1].visible = true;
        controller.children[0].scale.z = self.lineLength;

        if (controller.userData.attachedObject.name === 'Pippete') {
          self.model.remove(self.model.getObjectByName('1'));
          if (self.model.getObjectByName('2'))
            self.model.getObjectByName('2').visible = true;
          self.workingMatrixForAmoniac
            .identity()
            .extractRotation(controller.userData.attachedObject.matrixWorld);

          self.raycasterForAmoniac.ray.origin.setFromMatrixPosition(
            controller.userData.attachedObject.matrixWorld
          );
          self.raycasterForAmoniac.ray.direction
            .set(0, -0.1, 0)
            .applyMatrix4(self.workingMatrixForAmoniac);

          self.quaternionPippeteAmoniac = new THREE.Quaternion();
          self.posPippete = new THREE.Vector3();
          self.currentObejct = controller.userData.selected.clone();
          controller.userData.selected.localToWorld(self.posPippete);
          controller.userData.selected.getWorldQuaternion(
            self.quaternionPippeteAmoniac
          );

          if (controller.userData.selected) {
            if (
              colissionDetecter(
                controller.userData.selected,
                self.model.getObjectByName('Amoniac')
              )
            ) {
              if (
                controller.userData.squeezePressed &&
                controller.userData.selected &&
                controller.userData.selected.getObjectByName('pippeteAmoniac')
                  .scale.z < 0.2
              ) {
                setTimeout(() => {
                  controller.userData.selected.getObjectByName(
                    'pippeteAmoniac'
                  ).visible = true;

                  controller.userData.selected.getObjectByName(
                    'pippeteAmoniac'
                  ).scale.z += 0.01;
                }, [100]);
                self.flagForFullPippete = true;
                self.model.remove(self.model.getObjectByName('2'));
                self.model.getObjectByName('3').visible = true;
              }
            }
            if (
              controller.userData.squeezePressed &&
              controller.userData.selected.getObjectByName('topPippete').scale
                .x > 0.8
            ) {
              controller.userData.selected.getObjectByName(
                'topPippete'
              ).scale.x -= 0.01;
            } else if (
              !controller.userData.squeezePressed &&
              controller.userData.selected.getObjectByName('topPippete').scale
                .x < 1
            ) {
              controller.userData.selected.getObjectByName(
                'topPippete'
              ).scale.x += 0.01;
            }
            let droplet;
            if (controller.userData.selected.getObjectByName('Droplet')) {
              droplet = controller.userData.selected.getObjectByName('Droplet');
            } else {
              droplet = self.scene.getObjectByName('Droplet');
            }
            droplet.updateMatrixWorld();
            self.dropletCoords = new THREE.Vector3();
            self.dropletQuaternion = new THREE.Quaternion();
            droplet.localToWorld(self.dropletCoords);

            if (!self.scene.getObjectByName('DropletBackup')) {
              self.dropletBackup = droplet.clone();
              self.scene.add(self.dropletBackup);
              self.dropletBackup.name = 'DropletBackup';
              self.dropletBackup.position.set(
                self.dropletCoords.x,
                self.dropletCoords.y - 0.1,
                self.dropletCoords.z
              );
              self.pippetePos = controller.userData.selected.position;
            }

            self.glassPippete =
              controller.userData.selected.getObjectByName('glassPippete');
            self.workingMatrixForPippete
              .identity()
              .extractRotation(self.dropletBackup.matrixWorld);

            self.raycasterPippete.ray.origin.setFromMatrixPosition(
              self.dropletBackup.matrixWorld
            );
            self.raycasterPippete.ray.direction
              .set(0, -1, 0)
              .applyMatrix4(self.workingMatrixForPippete);

            self.intersectsDroplet = self.raycasterPippete.intersectObject(
              self.model
                .getObjectByName('Flask')
                .getObjectByName('flaksAmoniac'),
              false
            );
            if (
              self.intersectsDroplet.length > 0 &&
              controller.userData.squeezePressed
            ) {
              self.glassPippete.material.color.setHex(0x00ff00);
            } else {
              self.glassPippete.material.color.setHex(0xff0000);
            }

            if (controller.userData.selected) {
              if (
                !colissionDetecter(
                  controller.userData.selected,
                  self.model.getObjectByName('Amoniac')
                ) &&
                !controller.userData.squeezePressed &&
                self.flagForFullPippete &&
                self.dropletCounts < 5
              ) {
                self.scene
                  .getObjectByName('DropletBackup')
                  .position.lerp(
                    new THREE.Vector3(
                      self.dropletCoords.x,
                      -6,
                      self.dropletCoords.z
                    ),
                    self.intersectsDroplet.length > 0 ? 0.008 : 0.05
                  );
                self.dropletBackup.visible = true;

                // self.dropletBackup.position.y -= 0.1;
                if (
                  colissionDetecter(
                    self.dropletBackup,
                    self.model
                      .getObjectByName('Flask')
                      .getObjectByName('flaksAmoniac'),
                    true
                  )
                ) {
                  switch (self.dropletCounts) {
                    case 0:
                      self.model
                        .getObjectByName('Flask')
                        .getObjectByName('flaksAmoniac').material.opacity = 0.2;
                      self.dropletBackup.visible = false;

                      self.dropletCounts++;
                      self.dropletBackup.position.set(
                        self.dropletCoords.x,
                        self.dropletCoords.y - 0.1,
                        self.dropletCoords.z
                      );
                      controller.userData.selected.getObjectByName(
                        'pippeteAmoniac'
                      ).scale.z -= 0.05;

                      break;
                    case 1:
                      self.model
                        .getObjectByName('Flask')
                        .getObjectByName('flaksAmoniac').material.opacity = 0.4;
                      self.dropletBackup.visible = false;
                      self.dropletCounts++;
                      self.dropletBackup.position.set(
                        self.dropletCoords.x,
                        self.dropletCoords.y - 0.1,
                        self.dropletCoords.z
                      );
                      controller.userData.selected.getObjectByName(
                        'pippeteAmoniac'
                      ).scale.z -= 0.05;

                      break;
                    case 2:
                      self.model
                        .getObjectByName('Flask')
                        .getObjectByName('flaksAmoniac').material.opacity = 0.6;
                      self.dropletBackup.visible = false;

                      self.dropletCounts++;
                      self.dropletBackup.position.set(
                        self.dropletCoords.x,
                        self.dropletCoords.y - 0.1,
                        self.dropletCoords.z
                      );
                      controller.userData.selected.getObjectByName(
                        'pippeteAmoniac'
                      ).scale.z -= 0.05;

                      break;
                    case 3:
                      self.model
                        .getObjectByName('Flask')
                        .getObjectByName('flaksAmoniac').material.opacity = 0.8;
                      self.dropletBackup.visible = false;

                      self.dropletCounts++;
                      self.dropletBackup.position.set(
                        self.dropletCoords.x,
                        self.dropletCoords.y - 0.1,
                        self.dropletCoords.z
                      );
                      controller.userData.selected.getObjectByName(
                        'pippeteAmoniac'
                      ).scale.z -= 0.05;
                      break;
                    case 4:
                      self.model
                        .getObjectByName('Flask')
                        .getObjectByName('flaksAmoniac').material.opacity = 1;
                      self.model
                        .getObjectByName('Flask')
                        .getObjectByName('flaksAmoniac').visible = false;
                      self.model.getObjectByName(
                        'RefractionAmoniac'
                      ).visible = true;

                      self.dropletCounts++;
                      self.dropletBackup.visible = false;
                      self.dropletBackup.position.set(
                        self.dropletCoords.x,
                        self.dropletCoords.y - 0.1,
                        self.dropletCoords.z
                      );
                      controller.userData.selected.getObjectByName(
                        'pippeteAmoniac'
                      ).scale.z -= 0.05;
                      self.model.remove(self.model.getObjectByName('Acetic'));
                      if (!self.model.getObjectByName('Acetic'))
                        self.model.getObjectByName('parrent').visible = true;
                      break;
                    default:
                      break;
                  }
                }

                if (self.dropletBackup.position.y < -3) {
                  self.dropletCounts++;
                  self.dropletBackup.position.set(
                    self.dropletCoords.x,
                    self.dropletCoords.y - 0.1,
                    self.dropletCoords.z
                  );
                  // droplet.position.set(0, -1.9, 0);
                  // self.dropletBackup.position.set(
                  //   self.dropletCoords.x,
                  //   self.dropletCoords.y,
                  //   self.dropletCoords.z
                  // );
                  self.dropletBackup.visible = true;
                  controller.userData.selected.getObjectByName(
                    'pippeteAmoniac'
                  ).scale.z -= 0.05;
                }
              } else {
                self.dropletBackup.position.set(
                  self.dropletCoords.x,
                  self.dropletCoords.y - 0.1,
                  self.dropletCoords.z
                );
                self.dropletBackup.visible = false;
                // droplet.position.set(0, -1.9, 0);

                // self.dropletBackup.position.set(
                //   self.dropletCoords.x,
                //   self.dropletCoords.y,
                //   self.dropletCoords.z
                // );
                if (
                  controller.userData.selected.getObjectByName('pippeteAmoniac')
                    .scale.z < 0.1
                ) {
                  controller.userData.selected.getObjectByName(
                    'pippeteAmoniac'
                  ).visible = false;
                }
                if (self.dropletCounts >= 5) {
                  self.flagForFullPippete = false;
                  self.dropletCounts = 0;
                }
              }
            }
          }
        }
      }
    } else if (self.intersectsModel.length == 0) {
      controller.userData.attachedObject = undefined;
      controller.userData.selected = undefined;
      controller.children[0].scale.z = 10;
      controller.children[1].visible = false;
    }
  }
  //=================> movement <=================

  if (axes?.length) {
    if (axes[2] != 0) {
      a += axes[2];
    }
    if (axes[3] != 0) {
      b += axes[3];
    }
  }

  const quaternion = self.dolly.quaternion.clone();

  if (a !== 0 || b !== 0) {
    self.dolly.quaternion.copy(self.camera?.quaternion);
    self.dolly.translateZ(b * speed);
    self.dolly.translateX(a * speed);

    self.dolly.quaternion.copy(quaternion);
  }
};
