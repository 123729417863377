export const pageDown = (self) => {
  if (Math.round(self.counterForPagination) === 17) {
    self.prevPage = false;
    self.counterForPagination = 0;
    self.indexOfBlockerPage--;
  } else if (Math.round(self.counterForPagination) === 10) {
    self.indexOfVisiblePage--;
  }
  if (self.prevPage) {
    self.tabletGroups.forEach((element) => {
      element.position.z += 0.01;
    });
    self.counterForPagination++;
  }
};
