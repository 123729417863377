const solarSystemBk = require('./../img/adminPreviews/solarSystemBk.jpg');
const electricBk = require('./../img/adminPreviews/electicCircuitBk.png');
const chemicalBondBk = require('./../img/adminPreviews/chemichalBondBk.png');
const structureBk = require('./../img/adminPreviews/structureBk.jpg');
const coffeeBk = require('./../img/adminPreviews/coffeeBk.jpg');
const substancesBk = require('./../img/adminPreviews/substancesBk.jpg');
const chlorophyllBk = require('./../img/adminPreviews/chlorophyllBk.jpg');
const dnaBk = require('./../img/adminPreviews/dnaBk.jpg');
const co2Bk = require('./../img/adminPreviews/co2Bk.jpg');
const atomBk = require('./../img/adminPreviews/atomBk.png');
const eyeAnatomyBk = require('./../img/adminPreviews/eyeAnatomyBk.jpg');
const generalBiologyBk = require('./../img/adminPreviews/generalBiologyBk.jpg');
const humanCellBk = require('./../img/adminPreviews/humanCellBk.jpg');
const AnatomyBk = require('./../img/adminPreviews/AnatomyBk.jpeg');
const celularOrgBk = require('./../img/adminPreviews/celularOrgBk.jpg');
const chemReactionBk = require('./../img/adminPreviews/chemReactionBk.jpg');
const chemInteractionBk = require('./../img/adminPreviews/chemInteractionBk.jpg');
const nonmetalsBk = require('./../img/adminPreviews/nonmetalsBk.jpg');
const metalsBk = require('./../img/adminPreviews/metalsBk.jpg');
const systematicsBk = require('./../img/adminPreviews/systematicsBk.jpg');
const spaceMechBk = require('./../img/adminPreviews/spaceMechBk.jpg');
const starsBk = require('./../img/adminPreviews/starsBk.jpg');
const physicsAstronomyBk = require('./../img/adminPreviews/physicsAstronomyBk.jpg');
const practicalAstronomyBk = require('./../img/adminPreviews/practicalAstronomyBk.jpg');
const inorganicSubstancesBk = require('./../img/adminPreviews/inorganicSubstancesBk.png');
const chemBondBk = require('./../img/adminPreviews/chemBondsBk.png');
const hydrocarburesBk = require('./../img/adminPreviews/hydrocarburesBk.png');
const organycHydrocarbsBk = require('./../img/adminPreviews/organycHydrocarbsBk.png');
const orgnaycSubstBk = require('./../img/adminPreviews/orgnaycSubstBk.png');
const AldehidsBk = require('./../img/adminPreviews/AldehidsBk.png');
const organycCompoundsBk = require('./../img/adminPreviews/organycCompoundsBk.png');
const GeneralizationOrganicChemBk = require('./../img/adminPreviews/GeneralizationOrganicChemBk.png');

export const scenesData = [
  {
    sceneName: { EN: 'Solar System', RO: 'Sistemul Solar' },
    sceneAvatar: solarSystemBk,
    sceneId: 'solarSystem',
    isSpecialModule: true,
    path: 'astronomy'
  },

  {
    sceneName: { EN: 'Photosyntes', RO: 'Fotosinteza' },
    sceneAvatar: chemicalBondBk,
    sceneId: 'basesOfOrganicChemistry',
    isSpecialModule: true,
    path: 'chemistry'
  },
  {
    sceneName: {
      EN: 'Chemistry - the scientific ...',
      RO: 'Chimia - stiinte ...'
    },
    sceneAvatar: structureBk,
    sceneId: 'structure_and_composition.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: { EN: 'Coffeeine molecule', RO: 'Molecula de cofeina' },
    sceneAvatar: coffeeBk,
    sceneId: 'theobromine.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: {
      EN: 'Chemichal Substances structure',
      RO: 'Compozitia si structura substantei'
    },
    sceneAvatar: substancesBk,
    sceneId: 'study_of_substances1.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: { EN: 'Chlorophyll molecule', RO: 'Molecula de clorofila' },
    sceneAvatar: chlorophyllBk,
    sceneId: 'chlorophyll.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: { EN: 'Human DNA', RO: 'ADN-ul Uman' },
    sceneAvatar: dnaBk,
    sceneId: 'dna.glb',
    isSpecialModule: false,
    path: 'biology'
  },
  {
    sceneName: {
      EN: 'Carbon Dioxide Molecule',
      RO: 'Molecula de dioxid de carbon'
    },
    sceneAvatar: co2Bk,
    sceneId: 'co2.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: { EN: 'Atom structure', RO: 'Structura atomului' },
    sceneAvatar: atomBk,
    sceneId: 'atom_planetar_model.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  // {
  //     sceneName: 'Periodic Table',
  //     sceneAvatar: basicBk,
  //     sceneId: 'basic',
  //     isSpecialModule: false,
  // },
  {
    sceneName: { EN: 'Eye Anatomy', RO: 'Anatomia ochiului' },
    sceneAvatar: eyeAnatomyBk,
    sceneId: 'eyeAnatomy2.glb',
    isSpecialModule: false,
    path: 'biology'
  },
  {
    sceneName: { EN: 'General Biology', RO: 'Biologia ca stiinta' },
    sceneAvatar: generalBiologyBk,
    sceneId: 'biology.glb',
    isSpecialModule: false,
    path: 'biology'
  },
  {
    sceneName: { EN: 'Human Cell', RO: 'Celula umana' },
    sceneAvatar: humanCellBk,
    sceneId: 'humanCell.glb',
    isSpecialModule: false,
    path: 'biology'
  },
  {
    sceneName: { EN: 'Sensory Receptor', RO: 'Receptia senzoriala' },
    sceneAvatar: AnatomyBk,
    sceneId: 'Anatomy6.glb',
    isSpecialModule: false,
    path: 'biology'
  },
  {
    sceneName: {
      EN: 'Celular Organization',
      RO: 'Celula , unitate morfofunctionala'
    },
    sceneAvatar: celularOrgBk,
    sceneId: 'CelularOrg.glb',
    isSpecialModule: false,
    path: 'biology'
  },
  {
    sceneName: { EN: 'Chemical Reaction', RO: 'Reactiile Chimice' },
    sceneAvatar: chemReactionBk,
    sceneId: 'chemReaction.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: { EN: 'Chemical Interaction', RO: 'Interactiuni Chimice' },
    sceneAvatar: chemInteractionBk,
    sceneId: 'chemBond.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: { EN: 'Nonmetals', RO: 'Nemetale' },
    sceneAvatar: nonmetalsBk,
    sceneId: 'Nonmetals.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: { EN: 'Electric Circuit', RO: 'Circuit Electric' },
    sceneAvatar: electricBk,
    sceneId: 'electriccircuit',
    isSpecialModule: true,
    path: 'physics'
  },
  {
    sceneName: { EN: 'Metals', RO: 'Metalele' },
    sceneAvatar: metalsBk,
    sceneId: 'Metals__.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: {
      EN: 'Systematics of organism',
      RO: 'Sistematica organismelor'
    },
    sceneAvatar: systematicsBk,
    sceneId: 'Systematics.glb',
    isSpecialModule: false,
    path: 'biology'
  },
  {
    sceneName: { EN: 'Celestial Mechanics', RO: 'Mecanica Cereasca' },
    sceneAvatar: spaceMechBk,
    sceneId: 'spaceMech.glb',
    isSpecialModule: false,
    path: 'astronomy'
  },
  {
    sceneName: { EN: 'Stars', RO: 'Stelele' },
    sceneAvatar: starsBk,
    sceneId: 'stars.glb',
    isSpecialModule: false,
    path: 'astronomy'
  },
  {
    sceneName: {
      EN: 'Astronomy in the context of physics',
      RO: 'Astronomia in contextul fizicii'
    },
    sceneAvatar: physicsAstronomyBk,
    sceneId: 'physicsAstronomy.glb',
    isSpecialModule: false,
    path: 'astronomy'
  },
  {
    sceneName: {
      EN: 'Practical Astronomy',
      RO: 'Elemente de astronomie practica'
    },
    sceneAvatar: practicalAstronomyBk,
    sceneId: 'practicalAstronomy.glb',
    isSpecialModule: false,
    path: 'astronomy'
  },
  {
    sceneName: {
      EN: 'Inorganic substances in the life of society',
      RO: 'Substantele anorganice in viata societatii'
    },
    sceneAvatar: inorganicSubstancesBk,
    sceneId: 'InorganicSubstances.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: {
      EN: 'Oxygen-containing derivatives Hydrocarbons',
      RO: 'Derivati care contin oxigen Hidrocarburi'
    },
    sceneAvatar: organycHydrocarbsBk,
    sceneId: 'derivativesHydrocarbs',
    isSpecialModule: true,
    path: 'chemistry'
  },
  {
    sceneName: {
      EN: 'Chemical bonds',
      RO: 'Legături chimice'
    },
    sceneAvatar: chemBondBk,
    sceneId: 'chemicalBond',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: {
      EN: 'Hydrocarbons - a component of natural resources',
      RO: 'Hidrocarburile - componenta  a resurselor naturale'
    },
    sceneAvatar: hydrocarburesBk,
    sceneId: 'Hidrocarbures.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: {
      EN: 'Organic substances in everyday life',
      RO: 'Substante organice in viata de zi cu zi'
    },
    sceneAvatar: orgnaycSubstBk,
    sceneId: 'organycSubstances',
    isSpecialModule: true,
    path: 'chemistry'
  },
  {
    sceneName: {
      EN: 'Aldehids. Carboxylic acids. Esters',
      RO: 'Aldehide. Acizi carboxilici. Esteri'
    },
    sceneAvatar: AldehidsBk,
    sceneId: 'Aldehids',
    isSpecialModule: true,
    path: 'chemistry'
  },
  {
    sceneName: {
      EN: 'Organic compounds of vital importance and industrial',
      RO: 'Compusi organici de importanta vitala si industriala'
    },
    sceneAvatar: organycCompoundsBk,
    sceneId: 'OrganicCompunds.glb',
    isSpecialModule: false,
    path: 'chemistry'
  },
  {
    sceneName: {
      EN: 'Generalization of organic chemistry course',
      RO: 'Generalizarea cursului de chimie organica'
    },
    sceneAvatar: GeneralizationOrganicChemBk,
    sceneId: 'Dicloropentan',
    isSpecialModule: true,
    path: 'chemistry'
  }
];
