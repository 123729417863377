import * as THREE from 'three';

export default function createCo2(textMeshC, textMeshO, objectsGroup) {
  const geometry = new THREE.BoxGeometry(3, 29, 3);
  const CO2Box = new THREE.Mesh(geometry, {});

  CO2Box.position.set(-10, -13, 0);

  const carbonGeometry = new THREE.SphereGeometry(2, 30, 30);
  const carbonMaterial = new THREE.MeshStandardMaterial({
    color: 0x2980b9,
    side: THREE.DoubleSide
  });
  const carbon = new THREE.Mesh(carbonGeometry, carbonMaterial);
  carbon.position.set(0, -12, 0);
  carbon.name = 'CO2-1';

  const oxygenGeometry = new THREE.SphereGeometry(1, 30, 30);
  const oxygenMaterial = new THREE.MeshStandardMaterial({
    color: 0xe74c3c,
    side: THREE.DoubleSide
  });
  const oxygen = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
  oxygen.add(textMeshO);
  textMeshO.position.set(-0.4, -0.4, 1);
  const oxygenCopy = oxygen.clone();
  const textMeshOCopy = textMeshO.clone();
  oxygenCopy.add(textMeshOCopy);
  textMeshOCopy.position.set(-0.4, -0.4, 1);

  carbon.add(textMeshC);
  textMeshC.position.set(-0.5, -1, 2);
  carbon.add(oxygen);
  oxygen.position.set(-2.5, 0, 0);
  carbon.add(oxygenCopy);
  oxygenCopy.position.set(2.5, 0, 0);

  for (let i = 1; i <= 5; i++) {
    const carbonCopy = new THREE.Mesh(carbonGeometry, carbonMaterial);
    const oxygenCopy2 = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
    const firstTextMeshO = textMeshO.clone();
    oxygenCopy2.add(firstTextMeshO);
    firstTextMeshO.position.set(-0.5, -0.5, 1);
    const textMeshCCopy = textMeshC.clone();
    const oxygenClone = new THREE.Mesh(oxygenGeometry, oxygenMaterial);
    const secondTextMeshO = firstTextMeshO.clone();
    oxygenClone.add(secondTextMeshO);
    secondTextMeshO.position.set(-0.4, -0.4, 1);
    carbonCopy.name = `CO2-${i + 1}`;
    carbonCopy.add(oxygenCopy2);
    carbonCopy.add(textMeshCCopy);
    textMeshCCopy.position.set(-0.5, -0.5, 2);
    oxygenCopy2.position.set(-2.5, 0, 0);
    carbonCopy.add(oxygenClone);
    oxygenClone.position.set(2.5, 0, 0);
    carbonCopy.position.set(0, i * 5, 0);

    carbon.add(carbonCopy);
  }

  CO2Box.add(carbon);
  objectsGroup.add(CO2Box);
  CO2Box.name = 'CO2Box';
}
