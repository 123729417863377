/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import MoreOptions from '../../../common/moreOptions';
import EditUserModal from './userManagement/userEditModal';
import UserDeleteConfirmationModal from './userManagement/userDeleteConfirmation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserRow = ({
  firstname,
  lastname,
  status,
  role,
  email,
  phone,
  _id,
  setShowModal,
  setDeleteConfirmation,
  values,
  setShowAddChildModal,
  children,
  setShouldUpdate
}) => {
  const [options, setOptions] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [showChildModal, setShowChildModal] = React.useState({
    show: false,
    mode: 'add',
    user: null
  });

  const [deleteChildConfirmation, setDeleteChildConfirmation] = React.useState({
    show: false,
    userId: ''
  });

  useEffect(() => {
    if (values.length) {
      if (role === 'school') {
        setOptions([...values, { txt: 'Add Children', val: 3 }]);
      } else {
        setOptions([...values]);
      }
    }
  }, [values]);

  const onChildMoreOptionSelect = (selectedOption, child) => {
    switch (selectedOption.val) {
      case 1: {
        setShowChildModal({
          show: true,
          mode: 'edit',
          user: {
            firstname: child.firstname,
            lastname: child.lastname,
            status: 'Active',
            role: 'leaner',
            email: child.login,
            userId: child._id
          }
        });
        return;
      }
      case 2: {
        setDeleteConfirmation({
          show: true,
          userId: child._id
        });
        return;
      }

      default:
        return;
    }
  };

  const onMoreOptionSelect = (selectedOption) => {
    switch (selectedOption.val) {
      case 1: {
        setShowModal({
          show: true,
          mode: 'edit',
          user: {
            firstname,
            lastname,
            status,
            role,
            email,
            userId: _id,
            phone: phone
          }
        });
        return;
      }
      case 2: {
        setDeleteConfirmation({
          show: true,
          userId: _id
        });
        return;
      }
      case 3: {
        setShowAddChildModal({
          show: true,
          parentId: _id
        });
        return;
      }

      default:
        return;
    }
  };

  return (
    <div
      onClick={() => setToggle((prevState) => !prevState)}
      className={'w-full flex place-items-center border-t flex-col '}
    >
      <UserDeleteConfirmationModal
        deleteConfirmation={deleteChildConfirmation}
        setShouldUpdate={setShouldUpdate}
        setDeleteConfirmation={setDeleteChildConfirmation}
      />
      <EditUserModal
        showModal={showChildModal}
        setShouldUpdate={setShouldUpdate}
        mode={'edit'}
        setShowModal={setShowChildModal}
      />
      <div
        className={`w-full flex place-items-center justify-around border-t ${
          role === 'school' &&
          children?.length &&
          'hover:bg-emerald-500 cursor-pointer'
        }`}
      >
        {Boolean(children.length) && (
          <div className={'w-1'}>
            <span>
              <FontAwesomeIcon
                icon="arrow-down"
                size="1x"
                className={` ml-1 ${toggle && 'rotate-180'}`}
              />
            </span>
          </div>
        )}
        <div className={'flex justify-center text-center w-1/6'}>
          {firstname}
        </div>
        <div className={'flex justify-center text-center w-1/6'}>
          {lastname}
        </div>
        <div className={'flex justify-center text-center w-1/6'}>{email}</div>
        <div className={'flex justify-center text-center w-1/6'}>{role}</div>
        <div className={'flex justify-center text-center w-1/6'}>{status}</div>
        <div className={'flex justify-center text-center w-1/6 cursor-pointer'}>
          <MoreOptions options={options} onOptionSelect={onMoreOptionSelect} />
        </div>
      </div>

      {toggle &&
        children.map((child) => {
          return (
            <div
              key={child._id}
              className={
                'w-full flex place-items-center border-2 justify-around border-t'
              }
            >
              <div className={'flex justify-center text-sm text-center w-1/6'}>
                {child.firstname}
              </div>
              <div className={'flex justify-center text-sm text-center w-1/6'}>
                {child.lastname}
              </div>
              <div className={'flex justify-center text-sm text-center w-1/6'}>
                {child.login}
              </div>
              <div className={'flex justify-center text-sm text-center w-1/6'}>
                learner
              </div>
              <div className={'flex justify-center text-sm text-center w-1/6'}>
                Active
              </div>
              <div
                className={
                  'flex justify-center text-center w-1/6 cursor-pointer'
                }
              >
                <MoreOptions
                  options={options.filter((el) => el.val !== 3)}
                  onOptionSelect={(e) => onChildMoreOptionSelect(e, child)}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default UserRow;
