export const instruction = [
  {
    EN: 'Drag whatever chemical element (using the squeeze button) \n from the periodic table and put it into the wireframed box',
    RO: 'Trageți orice element chimic (folosind butonul squeeze) \n din tabelul periodic și puneți-l în cutia ',
    modelCoordsX: -1.3,
    modelCoordsY: 0.01,
    modelCoordsZ: -3,
    modelSize: 0.08,
    modelHeight: 0.01,
    name: 'DragDrop'
  },
  {
    EN: 'Drag  another  chemical element (using the squeeze button) \n from the periodic table and put it into the neighbor wireframed box',
    RO: 'Trageți un alt element chimic (folosind butonul de apăsare) din tabelul periodic și puneți-l în caseta vecină ',
    modelCoordsX: 0.5,
    modelCoordsY: -0.5,
    modelCoordsZ: -3,
    modelSize: 0.08,
    modelHeight: 0.01,
    name: 'NeighbourDragDrop'
  },
  {
    EN: 'If you wish to  change the number of links  \n press on the green plus with squeeze button',
    RO: 'Dacă doriți să schimbați numărul de link-uri  \n apăsați pe plus verde cu butonul squeeze',
    modelCoordsX: 1,
    modelCoordsY: 0.0001,
    modelCoordsZ: -3,
    modelSize: 0.08,
    modelHeight: 0.01,
    name: 'ChangeLinkNum'
  },
  {
    EN: '+',
    RO: '+',
    modelCoordsX: 2,
    modelCoordsY: 3,
    modelCoordsZ: -3,
    modelRotationZ: Math.PI * 0.3,
    modelRotationX: 0.5,
    modelRotationY: -0.5,
    color: 0xff0000,
    modelSize: 0.3,
    modelHeight: 0.1,
    bevel: true,
    name: 'delete',
    visible: 'false'
  }
];
