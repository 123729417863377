import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const CategoryCard = (props) => {
  let { path } = useRouteMatch();
  const { courseName, cardDescription, courseBackground, coursePath } = props;

  const [shortDescription, setShortDescription] = useState('');
  const { pageNum } = useParams();
  useEffect(() => {
    if (cardDescription) {
      if (cardDescription.length > 100) {
        const trimmedDesc = cardDescription.slice(0, 100) + '...';
        setShortDescription(trimmedDesc);
      } else {
        setShortDescription(cardDescription);
      }
    }
  }, [cardDescription]);
  return (
    <Link
      to={`${path}/${coursePath}/page=${pageNum ? pageNum : '1'}`}
      className={`columns-12 shadow-2xl my-2 h-72 w-72 rounded-3xl ${courseBackground} flex-grow bg-top bg-no-repeat bg-cover flex flex-col justify-end overflow-hidden ease-linear duration-300 transition-all hover:scale-105 `}
    >
      <div
        className={
          'w-full h-4/12 bg-gradient-to-t bg-opacity-0 from-dark to-transparent px-5 py-3'
        }
      >
        <p
          className={
            'text-justify text-2xl tracking-widest font-bold text-white'
          }
        >
          {courseName}
        </p>
        <span className={'text-sm font-medium text-slate-100'}>
          {shortDescription}
        </span>
      </div>
    </Link>
  );
};

export default CategoryCard;
